import { useCallback, useContext, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { Button } from 'primereact/button';
import { SelectedClientHeader } from './SelectedClientHeader';

import { SalesTarget } from '../../../typings/salesTarget';

import { ModalClientSelected } from './ModalClientSelected';
import { ClientSalesGoal } from '../contexts/SalesTargetContextInterface';
import { SalesTargetProvider } from '../contexts/SalesTargetContextt';

import './SelectedClientTarget.scss';

export interface SelectedClientTargetProps {
  mainSalesTarget: SalesTarget | null | undefined;
}

export const SelectedClientTarget = (props: SelectedClientTargetProps) => {
  const { selectedClientSalesTarget, selectedClient, setToggleView } =
    useContext(SalesTargetProvider);
  const { mainSalesTarget } = props;

  const [displayClientTarget, setDisplayClientTarget] = useState(false);

  const renderClient = useCallback(
    (client: ClientSalesGoal | null) => {
      if (client) {
        return (
          <div className="selected-client-target">
            <ModalClientSelected
              displayModal={displayClientTarget}
              setDisplayModal={setDisplayClientTarget}
              client={client}
              selectedClientSalesTarget={selectedClientSalesTarget}
              mainSalesTarget={mainSalesTarget ? mainSalesTarget.id : ''}
            />
            <div className="header">
              <Button
                className="button"
                onClick={() => {
                  if (setToggleView) {
                    setToggleView('CLIENTS');
                  }
                }}
              >
                Voltar
              </Button>
              <div>
                <Button
                  className="button"
                  onClick={() => {
                    setDisplayClientTarget(!displayClientTarget);
                  }}
                >
                  <FaEdit />
                </Button>
              </div>
            </div>
            <div className="info">
              <SelectedClientHeader
                client={client}
                percentage={
                  selectedClientSalesTarget
                    ? `${(Number.isNaN(selectedClientSalesTarget.percentage)
                        ? 0
                        : selectedClientSalesTarget.percentage
                      ).toFixed(2)}%`
                    : 'NÃO CRIADO'
                }
              />
            </div>
          </div>
        );
      }
      return <div>Sem Dados</div>;
    },
    [
      selectedClient,
      selectedClientSalesTarget,
      displayClientTarget,
      mainSalesTarget,
    ],
  );

  return renderClient(selectedClient!);
};
