// Importações Padrões
import React, { useContext } from 'react';

// MaskBr
import { maskBr } from 'js-brasil';

// Moment
import moment from 'moment';
import 'moment/locale/pt-br';

// React Prime
import { Button } from 'primereact/button';

// Icones
import { FaRegAddressCard, FaRegMoneyBillAlt } from 'react-icons/fa';
import { BsInfoCircle } from 'react-icons/bs';
import { BiDetail } from 'react-icons/bi';

// Componentes
import NewInputFormSimple from '../input/newInputFormSimple.component';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import { IEditOrder } from '../../typings/order.types';

interface IFormOrderInfoProps {
  visible: IEditOrder;
}

const FormOrderInfo = (props: IFormOrderInfoProps) => {
  const { visible } = props;

  const { windowWidth, system3cData } = useContext(Auth);

  const sprint = () => {
    const url = `https://us-central1-erp-cansho.cloudfunctions.net/pdfGenerator/budgetFDV/${visible.item.id}`;
    window.open(url, '_blank');
  };

  return (
    <>
      {windowWidth && windowWidth > 0 && windowWidth <= 900 && (
        <>
          <div className="width100 p-mb-2">
            <Button
              className="p-py-1 button-order-responsive-modal"
              label="Imprimir"
              onClick={() => sprint()}
            />
          </div>
          <div className="width100 p-mb-3">
            <Button
              className="p-py-1 button-product-responsive-modal"
              label="Ir para Produtos"
            />
          </div>
        </>
      )}
      <div className="p-d-flex">
        <div className="p-d-flex p-mr-2 settings-icon-background">
          <BsInfoCircle className="p-my-auto p-mx-auto settings-icon " />
        </div>
        <span className="p-my-auto">Informações do Pedido</span>
      </div>
      <div className="p-grid">
        <div className="p-col-12 p-md-4 p-lg-4">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.orderNumber
                ? String(visible.item.orderNumber)
                : 'Não foi Encontrado'
            }
            fieldLabel="Número do Pedido"
          />
        </div>
        <div className="p-col-12 p-md-4 p-lg-4">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={visible.item.isBonus ? 'SIM' : 'NÃO'}
            fieldLabel="Bonificação"
          />
        </div>
        <div className="p-col-12 p-md-4 p-lg-4">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.user_commission
                ? maskBr.currency(
                    (visible.item.total *
                      (visible.item.user_commission / 100)) /
                      100,
                  )
                : 'Não Possui'
            }
            fieldLabel="Comissão do Vendedor"
          />
        </div>
      </div>
      <div className="p-d-flex p-mt-2">
        <div className="p-d-flex p-mr-2 settings-icon-background">
          <BiDetail className="p-my-auto p-mx-auto settings-icon " />
        </div>
        <span className="p-my-auto">Detalhes do Pedido</span>
      </div>
      <div className="p-grid">
        <div className="p-col-12 p-md-4 p-lg-4">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.client_name
                ? visible.item.client_name
                : 'Não foi Encontrado'
            }
            fieldLabel="Nome do Cliente"
          />
        </div>
        <div className="p-col-12 p-md-4 p-lg-4">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.created_at
                ? String(moment(visible.item.created_at).format('L'))
                : 'Não foi Encontrado'
            }
            fieldLabel="Data de Criação"
          />
        </div>
        <div className="p-col-12 p-md-4 p-lg-4">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.user_name
                ? visible.item.user_name
                : 'Não foi Encontrado'
            }
            fieldLabel="Nome do Vendedor"
          />
        </div>
      </div>
      <div className="p-grid">
        <div className="p-col-12 p-md-4 p-lg-4">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.list_name
                ? visible.item.list_name
                : 'Não foi Encontrado'
            }
            fieldLabel="Nome da Lista"
          />
        </div>
        <div className="p-col-12 p-md-4 p-lg-4">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.province_name
                ? visible.item.province_name
                : 'Não foi Encontrado'
            }
            fieldLabel="Nome do Estado"
          />
        </div>
        <div className="p-col-12 p-md-4 p-lg-4">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.city_name
                ? visible.item.city_name
                : 'Não foi Encontrado'
            }
            fieldLabel="Nome da Cidade"
          />
        </div>
      </div>
      <div className="p-d-flex p-mt-2">
        <div className="p-d-flex p-mr-2 settings-icon-background">
          <FaRegMoneyBillAlt className="p-my-auto p-mx-auto settings-icon " />
        </div>
        <span className="p-my-auto">Informações de Pagamento</span>
      </div>
      <div className="p-grid">
        <div
          className={
            system3cData?.fdvSystemServices
              ? 'p-col-12 p-md-4 p-lg-4'
              : 'p-col-12 p-md-6 p-lg-6'
          }
        >
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.sumProduct
                ? maskBr.currency(visible.item.sumProduct / 100)
                : maskBr.currency(0)
            }
            fieldLabel="Total em Produtos"
          />
        </div>
        {system3cData?.fdvSystemServices && (
          <div className="p-col-12 p-md-4 p-lg-4">
            <NewInputFormSimple
              pleaceHolder="Não foi Encontrado"
              disabled
              item={
                visible.item.sumService
                  ? maskBr.currency(visible.item.sumService / 100)
                  : maskBr.currency(0)
              }
              fieldLabel="Total em Serviços"
            />
          </div>
        )}
        <div
          className={
            system3cData?.fdvSystemServices
              ? 'p-col-12 p-md-4 p-lg-4'
              : 'p-col-12 p-md-6 p-lg-6'
          }
        >
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.deliveryPrice
                ? maskBr.currency(visible.item.deliveryPrice / 100)
                : maskBr.currency(0)
            }
            fieldLabel="Valor do Frete"
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.total
                ? maskBr.currency(visible.item.total / 100)
                : maskBr.currency(0)
            }
            fieldLabel="Total"
          />
        </div>
        {visible.item.paymentOrder ? (
          <React.Fragment>
            {visible.item.paymentOrder.map((payment, index) => {
              return (
                <div className="p-col-12 p-md-6 p-lg-6" key={String(index)}>
                  <NewInputFormSimple
                    pleaceHolder="Não foi Encontrado"
                    disabled
                    item={payment.payment_name}
                    fieldLabel="Forma de Pagamento"
                  />
                </div>
              );
            })}
          </React.Fragment>
        ) : (
          <div className="p-col-12 p-md-6 p-lg-6">
            <NewInputFormSimple
              pleaceHolder="Não foi Encontrado"
              disabled
              item="Nenhuma Forma de Pagamento"
              fieldLabel="Forma de Pagamento"
            />
          </div>
        )}
      </div>
      <div className="p-d-flex p-mt-2">
        <div className="p-d-flex p-mr-2 settings-icon-background">
          <FaRegAddressCard className="p-my-auto p-mx-auto settings-icon " />
        </div>
        <span className="p-my-auto">Informações de Entrega</span>
      </div>
      <div className="p-grid">
        <div className="p-col-12 p-md-3 p-lg-3">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.freight_name
                ? String(visible.item.freight_name)
                : 'Não foi Encontrado'
            }
            fieldLabel="Frete"
          />
        </div>
        <div className="p-col-12 p-md-3 p-lg-3">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.address
                ? String(visible.item.address.postalCode)
                : 'Não foi Encontrado'
            }
            fieldLabel="CEP"
          />
        </div>
        <div className="p-col-12 p-md-3 p-lg-3">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.address
                ? String(visible.item.address.city)
                : 'Não foi Encontrado'
            }
            fieldLabel="Cidade"
          />
        </div>
        <div className="p-col-12 p-md-3 p-lg-3">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.address
                ? String(visible.item.address.province)
                : 'Não foi Encontrado'
            }
            fieldLabel="Estado"
          />
        </div>
      </div>
      <div className="p-grid p-mb-3">
        <div className="p-col-12 p-md-4 p-lg-4">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.address
                ? String(visible.item.address.neighborhood)
                : 'Não foi Encontrado'
            }
            fieldLabel="Bairro"
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.address
                ? String(visible.item.address.street)
                : 'Não foi Encontrado'
            }
            fieldLabel="Rua"
          />
        </div>
        <div className="p-col-12 p-md-2 p-lg-2">
          <NewInputFormSimple
            pleaceHolder="Não foi Encontrado"
            disabled
            item={
              visible.item.address
                ? String(visible.item.address.houseNumber)
                : 'Não foi Encontrado'
            }
            fieldLabel="Número"
          />
        </div>
      </div>
    </>
  );
};

export default FormOrderInfo;
