// Importações Padrões
import firebase from 'firebase';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Types
import { IManufacturer } from '../typings/manufacturer.types';

// Componentes
import {
  toasterError,
  toasterSuccess,
} from '../components/global/toaster.component';

// Funções
import { deleteImage } from '../functions/image.function';

const manufacturerCollection = firebaseConfig
  .firestore()
  .collection('FDV-manufacturer');

async function addManufacturer(data: Partial<IManufacturer>) {
  try {
    const response = await manufacturerCollection.add({
      ...data,
      created_at: firebase.firestore.Timestamp.now(),
      updated_at: firebase.firestore.Timestamp.now(),
    });
    if (response.id) {
      toasterSuccess('CRIAR', `Fabricante ${data.name} Criada`);
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua fabricante!');
    return false;
  }
}

async function editManufacturer(data: Partial<IManufacturer>, id: string) {
  try {
    const dataDBExists = await manufacturerCollection.doc(id).get();
    if (!dataDBExists) {
      return [];
    }
    await manufacturerCollection.doc(id).update(data);
    toasterSuccess('EDITAR', `Fabricante ${data.name} Editada!`);
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua fabricante!');
    return false;
  }
}

async function searchManufacturer(id: string) {
  try {
    const data = await manufacturerCollection.doc(id).get();
    return <IManufacturer>{ ...data.data(), id };
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Não foi possível ler os dados.');
    return null;
  }
}

async function deleteManufacturer(id: string, name: string) {
  try {
    const response = await manufacturerCollection.doc(id).get();
    if (response.data()) {
      const item = response.data() as IManufacturer;
      deleteImage(item.imageHeavy);
    }
    await manufacturerCollection.doc(id).delete();
    toasterSuccess('APAGAR', `Fabricante ${name} Apagada!`);
    return true;
  } catch (err) {
    console.log(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua fabricante!');
    return false;
  }
}

export {
  manufacturerCollection,
  addManufacturer,
  editManufacturer,
  searchManufacturer,
  deleteManufacturer,
};
