import { useContext, useEffect, useState } from 'react';

import { Auth } from '../../../services/auth.context';
import { DayOrders } from '../../../typings/dayOrders';
import { code3cCollection } from '../../../controllers/code3c.controller';
import { useSalesTarget } from './useSalesTarget';

export interface UseDayOrders {
  listDayOrders: Array<DayOrders>;
  pending: boolean;
}

export const useDayOrders = (): UseDayOrders => {
  const { code3cData } = useContext(Auth);
  const { mainSalesTarget } = useSalesTarget();

  const [listDayOrders, setListDayOrders] = useState<Array<DayOrders>>([]);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    if (mainSalesTarget) {
      const unsubscribe = code3cCollection
        .doc(code3cData!.id)
        .collection('FDV-shard-dayOrders')
        .where('dayOrdersDate', '>=', mainSalesTarget.startDate)
        .where('dayOrdersDate', '<=', mainSalesTarget.endDate)
        .onSnapshot((response) => {
          const list: Array<DayOrders> = [];
          response.docs.forEach((doc) => {
            const documentData = {
              ...doc.data(),
            } as DayOrders;
            list.push(documentData);
          });
          setListDayOrders(list);
        });
      return () => {
        unsubscribe();
        setTimeout(() => {
          setPending(false);
        }, 400);
      };
    }
    return () => {
      setPending(false);
    };
  }, [mainSalesTarget]);

  return { listDayOrders, pending };
};
