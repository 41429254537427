// Importações Padrões
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';

// Contexto
import { Auth } from '../services/auth.context';

// Types
import { IOrder } from '../typings/order.types';

// Controladores
import { orderCollection } from '../controllers/order.controller';

export function useOrdersList(
  searchStartDate: Date,
  searchEndDate: Date,
): {
  ordersList: Array<IOrder>;
  finishedOrdersList: Array<IOrder>;
  cancelledOrdersList: Array<IOrder>;
  pending: boolean;
} {
  const { code3cData } = useContext(Auth);

  const [ordersList, setOrdersList] = useState<Array<IOrder>>([]);
  const [finishedOrdersList, setFinishedOrdersList] = useState<Array<IOrder>>(
    [],
  );
  const [cancelledOrdersList, setCancelledOrdersList] = useState<Array<IOrder>>(
    [],
  );
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    setPending((state) => !state);
    const startDate = new Date(
      moment(searchStartDate)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .format(),
    );
    const endDate = new Date(
      moment(searchEndDate)
        .set({
          hour: 23,
          minute: 59,
          second: 59,
        })
        .format(),
    );
    const unsubscribe = orderCollection
      .where('code3c_id', '==', code3cData?.id)
      .where('inOrder', '==', false)
      .where('created_at', '>=', startDate)
      .where('created_at', '<=', endDate)
      .orderBy('created_at', 'desc')
      .onSnapshot((response) => {
        const list: Array<IOrder> = [];
        const finishedList: Array<IOrder> = [];
        const cancelledList: Array<IOrder> = [];

        response.docs.forEach((doc) => {
          const newDocument = doc.data();
          const documentData = {
            ...doc.data(),
            id: doc.id,
            created_at: newDocument.created_at.toDate(),
            updated_at: newDocument.created_at.toDate(),
          } as IOrder;
          if (documentData.status.finished !== 'Pendente') {
            finishedList.push(documentData);
          } else if (documentData.status.cancelled !== 'Pendente') {
            cancelledList.push(documentData);
          } else {
            list.push(documentData);
          }
        });
        setOrdersList(list);
        setFinishedOrdersList(finishedList);
        setCancelledOrdersList(cancelledList);
      });
    setPending((state) => !state);

    return () => {
      unsubscribe();
      setPending(false);
    };
  }, [code3cData?.id, searchStartDate, searchEndDate]);

  return { ordersList, finishedOrdersList, cancelledOrdersList, pending };
}
