// Configurações
import firebaseConfig from '../config/config.firebase';
import getApi from '../config/api.firebase';

// Types
import { IUser } from '../typings/users.types';

// Componentes
import {
  toasterError,
  toasterSuccess,
} from '../components/global/toaster.component';

const userCollection = firebaseConfig.firestore().collection('All-user');

async function addUser(data: Partial<IUser>, token: string) {
  try {
    const api = getApi(token);
    const response = await api.post('/user/create', data);
    if (response.data.id) {
      toasterSuccess('CRIAR', `Usuário ${data.name} Criado`);
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com seu cadastro!');
    return false;
  }
}

async function searchUser(id: string) {
  try {
    const data = await userCollection.doc(id).get();
    return <IUser>{ ...data.data(), id };
  } catch (err) {
    console.error(err);
    toasterError('Erro', 'Não foi possível ler os dados.');
    return null;
  }
}

const editUser = async (data: Partial<IUser>, id: string, token: string) => {
  try {
    const dataDBExists = await searchUser(id);
    if (!dataDBExists) {
      return false;
    }
    const api = getApi(token);
    const response = await api.patch(`user/edit/${id}`, data);
    if (response) {
      toasterSuccess('EDITAR', 'O Usuário foi editado.');
      return { ...data, id } as IUser;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com sua edição!');
    return false;
  }
};

async function deleteUser(id: string, token: string) {
  try {
    const api = getApi(token);
    const response = await api.delete(`/user/delete/${id}`);
    if (response.data.success) {
      toasterSuccess('APAGAR', 'O Usuário foi apagado!');
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', '*Não foi possível fazer essa requisição.');
    return false;
  }
}

export { userCollection, addUser, searchUser, editUser, deleteUser };
