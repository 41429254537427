import axios from 'axios';

function getApi(token: string) {
  const api = axios.create({
    baseURL: 'https://us-central1-erp-cansho.cloudfunctions.net',
    timeout: 9000,
  });

  api.interceptors.request.use(
    async (config) => {
      const apiConfig = config;
      apiConfig.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return api;
}

export default getApi;
