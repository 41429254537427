// Importações Padrões
import { Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Icones
import { FaLevelUpAlt } from 'react-icons/fa';

interface IItemMenuSubRouteProps {
  path: string;
  pathname: string;
  icon: IconProp;
  active: boolean;
  extender: boolean;
}

const ItemMenuSubRoute = (props: IItemMenuSubRouteProps) => {
  const { path, pathname, icon, active, extender } = props;

  return (
    <Link to={path} className="no-decoration">
      <div
        className={
          active
            ? 'p-grid p-col p-d-flex p-m-0 p-p-0 item-display-active'
            : 'p-grid p-col p-d-flex p-m-0 p-p-0 item-display-sub-route'
        }
      >
        <div className="p-col-2" />
        <div className="p-col-2 p-d-flex">
          <FaLevelUpAlt className="p-as-center item-icon-sub-arrow " />
        </div>
        <div
          className={
            extender
              ? 'p-col-5 p-d-inline p-m-0 p-as-center p-px-0'
              : 'p-d-none p-d-inline p-m-0 p-as-center p-px-0'
          }
        >
          <p
            className={
              extender
                ? 'p-d-block p-m-0 item-text-extended'
                : 'p-d-block p-m-0 item-text-closed'
            }
          >
            {pathname}
          </p>
        </div>
        <div
          className={
            extender
              ? 'p-col-3 p-d-inline p-d-flex p-as-center'
              : 'p-col p-d-inline p-d-flex p-as-center'
          }
        >
          <FontAwesomeIcon
            icon={icon}
            className="item-icon-sub-item p-mx-auto"
          />
        </div>
      </div>
    </Link>
  );
};

export default ItemMenuSubRoute;
