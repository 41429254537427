// Importações Padrões
import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

export function parseUnit(unit: string) {
  switch (unit) {
    case 'unit':
      return 'Unidades';

    case 'pack':
      return 'Pacotes';

    case 'box':
      return 'Caixas';

    case 'kg':
      return 'Quilogramas';

    case 'liter':
      return 'Litros';

    case 'dozen':
      return 'Dúzias';

    default:
      return '';
  }
}

export function parseUnitAbbrev(unit: string) {
  switch (unit) {
    case 'unit':
      return 'Unidade(s)';

    case 'pack':
      return 'Pacote(s)';

    case 'box':
      return 'Caixa(s)';

    case 'kg':
      return 'Kilograma(s)';

    case 'liter':
      return 'Litro(s)';

    case 'dozen':
      return 'Dúzia(s)';

    default:
      return '';
  }
}

export function parseUnitAbbreviation(unit: string) {
  switch (unit) {
    case 'unit':
      return 'Un';

    case 'pack':
      return 'Pct';

    case 'box':
      return 'Cx';

    case 'kg':
      return 'Kg';

    case 'liter':
      return 'L';

    case 'dozen':
      return 'Dz';

    default:
      return '';
  }
}

export function parseUnitFormatter(valor: number) {
  const mod = valor % 1;

  if (mod === 0) {
    return valor;
  }
  return parseFloat(valor.toFixed(2));
}

yup.setLocale(pt);

export const dataSchema = yup.object().shape({
  code3c_id: yup.string(),
  name: yup.string().label('Nome').min(3).required(),
  barCode: yup.string().label('Codigo de Barras').required(),
  minPrice: yup.string().label('Preço minimo').min(0).required(),
  maxPrice: yup.string().label('Preço maximo').min(0),
  productAmount: yup.number().label('Estoque').min(0),
  productReservation: yup.number().label('Reserva de Produto').min(0),
  saleAvailable: yup.number(),
  reservationAvailable: yup.number(),
  category_id: yup.string().label('Categoria').required(),
  branding_id: yup.string().label('Marca').required(),
  manufacturer_id: yup.string().label('Fabricante').required(),
  unit: yup.string().label('Unidade').required(),
});

export const dataSchemaAmount = yup.object().shape({
  productAmount: yup.number().label('Quantidade'),
  FutureproductAmount: yup.number().label('Quantidade'),
});
