// Importações Padrões
import React, { useContext } from 'react';
import { maskBr } from 'js-brasil';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import {
  IProvider,
  IEditProvider,
  IDeleteProvider,
} from '../../typings/provider.types';

// Componentes
import ItemCol from '../defaultBox/itemCol.component';
import ItemImage from '../defaultBox/itemImage.component';
import ItemAction, { IEdit } from '../defaultBox/itemAction.component';
import ResponsiveItemAction from '../defaultBox/responsiveItemAction.component';

interface IITemMenu {
  provider: IProvider;
  index: number;
  showHideEditProvider: IEditProvider;
  setShowHideEditProvider: React.Dispatch<
    React.SetStateAction<IEdit<IProvider>>
  >;
  showHideDeleteProvider: IDeleteProvider;
  setShowHideDeleteProvider: React.Dispatch<
    React.SetStateAction<IDeleteProvider>
  >;
}

const defaultImage = 'https://image.flaticon.com/icons/png/512/45/45880.png';

const ItemProvider = ({
  provider,
  index,
  showHideEditProvider,
  setShowHideEditProvider,
  showHideDeleteProvider,
  setShowHideDeleteProvider,
}: IITemMenu) => {
  const { windowWidth } = useContext(Auth);

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-mt-1 p-mx-auto p-p-3 p-grid item-box width100">
          <p className="p-col-9 width100 p-d-inline p-my-0 p-p-0 p-mr-0">
            #{index}
          </p>
          <div className="p-px-1 p-as-center p-mx-auto">
            <ItemImage
              colGrid={4}
              content={provider.imageHeavy}
              imageDefault={defaultImage}
            />
          </div>
          <ItemCol colGrid={4} field="Nome" content={provider.name} />
          <ItemCol
            colGrid={1}
            field="Tipo"
            content={
              provider.typeOf === 'simpleProvider' ? 'Simples' : 'Completo'
            }
          />
          {!provider.socialID && (
            <ItemCol colGrid={3} field="CPF/CNPJ" content="---" />
          )}
          {provider.socialID && provider.socialID.length === 11 && (
            <ItemCol
              colGrid={3}
              field="CPF"
              content={maskBr.cpf(provider.socialID)}
            />
          )}
          {provider.socialID && provider.socialID.length === 14 && (
            <ItemCol
              colGrid={3}
              field="CNPJ"
              content={maskBr.cnpj(provider.socialID)}
            />
          )}
          <ResponsiveItemAction
            item={provider}
            actionEdit={showHideEditProvider}
            setActionEdit={setShowHideEditProvider}
            actionDelete={showHideDeleteProvider}
            setActionDelete={setShowHideDeleteProvider}
          />
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div
          className="p-mt-1 p-mx-auto p-px-3 p-grid item-box width100"
          style={{
            border: '1px solid #e9ecef',
            borderWidth: '0 0 1px 0',
            backgroundColor: 'white',
          }}
        >
          <ItemCol colGrid={1} field="Id" content={String(index)} />
          <div className="p-col-3">
            <ItemImage
              colGrid={12}
              content={provider.imageHeavy}
              imageDefault={defaultImage}
            />
          </div>
          <ItemCol colGrid={2} field="Nome" content={provider.name} />
          <ItemCol
            colGrid={1}
            field="Tipo"
            content={
              provider.typeOf === 'simpleProvider' ? 'Simples' : 'Completo'
            }
          />
          {!provider.socialID && (
            <ItemCol colGrid={3} field="CPF/CNPJ" content="---" />
          )}
          {provider.socialID && provider.socialID.length === 11 && (
            <ItemCol
              colGrid={3}
              field="CPF"
              content={maskBr.cpf(provider.socialID)}
            />
          )}
          {provider.socialID && provider.socialID.length === 14 && (
            <ItemCol
              colGrid={3}
              field="CNPJ"
              content={maskBr.cnpj(provider.socialID)}
            />
          )}
          <ItemAction
            item={provider}
            actionEdit={showHideEditProvider}
            setActionEdit={setShowHideEditProvider}
            actionDelete={showHideDeleteProvider}
            setActionDelete={setShowHideDeleteProvider}
            colGrid={2}
          />
        </div>
      );
    }
    return <div />;
  }

  return changeBreakpoints();

  // return (
  //   <div
  //     className="p-mt-1 p-mx-auto p-p-3 p-grid item-box"
  //     style={{ width: '100%' }}
  //   >
  //     <div
  //       className={`${
  //         windowWidth && windowWidth > 900 && 'p-d-none'
  //       } p-px-1 p-as-center p-mx-auto`}
  //     >
  //       <ItemImage
  //         colGrid={4}
  //         content={provider.imageHeavy}
  //         imageDefault={defaultImage}
  //       />
  //     </div>
  //     <ItemCol colGrid={1} field="Id" content={String(index)} />
  //     <div
  //       className={`${windowWidth && windowWidth <= 900 && 'p-d-none'} p-col-3`}
  //     >
  //       <ItemImage
  //         colGrid={12}
  //         content={provider.imageHeavy}
  //         imageDefault={defaultImage}
  //       />
  //     </div>
  //     <ItemCol colGrid={2} field="Nome" content={provider.name} />
  //     <ItemCol
  //       colGrid={1}
  //       field="Tipo"
  //       content={provider.typeOf === 'simpleProvider' ? 'Simples' : 'Completo'}
  //     />
  //     {!provider.socialID && (
  //       <ItemCol colGrid={3} field="CPF/CNPJ" content="---" />
  //     )}
  //     {provider.socialID && provider.socialID.length === 11 && (
  //       <ItemCol
  //         colGrid={3}
  //         field="CPF"
  //         content={maskBr.cpf(provider.socialID)}
  //       />
  //     )}
  //     {provider.socialID && provider.socialID.length === 14 && (
  //       <ItemCol
  //         colGrid={3}
  //         field="CNPJ"
  //         content={maskBr.cnpj(provider.socialID)}
  //       />
  //     )}

  //     <ItemAction
  //       item={provider}
  //       actionEdit={showHideEditProvider}
  //       setActionEdit={setShowHideEditProvider}
  //       actionDelete={showHideDeleteProvider}
  //       setActionDelete={setShowHideDeleteProvider}
  //       colGrid={2}
  //     />
  //   </div>
  // );
};

export default ItemProvider;
