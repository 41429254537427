// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollTop } from 'primereact/scrolltop';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useBrandingsList } from '../hooks/useBrandingsList';

// Types
import {
  IBranding,
  IDeleteBranding,
  IEditBranding,
  initialBranding,
} from '../typings/branding.types';

// Componentes
import ItemBranding from '../components/brandings/itemBranding.component';
import AddBranding from '../components/brandings/addBranding.component';
import EditBranding from '../components/brandings/editBranding.component';
import DeleteBranding from '../components/brandings/deleteBranding.component';
import NavBranding from '../components/brandings/navBranding.component';

const Brandings: React.FC = () => {
  const { setPathName, windowWidth } = useContext(Auth);

  const [brandingsList, pending] = useBrandingsList();

  const [showHideAddBranding, setShowHideAddBranding] = useState(false);
  const [showHideDeleteBranding, setShowHideDeleteBranding] =
    useState<IDeleteBranding>({ id: '', name: '', visible: false });
  const [showHideEditBranding, setShowHideEditBranding] =
    useState<IEditBranding>({
      item: initialBranding,
      edit: false,
      visible: false,
    });

  const [inputSearch, setInputSearch] = useState('');

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  useEffect(() => {
    if (
      showHideAddBranding ||
      showHideDeleteBranding.visible ||
      showHideEditBranding.visible
    ) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHideAddBranding, showHideDeleteBranding, showHideEditBranding]);

  return (
    <div className="p-py-3 p-px-3 ">
      <ScrollTop className="button-scroll-top" />
      <AddBranding
        visible={showHideAddBranding}
        setVisible={setShowHideAddBranding}
      />
      <EditBranding
        visible={showHideEditBranding}
        setVisible={setShowHideEditBranding}
      />
      <DeleteBranding
        visible={showHideDeleteBranding}
        setVisible={setShowHideDeleteBranding}
      />
      <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
        <NavBranding
          placeHolder="Pesquisar..."
          pending={!pending}
          addFieldName="Nova Marca"
          actionAdd={showHideAddBranding}
          setActionAdd={setShowHideAddBranding}
          setInputSearch={setInputSearch}
          activeAdd
        />
        <div className="p-d-flex p-dir-col p-mt-2 p-mb-auto width100">
          {windowWidth && windowWidth > 900 && (
            <div
              className="p-grid p-my-1 p-px-3 p-mx-auto p-d-none p-d-md-inline-flex default-box-header"
              style={{
                backgroundColor: '#f8f9fa',
                border: '1px solid #e9ecef',
              }}
            >
              <div className="p-col-1 p-text-center p-as-center">Id</div>
              <div className="p-col-4 p-text-center p-as-center">Imagem</div>
              <div className="p-col-4 p-text-center p-as-center">Marca</div>
              <div className="p-col-3 p-text-center p-as-center">Ações</div>
            </div>
          )}

          {brandingsList
            .filter(
              (item) =>
                item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                -1,
            )
            .map((branding: IBranding, index: number) => {
              return (
                <ItemBranding
                  key={String(index)}
                  index={index + 1}
                  branding={branding}
                  showHideEditBranding={showHideEditBranding}
                  setShowHideEditBranding={setShowHideEditBranding}
                  showHideDeleteBranding={showHideDeleteBranding}
                  setShowHideDeleteBranding={setShowHideDeleteBranding}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Brandings;
