// Importações Padrões
import React, { useState, useEffect, useContext } from 'react';

// Icones
import { FaEdit, FaEye } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

// Componentes
import InputForm from '../input/inputForm.component';
import InputImageForm from '../input/inputImageForm.component';

// Controladores
import { editBranding } from '../../controllers/branding.controller';

// Funções
import { dataSchema } from '../../functions/branding.function';
import { getURLFromDB } from '../../functions/image.function';

// Types
import { IBranding, IEditBranding } from '../../typings/branding.types';
import { IValidationError } from '../../typings/yup.types';

// Contexto
import { Auth } from '../../services/auth.context';

interface IEditBrandingProps {
  visible: IEditBranding;
  setVisible: React.Dispatch<React.SetStateAction<IEditBranding>>;
}

const EditBrandings = (props: IEditBrandingProps) => {
  const { code3cData } = useContext(Auth);
  const { visible, setVisible } = props;

  const [name, setName] = useState<string>('');
  const [imageHeavy, setImageHeavy] = useState<string>('');
  const [imageLight, setImageLight] = useState<string>('');

  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  const initialState = () => {
    setName('');
    setFormErr([]);
    setImageHeavy('');
    setImageLight('');
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
    setVisible({ ...visible, visible: false });
  };

  async function handleEditBranding() {
    setPendingAction((state) => !state);
    let urlImage = '';
    if (imageHeavy !== '') {
      urlImage = await getURLFromDB(
        true,
        imageHeavy,
        imageLight,
        code3cData?.id ? code3cData.id : '',
        'FDV-branding',
      );
    }

    const dataValidation: Partial<IBranding> = {
      name,
      code3c_id: code3cData?.id,
      imageHeavy: urlImage,
      imageLight: urlImage,
    };
    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await editBranding(dataValidation, visible.item.id);
        if (response) {
          setFormErr([]);
          setPendingAction((state) => !state);
          initialState();
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  useEffect(() => {
    setName(visible.item.name);
    setImageHeavy(visible.item.imageHeavy);
    setImageLight(visible.item.imageLight);
  }, [visible]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              {visible.edit ? (
                <FaEdit className="p-mx-auto p-as-center modal-header-icon" />
              ) : (
                <FaEye className="p-mx-auto p-as-center modal-header-icon" />
              )}
            </div>
            <span className="p-my-auto users-button-text">
              {visible.edit ? 'Editar ' : 'Ver '}Marca
            </span>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label={visible.edit ? 'Salvar' : 'Editar'}
              disabled={pendingAction}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() =>
                visible.edit
                  ? handleEditBranding()
                  : setVisible({ ...visible, edit: true })
              }
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <InputForm
        pleaceHolder=""
        disabled={!visible.edit}
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome da Marca"
        fieldCode="name"
      />
      <InputImageForm
        visibleEdit={visible.edit}
        loadingImage={pendingAction}
        item={imageHeavy}
        setItem={setImageHeavy}
        fieldLabel="Imagem da Marca"
      />
    </Dialog>
  );
};
export default EditBrandings;
