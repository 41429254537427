// Importações Padrões
import React, { useContext, useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { ScrollTop } from 'primereact/scrolltop';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useOrdersList } from '../hooks/useOrdersList';

// Types
import { IOrder, IEditOrder, initialOrder } from '../typings/order.types';
import { IInput } from '../typings/input.types';

// Componentes
import ItemOrder from '../components/orders/itemOrder.component';
import EditOrder from '../components/orders/editOrder.component';
import NavOrders from '../components/orders/navOrders.component';
import { ProgressSpinnerSys } from '../components/global/progressSpinner.component';

// Estilos
import '../styles/global/orders.scss';

const Orders: React.FC = () => {
  const { setPathName, windowWidth } = useContext(Auth);

  const [startDate, setStartDate] = useState(
    new Date(moment().subtract(7, 'd').format()),
  );
  const [endDate, setEndDate] = useState(new Date(moment().format()));

  const [searchStartDate, setSearchStartDate] = useState(startDate);
  const [searchEndDate, setSearchEndDate] = useState(endDate);

  const [pendingOrder, setPendingOrder] = useState(false);

  const [showHideEditOrder, setShowHideEditOrder] = useState<IEditOrder>({
    item: initialOrder,
    edit: false,
    visible: false,
  });

  const [inputSearch, setInputSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<IInput>({
    name: 'Pedidos em Aberto',
    code: 'Opened',
  });

  const ordersStatus = [
    { name: 'Pedidos em Aberto', code: 'Opened' },
    { name: 'Pedidos Finalizados', code: 'Finished' },
    { name: 'Pedidos Cancelados', code: 'Cancelled' },
  ];

  const { ordersList, finishedOrdersList, cancelledOrdersList } = useOrdersList(
    searchStartDate,
    searchEndDate,
  );

  const location = useLocation();

  function searchReport() {
    setSearchStartDate(startDate);
    setSearchEndDate(endDate);
    setPendingOrder((state) => !state);
    setTimeout(() => {
      setPendingOrder((state) => !state);
    }, 1000);
  }

  const selectedListType = useCallback(() => {
    switch (selectedStatus.code) {
      case 'Opened': {
        return ordersList;
      }
      case 'Finished': {
        return finishedOrdersList;
      }
      case 'Cancelled': {
        return cancelledOrdersList;
      }
      default: {
        return ordersList;
      }
    }
  }, [
    selectedStatus,
    searchStartDate,
    searchEndDate,
    ordersList,
    finishedOrdersList,
    cancelledOrdersList,
  ]);

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  useEffect(() => {
    setPendingOrder((state) => !state);
    setTimeout(() => {
      setPendingOrder((state) => !state);
    }, 1000);
  }, [selectedStatus]);

  useEffect(() => {
    if (showHideEditOrder.visible) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHideEditOrder]);

  return (
    <div className="p-py-3 p-px-3 ">
      <ScrollTop className="button-scroll-top" />
      <EditOrder
        visible={showHideEditOrder}
        setVisible={setShowHideEditOrder}
      />
      <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
        <NavOrders
          setInputSearch={setInputSearch}
          pleaceHolder="Pesquisar por Pedidos..."
          selectedListType={selectedListType}
          selectedStatus={selectedStatus}
          ordersStatus={ordersStatus}
          setSelectedStatus={setSelectedStatus}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          searchEndDate={searchEndDate}
          searchStartDate={searchStartDate}
          searchReport={searchReport}
        />
        {!pendingOrder ? (
          <>
            {selectedListType().length === 0 ? (
              <>
                {windowWidth && windowWidth > 0 && windowWidth <= 900 ? (
                  <div className="p-d-flex p-jc-center p-as-center p-flex-column height38-8">
                    <div className="p-d-flex p-as-center p-text-center">
                      Voce não possui pedidos no momento.
                    </div>
                  </div>
                ) : (
                  <div className="p-d-flex p-jc-center p-as-center p-flex-column height75-8">
                    <div className="p-d-flex p-as-center p-text-center">
                      Voce não possui pedidos no momento.
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {windowWidth && windowWidth > 900 && (
                  <div
                    className="p-grid p-mt-4 p-px-3 p-mx-auto p-d-md-inline-flex default-box-header"
                    style={{
                      backgroundColor: '#f8f9fa',
                      border: '1px solid #e9ecef',
                    }}
                  >
                    <div className="p-col-1 p-text-center p-as-center">Nº</div>
                    <div className="p-col-3 p-text-center p-as-center">
                      Cliente
                    </div>
                    <div className="p-col-2 p-text-center p-as-center">
                      Vendedor
                    </div>
                    <div className="p-col-1 p-text-center p-as-center">
                      Data
                    </div>
                    <div className="p-col-2 p-text-center p-as-center">
                      Status
                    </div>
                    <div className="p-col-1 p-text-center p-as-center">
                      Total
                    </div>
                    <div className="p-col-2 p-text-center p-as-center">
                      Ações
                    </div>
                  </div>
                )}

                {selectedListType()
                  .filter(
                    (item) =>
                      item.client_name
                        .toUpperCase()
                        .indexOf(inputSearch.toUpperCase()) !== -1,
                  )
                  .map((order: IOrder, Index: number) => {
                    return (
                      <ItemOrder
                        key={String(Index)}
                        order={order}
                        setShowHideEditOrder={setShowHideEditOrder}
                        showHideEditOrder={showHideEditOrder}
                      />
                    );
                  })}
              </>
            )}
          </>
        ) : (
          <>
            {windowWidth && windowWidth > 0 && windowWidth <= 900 ? (
              <div className="p-d-flex p-as-center p-jc-center width100">
                <div className="p-d-flex p-jc-center p-as-center p-flex-column height38-8">
                  <div className="p-d-flex p-my-5">
                    <ProgressSpinnerSys />
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-d-flex p-as-center p-jc-center width100">
                <div className="p-d-flex p-jc-center p-as-center p-flex-column height75-8">
                  <div className="p-d-flex p-my-5">
                    <ProgressSpinnerSys />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Orders;
