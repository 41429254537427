// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Contexto
import { Auth } from '../services/auth.context';

// Types
import { IList } from '../typings/list.types';

// Controladores
import { listCollection } from '../controllers/list.controller';

export function useListList(): [Array<IList>, boolean] {
  const { code3cData } = useContext(Auth);

  const [listsList, setListList] = useState<Array<IList>>([]);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    setPending((state) => !state);
    const unsubscribe = listCollection
      .where('code3c_id', '==', code3cData?.id)
      .onSnapshot((response) => {
        const list: Array<IList> = [];

        response.docs.forEach((doc) => {
          const documentData = { ...doc.data(), id: doc.id } as IList;
          list.push(documentData);
        });
        setListList(list);
      });
    setPending((state) => !state);

    return () => {
      unsubscribe();
      setPending(false);
    };
  }, [code3cData?.id]);

  return [listsList, pending];
}
