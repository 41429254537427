import { Button } from 'primereact/button';
import { ColumnFilterApplyTemplateOptions, ColumnFilterClearTemplateOptions } from 'primereact/column';

import './DataTablesFilters.scss';

export const filterApplyTemplate = (options: ColumnFilterApplyTemplateOptions) => {
  return (
    <Button
      type="button"
      icon="pi pi-check"
      onClick={options.filterApplyCallback}
      className="filter-apply-template"
    ></Button>
  );
};

export const filterClearTemplate = (options: ColumnFilterClearTemplateOptions) => {
  return (
    <Button
      type="button"
      icon="pi pi-times"
      onClick={options.filterClearCallback}
      className="filter-clear-template"
    ></Button>
  );
};
