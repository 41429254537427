// Importações Padrões
import { useEffect, useState } from 'react';
import { maskBr } from 'js-brasil';
import Chart from 'react-apexcharts';
import moment from 'moment';
import 'moment/locale/pt-br';
import { ApexOptions } from 'apexcharts';

// Hooks
import { useDayOrdersList } from '../../../hooks/useDayOrdersList';

// Types
import {
  initialOptionsName,
  initialOptionValue,
} from '../../../typings/order.types';

export const SellersGraph = () => {
  const [dayOrdersList] = useDayOrdersList();
  const [optionsName, setOptionsName] = useState(initialOptionsName);
  const [optionsValue, setOptionsValue] = useState(initialOptionValue);

  useEffect(() => {
    const last7Days = [];
    const last7DaysName = [];
    const last7DaysValue = [];
    if (dayOrdersList.length > 0) {
      for (let i = 0; i <= 6; i += 1) {
        last7Days.push(moment().subtract(i, 'd').format('MM-DD-YY'));
        const [newSales] = dayOrdersList.filter(
          (item) =>
            moment().subtract(i, 'd').format('MM-DD-YY') ===
            moment(item.dayOrdersDate.toDate()).format('MM-DD-YY'),
        );
        const newData = {
          weekDays: moment().subtract(i, 'd').format('ddd').toUpperCase(),
          vendas: newSales
            ? parseFloat((newSales.dayOrdersTotal / 100).toFixed(2))
            : 0,
        };
        last7DaysName.push(newData.weekDays);
        last7DaysValue.push(newData.vendas);
      }
      setOptionsName(last7DaysName);
      setOptionsValue(last7DaysValue);
    }
  }, [dayOrdersList]);

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      fontFamily: 'Poppins-Light',
    },
    colors: ['#942222'],
    fill: {
      colors: ['#942222'],
      opacity: 1,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      // eslint-disable-next-line
      formatter: (val: number) => {
        return `${maskBr.currency(val)}`;
      },
      offsetY: -30,
      style: {
        fontSize: '14px',
        colors: ['#942222'],
      },
    },

    xaxis: {
      categories: optionsName,
      position: 'bottom',
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        // eslint-disable-next-line
        formatter: (val: any) => {
          return `${maskBr.currency(val)}`;
        },
      },
    },
  };

  const series = [
    {
      name: 'Valor em Vendas',
      data: optionsValue,
    },
  ];

  return (
    <div
      id="map"
      className="width100"
      style={{ background: '#ffffff', border: '1px solid #EEEEEE' }}
    >
      <div className="p-px-3 p-py-3 dashboard-properties-title">
        Vendas nos últimos 7 Dias
      </div>
      <div className="p-px-5">
        <div id="chart">
          <Chart
            options={options}
            series={series}
            type="bar"
            height={300}
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};
export default SellersGraph;
