// Importações Padrões
import React, { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollTop } from 'primereact/scrolltop';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useCitiesList } from '../hooks/useCitiesList';

// Types
import { ISystemFunction } from '../typings/users.types';
import {
  ICity,
  IEditCity,
  IDeleteCity,
  initialCity,
} from '../typings/cities.types';

// Componentes
import CitiesDropSelect from '../components/cities/citiesDropSelect.component';
import ItemCity from '../components/cities/itemCity.component';
import AddCity from '../components/cities/addCity.component';
import EditCity from '../components/cities/editCity.component';
import DeleteCity from '../components/cities/deleteCity.component';
import NavCities from '../components/cities/navCities.component';

const Cities: React.FC = () => {
  const { setPathName, windowWidth } = useContext(Auth);
  const [province, setProvince] = useState<ISystemFunction>({
    name: '',
    code: '',
  });
  const [citiesList] = useCitiesList(province.code);

  const [showHideAddCity, setShowHideAddCity] = useState(false);
  const [showHideEditCity, setShowHideEditCity] = useState<IEditCity>({
    item: initialCity,
    edit: false,
    visible: false,
  });
  const [showHideDeleteCity, setShowHideDeleteCity] = useState<IDeleteCity>({
    id: '',
    name: '',
    visible: false,
  });

  const [inputSearch, setInputSearch] = useState('');

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  useEffect(() => {
    if (
      showHideAddCity ||
      showHideEditCity.visible ||
      showHideDeleteCity.visible
    ) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHideAddCity, showHideEditCity, showHideDeleteCity]);

  return (
    <div className="p-py-3 p-px-3">
      <ScrollTop className="button-scroll-top" />
      <AddCity
        visible={showHideAddCity}
        setVisible={setShowHideAddCity}
        provinceId={province.code}
      />
      <EditCity visible={showHideEditCity} setVisible={setShowHideEditCity} />
      <DeleteCity
        visible={showHideDeleteCity}
        setVisible={setShowHideDeleteCity}
      />
      <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
        <NavCities
          placeHolder="Pesquisar..."
          pending={province.code === ''}
          addFieldName="Nova Cidade"
          actionAdd={showHideAddCity}
          setActionAdd={setShowHideAddCity}
          setInputSearch={setInputSearch}
          activeAdd
        />

        <div className="p-d-flex p-jc-center">
          <CitiesDropSelect
            province={province}
            setProvince={setProvince}
            onPage
            disabled={false}
          />
        </div>

        {citiesList.length !== 0 && province.code !== '' && (
          <>
            {windowWidth && windowWidth > 900 && (
              <div
                className="p-grid p-my-1 p-px-3 p-mx-auto p-d-none p-d-md-inline-flex default-box-header"
                style={{
                  backgroundColor: '#f8f9fa',
                  border: '1px solid #e9ecef',
                }}
              >
                <div className="p-col-1 p-text-center p-as-center">Id</div>
                <div className="p-col-9 p-text-center p-as-center">Cidade</div>
                <div className="p-col-2 p-text-center p-as-center">Ações</div>
              </div>
            )}
          </>
        )}
        {citiesList
          .filter(
            (item) =>
              item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !== -1,
          )
          .map((city: ICity, index: number) => (
            <ItemCity
              key={String(index)}
              index={index + 1}
              city={city}
              showHideEditCity={showHideEditCity}
              setShowHideEditCity={setShowHideEditCity}
              showHideDeleteCity={showHideDeleteCity}
              setShowHideDeleteCity={setShowHideDeleteCity}
            />
          ))}
        {citiesList.length === 0 && province.code === '' && (
          <div className="p-d-flex p-as-center p-jc-center width100">
            <div className="p-d-flex p-jc-center p-as-center p-flex-column height100-23">
              <div className="p-d-flex p-my-5">Selecione um Estado</div>
            </div>
          </div>
        )}
        {citiesList.length === 0 && province.code !== '' && (
          <div className="p-d-flex p-as-center p-jc-center width100">
            <div className="p-d-flex p-jc-center p-as-center p-flex-column height100-23">
              <div className="p-d-flex p-my-5">
                Nenhuma cidade foi encontrada!
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cities;
