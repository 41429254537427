// Importações Padrões
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ToastContainer } from 'react-toastify';

// Icones
import {
  faAsterisk,
  faBoxes,
  faBriefcase,
  faChevronDown,
  faChevronUp,
  faCity,
  faClipboardList,
  faCog,
  faIndustry,
  faLevelUpAlt,
  faPoll,
  faQuestionCircle,
  faRegistered,
  faTachometerAlt,
  faTags,
  faTruck,
  faTruckLoading,
  faUser,
  faWrench,
  faCalendarAlt,
  faClock,
  faBookReader,
  faFileInvoiceDollar,
  faCompressArrowsAlt,
  faCreditCard,
  faDesktop,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

// Rotas
import Routes from './routes/routes';

// Estilos
import './styles/global/inputs.scss';
import './styles/global/sizing.scss';
import './styles/global/fontSizing.scss';
import './styles/global/buttons.scss';
import './styles/global/text.scss';
import './styles/global/alerts.scss';
import './styles/global/fonts.scss';

import 'react-toastify/dist/ReactToastify.css';
import 'primereact/resources/themes/fluent-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './styles/global/index.scss';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://00cbe8c4ec0340ca8ca3d90971fb268e@o876175.ingest.sentry.io/5825522',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

library.add(
  faTachometerAlt,
  faPoll,
  faUser,
  faCog,
  faQuestionCircle,
  faChevronDown,
  faChevronUp,
  faBoxes,
  faTags,
  faRegistered,
  faIndustry,
  faWrench,
  faClipboardList,
  faCity,
  faAsterisk,
  faLevelUpAlt,
  faTruckLoading,
  faTruck,
  faBriefcase,
  faCalendarAlt,
  faClock,
  faBookReader,
  faFileInvoiceDollar,
  faCompressArrowsAlt,
  faCreditCard,
  faDesktop,
  faTruckLoading,
);

ReactDOM.render(
  <>
    <Routes />
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </>,
  document.getElementById('root'),
);

// Importações Padrões
// Types
// Configurações
// Controladores
// Paginas
// Componentes
// Funções
// Contexto
// Rotas
// Icones
// React Prime
// Estilos
// Hooks
// Imagens
