// Importações Padrões
import { useContext } from 'react';

// React Prime
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';

// Contexto
import { Auth } from '../../services/auth.context';

// Controladores
import { enableProvince } from '../../controllers/province.controller';

// Componentes
import ItemCol from '../defaultBox/itemCol.component';

interface IITemState {
  id: string;
  code3cEnabled: Array<string>;
  name: string;
  active: boolean;
}

const ItemProvince = ({ id, code3cEnabled, name, active }: IITemState) => {
  const { windowWidth, code3cData } = useContext(Auth);

  const code3cId = code3cData?.id ? code3cData.id : '';

  const handleProvince = (enabled: boolean) => {
    if (enabled) {
      enableProvince(
        id,
        code3cEnabled.filter((item) => item !== code3cId),
        enabled,
      );
    } else {
      enableProvince(id, [...code3cEnabled, code3cId], enabled);
    }
  };

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-mt-1 p-mx-auto p-px-3 p-grid item-box p-d-flex width100">
          <div className="p-col-12 p-grid p-as-center p-my-auto p-px-0 p-mx-0">
            <p className="p-col-9 width100 p-d-inline p-my-0 p-p-0 p-mr-0">
              #{id}
            </p>
            <ItemCol colGrid={10} field="Estado" content={name} />

            {!active ? (
              <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center p-mb-2">
                <Button
                  className="p-mx-auto p-as-center p-jc-center item-menu-list-responsive p-d-flex"
                  label="Selecionar"
                  onClick={() => {
                    handleProvince(active);
                  }}
                />
              </div>
            ) : (
              <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center p-mb-2">
                <Button
                  className="p-mx-auto p-as-center p-jc-center item-selected-list-responsive p-d-flex"
                  label="Selecionado"
                  onClick={() => {
                    handleProvince(active);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div
          className="p-mt-1 p-mx-auto p-px-3 p-grid item-box p-d-flex width100"
          style={{
            border: '1px solid #e9ecef',
            borderWidth: '0 0 1px 0',
            backgroundColor: 'white',
          }}
        >
          <div className="p-col-12 p-grid p-as-center p-my-auto p-px-0 p-mx-0'">
            <ItemCol colGrid={2} field="Id" content={String(id)} />
            <ItemCol colGrid={8} field="Estado" content={name} />
            <Checkbox
              checked={active}
              onChange={() => {
                handleProvince(active);
              }}
              className={`p-col-2 p-jc-center p-d-md-flex p-d-none ${
                active ? 'settings-checkbox-selected' : 'settings-checkbox'
              }`}
            />
          </div>
        </div>
      );
    }
    return <div />;
  }

  return changeBreakpoints();
};

export default ItemProvince;
