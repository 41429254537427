import { useContext, useEffect, useState } from 'react';

import { Auth } from '../../../services/auth.context';

import { IClient } from '../../../typings/client.types';

import { clientCollection } from '../../../controllers/client.controller';

export function useClientsList(): [Array<IClient>, boolean] {
  const { code3cData } = useContext(Auth);

  const [clientsList, setClientsList] = useState<Array<IClient>>([]);
  const [pendingClient, setPendingClient] = useState<boolean>(true);

  useEffect(() => {
    setPendingClient(true);
    const unsubscribe = clientCollection
      .where('code3c_id', '==', code3cData?.id)
      .where('systemId', '==', 'CFDV')
      .orderBy('name')
      .onSnapshot((response) => {
        const list: Array<IClient> = [];

        response.docs.forEach((doc) => {
          const documentData = { ...doc.data(), id: doc.id } as IClient;
          list.push(documentData);
        });
        setClientsList(list);
        setPendingClient(false);
      });

    return () => {
      unsubscribe();
    };
  }, [code3cData?.id]);

  return [clientsList, pendingClient];
}
