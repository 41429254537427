import { useState, useEffect } from 'react';

export default function useOnScreen(
  target: React.MutableRefObject<HTMLDivElement>,
): boolean {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );

    observer.observe(target.current);

    return () => {
      observer.disconnect();
    };
  }, [target]);

  return isIntersecting;
}
