// Importações Padrões
import React, { useCallback, useState, useEffect, useContext } from 'react';
import { maskBr } from 'js-brasil';
import moment from 'moment';
import 'moment/locale/pt-br';

// React Prime
import { Button } from 'primereact/button';

// Types
import { IOrder } from '../../typings/order.types';

// Componentes
import ItemCol from '../defaultBox/itemCol.component';
import { IEdit } from '../defaultBox/itemAction.component';

// Contexto
import { Auth } from '../../services/auth.context';

// Imagens
import '../../styles/global/orders.scss';

interface IITemMenu {
  order: IOrder;
  showHideEditOrder: IEdit<IOrder>;
  setShowHideEditOrder: React.Dispatch<React.SetStateAction<IEdit<IOrder>>>;
}

const ItemOrders = ({
  order,
  setShowHideEditOrder,
  showHideEditOrder,
}: IITemMenu) => {
  const { windowWidth } = useContext(Auth);

  const [statusColor, setStatusColor] = useState('');
  const [statusName, setStatusName] = useState('');

  const toggleStatusColor = useCallback(() => {
    if (order.status.cancelled !== 'Pendente') {
      setStatusColor('cancelled');
      setStatusName('Cancelado');
    } else if (order.status.processing === 'Pendente') {
      setStatusColor('created');
      setStatusName('Criado');
    } else if (order.status.processed === 'Pendente') {
      setStatusColor('processing');
      setStatusName('Processando');
    } else if (order.status.sended === 'Pendente') {
      setStatusColor('processed');
      setStatusName('Processado');
    } else if (order.status.finished === 'Pendente') {
      setStatusColor('sended');
      setStatusName('Expedido');
    } else {
      setStatusColor('finished');
      setStatusName('Finalizado');
    }
  }, [order]);

  useEffect(() => {
    toggleStatusColor();
  }, [toggleStatusColor]);

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-mt-1 p-mx-auto p-p-3 p-grid item-box width100">
          <p className="p-col-9 width100 p-d-inline p-my-0 p-p-0 p-mr-0">
            #{order.orderNumber}
          </p>
          <ItemCol colGrid={3} field="Cliente" content={order.client_name} />
          <ItemCol colGrid={2} field="Vendedor" content={order.user_name} />
          <ItemCol
            colGrid={1}
            field="Data"
            content={moment(order.created_at).format('L')}
          />

          <div className={`p-d-flex p-jc-center width100 ${statusColor}`}>
            {statusName}
          </div>

          <ItemCol
            colGrid={1}
            field="Total"
            content={maskBr.currency(order.total / 100)}
          />
          <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center p-d-flex p-as-center">
            <div className="p-d-inline p-col p-jc-center p-d-flex">
              <Button
                className="p-py-1 button-order-responsive"
                label="Ver Pedido"
                onClick={() => {
                  setShowHideEditOrder({
                    item: order,
                    edit: false,
                    visible: true,
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div
          className="p-mt-1 p-mx-auto p-px-3 p-py-1 p-grid item-box width100"
          style={{
            border: '1px solid #e9ecef',
            borderWidth: '0 0 1px 0',
            backgroundColor: 'white',
          }}
        >
          <ItemCol colGrid={1} field="N°" content={String(order.orderNumber)} />
          <ItemCol colGrid={3} field="Cliente" content={order.client_name} />
          <ItemCol colGrid={2} field="Vendedor" content={order.user_name} />
          <ItemCol
            colGrid={1}
            field="Data"
            content={moment(order.created_at).format('L')}
          />

          <div className={`p-col-2 ${statusColor}`}>
            <ItemCol colGrid={12} field="Status" content={statusName} />
          </div>

          <ItemCol
            colGrid={1}
            field="Total"
            content={maskBr.currency(order.total / 100)}
          />
          <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center">
            <div className="p-d-inline p-col p-jc-center">
              <Button
                className="p-py-1 button-order"
                label="Ver Pedido"
                onClick={() => {
                  setShowHideEditOrder({
                    item: order,
                    edit: false,
                    visible: true,
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }

  useEffect(() => {
    if (showHideEditOrder.visible && showHideEditOrder.item.id === order.id) {
      setShowHideEditOrder({
        item: order,
        edit: false,
        visible: true,
      });
    }
  }, [order]);

  return changeBreakpoints();
};

export default ItemOrders;
