import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import BoxInfo from './components/BoxInfo';
import SellersGraph from './components/SellersGraph';
import AbstractOrders from './components/AbstractOrders';
import RecentOrders from './components/RecentOrders';
import RecentVisits from './components/RecentVisits';
import LowStockProducts from './components/LowStockProducts';
import Loading from './components/Loading';

import { Auth } from '../../services/auth.context';

import { useProductList } from './hooks/useProducts';
import { useVisitsList } from './hooks/useVisits';
import { useOrdersList } from './hooks/useOrders';
import { useTotalOrdersList } from './hooks/useTotalOrders';
import { useClientsList } from './hooks/useClients';

import './DashboardPage.scss';

const DashboardPage: React.FC = () => {
  const { setPathName } = useContext(Auth);

  const [ordersList, pendingOrders] = useOrdersList();
  const [productsList, pendingProduct] = useProductList();
  const [visitList, pendingVisit] = useVisitsList();
  const [clientsList, pendingClient] = useClientsList();
  const [totalOrdersList, fullOrdersList, pendingTotalOrders] =
    useTotalOrdersList();

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  return (
    <div className="dashboard-background p-py-3 p-px-3">
      {pendingOrders ||
      pendingProduct ||
      pendingVisit ||
      pendingClient ||
      pendingTotalOrders ? (
        <Loading />
      ) : (
        <React.Fragment>
          <div className="p-grid p-mb-3">
            <div className="p-col-12 p-md-6 p-lg-4">
              <BoxInfo
                title="Clientes"
                icon="briefcase"
                useDivision={false}
                number={clientsList.length}
                division={-1}
              />
            </div>
            <div className="p-col-12 p-md-6 p-lg-4">
              <BoxInfo
                title="Visitas em Aberto"
                icon="calendar-alt"
                useDivision={false}
                number={visitList.length}
                division={1}
              />
            </div>
            <div className="p-col-12 p-md-6 p-lg-4">
              <BoxInfo
                title="Pedidos em Aberto"
                icon="book-reader"
                useDivision={true}
                number={
                  fullOrdersList
                    ? fullOrdersList.totalOrders -
                      fullOrdersList.totalFinished -
                      fullOrdersList.totalCancelled
                    : 0
                }
                division={fullOrdersList.totalOrders}
                subtitle="De Todo o Período"
              />
            </div>
          </div>
          <div className="p-grid p-mb-3">
            <div className="p-col-12 p-md-12 p-lg-7">
              <SellersGraph />
            </div>
            <div className="p-col-12 p-md-12 p-lg-5">
              <AbstractOrders totalOrdersList={totalOrdersList} />
            </div>
          </div>
          <div className="p-grid p-mb-3">
            <div className="p-col-12 p-md-6 p-lg-6">
              <RecentVisits visitList={visitList} />
            </div>
            <div className="p-col-12 p-md-6 p-lg-6">
              <LowStockProducts productsList={productsList} />
            </div>
          </div>
          <div className="p-grid p-mb-3">
            <div className="p-col-12 p-md-12 p-lg-12">
              <RecentOrders ordersList={ordersList} />
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default DashboardPage;
