export interface ICategory {
  id: string;
  name: string;
  code3c_id: string;
  imageLight: string;
  imageHeavy: string;
}

export interface IEditCategory {
  item: ICategory;
  edit: boolean;
  visible: boolean;
}

export interface IDeleteCategory {
  id: string;
  name: string;
  visible: boolean;
}

export const initialCategory = {
  id: '',
  name: '',
  code3c_id: '',
  imageLight: '',
  imageHeavy: '',
};
