import firebase from 'firebase';
import 'firebase/auth';

const firebaseConfig = firebase.initializeApp({
  apiKey: 'AIzaSyDnNUC61OQ-4oIJ60zlCjUsaInYTjmqYRk',
  authDomain: 'erp-cansho.firebaseapp.com',
  databaseURL: 'https://erp-cansho.firebaseio.com',
  projectId: 'erp-cansho',
  storageBucket: 'erp-cansho.appspot.com',
  messagingSenderId: '986823077969',
  appId: '1:986823077969:web:9c9c94f6e5b5d506b84870',
  measurementId: 'G-L6P36CMYXX',
});

export default firebaseConfig;
