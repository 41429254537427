// Importações Padrões
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CountUp from 'react-countup';

// Icones
import {
  // FaArrowUp,
  FaBookReader,
  FaBox,
  FaBoxOpen,
  FaCalendarAlt,
  FaCheck,
  FaCheckDouble,
  // FaRegChartBar,
  FaRegTimesCircle,
  FaTruck,
  FaUsers,
} from 'react-icons/fa';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useUserslist } from '../hooks/useUsersList';
import { useVisitsList } from '../hooks/useVisitsList';
import { useTotalOrdersList } from '../hooks/useTotalOrdersList';
// import { useSalesTargetList } from '../hooks/useSalesTarget';

// Componentes
import ChartBarData from '../components/chartDashboard/newChartBarData.jsx';

// Estilos
import '../styles/global/dashboard.scss';

const Dashboard: React.FC = () => {
  const { setPathName, system3cData, userData } = useContext(Auth);

  const { usersList } = useUserslist();
  // const { salesTarget, progressSales } = useSalesTargetList();
  const [visitsList, pendingVisits, pendingVisitsList] = useVisitsList();
  const [totalOrdersList] = useTotalOrdersList();

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  // function getPercentageOf() {
  //   if (salesTarget) {
  //     return (100 * progressSales.totalSales) / salesTarget.price;
  //   }
  //   return 0;
  // }

  return (
    <div className="dashboard-display p-py-4 p-px-3">
      <div className="p-col p-d-flex p-grid">
        <div className="p-col p-d-flex dashboard-box-welcome p-mb-3">
          <h1 className="p-mx-auto p-as-center p-text-center">
            Bem Vindo {userData?.name}!
          </h1>
        </div>
      </div>
      <div className="p-grid p-mb-3">
        <div className="p-col-12 p-md-6 p-lg-4">
          <div className="box dashboard-box-stats p-d-flex">
            <div className="p-mx-auto p-as-center p-d-flex dashboard-box-stats-icon">
              <div className="p-grid p-dir-col p-mx-auto p-my-auto">
                <FaUsers
                  className="p-mx-auto p-as-center p-col p-my-auto p-p-0
                dashboard-box-stats-icon-width"
                />
                <small
                  className="p-text-center p-as-center p-mx-auto p-col p-p-0"
                  style={{ fontSize: '.75rem' }}
                >
                  Usuários Ativos
                </small>
              </div>
            </div>
            <div className="p-mx-auto p-as-center p-d-flex dashboard-box-stats-content">
              <span className="p-ml-auto p-as-center">
                <CountUp end={usersList.length} duration={3} />
              </span>
              <span className="p-mr-auto p-as-center dashboard-box-stats-muted">
                /
                <CountUp
                  end={
                    system3cData?.systemBilling.systemAccounts
                      ? parseInt(system3cData?.systemBilling.systemAccounts, 10)
                      : 10
                  }
                  duration={3}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-lg-4">
          <div className="box dashboard-box-stats p-d-flex">
            <div className="p-mx-auto p-as-center p-d-flex dashboard-box-stats-icon">
              <div className="p-grid p-dir-col p-mx-auto p-my-auto">
                <FaCalendarAlt
                  className="p-mx-auto p-as-center p-col p-my-auto p-p-0
                dashboard-box-stats-icon-width"
                />
                <small
                  className="p-text-center p-as-center p-mx-auto p-col p-p-0"
                  style={{ fontSize: '.75rem' }}
                >
                  Visitas em Aberto
                </small>
              </div>
            </div>
            <div className="p-mx-auto p-as-center p-d-flex dashboard-box-stats-content">
              {pendingVisits ? (
                <>
                  <span className="p-ml-auto p-as-center">?</span>
                  <span className="p-mr-auto p-as-center dashboard-box-stats-muted">
                    /?
                  </span>
                </>
              ) : (
                <>
                  <span className="p-ml-auto p-as-center">
                    <CountUp end={pendingVisitsList.length} duration={3} />
                  </span>
                  <span className="p-mr-auto p-as-center dashboard-box-stats-muted">
                    /
                    <CountUp end={visitsList.length} duration={3} />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-lg-4">
          <div className="box dashboard-box-stats p-d-flex">
            <div className="p-mx-auto p-as-center p-d-flex dashboard-box-stats-icon">
              <div className="p-grid p-dir-col p-mx-auto p-my-auto">
                <FaBookReader
                  className="p-mx-auto p-as-center p-col p-my-auto p-p-0
                 dashboard-box-stats-icon-width"
                />
                <small
                  className="p-text-center p-as-center p-mx-auto p-col p-p-0"
                  style={{ fontSize: '.75rem' }}
                >
                  Pedidos em Aberto
                </small>
              </div>
            </div>
            <div className="p-mx-auto p-as-center p-d-flex dashboard-box-stats-content">
              <span className="p-ml-auto p-as-center">
                <CountUp
                  end={
                    totalOrdersList
                      ? totalOrdersList.totalOrders -
                        totalOrdersList.totalFinished -
                        totalOrdersList.totalCancelled
                      : 0
                  }
                  duration={3}
                />
              </span>
              <span className="p-mr-auto p-as-center dashboard-box-stats-muted">
                /
                <CountUp end={totalOrdersList.totalOrders} duration={3} />
              </span>
            </div>
          </div>
        </div>
        {/* <div className="p-col-12 p-md-6 p-lg-3">
          <div className="box dashboard-box-stats p-d-flex">
            <div className="p-mx-auto p-as-center p-d-flex dashboard-box-stats-icon">
              <div className="p-grid p-dir-col p-mx-auto p-my-auto">
                <FaRegChartBar
                  className="p-mx-auto p-as-center p-col p-my-auto p-p-0
                dashboard-box-stats-icon-width"
                />
                <small
                  className="p-text-center p-as-center p-mx-auto p-col p-p-0"
                  style={{ fontSize: '.75rem' }}
                >
                  Meta de Vendas
                </small>
              </div>
            </div>
            <div className="p-mx-auto p-as-center p-d-flex dashboard-box-stats-content">
              <div className="p-grid p-dir-col p-mx-auto p-my-auto">
                {salesTarget ? (
                  <>
                    <div
                      className="p-mx-auto p-as-center p-col p-my-auto p-p-0"
                      style={{ fontSize: '2rem' }}
                    >
                      <FaArrowUp
                        style={{
                          fontSize: '1rem',
                          color:
                            getPercentageOf() > 80 ? 'green' : 'darkorange',
                        }}
                      />
                      <CountUp end={getPercentageOf()} duration={3} />%
                    </div>
                    <small
                      className="p-text-center p-as-center p-mx-auto p-col p-p-0"
                      style={{ fontSize: '.75rem' }}
                    >
                      da Meta Alcançada
                    </small>
                  </>
                ) : (
                  <small
                    className="p-text-center p-as-center p-mx-auto p-col p-p-0"
                    style={{ fontSize: '.75rem' }}
                  >
                    Nenhuma Meta Cadastrada
                  </small>
                )}
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="p-grid">
        <div className="p-col-12 p-md-6 p-lg-4" style={{ overflow: 'hidden' }}>
          <div className="p-p-0 p-m-0 dashboard-box-graphics">
            <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
              <div className="p-col-10 p-as-center dashboard-box-order-status">
                <FaBookReader className="p-mr-2" />
                TODOS OS PEDIDOS
              </div>
              <div
                className="p-col-2 p-text-center p-as-center
              dashboard-box-order-numbers"
              >
                {totalOrdersList.totalOrders}
              </div>
            </div>

            <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
              <div className="p-col-10 p-as-center dashboard-box-order-status">
                <FaCheck className="p-mr-2" />
                PEDIDOS CRIADOS
              </div>
              <div className="p-col-2 p-text-center p-as-center dashboard-box-order-numbers">
                {totalOrdersList.totalCreated}
              </div>
            </div>
            <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
              <div
                className="p-col-10 p-as-center
              dashboard-box-order-status"
              >
                <FaBoxOpen className="p-mr-2" />
                PEDIDOS PROCESSANDO
              </div>
              <div
                className="p-col-2 p-text-center p-as-center
              dashboard-box-order-numbers"
              >
                {totalOrdersList.totalProcessing}
              </div>
            </div>
            <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
              <div
                className="p-col-10 p-as-center
              dashboard-box-order-status"
              >
                <FaBox className="p-mr-2" />
                PEDIDOS PROCESSADOS
              </div>
              <div
                className="p-col-2 p-text-center p-as-center
              dashboard-box-order-numbers"
              >
                {totalOrdersList.totalProcessed}
              </div>
            </div>
            <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
              <div
                className="p-col-10 p-as-center
              dashboard-box-order-status"
              >
                <FaTruck className="p-mr-2" />
                PEDIDOS EXPEDIDOS
              </div>
              <div
                className="p-col-2 p-text-center p-as-center
              dashboard-box-order-numbers"
              >
                {totalOrdersList.totalSended}
              </div>
            </div>
            <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
              <div
                className="p-col-10 p-as-center
              dashboard-box-order-status"
              >
                <FaCheckDouble className="p-mr-2" />
                PEDIDOS FINALIZADOS
              </div>
              <div
                className="p-col-2 p-text-center p-as-center
              dashboard-box-order-numbers"
              >
                {totalOrdersList.totalFinished}
              </div>
            </div>
            <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
              <div
                className="p-col-10 p-as-center
              dashboard-box-order-status"
              >
                <FaRegTimesCircle className="p-mr-2" />
                PEDIDOS CANCELADOS
              </div>
              <div
                className="p-col-2 p-text-center p-as-center
              dashboard-box-order-numbers"
              >
                {totalOrdersList.totalCancelled}
              </div>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-lg-8">
          <div className="p-p-0 p-m-0 dashboard-box-graphics">
            <div className="p-p-0 p-m-0 dashboard-box-charts">
              <ChartBarData />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
