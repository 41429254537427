import { useState, useEffect, useContext } from 'react';
import { ProgressSpinnerSys } from '../global/progressSpinner.component';
import { IListProducts } from '../../typings/list.types';
import ServiceOnList from './serviceOnList.component';
import { useServicesList } from '../../hooks/useServicesList';
import { Auth } from '../../services/auth.context';

interface IListItemsProps {
  inputSearch: string;
  servicesOnList: IListProducts;
  setServicesOnList: React.Dispatch<React.SetStateAction<IListProducts>>;
}

const ListItemsServices = (props: IListItemsProps) => {
  const { windowWidth } = useContext(Auth);

  const { servicesOnList, setServicesOnList, inputSearch } = props;
  const [serviceList] = useServicesList();
  const [pending, setPending] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPending(false);
    }, 1000);
  }, []);

  return (
    <div>
      {windowWidth && windowWidth > 900 && (
        <div className="p-grid p-my-1 p-mx-auto p-d-none p-d-md-inline-flex default-box-header">
          <div className="p-col-1 p-text-center p-as-center">Inserir</div>
          <div className="p-col-6 p-text-center p-as-center">Nome</div>
          <div className="p-col-3 p-text-center p-as-center">Referência</div>
          <div className="p-col-2 p-text-center p-as-center">Preço</div>
        </div>
      )}

      {!pending ? (
        serviceList
          .filter(
            (item) =>
              item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !== -1,
          )
          .map((item, index) => (
            <ServiceOnList
              key={String(index)}
              index={index}
              item={item}
              servicesOnList={servicesOnList}
              setServicesOnList={setServicesOnList}
              inputSearch={inputSearch}
            />
          ))
      ) : (
        <div
          className="p-d-flex p-jc-center p-as-center"
          style={{ height: '70vh' }}
        >
          <ProgressSpinnerSys />
        </div>
      )}
    </div>
  );
};

export default ListItemsServices;
