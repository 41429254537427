import { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import { Auth } from '../../../services/auth.context';
import { IOrder } from '../../../typings/order.types';
import { orderCollection } from '../../../controllers/order.controller';

export function useOrdersList(): [Array<IOrder>, boolean] {
  const { code3cData } = useContext(Auth);

  const [ordersList, setOrdersList] = useState<Array<IOrder>>([]);
  const [pendingOrders, setPendingOrders] = useState<boolean>(true);

  useEffect(() => {
    setPendingOrders(true);

    const startDate = new Date(
      moment()
        .subtract(7, 'd')
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .format(),
    );
    const endDate = new Date(
      moment()
        .set({
          hour: 23,
          minute: 59,
          second: 59,
        })
        .format(),
    );

    const unsubscribe = orderCollection
      .where('code3c_id', '==', code3cData?.id)
      .where('inOrder', '==', false)
      .where('created_at', '>=', startDate)
      .where('created_at', '<=', endDate)
      .orderBy('created_at', 'desc')

      .onSnapshot((response) => {
        const list: Array<IOrder> = [];

        response.docs.forEach((doc) => {
          const newDocument = doc.data();
          const documentData = {
            ...doc.data(),
            id: doc.id,
            created_at: newDocument.created_at.toDate(),
            updated_at: newDocument.created_at.toDate(),
          } as IOrder;

          list.push(documentData);
        });
        setOrdersList(
          list.filter(
            (item) =>
              item.status.finished === 'Pendente' &&
              item.status.cancelled === 'Pendente',
          ),
        );
        setPendingOrders(false);
      });

    return () => {
      unsubscribe();
    };
  }, [code3cData?.id]);

  return [ordersList, pendingOrders];
}
