// Importações Padrões
import React, { useEffect } from 'react';
import { maskBr } from 'js-brasil';

// React Prime
import { InputText } from 'primereact/inputtext';

// Types
import { IValidationError } from '../../typings/yup.types';

// Componentes
import { catchErrors, catchInvalid } from '../global/formErrors.component';

interface IInputFormProps {
  disabled: boolean;
  item: number;
  setItem: React.Dispatch<React.SetStateAction<number>>;
  formErr: Array<IValidationError>;
  fieldLabel: string;
  fieldCode: string;
  floatLabel: boolean;
  iconStatus: () => JSX.Element;
}

function formatCurrency(currency: string, price: number) {
  switch (true) {
    case currency === 'BRL': {
      return maskBr.currency(price / 100);
    }
    case currency === 'USD': {
      return maskBr.currency(price / 100).replace('R$', '$');
    }
    default: {
      return `${(price / 100).toFixed(2)}`;
    }
  }
}

const InputFormCurrency = (props: IInputFormProps) => {
  const {
    disabled,
    item,
    setItem,
    formErr,
    fieldLabel,
    fieldCode,
    floatLabel,
    iconStatus,
  } = props;

  useEffect(() => {
    if (Number.isNaN(item)) {
      setItem(0);
    }
  }, [item, setItem]);

  return (
    <div className="p-field">
      {!floatLabel && <label className="p-my-0 p-mt-4">{fieldLabel}</label>}

      <span className="p-d-flex p-float-label p-input-icon-right">
        {iconStatus()}
        <InputText
          disabled={disabled}
          id="priceLabel"
          className={`${
            !floatLabel &&
            (catchInvalid(formErr, fieldCode)
              ? 'settings-input-invalid'
              : 'settings-input')
          }`}
          value={formatCurrency('BRL', item)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setItem(
              parseInt(event.target.value.replace(/([^\d])+/gim, ''), 10),
            );
          }}
        />
        {floatLabel && (
          <label htmlFor="priceLabel" className="p-my-0">
            {fieldLabel}
          </label>
        )}
      </span>
      {catchErrors(formErr, fieldCode)}
    </div>
  );
};

export default InputFormCurrency;
