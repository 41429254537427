// Importações Padrões
import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

// Contexto
import { Auth } from '../../services/auth.context';

// Componentes
import ConfirmNewTab from './confirmNewTab.component';

// Estilos
import '../../styles/global/footer.scss';

const Footer: React.FC = () => {
  const { extender, currentUser } = useContext(Auth);

  const mantainerLink = 'https://www.canshogroup.com.br/';

  const toggleDisplay = useCallback(() => {
    if (!!currentUser) {
      if (extender) {
        return 'p-d-flex footer-display-menu-extended';
      }
      return 'p-d-flex footer-display-menu-closed';
    }
    return 'p-d-flex footer-display-login';
  }, [extender, currentUser]);

  return (
    <div className={toggleDisplay()}>
      <p className="p-my-0 p-mx-auto p-text-center p-as-center ">
        @Copyright {moment().format('YYYY')} - &nbsp;
        <span className="p-d-none p-d-md-inline-flex">
          Todos os direitos reservados. - Criado Orgulhosamente por &nbsp;
        </span>
        <span
          className="mantainer-link"
          aria-hidden="true"
          onPointerDown={(e) => {
            e.buttons === 1 && ConfirmNewTab(mantainerLink);
          }}
        >
          Cansho®
        </span>
      </p>
    </div>
  );
};

export default Footer;
