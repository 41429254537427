// Importações Padrões
import { useEffect, useState } from 'react';

// Icones
import { FaHome } from 'react-icons/fa';

// React Prime
import { Button } from 'primereact/button';

// Controladores
import { consultCep } from '../../controllers/cep.controller';

// Types
import { IClientAddress } from '../../typings/client.types';
import { IValidationError } from '../../typings/yup.types';

// Componentes
import InputForm from '../input/inputForm.component';
import InputFormFunction from '../input/inputFormFunction.component';

// Estilos
import '../../styles/global/addressList.scss';

interface IAddressListProps {
  disabled: boolean;
  formErr: Array<IValidationError>;
  address: IClientAddress;
  addressList: Array<IClientAddress>;
  setAddressList: React.Dispatch<React.SetStateAction<Array<IClientAddress>>>;
}

const AddressList = (props: IAddressListProps) => {
  const { disabled, formErr, address, addressList, setAddressList } = props;

  const [postalCode, setPostalCode] = useState<string>(address.postalCode);
  const [province, setProvince] = useState<string>(address.province);
  const [city, setCity] = useState<string>(address.city);
  const [neighborhood, setNeighborhood] = useState<string>(
    address.neighborhood,
  );
  const [street, setStreet] = useState<string>(address.street);
  const [houseNumber, setHouseNumber] = useState<string>(address.houseNumber);

  async function getAddress(CEP: string) {
    setPostalCode(CEP);
    if (CEP.replace('-', '').length === 8) {
      const AddressInfo = await consultCep(CEP);
      setPostalCode(AddressInfo.cep);
      setProvince(AddressInfo.uf);
      setCity(AddressInfo.localidade);
      setNeighborhood(AddressInfo.bairro);
      setStreet(AddressInfo.logradouro);
    }
  }

  function addressListCreate() {
    setPostalCode(address.postalCode);
    setProvince(address.province);
    setCity(address.city);
    setNeighborhood(address.neighborhood);
    setStreet(address.street);
    setHouseNumber(address.houseNumber);
  }

  function addressListTransform() {
    setAddressList(
      addressList.map((item) => {
        if (item.index === address.index) {
          return {
            index: address.index,
            postalCode,
            province,
            city,
            neighborhood,
            street,
            houseNumber,
          };
        }
        return item;
      }),
    );
  }

  function addressListDestroy() {
    const emptyString = '';
    setPostalCode(emptyString);
    setProvince(emptyString);
    setCity(emptyString);
    setNeighborhood(emptyString);
    setStreet(emptyString);
    setHouseNumber(emptyString);
  }

  function removeAddress() {
    const filterAddressList = addressList.filter(
      (item) => item.index !== address.index,
    );
    addressListDestroy();
    const newAddressList = filterAddressList.map((adrs, id) => {
      return {
        ...adrs,
        index: id + 1,
      };
    });
    setAddressList(newAddressList);
  }
  useEffect(() => {
    addressListTransform();
    // Corrigir
    // eslint-disable-next-line
  }, [postalCode, province, city, neighborhood, street, houseNumber]);

  useEffect(() => {
    addressListCreate();
    // Corrigir
    // eslint-disable-next-line
  }, [addressList.length]);

  return (
    <div>
      <div className="p-mt-3 p-col-12 p-px-0 p-mx-0">
        <div className="p-grid p-px-2">
          <span className="p-mr-2 p-d-flex addressList-icon-display">
            <FaHome className="p-mx-auto p-as-center addressList-icon-icon" />
          </span>
          <span className="p-as-center">
            Endereço {address.index === 1 ? 'Principal' : address.index}
          </span>
          {address.index !== 1 && (
            <Button
              disabled={disabled}
              className="p-ml-auto p-as-center addressList-button-remove"
              label="Remover"
              onClick={() => {
                removeAddress();
              }}
            />
          )}
        </div>
      </div>
      <div className="p-grid p-mt-2">
        <div className="p-col-12 p-md-6 p-lg-6">
          <label className="p-my-0 p-mt-4">CEP</label>
          <InputFormFunction
            disabled={disabled}
            maxLength={11}
            item={postalCode}
            setFunction={getAddress}
            formErr={formErr}
            fieldCode={`address[${address.index - 1}].postalCode`}
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputForm
            disabled={disabled}
            pleaceHolder="Insira o CEP"
            item={province}
            setItem={setProvince}
            formErr={formErr}
            fieldLabel="Estado"
            fieldCode={`address[${address.index - 1}].province`}
          />
        </div>
      </div>
      <div className="p-grid p-mt-2">
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputForm
            disabled={disabled}
            pleaceHolder="Insira o CEP"
            item={city}
            setItem={setCity}
            formErr={formErr}
            fieldLabel="Cidade"
            fieldCode={`address[${address.index - 1}].city`}
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputForm
            disabled={disabled}
            pleaceHolder=""
            item={neighborhood}
            setItem={setNeighborhood}
            formErr={formErr}
            fieldLabel="Bairro"
            fieldCode={`address[${address.index - 1}].neighborhood`}
          />
        </div>
      </div>
      <div className="p-grid p-mt-2">
        <div className="p-col-12 p-md-10 p-lg-10">
          <InputForm
            disabled={disabled}
            pleaceHolder=""
            item={street}
            setItem={setStreet}
            formErr={formErr}
            fieldLabel="Rua"
            fieldCode={`address[${address.index - 1}].street`}
          />
        </div>
        <div className="p-col-12 p-md-2 p-lg-2">
          <InputForm
            disabled={disabled}
            pleaceHolder=""
            item={houseNumber}
            setItem={setHouseNumber}
            formErr={formErr}
            fieldLabel="Nº"
            fieldCode={`address[${address.index - 1}].houseNumber`}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressList;
