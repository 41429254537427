import firebase from 'firebase';

import {
  toasterError,
  toasterSuccess,
} from '../components/global/toaster.component';

import firebaseConfig from '../config/config.firebase';

import { SalesTarget } from '../typings/salesTarget';

// Nome provisório;
export const salesGoalCollection = firebaseConfig
  .firestore()
  .collection('FDV-salesGoal');

export const addSalesTarget = async (data: Partial<SalesTarget>) => {
  try {
    const response = await salesGoalCollection.add({
      ...data,
      created_at: firebase.firestore.Timestamp.now(),
      updated_at: firebase.firestore.Timestamp.now(),
    });
    if (response.id) {
      toasterSuccess('CRIADO', 'Nova Meta Criada');
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua categoria!');
    return false;
  }
};
