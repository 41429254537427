import { ProgressBar } from 'primereact/progressbar';

import { formatCurrency } from '../../../functions/list.function';

import './DataTableBody.scss';

export interface FeedBackText {
  message: string;
  color: string;
}

export const formatGoalPotential = (value: number): FeedBackText => {
  if (value > -1) {
    return { message: formatCurrency('USD', value), color: 'none' };
  }
  return { message: 'NÃO CRIADO', color: 'none' };
};

export const formatRealized = (value: number, comparator: number): FeedBackText => {
  if (value > -1 && comparator > -1) {
    switch (true) {
      case value >= comparator: {
        return { message: formatCurrency('USD', value), color: 'good' };
      }
      case value >= comparator * 0.75: {
        return { message: formatCurrency('USD', value), color: 'regular' };
      }
      default: {
        return { message: formatCurrency('USD', value), color: 'bad' };
      }
    }
  }
  return { message: 'NÃO CRIADO', color: 'none' };
};

export const formatNotRealized = (value: number, comparator: number): FeedBackText => {
  if (value !== 0.1 && comparator > -1) {
    switch (true) {
      case value >= 0: {
        return { message: `${value > 0 ? '+' : ''}${formatCurrency('USD', value)}`, color: 'good' };
      }
      case value >= comparator * 0.25 * -1: {
        return { message: formatCurrency('USD', value), color: 'regular' };
      }
      default: {
        return { message: formatCurrency('USD', value), color: 'bad' };
      }
    }
  }
  return { message: 'NÃO CRIADO', color: 'none' };
};

export const parseGoal = (value: number): FeedBackText => {
  if (value > -1) {
    return { message: formatCurrency('USD', value), color: 'none' };
  }
  return { message: '---', color: 'none' };
};

export const parseRealized = (value: number, comparator: number): FeedBackText => {
  if (value > -1 && comparator > -1) {
    switch (true) {
      case value >= comparator: {
        return { message: formatCurrency('USD', value), color: 'good' };
      }
      case value >= comparator * 0.75: {
        return { message: formatCurrency('USD', value), color: 'regular' };
      }
      default: {
        return { message: formatCurrency('USD', value), color: 'bad' };
      }
    }
  }
  return { message: '---', color: 'none' };
};

export const parseNotRealized = (value: number, comparator: number): FeedBackText => {
  if (value !== 0.1 && comparator > -1) {
    switch (true) {
      case value >= 0: {
        return { message: `${value > 0 ? '+' : ''}${formatCurrency('USD', value)}`, color: 'good' };
      }
      case value >= comparator * 0.25 * -1: {
        return { message: formatCurrency('USD', value), color: 'regular' };
      }
      default: {
        return { message: formatCurrency('USD', value), color: 'bad' };
      }
    }
  }
  return { message: '---', color: 'none' };
};

export const parseStatus = (active: string): FeedBackText => {
  if (active === 'ABERTO') {
    return { message: 'ABERTO', color: 'opened' };
  }
  return { message: 'NÃO CRIADO', color: 'not-created' };
};

export const goalBodyTemplate = (feedback: FeedBackText) => {
  return <span className={`goal-template ${feedback.color}`}>{feedback.message}</span>;
};

export const feedbackBodyTemplate = (feedback: FeedBackText) => {
  return <span className={`feedback-template ${feedback.color}`}>{feedback.message}</span>;
};

export const statusBodyTemplate = (status: FeedBackText) => {
  return <span className={`status-body-template ${status.color}`}>{status.message}</span>;
};

export const progressBodyTemplate = (progress: number) => {
  const newProgress = Number.isNaN(progress) || !Number.isFinite(progress) ? 0 : progress;
  return (
    <div className="progress-bar-body">
      <ProgressBar value={newProgress} showValue className="progress-bar-template" />
      <small>{`${newProgress.toFixed(2)}%`}</small>
    </div>
  );
};

export const statusTemplate = (status: FeedBackText) => {
  return <span className={`status-template ${status.color}`}>{status.message}</span>;
};
