// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Contexto
import { Auth } from '../services/auth.context';

// Types
import { IProvince } from '../typings/province.types';

// Controladores
import { provincesCollection } from '../controllers/province.controller';

export function useProvincesList(): [Array<IProvince>, boolean] {
  const { code3cData } = useContext(Auth);

  const [provincesList, setProvincesList] = useState<Array<IProvince>>([]);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    setPending((state) => !state);
    const unsubscribeDb = provincesCollection.onSnapshot((response) => {
      const list: Array<IProvince> = [];

      response.docs.forEach((doc) => {
        const isEnabled = doc.data() as IProvince;
        const code3cId = code3cData?.id ? code3cData.id : '';

        if (isEnabled.code3cEnabled.find((item) => code3cId === item)) {
          const documentData = {
            ...doc.data(),
            id: doc.id,
            active: true,
          } as IProvince;
          list.push(documentData);
        } else {
          const documentData = {
            ...doc.data(),
            id: doc.id,
            active: false,
          } as IProvince;
          list.push(documentData);
        }
      });
      setProvincesList(list);
    });

    setPending((state) => !state);

    return () => {
      unsubscribeDb();
      setPending(false);
    };
  }, [code3cData?.id]);

  return [provincesList, pending];
}
