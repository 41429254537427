export interface IFreight {
  id: string;
  name: string;
  code3c_id: string;
  imageLight: string;
  imageHeavy: string;
}

export interface IEditFreight {
  item: IFreight;
  edit: boolean;
  visible: boolean;
}

export interface IDeleteFreight {
  id: string;
  name: string;
  visible: boolean;
}

export const initialFreight: IFreight = {
  id: '',
  name: '',
  code3c_id: '',
  imageHeavy: '',
  imageLight: '',
};
