import { useContext, useState, useEffect } from 'react';
import moment from 'moment';

import { Auth } from '../../../services/auth.context';
import { SalesTarget } from '../../../typings/salesTarget';
import { salesGoalCollection } from '../../../controllers/salesTarget';

export interface UseSalesTarget {
  mainSalesTarget: SalesTarget | undefined | null;
  listSalesTarget: Array<SalesTarget>;
  pending: boolean;
}

export const useSalesTarget = (): UseSalesTarget => {
  const { code3cData } = useContext(Auth);

  const [mainSalesTarget, setMainSalesTarget] = useState<SalesTarget | undefined | null>(null);
  const [listSalesTarget, setListSalesTarget] = useState<Array<SalesTarget>>([]);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = salesGoalCollection.where('code3c_id', '==', code3cData!.id).onSnapshot((response) => {
      const list: Array<SalesTarget> = [];
      response.docs.forEach((doc) => {
        const newDocument = doc.data();
        const documentData = {
          ...doc.data(),
          startDate: newDocument.startDate.toDate(),
          endDate: newDocument.endDate.toDate(),
          id: doc.id,
        } as SalesTarget;
        list.push(documentData);
      });

      const mainSalesGoal = list.find((document) => moment().isBetween(document.startDate, document.endDate));

      setListSalesTarget(list);
      setMainSalesTarget(mainSalesGoal);
    });
    return () => {
      unsubscribe();
      setTimeout(() => {
        setPending(false);
      }, 400);
    };
  }, [code3cData]);

  return {
    mainSalesTarget,
    listSalesTarget,
    pending,
  };
};
