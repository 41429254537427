export interface IUser {
  id: string;
  name: string;
  playerIDs?: string[];
  citiesEnabled?: string[];
  code3c_id: string;
  sector_id?: string;
  sector_name?: string;
  heavyImage?: string;
  email: string;
  password: string;
  passwordValidation?: string;
  commission?: number;
  systems: Array<ISystemsUser>;
  updated_at?: Date;
  created_at?: Date;
}

export interface ISystemsUser {
  systemId: string;
  systemName: string;
  systemActive: boolean;
  function: string;
}

export interface IEditUser {
  item: IUser;
  edit: boolean;
  visible: boolean;
}

export interface IDeleteUser {
  id: string;
  name: string;
  visible: boolean;
}

export interface ISystemFunction {
  name: string;
  code: string;
}

export type IFunction =
  | 'administrator' // ALL
  | 'master' // ALL-HIDDEN
  | 'visitor' // CFA
  | 'seller' // CFDV
  | 'stockist' // CFDV
  | 'user' // ALL
  | 'controller'; // CGA

export const initialUser: IUser = {
  id: '',
  name: '',
  code3c_id: '',
  email: '',
  password: '',
  systems: [
    {
      systemId: '',
      systemName: '',
      systemActive: true,
      function: '',
    },
  ],
};
