// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollTop } from 'primereact/scrolltop';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useProvidersList } from '../hooks/useProvidersList';

// Types
import {
  IProvider,
  IDeleteProvider,
  IEditProvider,
  initialProvider,
} from '../typings/provider.types';

// Componentes
import ItemProvider from '../components/providers/itemProvider.component';
import AddProvider from '../components/providers/addProvider.component';
import EditProvider from '../components/providers/editProvider.component';
import DeleteProvider from '../components/providers/deleteProvider.component';
import NavProvider from '../components/providers/navProvider.component';

const Providers: React.FC = () => {
  const { setPathName, windowWidth } = useContext(Auth);

  const [providersList, pending] = useProvidersList();

  const [showHideAddProvider, setShowHideAddProvider] = useState(false);
  const [showHideEditProvider, setShowHideEditProvider] =
    useState<IEditProvider>({
      item: initialProvider,
      edit: false,
      visible: false,
    });
  const [showHideDeleteProvider, setShowHideDeleteProvider] =
    useState<IDeleteProvider>({ id: '', name: '', visible: false });

  const [inputSearch, setInputSearch] = useState('');

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  useEffect(() => {
    if (
      showHideAddProvider ||
      showHideEditProvider.visible ||
      showHideDeleteProvider.visible
    ) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHideAddProvider, showHideEditProvider, showHideDeleteProvider]);

  return (
    <div className="p-py-3 p-px-3 widthAuto">
      <ScrollTop className="button-scroll-top" />
      <AddProvider
        visible={showHideAddProvider}
        setVisible={setShowHideAddProvider}
      />
      <EditProvider
        visible={showHideEditProvider}
        setVisible={setShowHideEditProvider}
      />
      <DeleteProvider
        visible={showHideDeleteProvider}
        setVisible={setShowHideDeleteProvider}
      />
      <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
        <NavProvider
          placeHolder="Pesquisar..."
          pending={!pending}
          addFieldName="Novo Fornecedor"
          actionAdd={showHideAddProvider}
          setActionAdd={setShowHideAddProvider}
          setInputSearch={setInputSearch}
          activeAdd
        />
        <div className="p-d-flex p-dir-col p-mt-2 p-mb-auto width100">
          {windowWidth && windowWidth > 900 && (
            <div
              className="p-grid p-my-1 p-px-3 p-mx-auto p-d-none p-d-md-inline-flex default-box-header"
              style={{
                backgroundColor: '#f8f9fa',
                border: '1px solid #e9ecef',
              }}
            >
              <div className="p-col-1 p-text-center p-as-center">Id</div>
              <div className="p-col-3 p-text-center p-as-center">Imagem</div>
              <div className="p-col-2 p-text-center p-as-center">
                Fornecedor
              </div>
              <div className="p-col-1 p-text-center p-as-center">Tipo</div>
              <div className="p-col-3 p-text-center p-as-center">CNPJ/CPF</div>
              <div className="p-col-2 p-text-center p-as-center">Ações</div>
            </div>
          )}

          {providersList
            .filter(
              (item) =>
                item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                -1,
            )
            .map((provider: IProvider, index: number) => {
              return (
                <ItemProvider
                  key={String(index)}
                  index={index + 1}
                  provider={provider}
                  showHideEditProvider={showHideEditProvider}
                  setShowHideEditProvider={setShowHideEditProvider}
                  showHideDeleteProvider={showHideDeleteProvider}
                  setShowHideDeleteProvider={setShowHideDeleteProvider}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Providers;
