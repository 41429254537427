// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Contexto
import { Auth } from '../services/auth.context';

// Types
import { ICity } from '../typings/cities.types';

// Controladores
import { citiesCollection } from '../controllers/city.controller';

export function useCitiesList(cityId: string): [Array<ICity>, boolean] {
  const { code3cData } = useContext(Auth);

  const [citiesList, setCitiesList] = useState<Array<ICity>>([]);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    setPending((state) => !state);
    const unsubscribeDb = citiesCollection
      .where('code3c_id', '==', code3cData?.id)
      .where('province_id', '==', cityId)
      .onSnapshot((response) => {
        const list: Array<ICity> = [];

        response.docs.forEach((doc) => {
          const documentData = { ...doc.data(), id: doc.id } as ICity;
          list.push(documentData);
        });
        setCitiesList(list);
      });

    setPending((state) => !state);

    return () => {
      unsubscribeDb();
      setPending(false);
    };
  }, [code3cData?.id, cityId]);

  return [citiesList, pending];
}
