// Importações Padrões
import React from 'react';

// React Prime
import { InputText } from 'primereact/inputtext';

// Types
import { IValidationError } from '../../typings/yup.types';

// Componentes
import { catchErrors, catchInvalid } from '../global/formErrors.component';

interface IInputFormProps {
  disabled: boolean;
  item: string;
  setItem: React.Dispatch<React.SetStateAction<string>>;
  formErr: Array<IValidationError>;
  fieldLabel: string;
  fieldCode: string;
  pleaceHolder: string;
}

const InputForm = (props: IInputFormProps) => {
  const {
    disabled,
    item,
    setItem,
    formErr,
    fieldLabel,
    fieldCode,
    pleaceHolder,
  } = props;
  return (
    <div className="p-field">
      <label className="p-my-0 p-mt-4">{fieldLabel}</label>
      <span className="p-d-flex p-input-icon-right">
        {catchInvalid(formErr, fieldCode) && (
          <i className="pi pi-times input-invalid" />
        )}
        <InputText
          disabled={disabled}
          className={
            catchInvalid(formErr, fieldCode)
              ? 'settings-input-invalid'
              : 'settings-input'
          }
          value={item}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setItem(event.target.value);
          }}
          placeholder={pleaceHolder}
        />
      </span>
      {catchErrors(formErr, fieldCode)}
    </div>
  );
};

export default InputForm;
