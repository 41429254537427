import { useCallback, useContext, useEffect } from 'react';
import { Skeleton } from 'primereact/skeleton';

import { useSalesTarget } from './hooks/useSalesTarget';

import { SalesTargetHeader } from './components/SalesTargetHeader';
import { CreateSalesTarget } from './components/CreateSalesTarget';
import { ClientSalesTarget } from './components/ClientSalesTarget';
import { SellerSalesTarget } from './components/SellerSalesTarget';
import { SelectedSellerTarget } from './components/SelectedSellerTarget';
import { SelectedClientTarget } from './components/SelectedClientTarget';

import { SalesTargetProvider } from './contexts/SalesTargetContextt';
import { SalesTargetView } from './contexts/SalesTargetContextInterface';

import './SalesTarget.scss';

export default function SalesTarget(): JSX.Element {
  const { clients, toggleView, setToggleView, pendingInfo } =
    useContext(SalesTargetProvider);

  const { mainSalesTarget } = useSalesTarget();

  const toggleViewHandler = useCallback(
    (view: SalesTargetView | undefined) => {
      switch (view) {
        case 'CLIENTS': {
          return <ClientSalesTarget />;
        }
        case 'SELLERS': {
          return <SellerSalesTarget />;
        }
        case 'CREATE': {
          return <CreateSalesTarget />;
        }
        case 'SELECTED_CLIENT': {
          return <SelectedClientTarget mainSalesTarget={mainSalesTarget} />;
        }
        case 'SELECTED_SELLER': {
          return <SelectedSellerTarget mainSalesTarget={mainSalesTarget} />;
        }
        default: {
          return <p>Sem Dados</p>;
        }
      }
    },
    [toggleView, mainSalesTarget, clients],
  );

  useEffect(() => {
    if (mainSalesTarget === undefined) {
      setToggleView!('CREATE');
    }
    if (mainSalesTarget !== undefined) {
      setToggleView!('CLIENTS');
    }
  }, [mainSalesTarget]);

  return (
    <div className="p-py-3 p-px-3 width100">
      <div className="p-py-3 p-px-3 p-text-justify dashboard-display sales-target">
        <SalesTargetHeader />
        {pendingInfo ? (
          <Skeleton width="100%" className="skel"></Skeleton>
        ) : (
          toggleViewHandler(toggleView)
        )}
      </div>
    </div>
  );
}
