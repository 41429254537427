import React, { useState } from 'react';

import { FaFileExport } from 'react-icons/fa';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';

import { IExportProducts } from '../../typings/product.types';

import ExportExcelButton from './exportExcelButton.component';

import '../../styles/components/products/exportProducts.scss';

interface IExports {
  exports: IExportProducts;
  setExports: React.Dispatch<React.SetStateAction<IExportProducts>>;
}

interface IExt {
  name: string;
  code: string;
}

const ExportProducts = (props: IExports): JSX.Element => {
  const { exports, setExports } = props;

  const [options, setOptions] = useState<Array<string>>(['ESTOQUE']);
  const [format, setFormat] = useState<IExt>({
    name: 'XLSX (Excel 2007 ou Superior)',
    code: 'XLSX',
  });

  const formatSelect: Array<IExt> = [
    { name: 'XLSX (Excel 2007 ou Superior)', code: 'XLSX' },
    { name: 'XLS (Excel 97 ~ 2004)', code: 'XLS' },
    { name: 'XLML (Outras Versões de planilha)', code: 'XLML' },
    { name: 'CSV (Valores separados por vírgula)', code: 'CSV' },
  ];

  const onOptionsChange = (e: { value: string; checked: boolean }) => {
    const selectedOptions = [...options];

    if (e.checked) selectedOptions.push(e.value);
    else selectedOptions.splice(selectedOptions.indexOf(e.value), 1);

    setOptions(selectedOptions);
  };

  const initialState = () => {
    setExports({
      item: [],
      visible: false,
    });
  };

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              <FaFileExport className="p-mx-auto p-as-center modal-header-icon" />
            </div>
            <span className="p-my-auto users-button-text">
              Exportar Produtos
            </span>
          </div>
        );
      }}
      visible={exports.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '35rem' }}
      footer={() => {
        return (
          <p className="p-p-0 p-m-0 p-text-center">
            <small className="p-text-center">
              Antes de imprimir, verificar o layout de impressão no leitor de
              sua preferência.
            </small>
          </p>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <p className="p-text-center exportProducts-subtitle">
        <span>{exports.item.length}</span> Produtos a serem Exportados
      </p>
      <div className="p-grid">
        <div className="p-col-3 p-as-center">
          <p className="p-text-center exportProducts-checkbox">EAN</p>
          <div className="p-d-flex p-justify-center">
            <Checkbox
              className={`${
                options.indexOf('EAN') !== -1
                  ? 'exportProducts-checkbox-checked'
                  : 'exportProducts-checkbox-unchecked'
              }`}
              inputId="ean"
              name="ean"
              value="EAN"
              onChange={onOptionsChange}
              checked={options.indexOf('EAN') !== -1}
            />
          </div>
        </div>
        <div className="p-col-3 p-as-center">
          <p className="p-text-center exportProducts-checkbox">ESTOQUE</p>
          <div className="p-d-flex p-justify-center">
            <Checkbox
              className={`${
                options.indexOf('ESTOQUE') !== -1
                  ? 'exportProducts-checkbox-checked'
                  : 'exportProducts-checkbox-unchecked'
              }`}
              inputId="estoque"
              name="estoque"
              value="ESTOQUE"
              onChange={onOptionsChange}
              checked={options.indexOf('ESTOQUE') !== -1}
            />
          </div>
        </div>
        <div className="p-col-3 p-as-center">
          <p className="p-text-center exportProducts-checkbox">RESERVA</p>
          <div className="p-d-flex p-justify-center">
            <Checkbox
              className={`${
                options.indexOf('RESERVA') !== -1
                  ? 'exportProducts-checkbox-checked'
                  : 'exportProducts-checkbox-unchecked'
              }`}
              inputId="reserva"
              name="reserva"
              value="RESERVA"
              onChange={onOptionsChange}
              checked={options.indexOf('RESERVA') !== -1}
            />
          </div>
        </div>
        <div className="p-col-3 p-as-center">
          <p className="p-text-center exportProducts-checkbox">PREÇOS</p>
          <div className="p-d-flex p-justify-center">
            <Checkbox
              className={`${
                options.indexOf('PRECOS') !== -1
                  ? 'exportProducts-checkbox-checked'
                  : 'exportProducts-checkbox-unchecked'
              }`}
              inputId="precos"
              name="precos"
              value="PRECOS"
              onChange={onOptionsChange}
              checked={options.indexOf('PRECOS') !== -1}
            />
          </div>
        </div>
        <div className="p-col-4 p-as-center">
          <p className="p-text-center exportProducts-checkbox">
            DISPONIVEL PARA VENDA
          </p>
          <div className="p-d-flex p-justify-center">
            <Checkbox
              className={`${
                options.indexOf('DISPONIVEL_VENDA') !== -1
                  ? 'exportProducts-checkbox-checked'
                  : 'exportProducts-checkbox-unchecked'
              }`}
              inputId="DISPONIVEL_VENDA"
              name="DISPONIVEL_VENDA"
              value="DISPONIVEL_VENDA"
              onChange={onOptionsChange}
              checked={options.indexOf('DISPONIVEL_VENDA') !== -1}
            />
          </div>
        </div>
        <div className="p-col-4 p-as-center">
          <p className="p-text-center exportProducts-checkbox">
            DISPONIVEL PARA RESERVA
          </p>
          <div className="p-d-flex p-justify-center">
            <Checkbox
              className={`${
                options.indexOf('DISPONIVEL_RESERVA') !== -1
                  ? 'exportProducts-checkbox-checked'
                  : 'exportProducts-checkbox-unchecked'
              }`}
              inputId="DISPONIVEL_RESERVA"
              name="DISPONIVEL_RESERVA"
              value="DISPONIVEL_RESERVA"
              onChange={onOptionsChange}
              checked={options.indexOf('DISPONIVEL_RESERVA') !== -1}
            />
          </div>
        </div>
        <div className="p-col-4 p-as-center">
          <p className="p-text-center exportProducts-checkbox">
            USANDO PRODUTO BASE
          </p>
          <div className="p-d-flex p-justify-center">
            <Checkbox
              className={`${
                options.indexOf('PRODUTO_BASE') !== -1
                  ? 'exportProducts-checkbox-checked'
                  : 'exportProducts-checkbox-unchecked'
              }`}
              inputId="PRODUTO_BASE"
              name="PRODUTO_BASE"
              value="PRODUTO_BASE"
              onChange={onOptionsChange}
              checked={options.indexOf('PRODUTO_BASE') !== -1}
            />
          </div>
        </div>
        <div className="width100">
          <p className="p-text-center exportProducts-dropdown-title">
            Escolha um Formato
          </p>
          <div className="p-d-flex">
            <Dropdown
              disabled={false}
              options={formatSelect}
              value={format}
              onChange={(e) => {
                setFormat({ name: e.value.name, code: e.value.code });
              }}
              optionLabel="name"
              filterBy="name"
              placeholder="Selecione um Formato"
              className="p-p-0 p-my-2 p-mx-auto exportProducts-dropdown"
            />
          </div>
          <ExportExcelButton
            format={format.code}
            productList={exports.item}
            options={options}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ExportProducts;
