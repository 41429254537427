// Importações Padrões
import { toast } from 'react-toastify';

// Icones
import { FaCheck, FaExclamationTriangle, FaTimes } from 'react-icons/fa';

const toasterSuccess = (header: string, message: string) => {
  return toast.success(
    <div className="p-grid">
      <div className="p-col-2 p-d-flex">
        <FaCheck style={{ fontSize: '30px' }} className=" p-as-center" />
      </div>
      <div className="p-col-10">
        <h4 className="p-my-0">{header}</h4>
        <p className="p-mt-1 p-mb-0">{message}</p>
      </div>
    </div>,
    {
      className: 'toast-success',
    },
  );
};

const toasterWarn = (header: string, message: string) => {
  return toast.warn(
    <div className="p-grid">
      <div className="p-col-2 p-d-flex">
        <FaExclamationTriangle
          style={{ fontSize: '30px' }}
          className=" p-as-center"
        />
      </div>
      <div className="p-col-10">
        <h4 className="p-my-0">{header}</h4>
        <p className="p-mt-1 p-mb-0">{message}</p>
      </div>
    </div>,
    {
      className: 'toast-warn',
    },
  );
};

const toasterError = (header: string, message: string) => {
  return toast.error(
    <div className="p-grid">
      <div className="p-col-2 p-d-flex">
        <FaTimes style={{ fontSize: '30px' }} className=" p-as-center" />
      </div>
      <div className="p-col-10">
        <h4 className="p-my-0">{header}</h4>
        <p className="p-mt-1 p-mb-0">{message}</p>
      </div>
    </div>,
    {
      className: 'toast-error',
    },
  );
};

export { toasterSuccess, toasterWarn, toasterError };
