// React Prime
import { confirmDialog } from 'primereact/confirmdialog';

// Configurações
import firebaseConfig from '../config/config.firebase';

const setLimitSize = (width: number): number => {
  switch (true) {
    case width <= 1366: {
      return 6;
    }
    case width <= 1600: {
      return 10;
    }
    case width <= 1920: {
      return 13;
    }
    case width > 1920: {
      return 20;
    }
    default: {
      return 6;
    }
  }
};

const signOut = () => {
  const signOutConfirmation = async () => {
    await firebaseConfig.auth().signOut();
    window.location.reload();
  };
  confirmDialog({
    message: 'Tem certeza que você quer sair?',
    icon: 'pi pi-sign-out',
    acceptClassName: 'accept-signout-button',
    rejectClassName: 'reject-button',
    rejectLabel: 'Voltar',
    accept: () => {
      signOutConfirmation();
    },
    acceptLabel: 'Sair',
  });
};

export { setLimitSize, signOut };
