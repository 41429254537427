// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// Contexto
import { Auth, AuthProvider } from '../services/auth.context';

// Rotas
import PrivateRoutes from './private.routes';
import listRoutes from './list.routes';
import listStockistRoutes from './listStockist.routes';

// Componentes
import Header from '../components/global/header.component';
import SideMenu from '../components/sideMenu/sideMenu.component';
import Footer from '../components/global/footer.component';

// Paginas

import Login from '../pages/login';
import Erro404 from '../pages/erro404';
import SelectedList from '../pages/selectedList';

// Types
import IRoute from '../typings/routes.types';

const Routes: React.FC = () => {
  const { currentUser, systemData, system3cData } = useContext(Auth);
  const [listRouter, setListRouter] = useState(
    (): Array<IRoute> => {
      if (systemData) {
        switch (systemData.function) {
          case 'administrator':
            return system3cData?.fdvSystemServices
              ? listRoutes
              : listRoutes.filter((rt) => rt.pathname !== 'Serviços');
          case 'stockist':
            return system3cData?.fdvSystemServices
              ? listStockistRoutes
              : listStockistRoutes.filter((rt) => rt.pathname !== 'Serviços');
          default:
            return listRoutes;
        }
      }
      return listRoutes;
    },
  );

  useEffect(() => {
    if (systemData && system3cData) {
      console.log(system3cData.fdvSystemServices);
      switch (systemData.function) {
        case 'administrator':
          setListRouter(
            system3cData?.fdvSystemServices
              ? listRoutes
              : listRoutes.filter((rt) => rt.pathname !== 'Serviços'),
          );
          break;
        case 'stockist':
          setListRouter(
            system3cData.fdvSystemServices
              ? listStockistRoutes
              : listStockistRoutes.filter((rt) => rt.pathname !== 'Serviços'),
          );
          break;
        default:
          setListRouter(listRoutes);
          break;
      }
    }
  }, [currentUser, systemData, system3cData]);

  return (
    <AuthProvider>
      <BrowserRouter>
        <SideMenu />
        <Header />
        <div className="p-grid p-dir-col p-m-0 scroller">
          <Switch>
            {listRouter.map((router, id) => (
              <PrivateRoutes
                exact
                key={id.toString()}
                path={router.path}
                component={router.component}
              />
            ))}
            <Route exact path="/login" component={Login} />
            <Route exact path="/lista-selecionada/:ListId">
              <SelectedList />
            </Route>
            <Route component={Erro404} />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default Routes;
