// Importações Padrões
import React, { useState, useEffect, useContext } from 'react';

// Icones
import { FaEdit, FaEye } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

// Contexto
import { Auth } from '../../services/auth.context';

// Hooks
import { useCitiesList } from '../../hooks/useCitiesList';

// Types
import { ICity, IEditCity } from '../../typings/cities.types';
import { IValidationError } from '../../typings/yup.types';

// Controladores
import { editCity } from '../../controllers/city.controller';

// Componentes
import InputForm from '../input/inputForm.component';

// Funções
import { dataSchema } from '../../functions/category.function';

interface IEditCityProps {
  visible: IEditCity;
  setVisible: React.Dispatch<React.SetStateAction<IEditCity>>;
}

const EditCity = (props: IEditCityProps) => {
  const { visible, setVisible } = props;

  const { code3cData } = useContext(Auth);

  const [citiesList] = useCitiesList(visible.item.province_id);
  const [cityExits, setCityExits] = useState(false);

  const [name, setName] = useState<string>('');

  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  const initialState = () => {
    setName('');
    setFormErr([]);
    setVisible({ ...visible, visible: false });
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
  };

  async function handleEditCity() {
    setPendingAction((state) => !state);

    const dataValidation: Partial<ICity> = {
      name,
      code3c_id: code3cData?.id,
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await editCity(dataValidation, visible.item.id);
        if (response) {
          setFormErr([]);
          initialState();
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  useEffect(() => {
    setName(visible.item.name);
  }, [visible]);

  useEffect(() => {
    setCityExits(
      citiesList.filter(
        (item) => item.name === name && item.id !== visible.item.id,
      ).length >= 1,
    );
  }, [name, citiesList, visible.item.id]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              {visible.edit ? (
                <FaEdit className="p-mx-auto p-as-center modal-header-icon" />
              ) : (
                <FaEye className="p-mx-auto p-as-center modal-header-icon" />
              )}
            </div>
            <span className="p-my-auto users-button-text">
              {visible.edit ? 'Editar ' : 'Ver '}Cidade
            </span>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label={visible.edit ? 'Salvar' : 'Editar'}
              disabled={pendingAction || cityExits}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() =>
                visible.edit
                  ? handleEditCity()
                  : setVisible({ ...visible, edit: true })
              }
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <InputForm
        pleaceHolder=""
        disabled={!visible.edit}
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome da Cidade"
        fieldCode="name"
      />
      {cityExits && (
        <small className="delete-description">
          Essa cidade já está cadastrada
        </small>
      )}
    </Dialog>
  );
};

export default EditCity;
