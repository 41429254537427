import './ErrorsInput.scss';

export interface ErrorsInputProps {
  errorMessage: Array<string>;
}
export const ErrorsInput = (props: ErrorsInputProps) => {
  const { errorMessage } = props;
  return (
    <div className="errors-input">
      <span>- {errorMessage}</span>
    </div>
  );
};
