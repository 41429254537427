import React, { useContext, useState } from 'react';
import moment from 'moment';

import { formatCurrency } from '../../../functions/list.function';

import {
  BasicDropDown,
  Option,
} from '../../../components/dropdown/basic-dropdown/BasicDropDown';

import './SelectedSalesTargetHeader.scss';
import { useSalesTarget } from '../hooks/useSalesTarget';
import { SalesTargetView } from '../contexts/SalesTargetContextInterface';
import { SalesTargetProvider } from '../contexts/SalesTargetContextt';

export interface SelectedSalesTargetHeaderProps {
  headerGoal: number;
  headerRealized: number;
  toggleView: SalesTargetView;
  setToggleView: React.Dispatch<React.SetStateAction<SalesTargetView>>;
}

export const SelectedSalesTargetHeader = (
  props: SelectedSalesTargetHeaderProps,
) => {
  const { pendingInfo } = useContext(SalesTargetProvider);
  const { mainSalesTarget } = useSalesTarget();

  const { headerGoal, headerRealized, toggleView, setToggleView } = props;

  const options: Array<Option> = [
    { name: 'Clientes', code: 'CLIENTS' },
    { name: 'Vendedores', code: 'SELLERS' },
  ];

  const [selectedOption, setSelectedOption] = useState<Option>(
    options.find((opt) => opt.code === toggleView) || {
      name: 'Clientes',
      code: 'CLIENTS',
    },
  );

  const handleToggleView = (value: Option) => {
    setSelectedOption(value);
    setToggleView(value.code as SalesTargetView);
  };

  const toggleSelectedSalesTargetHeader = () => {
    if (pendingInfo) {
      return <div className="selected-sales-target-header" />;
    } else {
      return (
        <div className="selected-sales-target-header">
          <BasicDropDown
            selectedOption={selectedOption}
            options={options}
            action={handleToggleView}
            placeHolder="Visualizar..."
            className="dropdown"
          />
          <p>
            <span>Meta em Vigor: </span>
            <b>{formatCurrency('BRL', headerGoal)}</b>
            <span> | Alcançado: </span>
            <b>{formatCurrency('BRL', headerRealized)}</b>
            <span> | Período: </span>
            <b>
              {moment(mainSalesTarget?.startDate).format('L')} à{' '}
              {moment(mainSalesTarget?.endDate).format('L')}
            </b>
          </p>
        </div>
      );
    }
  };

  return toggleSelectedSalesTargetHeader();
};
