// Importações Padrões
import firebase from 'firebase';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Types
import { IFreight } from '../pages/freight/types/Freight';

// Componentes
import {
  toasterError,
  toasterSuccess,
} from '../components/global/toaster.component';

// Funções
import { deleteImage } from '../functions/image.function';

const freightCollection = firebaseConfig.firestore().collection('FDV-freight');

async function addFreight(data: Partial<IFreight>) {
  try {
    const response = await freightCollection.add({
      ...data,
      created_at: firebase.firestore.Timestamp.now(),
      updated_at: firebase.firestore.Timestamp.now(),
    });
    if (response.id) {
      toasterSuccess('CRIAR', `Marca ${data.name} Criada`);
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua marca!');
    return false;
  }
}

async function editFreight(data: Partial<IFreight>, id: string) {
  try {
    const dataDBExists = await freightCollection.doc(id).get();
    if (!dataDBExists) {
      return [];
    }
    await freightCollection
      .doc(id)
      .update({ ...data, updated_at: firebase.firestore.Timestamp.now() });
    toasterSuccess('EDITAR', `Marca ${data.name} Editada!`);
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua categoria!');
    return false;
  }
}

async function searchFreight(id: string) {
  try {
    const data = await freightCollection.doc(id).get();
    return <IFreight>{ ...data.data(), id };
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Não foi possível ler os dados.');
    return null;
  }
}

async function deleteFreight(id: string, name: string) {
  try {
    const response = await freightCollection.doc(id).get();
    if (response.data()) {
      const item = response.data() as IFreight;
      deleteImage(item.imageHeavy);
    }
    await freightCollection.doc(id).delete();
    toasterSuccess('APAGAR', `Marca ${name} Apagada!`);
    return true;
  } catch (err) {
    console.log(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua marca!');
    return false;
  }
}

export {
  freightCollection,
  addFreight,
  editFreight,
  searchFreight,
  deleteFreight,
};
