// Importações Padrões
import { useContext } from 'react';

// React Prime
import { Dropdown } from 'primereact/dropdown';

// Hooks
import { useProvincesList } from '../../hooks/useProvincesList';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import { ISystemFunction } from '../../typings/users.types';

interface ICitiesDropSelect {
  province: ISystemFunction;
  setProvince: React.Dispatch<React.SetStateAction<ISystemFunction>>;
  onPage: boolean;
  disabled: boolean;
}

const CitiesDropSelect = ({
  province,
  setProvince,
  onPage,
  disabled,
}: ICitiesDropSelect) => {
  const [provincesList] = useProvincesList();
  const { windowWidth } = useContext(Auth);

  return (
    <>
      {windowWidth && windowWidth > 0 && windowWidth <= 900 ? (
        <Dropdown
          disabled={disabled}
          value={province}
          options={provincesList
            .filter((item) => item.active)
            .map((provinces) => {
              return { name: provinces.name, code: provinces.id };
            })}
          onChange={(e) => {
            setProvince(e.value);
          }}
          optionLabel="name"
          filter
          filterBy="name"
          placeholder="Selecione um Estado"
          className="p-my-2 settings-input settings-dropdown width100 p-my-3"
        />
      ) : (
        <Dropdown
          disabled={disabled}
          value={province}
          options={provincesList
            .filter((item) => item.active)
            .map((provinces) => {
              return { name: provinces.name, code: provinces.id };
            })}
          onChange={(e) => {
            setProvince(e.value);
          }}
          optionLabel="name"
          filter
          filterBy="name"
          placeholder="Selecione um Estado"
          className={`${
            onPage && 'p-md-6 p-sm-12 p-p-0 '
          }p-my-2 settings-input settings-dropdown p-my-3`}
        />
      )}
    </>
  );
};

export default CitiesDropSelect;
