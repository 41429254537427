// Importações Padrões
import React, { useState } from 'react';

// Icones
import { FaTrash } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

// Controladores
import { deleteBranding } from '../../controllers/branding.controller';

// Types
import { IDeleteBranding } from '../../typings/branding.types';

interface IDeleteBrandingProps {
  visible: IDeleteBranding;
  setVisible: React.Dispatch<React.SetStateAction<IDeleteBranding>>;
}

const DeleteBrandings = (props: IDeleteBrandingProps) => {
  const { visible, setVisible } = props;

  const [pendingAction, setPendingAction] = useState(false);
  const [deleteCheck, setDeleteCheck] = useState('');

  const initialState = () => {
    setDeleteCheck('');
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
    setVisible({ id: '', name: '', visible: false });
  };

  const handleDeleteBranding = async () => {
    setPendingAction((state) => !state);
    const response = await deleteBranding(visible.id, visible.name);
    if (response) {
      initialState();
    } else {
      setPendingAction((state) => !state);
    }
  };

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-d-flex">
              <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
                <FaTrash className="p-p-1 p-mx-auto p-as-center modal-header-icon" />
              </div>
              <span className="p-my-auto users-button-text">Apagar Marca</span>
            </div>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '33rem' }}
      footer={() => {
        return (
          <div className="p-d-flex p-mx-auto p-jc-center">
            <Button
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              className="modal-save-button"
              disabled={deleteCheck !== visible.name || pendingAction}
              label="Apagar"
              onClick={() => {
                handleDeleteBranding();
              }}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <div className="p-text-center">
        <p className="p-m-2 p-p-0">
          <b className="p-m-0">
            Tem certeza que quer apagar a marca: {visible.name}?
          </b>
          <div className="p-d-flex p-jc-center ">
            <i className="p-mr-2 p-mt-2 p-mb-2 pi pi-exclamation-triangle input-exclamation p-as-center" />
            <small className="p-mt-2 p-mb-2 input-exclamation">
              Todos os produtos dessa marca serão APAGADOS!
            </small>
          </div>
        </p>
        <div className="p-field p-mb-2 p-mt-2">
          <span className="p-d-flex p-input-icon-right">
            <InputText
              className="settings-input"
              placeholder={visible.name}
              value={deleteCheck}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDeleteCheck(event.target.value);
              }}
            />
          </span>
        </div>
      </div>
      <div className="p-d-flex p-jc-center ">
        <i className="p-mr-2 p-mt-2 pi pi-exclamation-circle input-warn p-as-center" />
        <small className="p-mt-2 input-warn">
          Para apagar digite o nome da marca igual está acima.
        </small>
      </div>
    </Dialog>
  );
};

export default DeleteBrandings;
