// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Contexto
import { Auth } from '../services/auth.context';

// Types
import { initialTotalOrders, ITotalOrders } from '../typings/order.types';

// Controladores
import { code3cCollection } from '../controllers/code3c.controller';

export function useTotalOrdersList(): [ITotalOrders, boolean] {
  const { code3cData } = useContext(Auth);

  const [totalOrdersList, setTotalOrdersList] = useState<ITotalOrders>(
    initialTotalOrders,
  );
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    if (code3cData && code3cData.id) {
      setPending((state) => !state);
      const unsubscribe = code3cCollection
        .doc(code3cData.id)
        .collection('FDV-shard-totalOrders')
        .onSnapshot((response) => {
          const list: Array<ITotalOrders> = [];
          response.docs.forEach((item) => {
            list.push({ ...item.data(), id: item.id } as ITotalOrders);
          });
          const [newData] = list;
          if (newData) {
            setTotalOrdersList(newData);
          }
        });
      setPending((state) => !state);

      return () => {
        unsubscribe();
        setPending(false);
      };
    }
    return () => {
      setPending(false);
    };
  }, [code3cData, code3cData?.id]);

  return [totalOrdersList, pending];
}
