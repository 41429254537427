// Importações Padrões
import React, { useCallback, useEffect, useState } from 'react';

// React Prime
import { ProgressBar } from 'primereact/progressbar';

// Funções
import {
  generatorImageURL,
  generatorProductBase,
  previewImage,
  ValidateSingleInput,
} from '../../functions/image.function';

// Estilos
import '../../styles/global/image.scss';

interface IInputImageFormProps extends Partial<ITeste> {
  loadingImage: boolean;
  item: string;
  setItem: React.Dispatch<React.SetStateAction<string>>;
  fieldLabel: string;
  visibleEdit: boolean;
}

interface ITeste {
  useProductBase: boolean;
  setUseProductBase: React.Dispatch<React.SetStateAction<boolean>>;
}

const InputImageForm = (props: IInputImageFormProps) => {
  const {
    loadingImage,
    item,
    setItem,
    fieldLabel,
    visibleEdit,
    useProductBase,
    setUseProductBase,
  } = props;

  const [imageName, setImageName] = useState<string>('');
  const [image, setImage] = useState(false);

  const [imageSRC, setImageSRC] = useState('https://via.placeholder.com/150');

  const listenImageSRC = useCallback(() => {
    if (item !== '' && !image) {
      setImageSRC(generatorImageURL(item));
    }
    if (item !== '' && image) {
      setImageSRC(item as string);
    }
  }, [item, image]);

  async function handleImage(e: React.ChangeEvent<HTMLInputElement>) {
    try {
      if (ValidateSingleInput(e.target)) {
        setImageName(e.target.files ? e.target.files[0].name : '');
        if (setUseProductBase) {
          setUseProductBase(false);
        }

        if (e.target.files && e.target.files[0]) {
          await previewImage(e.target.files[0], setItem, setImage);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    listenImageSRC();
  }, [item, image, listenImageSRC]);

  return (
    <div className="p-field">
      {visibleEdit ? (
        <>
          <label className="p-my-0 p-mt-4">{fieldLabel}</label>
          <div className="p-grid p-my-2">
            <div className="p-md-7 p-sm-12 ">
              <input
                type="file"
                name="file"
                id="file"
                className="p-d-none"
                accept=".jpg,.jpeg,.bmp,.gif,.png"
                onChange={(e) => handleImage(e)}
              />
              <label htmlFor="file">
                <div className="p-grid p-mx-auto input-image-button">
                  <div className="p-col-10 p-px-0 p-mx-auto p-text-center p-button-label input-image-label">
                    {imageName === '' ? 'Selecionar imagem' : imageName}
                  </div>
                  <div className="p-col-2 p-px-0 p-text-center p-button-icon-right">
                    <i className="pi pi-upload" />
                  </div>
                </div>
              </label>
              {loadingImage ? (
                <ProgressBar
                  mode="indeterminate"
                  className="p-my-3 input-progress-bar"
                />
              ) : (
                <ProgressBar className="p-my-3 input-progress-bar" />
              )}
              <div className="p-grid">
                <i className="p-col-1 pi pi-exclamation-circle input-warn p-as-center" />
                <small className="p-col-11 input-warn">
                  Caso nenhuma imagem seja escolhida uma imagem padrão será
                  assimilada.
                </small>
              </div>
            </div>
            {useProductBase ? (
              <div className="p-d-flex p-p-0 p-md-5 p-sm-12 p-jc-center p-mx-auto">
                <div className="p-d-flex input-image-preview-box">
                  <img
                    className="p-as-center p-mx-auto input-image-preview"
                    alt="upload"
                    src={generatorProductBase(item)}
                  />
                </div>
              </div>
            ) : (
              <div className="p-d-flex p-p-0 p-md-5 p-sm-12 p-jc-center p-mx-auto">
                <div className="p-d-flex input-image-preview-box">
                  <img
                    className="p-as-center p-mx-auto input-image-preview"
                    alt="upload"
                    src={imageSRC}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {useProductBase ? (
            <>
              <div className="p-d-flex p-jc-center p-my-3">
                <label className="p-my-0 p-mt-4 p-as-center">
                  {fieldLabel}
                </label>
              </div>
              <div className="p-d-flex p-p-0 p-md-5 p-sm-12 p-jc-center p-mx-auto">
                <div className="p-d-flex input-image-preview-box">
                  <img
                    className="p-as-center p-mx-auto input-image-preview"
                    alt="upload"
                    src={generatorProductBase(item)}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="p-d-flex p-jc-center p-my-3">
                <label className="p-my-0 p-mt-4 p-as-center">
                  {fieldLabel}
                </label>
              </div>
              <div className="p-d-flex p-p-0 p-md-5 p-sm-12 p-jc-center p-mx-auto">
                <div className="p-d-flex input-image-preview-box">
                  <img
                    className="p-as-center p-mx-auto input-image-preview"
                    alt="upload"
                    src={imageSRC}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default InputImageForm;
