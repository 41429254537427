// Importações Padrões
import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

export const dataSchema = yup.object().shape({
  code3c_id: yup.string(),
  name: yup.string().label('Nome').min(3).required(),
});

export function checkUnitSell(unitSell: string) {
  switch (unitSell) {
    case 'unit':
      return 'Unidade(s)';

    case 'pack':
      return 'Pacote(s)';

    case 'box':
      return 'Caixa(s)';

    case 'kg':
      return 'Kilograma(s)';

    case 'liter':
      return 'Litro(s)';

    case 'dozen':
      return 'Dúzia(s)';

    default:
      return '';
  }
}
