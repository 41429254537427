// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Contexto
import { Auth } from '../services/auth.context';

// Types
import { IBranding } from '../typings/branding.types';

// Controladores
import { brandingCollection } from '../controllers/branding.controller';

export function useBrandingsList(): [Array<IBranding>, boolean] {
  const { code3cData } = useContext(Auth);

  const [brandingsList, setBrandingsList] = useState<Array<IBranding>>([]);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    setPending((state) => !state);
    const unsubscribe = brandingCollection
      .where('code3c_id', '==', code3cData?.id)
      .orderBy('name')
      .onSnapshot((response) => {
        const list: Array<IBranding> = [];

        response.docs.forEach((doc) => {
          const documentData = { ...doc.data(), id: doc.id } as IBranding;
          list.push(documentData);
        });
        setBrandingsList(list);
      });
    setPending((state) => !state);

    return () => {
      unsubscribe();
      setPending(false);
    };
  }, [code3cData?.id]);

  return [brandingsList, pending];
}
