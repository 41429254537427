import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { formatCurrency } from '../../../functions/list.function';
import { IValidationError } from '../../../typings/yup.types';
import { ErrorsInput } from '../errors-input/ErrorsInput';
import { MandatoryAsterisk } from '../mandatory-asterisk/MandatoryAsterisk';

import './CurrencyInput.scss';

export interface CurrencyInputProps {
  label: string;
  currency: 'BRL' | 'USD';
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  mandatory: boolean;
  errors?: Array<IValidationError>;
  errorCode?: string;
}
export const CurrencyInput = (props: CurrencyInputProps) => {
  const { label, value, setValue, mandatory, errors, errorCode } = props;

  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    if (
      errors &&
      errors.filter((error) => error.path === errorCode).length >= 1
    ) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
  }, [errors]);

  return (
    <div className="currency-input">
      <label className={`${isInvalid && 'invalid'}`}>
        {label} {mandatory && <MandatoryAsterisk />}
      </label>
      <InputText
        value={formatCurrency('BRL', value)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(parseInt(event.target.value.replace(/([^\d])+/gim, ''), 10));
        }}
      />
      {errors
        ?.filter((error) => error.path === errorCode)
        .map((errorComponent, index) => {
          return (
            <ErrorsInput
              key={String(index)}
              errorMessage={errorComponent.errors}
            />
          );
        })}
    </div>
  );
};
