import React from 'react';
import moment from 'moment';

import { FaBox } from 'react-icons/fa';
import { ILogAmountProduct, IProduct } from '../../typings/product.types';
// Funções
import { parseUnitAbbrev } from '../../functions/product.function';

interface IProductLog {
  AmountProductLog: ILogAmountProduct;
  Product: IProduct;
}

const ProductLog = (props: IProductLog): JSX.Element => {
  const { AmountProductLog, Product } = props;
  const dontShow =
    AmountProductLog.oldProductAmount !== AmountProductLog.productAmount ||
    AmountProductLog.oldProductReservation !==
      AmountProductLog.productReservation ||
    AmountProductLog.oldSaleAvailable !== AmountProductLog.saleAvailable;

  return dontShow ? (
    <div className="p-grid p-my-2 p-py-1 width100 editAmount-log">
      <div className="p-col-1 p-as-center editAmount-log-icon">
        <FaBox />
      </div>
      <div className="p-grid p-p-0 p-m-0 p-col-11">
        <p className="p-py-0 p-m-0 p-pl-2 p-col-12 p-as-center editAmount-log-title">
          <span>{AmountProductLog.user_name}</span> alterou ás
          <span>
            {` ${moment(AmountProductLog.created_at).format('lll')}.`}
          </span>
        </p>
        {AmountProductLog.oldProductAmount !==
          AmountProductLog.productAmount && (
          <p className="p-py-0 p-m-0 p-pl-2 p-col-12 p-as-center editAmount-log-title">
            Alterou o <span>ESTOQUE</span> de
            <span>{` ${AmountProductLog.oldProductAmount} `}</span>
            para
            <span>
              {` ${AmountProductLog.productAmount} ${parseUnitAbbrev(
                Product.unit,
              )}.`}
            </span>
          </p>
        )}
        {AmountProductLog.oldProductReservation !==
          AmountProductLog.productReservation && (
          <p className="p-py-0 p-m-0 p-pl-2 p-col-12 p-as-center editAmount-log-title">
            Alterou a <span id="#modificationType">RESERVA</span> de
            <span>{` ${AmountProductLog.oldProductReservation} `}</span>
            para
            <span>
              {` ${AmountProductLog.productReservation} ${parseUnitAbbrev(
                Product.unit,
              )}.`}
            </span>
          </p>
        )}
        {AmountProductLog.oldSaleAvailable !==
          AmountProductLog.saleAvailable && (
          <p className="p-py-0 p-m-0 p-pl-2 p-col-12 p-as-center editAmount-log-title">
            Disponível para <span id="#modificationType">VENDA</span> de
            <span>{` ${AmountProductLog.oldSaleAvailable} `}</span>
            para
            <span>
              {` ${AmountProductLog.saleAvailable} ${parseUnitAbbrev(
                Product.unit,
              )}.`}
            </span>
          </p>
        )}
      </div>
    </div>
  ) : (
    <div />
  );
};

export default ProductLog;
