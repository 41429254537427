import { useContext, useEffect, useState } from 'react';

import { Auth } from '../../../services/auth.context';

import { IVisit } from '../../../typings/visit.types';

import { visitCollection } from '../../../controllers/visit.controller';

export function useVisitsList(): [Array<IVisit>, boolean] {
  const { code3cData } = useContext(Auth);

  const [visitsList, setVisitsList] = useState<Array<IVisit>>([]);
  const [pendingVisit, setPendingVisit] = useState<boolean>(true);

  useEffect(() => {
    setPendingVisit(true);
    const unsubscribe = visitCollection.where('code3c_id', '==', code3cData?.id).onSnapshot((response) => {
      const list: Array<IVisit> = [];

      response.docs.forEach((doc) => {
        const newDocument = doc.data();
        const documentData = {
          ...doc.data(),
          id: doc.id,
          startDate: newDocument.startDate && newDocument.startDate.toDate(),
          endDate: newDocument.endDate && newDocument.endDate.toDate(),
        } as IVisit;
        if (!documentData.visitEnd) {
          list.push(documentData);
        }
      });
      setVisitsList(list);
      setPendingVisit(false);
    });

    return () => {
      unsubscribe();
    };
  }, [code3cData?.id]);

  return [visitsList, pendingVisit];
}
