// Importações Padrões
import firebase from 'firebase';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Types
import { ILogAmountProduct, IProduct } from '../typings/product.types';

// Componentes
import {
  toasterError,
  toasterSuccess,
} from '../components/global/toaster.component';

// Funções
import { deleteImage } from '../functions/image.function';

const productCollection = firebaseConfig.firestore().collection('FDV-product');
const productBaseCollection = firebaseConfig
  .firestore()
  .collection('All-productBase');

async function addProduct(data: Partial<IProduct>) {
  try {
    const response = await productCollection.add({
      ...data,
      created_at: firebase.firestore.Timestamp.now(),
      updated_at: firebase.firestore.Timestamp.now(),
    });
    if (response.id) {
      toasterSuccess('CRIAR', `Produto ${data.name} Criado`);
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a seu produto!');
    return false;
  }
}

async function editProduct(data: Partial<IProduct>, id: string) {
  try {
    const dataDBExists = await productCollection.doc(id).get();
    if (!dataDBExists) {
      return [];
    }
    await productCollection.doc(id).update({
      ...data,
      updated_at: firebase.firestore.Timestamp.now(),
    });

    if (data.name) {
      toasterSuccess('EDITAR', `Produto ${data.name} Editado!`);
    } else {
      toasterSuccess('EDITAR', 'Produto Editado!');
    }

    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a seu produto!');
    return false;
  }
}

async function editProductNoRes(data: Partial<IProduct>, id: string) {
  try {
    const dataDBExists = await productCollection.doc(id).get();
    if (!dataDBExists) {
      return [];
    }
    await productCollection.doc(id).update({
      ...data,
      updated_at: firebase.firestore.Timestamp.now(),
    });
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a seu produto!');
    return false;
  }
}

async function searchProduct(id: string) {
  try {
    const data = await productCollection.doc(id).get();
    return <IProduct>{ ...data.data(), id };
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Não foi possível ler os dados.');
    return null;
  }
}

async function searchProductBase(
  barCode: string,
): Promise<IProduct | undefined> {
  try {
    const data = await productBaseCollection
      .where('barCode', '==', barCode)
      .get();
    if (data.empty) {
      return undefined;
    }
    const [response]: IProduct[] = data.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as IProduct),
    );

    return response;
  } catch (error) {
    return undefined;
  }
}

async function searchCodeBar(
  barCode: string,
  code3c: string,
): Promise<IProduct | undefined> {
  try {
    const data = await productCollection
      .where('barCode', '==', barCode)
      .where('code3c_id', '==', code3c)
      .get();
    if (data.empty) {
      return undefined;
    }
    const [response]: IProduct[] = data.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as IProduct),
    );

    return response;
  } catch (error) {
    return undefined;
  }
}

async function deleteProduct(id: string, name: string) {
  try {
    const response = await productCollection.doc(id).get();
    if (response.data()) {
      const item = response.data() as IProduct;
      deleteImage(item.imageHeavy);
    }
    await productCollection.doc(id).delete();
    toasterSuccess('APAGAR', `Produto ${name} Apagado!`);
    return true;
  } catch (err) {
    console.log(err);
    toasterError('ERRO', 'Parece que tem algo errado com o seu produto!');
    return false;
  }
}

async function logAmountProduct(data: ILogAmountProduct, id: string) {
  try {
    const dataDBExists = await productCollection.doc(id).get();
    if (!dataDBExists) {
      return false;
    }
    const response = await productCollection
      .doc(id)
      .collection('FDV-log-products')
      .add({
        ...data,
        updated_at: firebase.firestore.Timestamp.now(),
        created_at: firebase.firestore.Timestamp.now(),
      });

    if (response) {
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a seu produto!');
    return false;
  }
}

async function getLogAmountProduct(id: string) {
  try {
    const data = await productCollection
      .doc(id)
      .collection('FDV-log-products')
      .get();
    const allData: Array<ILogAmountProduct> = [];
    data.docs.forEach((item) => {
      allData.push({
        ...item.data(),
        created_at: item.data().created_at.toDate(),
        updated_at: item.data().updated_at.toDate(),
      } as ILogAmountProduct);
    });
    return allData;
  } catch (err) {
    console.log(err);
    toasterError('ERRO', 'Parece que tem algo errado com o seu pedido!');
    return [];
  }
}

export {
  productCollection,
  addProduct,
  editProduct,
  editProductNoRes,
  searchProduct,
  deleteProduct,
  logAmountProduct,
  getLogAmountProduct,
  searchProductBase,
  searchCodeBar,
};
