// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollTop } from 'primereact/scrolltop';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useListList } from '../hooks/useListList';

// Types
import { IList, IDeleteList, IEditList } from '../typings/list.types';

// Componentes
import ItemList from '../components/lists/itemList.component';
import AddList from '../components/lists/addList.component';
import EditList from '../components/lists/editList.component';
import EditListItens from '../components/lists/editListItems.component';
import DeleteList from '../components/lists/deleteList.component';
import NavList from '../components/lists/navList.component';

const initialList: IList = {
  id: '',
  name: '',
  code3c_id: '',
};

const List: React.FC = () => {
  const { setPathName, windowWidth } = useContext(Auth);
  const [listList, pending] = useListList();

  const [showHideAddList, setShowHideAddList] = useState(false);
  const [showHideEditList, setShowHideEditList] = useState<IEditList>({
    item: initialList,
    edit: false,
    visible: false,
  });
  const [showHideEditItemsList, setShowHideEditItemsList] = useState<IEditList>(
    {
      item: initialList,
      edit: false,
      visible: false,
    },
  );
  const [showHideDeleteList, setShowHideDeleteList] = useState<IDeleteList>({
    id: '',
    name: '',
    visible: false,
  });

  const [inputSearch, setInputSearch] = useState('');

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  useEffect(() => {
    if (
      showHideAddList ||
      showHideEditList.visible ||
      showHideDeleteList.visible
    ) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHideAddList, showHideEditList, showHideDeleteList]);

  return (
    <div className="p-py-3 p-px-3 ">
      <ScrollTop className="button-scroll-top" />
      <AddList visible={showHideAddList} setVisible={setShowHideAddList} />
      <EditList visible={showHideEditList} setVisible={setShowHideEditList} />
      <DeleteList
        visible={showHideDeleteList}
        setVisible={setShowHideDeleteList}
      />
      <EditListItens
        visible={showHideEditItemsList}
        setVisible={setShowHideEditItemsList}
      />
      <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
        <NavList
          placeHolder="Pesquisar..."
          pending={!pending}
          addFieldName="Nova Lista"
          actionAdd={showHideAddList}
          setActionAdd={setShowHideAddList}
          setInputSearch={setInputSearch}
          activeAdd
        />

        <div className="p-d-flex p-dir-col p-mb-auto width100">
          {windowWidth && windowWidth > 900 && (
            <div
              className="p-grid p-my-1 p-px-3 p-mx-auto p-d-none p-d-md-inline-flex default-box-header"
              style={{
                backgroundColor: '#f8f9fa',
                border: '1px solid #e9ecef',
              }}
            >
              <div className="p-col-1 p-text-center p-as-center">Id</div>
              <div className="p-col-5 p-text-center p-as-center">Lista</div>
              <div className="p-col-3 p-text-center p-as-center">Itens</div>
              <div className="p-col-3 p-text-center p-as-center">Ações</div>
            </div>
          )}

          {listList
            .filter((item) => item.name)
            .filter(
              (item) =>
                item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                -1,
            )
            .map((list: IList, index: number) => {
              return (
                <ItemList
                  key={String(index)}
                  index={index + 1}
                  list={list}
                  showHideEditList={showHideEditList}
                  setShowHideEditList={setShowHideEditList}
                  showHideDeleteList={showHideDeleteList}
                  setShowHideDeleteList={setShowHideDeleteList}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default List;
