// Importações Padrões
import React, { useContext } from 'react';

// Types
import { ICity, IDeleteCity, IEditCity } from '../../typings/cities.types';

// Contexto
import { Auth } from '../../services/auth.context';

// Componentes
import ItemCol from '../defaultBox/itemCol.component';
import ItemAction, { IEdit } from '../defaultBox/itemAction.component';
import ResponsiveItemAction from '../defaultBox/responsiveItemAction.component';

interface IITemMenu {
  city: ICity;
  index: number;
  showHideEditCity: IEditCity;
  setShowHideEditCity: React.Dispatch<React.SetStateAction<IEdit<ICity>>>;
  showHideDeleteCity: IDeleteCity;
  setShowHideDeleteCity: React.Dispatch<React.SetStateAction<IDeleteCity>>;
}

const ItemCity = ({
  city,
  index,
  showHideEditCity,
  setShowHideEditCity,
  showHideDeleteCity,
  setShowHideDeleteCity,
}: IITemMenu) => {
  const { windowWidth } = useContext(Auth);

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-mt-1 p-mx-auto p-p-3 p-grid item-box width100">
          <p className="p-col-9 width100 p-d-inline p-my-0 p-p-0 p-mr-0">
            #{index}
          </p>
          <ItemCol colGrid={9} field="Cidade" content={city.name} />
          <ResponsiveItemAction
            item={city}
            actionEdit={showHideEditCity}
            setActionEdit={setShowHideEditCity}
            actionDelete={showHideDeleteCity}
            setActionDelete={setShowHideDeleteCity}
          />
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div
          className="p-mt-1 p-mx-auto p-p-3 p-grid item-box width100"
          style={{
            border: '1px solid #e9ecef',
            borderWidth: '0 0 1px 0',
            backgroundColor: 'white',
          }}
        >
          <ItemCol colGrid={1} field="Id" content={String(index)} />
          <ItemCol colGrid={9} field="Cidade" content={city.name} />
          <ItemAction
            item={city}
            actionEdit={showHideEditCity}
            setActionEdit={setShowHideEditCity}
            actionDelete={showHideDeleteCity}
            setActionDelete={setShowHideDeleteCity}
            colGrid={2}
          />
        </div>
      );
    }
    return <div />;
  }

  return changeBreakpoints();
};

export default ItemCity;
