import { DayOrders, DayOrdersClients } from '../../../typings/dayOrders';

export const getRealized = (
  listDayOrders: Array<DayOrders>,
  clientId: string,
) => {
  const newListDayOrders = listDayOrders
    .filter(
      (dayOrder) =>
        dayOrder.dayOrdersClients.filter(
          (ordersClient) => ordersClient.client_id === clientId,
        ).length > 0,
    )
    .map((newDayOrder) => {
      return newDayOrder.dayOrdersClients.find(
        (orderClient) => orderClient.client_id === clientId,
      );
    });

  if (newListDayOrders && newListDayOrders.length > 0) {
    const realized = newListDayOrders.reduce(
      (
        accumulator: DayOrdersClients | undefined,
        currentValue: DayOrdersClients | undefined,
      ) => {
        if (accumulator && currentValue) {
          const newValue = {
            client_id: currentValue.client_id,
            client_name: currentValue.client_name,
            client_quantity:
              currentValue.client_quantity + accumulator.client_quantity,
            client_total: currentValue.client_total + accumulator.client_total,
          };
          return newValue;
        }
        return currentValue;
      },
      {
        client_id: '',
        client_name: '',
        client_quantity: 0,
        client_total: 0,
      },
    );

    return realized;
  }

  return {
    client_id: '',
    client_name: '',
    client_quantity: 0,
    client_total: 0,
  };
};
