// Importações Padrões
import firebase from 'firebase';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Types
import { IService } from '../typings/service.types';

// Componentes
import {
  toasterError,
  toasterSuccess,
} from '../components/global/toaster.component';

// Funções
import { deleteImage } from '../functions/image.function';

const serviceCollection = firebaseConfig.firestore().collection('FDV-service');

async function addService(data: Partial<IService>) {
  try {
    const response = await serviceCollection.add({
      ...data,
      created_at: firebase.firestore.Timestamp.now(),
      updated_at: firebase.firestore.Timestamp.now(),
    });
    if (response.id) {
      toasterSuccess('CRIAR', `Serviço ${data.name} Criada`);
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com o seu serviço!');
    return false;
  }
}

async function editService(data: Partial<IService>, id: string) {
  try {
    const dataDBExists = await serviceCollection.doc(id).get();
    if (!dataDBExists) {
      return [];
    }
    await serviceCollection.doc(id).update({
      ...data,
      updated_at: firebase.firestore.Timestamp.now(),
    });
    toasterSuccess('EDITAR', `Serviço ${data.name} Editada!`);
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com o seu serviço!');
    return false;
  }
}

async function editServiceNoRes(data: Partial<IService>, id: string) {
  try {
    const dataDBExists = await serviceCollection.doc(id).get();
    if (!dataDBExists) {
      return [];
    }
    await serviceCollection.doc(id).update(data);
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com o seu serviço!');
    return false;
  }
}

async function searchService(id: string) {
  try {
    const data = await serviceCollection.doc(id).get();
    return <IService>{ ...data.data(), id };
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Não foi possível ler os dados.');
    return null;
  }
}

async function deleteService(id: string, name: string) {
  try {
    const response = await serviceCollection.doc(id).get();
    if (response.data()) {
      const item = response.data() as IService;
      deleteImage(item.imageHeavy);
    }
    await serviceCollection.doc(id).delete();
    toasterSuccess('APAGAR', `Serviço ${name} Apagada!`);
    return true;
  } catch (err) {
    console.log(err);
    toasterError('ERRO', 'Parece que tem algo errado com o seu serviço!');
    return false;
  }
}

export {
  serviceCollection,
  addService,
  editService,
  editServiceNoRes,
  searchService,
  deleteService,
};
