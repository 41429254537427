// Importações Padrões
import React, { useEffect, useState, useCallback, useContext } from 'react';

// Firebase
import firebase from 'firebase';

// Moment
import moment from 'moment';
import 'moment/locale/pt-br';

// Icones
import { AiFillPrinter } from 'react-icons/ai';

// React Prime
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

// Contexto
import { Auth } from '../../services/auth.context';

// Hooks
import { useTotalOrdersList } from '../../hooks/useTotalOrdersList';

// Componentes
import ItemProduct from './Products/itemProduct.component';
import ItemService from './Services/itemService.component';
import FormOrderInfo from './formOrderInfos.component';
import ChangeOrderStatus from './changeOrderStatus.component';
import NewInputTextAreaSimple from '../input/newInputTextAreaSimple.component';

// Controladores
import {
  editOrderShard,
  editCancelOrder,
} from '../../controllers/order.controller';

// Types
import { IEditOrder, IOrder, ITotalOrders } from '../../typings/order.types';

interface IEditOrderProps {
  visible: IEditOrder;
  setVisible: React.Dispatch<React.SetStateAction<IEditOrder>>;
}

const EditOrders = (props: IEditOrderProps) => {
  const { visible, setVisible } = props;

  const [totalOrdersList] = useTotalOrdersList();
  const { code3cData, system3cData, accessToken, windowWidth } = useContext(
    Auth,
  );

  const [note, setNote] = useState<string>('');

  const [created, setCreated] = useState('');
  const [processing, setProcessing] = useState('');
  const [processed, setProcessed] = useState('');
  const [sended, setSended] = useState('');
  const [finished, setFinished] = useState('');
  const [cancelled, setCancelled] = useState('');

  const [processingDisabled, setProcessingDisabled] = useState(false);
  const [processedDisabled, setProcessedDisabled] = useState(false);
  const [sendedDisabled, setSendedDisabled] = useState(false);
  const [finishedDisabled, setFinishedDisabled] = useState(false);

  const [sumProductSeparated, setSumProductSeparated] = useState(0);
  const [sumFutureProductSeparated, setSumFutureProductSeparated] = useState(0);
  const [sumTotalService, setTotalService] = useState(0);
  const [sumServiceFinished, setSumServiceFinished] = useState(0);

  const [toggleList, setToggleList] = useState(false);

  const [statusColor, setStatusColor] = useState('');
  const [statusName, setStatusName] = useState('');

  const [search, setSearch] = useState('');
  const [inputSearch, setInputSearch] = useState('');

  const [pending, setPending] = useState(false);

  const initialState = () => {
    setVisible({ ...visible, visible: false });
    setProcessingDisabled(false);
    setProcessedDisabled(false);
    setSendedDisabled(false);
    setFinishedDisabled(false);
    setCreated('');
    setProcessing('');
    setProcessed('');
    setSended('');
    setFinished('');
    setCancelled('');
  };

  const sprint = () => {
    const url = `https://us-central1-erp-cansho.cloudfunctions.net/pdfGenerator/budgetFDV/${visible.item.id}`;
    window.open(url, '_blank');
  };

  const handleEditOrderDB = async (item: string) => {
    setPending(true);
    if (item === 'processing') {
      const dataOrder: Partial<IOrder> = {
        status: {
          ...visible.item.status,
          processing: firebase.firestore.Timestamp.now(),
          processed: 'Pendente',
          sended: 'Pendente',
          finished: 'Pendente',
        },
      };
      const dataTotal: Partial<ITotalOrders> = {
        totalCreated: totalOrdersList.totalCreated - 1,
        totalProcessing: totalOrdersList.totalProcessing + 1,
      };
      if (code3cData) {
        const response = await editOrderShard(
          dataOrder,
          dataTotal,
          visible.item.id,
          code3cData.id,
          totalOrdersList.id,
        );
        if (response) {
          setPending(false);
        } else {
          setPending(false);
        }
      }
    }
    if (item === 'processed') {
      const dataOrder: Partial<IOrder> = {
        status: {
          ...visible.item.status,
          processed: firebase.firestore.Timestamp.now(),
          sended: 'Pendente',
          finished: 'Pendente',
        },
      };
      const dataTotal: Partial<ITotalOrders> = {
        totalProcessing: totalOrdersList.totalProcessing - 1,
        totalProcessed: totalOrdersList.totalProcessed + 1,
      };
      if (code3cData) {
        const response = await editOrderShard(
          dataOrder,
          dataTotal,
          visible.item.id,
          code3cData.id,
          totalOrdersList.id,
        );
        if (response) {
          setPending(false);
        } else {
          setPending(false);
        }
      }
    }
    if (item === 'sended') {
      const dataOrder: Partial<IOrder> = {
        status: {
          ...visible.item.status,
          sended: firebase.firestore.Timestamp.now(),
          finished: 'Pendente',
        },
      };
      const dataTotal: Partial<ITotalOrders> = {
        totalProcessed: totalOrdersList.totalProcessed - 1,
        totalSended: totalOrdersList.totalSended + 1,
      };
      if (code3cData) {
        const response = await editOrderShard(
          dataOrder,
          dataTotal,
          visible.item.id,
          code3cData.id,
          totalOrdersList.id,
        );
        if (response) {
          setPending(false);
        } else {
          setPending(false);
        }
      }
    }
    if (item === 'finished') {
      const dataOrder: Partial<IOrder> = {
        status: {
          ...visible.item.status,
          finished: firebase.firestore.Timestamp.now(),
        },
      };
      const dataTotal: Partial<ITotalOrders> = {
        totalSended: totalOrdersList.totalSended - 1,
        totalFinished: totalOrdersList.totalFinished + 1,
      };
      if (code3cData) {
        const response = await editOrderShard(
          dataOrder,
          dataTotal,
          visible.item.id,
          code3cData.id,
          totalOrdersList.id,
        );
        if (response) {
          setVisible({ ...visible, visible: false });
          setPending(false);
        } else {
          setPending(false);
        }
      }
    }
  };

  async function handleCancelOrder() {
    setPending(true);
    if (accessToken) {
      const response = await editCancelOrder(visible.item.id, accessToken);
      if (response) {
        setVisible({ ...visible, visible: false });
        setPending(false);
      } else {
        setPending(false);
      }
    }
  }

  const toggleStatusColor = useCallback(() => {
    if (visible.item.status.cancelled !== 'Pendente') {
      setStatusColor('cancelled');
      setStatusName('CANCELADO');
      setCreated('selected');
      setProcessing('selected');
      setProcessed('selected');
      setSended('selected');
      setFinished('selected');
      setCancelled('selected');
    } else if (visible.item.status.processing === 'Pendente') {
      setStatusColor('created');
      setStatusName('CRIADO');
      setCreated('selected');
      setProcessing('default');
      setProcessed('default');
      setSended('default');
      setFinished('default');
      setCancelled('default');

      setProcessingDisabled(false);
      setProcessedDisabled(true);
      setSendedDisabled(true);
      setFinishedDisabled(true);
    } else if (visible.item.status.processed === 'Pendente') {
      setStatusColor('processing');
      setStatusName('PROCESSANDO');
      setCreated('selected');
      setProcessing('selected');
      setProcessed('default');
      setSended('default');
      setFinished('default');
      setCancelled('default');

      setProcessingDisabled(true);
      setProcessedDisabled(false);
      setSendedDisabled(true);
      setFinishedDisabled(true);
    } else if (visible.item.status.sended === 'Pendente') {
      setStatusColor('processed');
      setStatusName('PROCESSADO');
      setCreated('selected');
      setProcessing('selected');
      setProcessed('selected');
      setSended('default');
      setFinished('default');
      setCancelled('default');

      setProcessingDisabled(true);
      setProcessedDisabled(true);
      setSendedDisabled(false);
      setFinishedDisabled(true);
    } else if (visible.item.status.finished === 'Pendente') {
      setStatusColor('sended');
      setStatusName('EXPEDIDO');
      setCreated('selected');
      setProcessing('selected');
      setProcessed('selected');
      setSended('selected');
      setFinished('default');
      setCancelled('default');

      setProcessingDisabled(true);
      setProcessedDisabled(true);
      setSendedDisabled(true);
      setFinishedDisabled(false);
    } else {
      setStatusColor('finished');
      setStatusName('FINALIZADO');
      setCreated('selected');
      setProcessing('selected');
      setProcessed('selected');
      setSended('selected');
      setFinished('selected');
      setCancelled('default');

      setProcessingDisabled(true);
      setProcessedDisabled(true);
      setSendedDisabled(true);
      setFinishedDisabled(true);
    }
  }, [visible]);

  function handleEditOrder(item: string) {
    if (item === 'processing' && !processingDisabled) {
      setCreated('selected');
      setProcessing('selected');
      setProcessed('default');
      setSended('default');
      setFinished('default');
      setCancelled('default');

      setProcessingDisabled(true);
      setProcessedDisabled(false);
      setSendedDisabled(true);
      setFinishedDisabled(true);

      handleEditOrderDB('processing');
    } else if (item === 'processed' && !processedDisabled) {
      setCreated('selected');
      setProcessing('selected');
      setProcessed('selected');
      setSended('default');
      setFinished('default');
      setCancelled('default');

      setProcessingDisabled(false);
      setProcessedDisabled(true);
      setSendedDisabled(false);
      setFinishedDisabled(true);

      handleEditOrderDB('processed');
    } else if (item === 'sended' && !sendedDisabled) {
      setCreated('selected');
      setProcessing('selected');
      setProcessed('selected');
      setSended('selected');
      setFinished('default');
      setCancelled('default');

      setProcessingDisabled(true);
      setProcessedDisabled(false);
      setSendedDisabled(true);
      setFinishedDisabled(false);

      handleEditOrderDB('sended');
    } else if (item === 'finished' && !finishedDisabled) {
      setCreated('selected');
      setProcessing('selected');
      setProcessed('selected');
      setSended('selected');
      setFinished('selected');
      setCancelled('default');

      setProcessingDisabled(true);
      setProcessedDisabled(true);
      setSendedDisabled(false);
      setFinishedDisabled(true);

      handleEditOrderDB('finished');
    } else if (item === 'cancelled') {
      handleCancelOrder();
    }
  }

  useEffect(() => {
    toggleStatusColor();
  }, [toggleStatusColor]);

  useEffect(() => {
    setToggleList(false);
  }, [visible.visible]);

  useEffect(() => {
    if (visible.item.note) {
      setNote(visible.item.note);
    } else {
      setNote('Não possui');
    }
    setSumFutureProductSeparated(
      visible.item.productOrder.filter(
        (item) =>
          item.useProductReservation === true && item.disabled === false,
      ).length,
    );
    setSumProductSeparated(
      visible.item.productOrder.filter(
        (item) => item.separated === false && item.disabled === false,
      ).length,
    );
    setTotalService(
      visible.item.serviceOrder.filter((item) => item.disabled === false)
        .length,
    );

    setSumServiceFinished(
      visible.item.serviceOrder.filter(
        (item) =>
          item.disabled === false &&
          item.status.doing !== 'Pendente' &&
          item.status.done !== 'Pendente' &&
          item.status.toDo !== 'Pendente',
      ).length,
    );
  }, [visible]);

  const confirmDialogOrder = (item: string, text: string) => {
    confirmDialog({
      message: () => {
        return <div>{text}</div>;
      },
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'accept-button',
      rejectClassName: 'reject-button',
      rejectLabel: 'Não',
      acceptLabel: 'Sim',
      accept: () => {
        handleEditOrder(item);
      },
    });
  };

  return (
    <Dialog
      header={() => {
        return (
          <>
            {windowWidth && windowWidth > 0 && windowWidth <= 900 ? (
              <>
                {windowWidth && windowWidth <= 400 ? (
                  <div className="p-d-flex p-flex-column">
                    <span className="p-my-auto users-button-text">
                      <div className="p-d-flex">Status do Pedido:</div>
                      <span className={` ${statusColor}`}>{statusName} </span>
                    </span>
                  </div>
                ) : (
                  <div className="p-d-flex p-flex-column">
                    <span className="p-my-auto users-button-text">
                      <div className="p-d-flex">
                        Status do Pedido: &nbsp;
                        <span className={` ${statusColor}`}>{statusName} </span>
                      </div>
                    </span>
                  </div>
                )}
              </>
            ) : (
              <div className="p-d-flex p-flex-column">
                <span className="p-my-auto users-button-text">
                  <div className="p-d-flex">
                    Status do Pedido: &nbsp;
                    <span className={` ${statusColor}`}>{statusName} </span>
                    <br />
                    <div className="p-p-0 p-ml-3 p-d-flex modal-header-button cursor-pointer">
                      <AiFillPrinter
                        onClick={() => sprint()}
                        className="p-mx-auto p-as-center modal-header-icon"
                      />
                    </div>
                  </div>
                </span>
              </div>
            )}
          </>
        );
      }}
      footer={() => {
        return (
          <>
            {windowWidth && windowWidth <= 400 ? (
              <>
                <div className="p-m-0 p-p-0" style={{ float: 'left' }}>
                  Última Modificação:
                </div>
                <div className="p-m-0 p-p-0" style={{ float: 'left' }}>
                  {moment(visible.item.updated_at).format('LLL')}
                </div>
              </>
            ) : (
              <div className="p-m-0 p-p-0" style={{ float: 'left' }}>
                Última Modificação: &nbsp;
                {moment(visible.item.updated_at).format('LLL')}
              </div>
            )}
          </>
        );
      }}
      draggable={false}
      maximized
      maximizable
      visible={visible.visible}
      breakpoints={{ '960px': '100vw' }}
      style={{ width: '96vw' }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <>
        <FormOrderInfo visible={visible} />

        <ChangeOrderStatus
          visible={visible}
          created={created}
          processing={processing}
          processed={processed}
          sended={sended}
          finished={finished}
          cancelled={cancelled}
          processingDisabled={processingDisabled}
          processedDisabled={processedDisabled}
          sendedDisabled={sendedDisabled}
          finishedDisabled={finishedDisabled}
          sumProductSeparated={sumProductSeparated}
          confirmDialogOrder={confirmDialogOrder}
          pending={pending}
          sumTotalService={sumTotalService}
          sumServiceFinished={sumServiceFinished}
          SumFutureProductSeparated={sumFutureProductSeparated}
        />

        <div className="p-grid p-px-2 p-pt-2 p-mt-2">
          <div
            className={`${
              system3cData?.fdvSystemServices ? 'p-col-6' : 'p-col-12'
            } p-d-flex ${
              toggleList ? 'editListItems-tab' : 'editListItems-active-tab'
            }`}
            aria-hidden="true"
            onClick={() => {
              setToggleList(false);
            }}
          >
            <p className="p-mx-auto">Produtos</p>
          </div>
          {system3cData?.fdvSystemServices && (
            <div
              className={
                toggleList
                  ? 'p-col-6 p-d-flex editListItems-active-tab'
                  : 'p-col-6 p-d-flex editListItems-tab'
              }
              aria-hidden="true"
              onClick={() => {
                setToggleList(true);
              }}
            >
              <p className="p-mx-auto">Serviços</p>
            </div>
          )}
        </div>

        <div className="p-d-flex p-justify-end width100">
          <div className="p-my-3" style={{ width: '25rem' }}>
            <span className="p-input-icon-right width90">
              {search !== '' && (
                <i
                  aria-hidden="true"
                  className="p-d-none p-d-md-inline-flex pi pi-times default-box-header-search-clear"
                  onClick={() => {
                    setSearch('');
                    setInputSearch('');
                  }}
                />
              )}
              <InputText
                placeholder={
                  !toggleList
                    ? 'Buscar por Produtos...'
                    : 'Buscar por Serviços...'
                }
                className="editList-input"
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(e.target.value);
                }}
              />
            </span>
            <Button
              icon="pi pi-search"
              className="editList-select-all width10"
              onClick={() => setInputSearch(search)}
            />
          </div>
        </div>

        {!toggleList ? (
          <ItemProduct
            search={inputSearch}
            processing={processing}
            visible={visible.item}
          />
        ) : (
          <ItemService processing={processing} visible={visible.item} />
        )}
        <div className="p-mt-3">
          <NewInputTextAreaSimple
            disabled
            item={note}
            fieldLabel="Anotações do pedido."
            rows={1}
            cols={30}
          />
        </div>
      </>
    </Dialog>
  );
};
export default EditOrders;
