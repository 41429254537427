// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollTop } from 'primereact/scrolltop';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useClientsList } from '../hooks/useClientsList';

// Types
import {
  IClient,
  IDeleteClient,
  IEditClient,
  initialClient,
} from '../typings/client.types';

// Componentes
import ItemClient from '../components/clients/itemClient.component';
import AddClient from '../components/clients/addClient.component';
import EditClient from '../components/clients/editClient.component';
import DeleteClient from '../components/clients/deleteClient.component';
import NavClient from '../components/clients/navClient.component';

const Clients: React.FC = () => {
  const { setPathName, windowWidth } = useContext(Auth);

  const { clientsList, pending } = useClientsList();

  const [showHideAddClient, setShowHideAddClient] = useState(false);
  const [showHideEditClient, setShowHideEditClient] = useState<IEditClient>({
    item: initialClient,
    edit: false,
    visible: false,
  });
  const [showHideDeleteClient, setShowHideDeleteClient] =
    useState<IDeleteClient>({ id: '', name: '', visible: false });

  const [inputSearch, setInputSearch] = useState('');

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  useEffect(() => {
    if (
      showHideAddClient ||
      showHideEditClient.visible ||
      showHideDeleteClient.visible
    ) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHideAddClient, showHideEditClient, showHideDeleteClient]);

  return (
    <div className="p-py-3 p-px-3 ">
      <ScrollTop className="button-scroll-top" />
      <AddClient
        visible={showHideAddClient}
        setVisible={setShowHideAddClient}
      />
      <EditClient
        visible={showHideEditClient}
        setVisible={setShowHideEditClient}
      />
      <DeleteClient
        visible={showHideDeleteClient}
        setVisible={setShowHideDeleteClient}
      />
      <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
        <NavClient
          placeHolder="Pesquisar..."
          pending={!pending}
          addFieldName="Novo Cliente"
          actionAdd={showHideAddClient}
          setActionAdd={setShowHideAddClient}
          setInputSearch={setInputSearch}
          activeAdd
        />
        <div className="p-d-flex p-dir-col p-mt-2 p-mb-auto width100">
          {windowWidth && windowWidth > 900 && (
            <div
              className="p-grid p-my-1 p-px-3 p-mx-auto p-d-none p-d-md-inline-flex default-box-header"
              style={{
                backgroundColor: '#f8f9fa',
                border: '1px solid #e9ecef',
              }}
            >
              <div className="p-col-1 p-text-center p-as-center">Id</div>
              <div className="p-col-2 p-text-center p-as-center">Imagem</div>
              <div className="p-col-3 p-text-center p-as-center">Cliente</div>
              <div className="p-col-2 p-text-center p-as-center">Tipo</div>
              <div className="p-col-2 p-text-center p-as-center">CNPJ/CPF</div>
              <div className="p-col-2 p-text-center p-as-center">Ações</div>
            </div>
          )}

          {clientsList
            .filter(
              (item) =>
                item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                -1,
            )
            .map((client: IClient, index: number) => {
              return (
                <ItemClient
                  key={String(index)}
                  index={index + 1}
                  client={client}
                  showHideEditClient={showHideEditClient}
                  setShowHideEditClient={setShowHideEditClient}
                  showHideDeleteClient={showHideDeleteClient}
                  setShowHideDeleteClient={setShowHideDeleteClient}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Clients;
