// Importações Padrões
import firebase from 'firebase';

// Configurações
import firebaseConfig from '../config/config.firebase';
import getApi from '../config/api.firebase';

// Types
import { IOrder, ITotalOrders } from '../typings/order.types';
import { IProduct } from '../typings/product.types';

// Controladores
import { productCollection } from './product.controller';
import { code3cCollection } from './code3c.controller';

// Componentes
import {
  toasterError,
  toasterSuccess,
} from '../components/global/toaster.component';

const orderCollection = firebaseConfig.firestore().collection('FDV-order');

async function getOrders(id: string, productId: string) {
  try {
    const data = await orderCollection
      .where('code3c_id', '==', id)
      .where('inOrder', '==', false)
      .where('productListMeta', 'array-contains', productId)
      .where('status.cancelled', '==', 'Pendente')
      .where('status.finished', '==', 'Pendente')
      .get();
    const allData: Array<IOrder> = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data(), id: item.id } as IOrder);
    });
    return allData;
  } catch (err) {
    console.log(err);
    toasterError('ERRO', 'Parece que tem algo errado com o seu pedido!');
    return [];
  }
}

async function editOrder(data: Partial<IOrder>, id: string) {
  try {
    const dataDBExists = await orderCollection.doc(id).get();
    if (!dataDBExists) {
      return [];
    }
    await orderCollection.doc(id).update({
      ...data,
      updated_at: firebase.firestore.Timestamp.now(),
    });
    toasterSuccess('EDITAR', 'Seu pedido foi atualizado!');
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com o seu pedido!');
    return false;
  }
}

async function editOrderShard(
  dataOrder: Partial<IOrder>,
  dataTotal: Partial<ITotalOrders>,
  id: string,
  code3c_id: string,
  totalId: string,
) {
  try {
    const dataDBExists = await orderCollection.doc(id).get();
    if (!dataDBExists) {
      return [];
    }
    await orderCollection.doc(id).update(dataOrder);
    await code3cCollection
      .doc(code3c_id)
      .collection('FDV-shard-totalOrders')
      .doc(totalId)
      .update(dataTotal);
    toasterSuccess('EDITAR', 'Seu pedido foi atualizado!');
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com o seu pedido!');
    return false;
  }
}

async function editOrderProduct(
  dataOrder: Partial<IOrder>,
  dataProduct: Partial<IProduct>,
  idOrder: string,
  idProduct: string,
) {
  try {
    const dataDBOrder = await orderCollection.doc(idOrder).get();
    const dataDBProduct = await productCollection.doc(idProduct).get();
    if (!dataDBOrder && !dataDBProduct) {
      return [];
    }
    await orderCollection.doc(idOrder).update(dataOrder);
    await productCollection.doc(idProduct).update(dataProduct);
    toasterSuccess('EDITAR', 'Seu pedido foi atualizado!');
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com o seu pedido!');
    return false;
  }
}

async function searchOrder(id: string) {
  try {
    const data = await orderCollection.doc(id).get();
    return <IOrder>{ ...data.data(), id };
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Não foi possível ler os dados.');
    return null;
  }
}

async function deleteOrder(id: string, name: string) {
  try {
    const response = await orderCollection.doc(id).get();
    if (response.data()) {
      response.data() as IOrder;
    }
    await orderCollection.doc(id).delete();
    toasterSuccess('APAGAR', `Marca ${name} Apagada!`);
    return true;
  } catch (err) {
    console.log(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua marca!');
    return false;
  }
}

const editCancelOrder = async (id: string, token: string) => {
  try {
    const dataDBExists = await searchOrder(id);
    if (!dataDBExists) {
      return false;
    }
    const api = getApi(token);
    const response = await api.post(`fdvOrder/orderCancel/${id}`);
    if (response) {
      toasterSuccess('EDITAR', 'Seu pedido foi cancelado!');
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com sua edição!');
    return false;
  }
};

export {
  editCancelOrder,
  editOrderShard,
  getOrders,
  orderCollection,
  editOrder,
  searchOrder,
  deleteOrder,
  editOrderProduct,
};
