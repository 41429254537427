// Types
import IRoute from '../typings/routes.types';

// Paginas
import Dashboard from '../pages/dashboard';
import Categories from '../pages/categories';
import Brandings from '../pages/brandings';
import Manufacturers from '../pages/manufacturers';
import Products from '../pages/products';
import Services from '../pages/services';
import Orders from '../pages/orders';
import Providers from '../pages/providers';
import Help from '../pages/help';
import Settings from '../pages/settings';

export const listStockistRoutes: Array<IRoute> = [
  {
    type: 'route',
    path: '/',
    pathname: 'Dashboard',
    icon: 'poll',
    component: Dashboard,
  },
  {
    type: 'route',
    path: '/servicos',
    pathname: 'Serviços',
    icon: 'wrench',
    component: Services,
  },
  {
    type: 'route-list',
    path: '/',
    pathname: 'Produtos',
    icon: 'boxes',
    component: Dashboard,
    subroutes: ['Categorias', 'Marcas', 'Fabricantes', 'Produtos'],
  },
  {
    type: 'sub-route',
    path: '/categorias',
    pathname: 'Categorias',
    icon: 'tags',
    component: Categories,
  },
  {
    type: 'sub-route',
    path: '/marcas',
    pathname: 'Marcas',
    icon: 'registered',
    component: Brandings,
  },
  {
    type: 'sub-route',
    path: '/fabricantes',
    pathname: 'Fabricantes',
    icon: 'industry',
    component: Manufacturers,
  },
  {
    type: 'sub-route',
    path: '/produtos',
    pathname: 'Produtos',
    icon: 'boxes',
    component: Products,
  },
  {
    type: 'route',
    path: '/Pedidos',
    pathname: 'Pedidos',
    icon: 'book-reader',
    component: Orders,
  },
  {
    type: 'route',
    path: '/fornecedores',
    pathname: 'Fornecedores',
    icon: 'truck',
    component: Providers,
  },
  {
    type: 'route',
    path: '/configuracoes',
    pathname: 'Configurações',
    icon: 'cog',
    component: Settings,
  },
  {
    type: 'route',
    path: '/ajuda',
    pathname: 'Ajuda',
    icon: 'question-circle',
    component: Help,
  },
];

export default listStockistRoutes;
