// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Contexto
import { Auth } from '../services/auth.context';

// Types
import { IProduct } from '../typings/product.types';

// Controladores
import { productCollection } from '../controllers/product.controller';

// Funções
import { parseUnitFormatter } from '../functions/product.function';

export function useProductList(): [Array<IProduct>, boolean] {
  const { code3cData } = useContext(Auth);

  const [productsList, setProductsList] = useState<Array<IProduct>>([]);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    setPending((state) => !state);
    const unsubscribe = productCollection
      .where('code3c_id', '==', code3cData?.id)
      .orderBy('name')
      .onSnapshot((response) => {
        const list: Array<IProduct> = [];
        response.docs.forEach((doc) => {
          const documentData = {
            ...doc.data(),
            productAmount: parseUnitFormatter(doc.data().productAmount),
            saleAvailable: parseUnitFormatter(doc.data().saleAvailable),
            reservationAvailable: parseUnitFormatter(
              doc.data().reservationAvailable,
            ),
            productReservation: parseUnitFormatter(
              doc.data().productReservation,
            ),
            id: doc.id,
          } as IProduct;
          list.push(documentData);
        });
        setProductsList(list);
      });
    setPending((state) => !state);

    return () => {
      unsubscribe();
      setPending(false);
    };
  }, [code3cData?.id]);

  return [productsList, pending];
}
