// Importações Padrões
import firebase from 'firebase';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Types
import { IVisit } from '../typings/visit.types';

// Componentes
import {
  toasterError,
  toasterSuccess,
} from '../components/global/toaster.component';

const visitCollection = firebaseConfig.firestore().collection('FDV-visit');

async function addVisit(data: Partial<IVisit>) {
  try {
    const response = await visitCollection.add({
      ...data,
      created_at: firebase.firestore.Timestamp.now(),
      updated_at: firebase.firestore.Timestamp.now(),
    });
    if (response.id) {
      toasterSuccess('CRIAR', 'Visita Criada');
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua visita!');
    return false;
  }
}

async function editVisit(data: Partial<IVisit>, id: string) {
  try {
    const dataDBExists = await visitCollection.doc(id).get();
    if (!dataDBExists) {
      return [];
    }
    await visitCollection.doc(id).update({
      ...data,
      updated_at: firebase.firestore.Timestamp.now(),
    });
    toasterSuccess('EDITAR', 'Visita Editada!');
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua visita!');
    return false;
  }
}
async function deleteVisit(id: string) {
  try {
    const response = await visitCollection.doc(id).get();
    if (response.data()) {
      await visitCollection.doc(id).delete();
      toasterSuccess('APAGAR', 'Visita Apagada!');
      return true;
    }
    return false;
  } catch (err) {
    console.log(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua visita!');
    return false;
  }
}

async function searchVisit(id: string) {
  try {
    const data = await visitCollection.doc(id).get();
    return <IVisit>{ ...data.data(), id };
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Não foi possível ler os dados.');
    return null;
  }
}

export { visitCollection, addVisit, editVisit, searchVisit, deleteVisit };
