// Importações Padrões
import React, { useState, useEffect, useContext } from 'react';

// Icones
import { FaPlus } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';

// Hooks
import { useClientsList } from '../../hooks/useClientsList';
import { useUserslist } from '../../hooks/useUsersList';

// Contexto
import { Auth } from '../../services/auth.context';

// Componentes
import InputDropForm from '../input/inputDropForm.component';
import InputTextareaComponent from '../input/inputTextarea.component';
import { catchErrors, catchInvalid } from '../global/formErrors.component';

// Controladores
import { addVisit } from '../../controllers/visit.controller';

// Types
import { IAddVisit, IVisit } from '../../typings/visit.types';
import { IInput } from '../../typings/input.types';
import { IValidationError } from '../../typings/yup.types';

// Funções
import { dataSchema } from '../../functions/visit.function';

interface IAddVisitProps {
  visible: IAddVisit;
  setVisible: React.Dispatch<React.SetStateAction<IAddVisit>>;
}

const AddVisit = (props: IAddVisitProps) => {
  const { visible, setVisible } = props;

  const { code3cData } = useContext(Auth);

  const { clientsList } = useClientsList();
  const { usersList } = useUserslist();

  const [startDate, setStartDate] = useState<Date>();

  const [allDay, setAllDay] = useState<boolean>(true);
  const [note, setNote] = useState<string>('');

  const [client, setClient] = useState<IInput>({
    name: '',
    code: '',
    imageHeavy: '',
    imageLight: '',
  });

  const [seller, setSeller] = useState<IInput>({
    name: '',
    code: '',
  });

  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  function handleSeller() {
    const newUsersList = usersList.map((item) => {
      const [userSystem] = item.systems.filter(
        (system) => system.function === 'seller',
      );
      if (userSystem) {
        return { name: item.name, code: item.id };
      }
      return null;
    });
    return newUsersList.filter((userList) => userList !== null) as Array<
      IInput
    >;
  }

  const initialState = () => {
    setClient({ name: '', code: '', imageHeavy: '', imageLight: '' });
    setSeller({ name: '', code: '' });
    setAllDay(true);
    setNote('');
    setVisible({ ...visible, visible: false });
    setFormErr([]);
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
  };

  async function handleAddVisit() {
    setPendingAction((state) => !state);

    const dataValidation: Partial<IVisit> = {
      code3c_id: code3cData?.id,
      note,

      startDate,
      endDate: startDate,

      client_id: client.code,
      client_name: client.name,
      client_imageHeavy: client.imageHeavy,
      client_imageLight: client.imageLight,

      seller_id: seller.code,
      seller_name: seller.name,

      allDay,
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await addVisit(dataValidation);
        if (response) {
          setFormErr([]);
          setPendingAction((state) => !state);
          initialState();
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  useEffect(() => {
    setStartDate(visible.item.visitStart);
  }, [visible]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              <FaPlus className="p-mx-auto p-as-center modal-header-icon" />
            </div>
            <span className="p-my-auto users-button-text">Nova Visita</span>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label="Salvar"
              disabled={pendingAction}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() => handleAddVisit()}
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <InputDropForm
        filter
        filterBy="name"
        disabled={false}
        item={client}
        setItem={setClient}
        options={clientsList.map((clients) => {
          return {
            name: clients.name,
            code: clients.id,
            imageHeavy: clients.imageHeavy,
            imageLight: clients.imageLight,
          };
        })}
        formErr={formErr}
        fieldLabel="Selecione o Cliente"
        fieldCode="client_id"
      />
      <InputDropForm
        filter
        filterBy="name"
        disabled={false}
        item={seller}
        setItem={setSeller}
        options={handleSeller()}
        formErr={formErr}
        fieldLabel="Selecione o Vendedor"
        fieldCode="seller_id"
      />
      <div className="visits-button" style={{ width: '100%' }}>
        <label className="p-my-0 p-mt-4">Selecione a Data</label>
        <Calendar
          className="settings-input-calendar"
          showIcon
          locale="pt"
          hourFormat="24"
          dateFormat="dd/mm/yy"
          value={startDate}
          onChange={(e) => setStartDate(new Date(e.target.value as Date))}
        />
      </div>
      <div className="p-my-3">
        <Checkbox
          className={
            !allDay
              ? 'p-p-0 p-mx-auto p-as-center settings-checkbox-selected'
              : 'p-p-0 p-mx-auto p-as-center settings-checkbox'
          }
          checked={!allDay}
          onChange={() => setAllDay(!allDay)}
        />
        <label className="p-ml-2">Especificar Horário</label>
      </div>
      {!allDay && (
        <>
          <div className="visits-button">
            <label className="p-my-0 p-mt-4 ">Selecione um Horário</label>
            {catchInvalid(formErr, 'startDate') && (
              <i className="pi pi-times input-invalid" />
            )}
            <Calendar
              className="settings-input-calendar"
              showIcon
              timeOnly
              icon="pi pi-spin pi-clock"
              hourFormat="24"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value as Date)}
            />
            {catchErrors(formErr, 'startDate')}
          </div>
        </>
      )}
      <div className="p-d-flex p-my-2 p-jc-center">
        <small className="input-warn">Anotações.</small>
      </div>
      <InputTextareaComponent
        disabled={false}
        item={note}
        setItem={setNote}
        formErr={formErr}
        fieldLabel="Observações"
        fieldCode={note}
        rows={5}
        cols={30}
      />
    </Dialog>
  );
};

export default AddVisit;
