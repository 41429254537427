// React Prime
import { confirmDialog } from 'primereact/confirmdialog';

const ConfirmNewTab = (url: string) => {
  confirmDialog({
    message: 'Você será redirecionado para outro dominio.',
    icon: 'pi pi-exclamation-triangle',
    acceptClassName: 'accept-button',
    rejectClassName: 'reject-button',
    rejectLabel: 'Voltar',
    accept: () => {
      window.open(url, '_blank');
    },
    acceptLabel: 'Continuar',
  });
};

export default ConfirmNewTab;
