import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import {
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
} from 'primereact/column';

import { IProduct } from '../../../typings/product.types';

import './LowStockProducts.scss';

export interface LowStockProductsProps {
  productsList: Array<IProduct>;
}

export const LowStockProducts = (props: LowStockProductsProps) => {
  const { productsList } = props;

  const [products, setProducts] = useState<Array<IProduct>>([]);

  useEffect(() => {
    setProducts(productsList);
  }, [productsList]);

  const statusBodyTemplate = () => {
    return (
      <span className="order-badge-order-cancelled p-p-1">Estoque Baixo</span>
    );
  };
  const actionBodyTemplate = () => {
    return (
      <div>
        <Link to={`/produtos`} className="p-mx-auto item-menu-action">
          <Button
            type="button"
            icon="pi pi-search"
            className="p-button-success"
          />
        </Link>
      </div>
    );
  };

  const filterApplyTemplate = (options: ColumnFilterApplyTemplateOptions) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        className="filter-apply-template"
      ></Button>
    );
  };

  const filterClearTemplate = (options: ColumnFilterClearTemplateOptions) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        className="filter-clear-template"
      />
    );
  };

  return (
    <div
      id="map"
      className="width100"
      style={{
        background: '#ffffff',
        border: '1px solid #EEEEEE',
        height: '25.1rem',
      }}
    >
      <div className="p-px-3 p-py-3 dashboard-properties-title">
        Produtos com Estoque Baixo
      </div>
      <div className="p-px-3" style={{ height: '20rem', overflow: 'auto' }}>
        <div>
          <DataTable
            value={products}
            stripedRows
            showGridlines
            rowHover
            removableSort
            emptyMessage="Nenhum Produto com Estoque Baixo Foi Encontrado."
            sortField="name"
            sortOrder={1}
          >
            <Column
              field="product"
              header="Produto"
              sortable
              body={(rowData) => {
                return <b>{rowData.name}</b>;
              }}
              filter
              filterPlaceholder="Pesquisar Produto"
              filterClear={filterClearTemplate}
              filterApply={filterApplyTemplate}
              filterMatchMode="contains"
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              maxConstraints={1}
            />
            <Column field="status" header="Status" body={statusBodyTemplate} />
            <Column field="action" header="Ação" body={actionBodyTemplate} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};
export default LowStockProducts;
