// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Contexto
import { Auth } from '../services/auth.context';

// Types
import { IProvider } from '../typings/provider.types';

// Controladores
import { providerCollection } from '../controllers/provider.controller';

export function useProvidersList(): [Array<IProvider>, boolean] {
  const { code3cData } = useContext(Auth);

  const [providersList, setProvidersList] = useState<Array<IProvider>>([]);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    setPending((state) => !state);
    const unsubscribe = providerCollection
      .where('code3c_id', '==', code3cData?.id)
      .onSnapshot((response) => {
        const list: Array<IProvider> = [];

        response.docs.forEach((doc) => {
          const documentData = { ...doc.data(), id: doc.id } as IProvider;
          list.push(documentData);
        });
        setProvidersList(list);
      });
    setPending((state) => !state);

    return () => {
      unsubscribe();
      setPending(false);
    };
  }, [code3cData?.id]);

  return [providersList, pending];
}
