// Importações Padrões
import React, { useContext } from 'react';

// Types
import {
  IManufacturer,
  IEditManufacturer,
  IDeleteManufacturer,
} from '../../typings/manufacturer.types';

// Contexto
import { Auth } from '../../services/auth.context';

// Componentes
import ItemCol from '../defaultBox/itemCol.component';
import ItemImage from '../defaultBox/itemImage.component';
import ItemAction, { IEdit } from '../defaultBox/itemAction.component';
import ResponsiveItemAction from '../defaultBox/responsiveItemAction.component';

interface IITemMenu {
  manufacturer: IManufacturer;
  index: number;
  showHideEditManufacturer: IEditManufacturer;
  setShowHideEditManufacturer: React.Dispatch<
    React.SetStateAction<IEdit<IManufacturer>>
  >;
  showHideDeleteManufacturer: IDeleteManufacturer;
  setShowHideDeleteManufacturer: React.Dispatch<
    React.SetStateAction<IDeleteManufacturer>
  >;
}

const defaultImage = 'https://static.thenounproject.com/png/475089-200.png';

const ItemManufacturer = ({
  manufacturer,
  index,
  showHideEditManufacturer,
  setShowHideEditManufacturer,
  showHideDeleteManufacturer,
  setShowHideDeleteManufacturer,
}: IITemMenu) => {
  const { windowWidth } = useContext(Auth);

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-mt-1 p-mx-auto p-p-3 p-grid item-box width100">
          <p className="p-col-9 width100 p-d-inline p-my-0 p-p-0 p-mr-0">
            #{index}
          </p>
          <div className="p-px-1 p-as-center p-mx-auto">
            <ItemImage
              colGrid={4}
              content={manufacturer.imageHeavy}
              imageDefault={defaultImage}
            />
          </div>
          <ItemCol colGrid={4} field="Nome" content={manufacturer.name} />
          <ResponsiveItemAction
            item={manufacturer}
            actionEdit={showHideEditManufacturer}
            setActionEdit={setShowHideEditManufacturer}
            actionDelete={showHideDeleteManufacturer}
            setActionDelete={setShowHideDeleteManufacturer}
          />
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div
          className="p-mt-1 p-mx-auto p-px-3 p-grid item-box width100"
          style={{
            border: '1px solid #e9ecef',
            borderWidth: '0 0 1px 0',
            backgroundColor: 'white',
          }}
        >
          <ItemCol colGrid={1} field="Id" content={String(index)} />
          <div className="p-col-4">
            <ItemImage
              colGrid={12}
              content={manufacturer.imageHeavy}
              imageDefault={defaultImage}
            />
          </div>
          <ItemCol colGrid={4} field="Nome" content={manufacturer.name} />
          <ItemAction
            item={manufacturer}
            actionEdit={showHideEditManufacturer}
            setActionEdit={setShowHideEditManufacturer}
            actionDelete={showHideDeleteManufacturer}
            setActionDelete={setShowHideDeleteManufacturer}
            colGrid={3}
          />
        </div>
      );
    }
    return <div />;
  }

  return changeBreakpoints();
};

export default ItemManufacturer;
