export interface IVisit {
  id?: string;
  code3c_id?: string;
  note?: string;

  startDate?: Date;
  endDate?: Date;

  visitStart?: Date;
  visitEnd?: Date;

  cancelled?: Date;

  client_id?: string;
  client_name?: string;

  client_imageHeavy?: string;
  client_imageLight?: string;

  seller_name?: string;
  seller_id?: string;
  allDay?: boolean;

  sellerNote?: string;
}

export interface IAddVisit {
  item: IVisit;
  edit: boolean;
  visible: boolean;
}

export interface IEditVisit {
  item: IVisit;
  edit: boolean;
  visible: boolean;
}

export const initialVisit: IVisit = {
  id: '',
  code3c_id: '',
  note: '',

  startDate: new Date(),
  endDate: new Date(),

  visitStart: new Date(),
  visitEnd: new Date(),

  cancelled: new Date(),

  client_id: '',
  client_name: '',

  client_imageHeavy: '',
  client_imageLight: '',

  seller_name: '',
  seller_id: '',

  allDay: false,
};
