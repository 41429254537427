// Importações Padrões
import React, { useState, useEffect, useContext, useMemo } from 'react';

// Icones
import { FaEdit, FaEye } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

// Componentes
import InputForm from '../input/inputForm.component';
import InputDropForm from '../input/inputDropForm.component';
import InputImageForm from '../input/inputImageForm.component';
import InputTextareaComponent from '../input/inputTextarea.component';
import InputFormCurrency from '../input/inputFormCurrency.component';

// Controladores
import { editService } from '../../controllers/service.controller';

// Funções
import { dataSchema } from '../../functions/service.function';
import { getURLFromDB } from '../../functions/image.function';

// Types
import { IInput } from '../../typings/input.types';
import { IService, IEditService } from '../../typings/service.types';
import { IValidationError } from '../../typings/yup.types';

// Contexto
import { Auth } from '../../services/auth.context';

interface IEditServiceProps {
  visible: IEditService;
  setVisible: React.Dispatch<React.SetStateAction<IEditService>>;
}

const EditServices = (props: IEditServiceProps) => {
  const { code3cData } = useContext(Auth);
  const { visible, setVisible } = props;

  const [name, setName] = useState<string>('');
  const [useMaxPrice, setUseMaxPrice] = useState(false);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [note, setNote] = useState<string>('');
  const [imageHeavy, setImageHeavy] = useState<string>('');
  const [imageLight, setImageLight] = useState<string>('');

  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  const [estimatedTime, setEstimatedTime] = useState<string>('');

  const [estimatedType, setEstimatedType] = useState<IInput>({
    name: '',
    code: '',
  });

  const estimatedTypeList: Array<IInput> = useMemo(() => {
    return [
      { name: 'Minutos', code: 'minutes' },
      { name: 'Horas', code: 'hours' },
      { name: 'Dias', code: 'days' },
      { name: 'Semanas', code: 'weeks' },
      { name: 'Meses', code: 'months' },
    ];
  }, []);

  const initialState = () => {
    setName('');
    setUseMaxPrice(false);
    setMinPrice(0);
    setMaxPrice(0);
    setEstimatedTime('');
    setEstimatedType({ name: '', code: '' });
    setNote('');
    setFormErr([]);
    setImageHeavy('');
    setImageLight('');
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
    setVisible({ ...visible, visible: false });
  };

  async function handleEditService() {
    setPendingAction((state) => !state);
    let urlImage = '';
    if (imageHeavy !== '') {
      urlImage = await getURLFromDB(
        true,
        imageHeavy,
        imageLight,
        code3cData?.id ? code3cData.id : '',
        'FDV-service',
      );
    }

    const dataValidation: Partial<IService> = {
      name,
      useMaxPrice,
      minPrice,
      maxPrice,
      note,
      estimatedTime,
      estimatedType: estimatedType.code,
      code3c_id: code3cData?.id,
      imageHeavy: urlImage,
      imageLight: urlImage,
    };
    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await editService(dataValidation, visible.item.id);
        if (response) {
          setFormErr([]);
          setPendingAction((state) => !state);
          initialState();
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  useEffect(() => {
    setName(visible.item.name);
    setUseMaxPrice(visible.item.useMaxPrice);
    setMinPrice(visible.item.minPrice);
    setMaxPrice(visible.item.maxPrice);
    setNote(visible.item.note);
    setEstimatedTime(visible.item.estimatedTime);
    setImageHeavy(visible.item.imageHeavy);
    setImageLight(visible.item.imageLight);
    const [typeSelected] = estimatedTypeList.filter(
      (system) => system.code === visible.item.estimatedType,
    );
    if (typeSelected) {
      setEstimatedType({
        name: typeSelected.name,
        code: visible.item.estimatedType,
      });
    }
  }, [visible, estimatedTypeList]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              {visible.edit ? (
                <FaEdit className="p-mx-auto p-as-center modal-header-icon" />
              ) : (
                <FaEye className="p-mx-auto p-as-center modal-header-icon" />
              )}
            </div>
            <span className="p-my-auto users-button-text">
              {visible.edit ? 'Editar ' : 'Ver '}Serviço
            </span>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label={visible.edit ? 'Salvar' : 'Editar'}
              disabled={pendingAction}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() =>
                visible.edit
                  ? handleEditService()
                  : setVisible({ ...visible, edit: true })
              }
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <InputForm
        pleaceHolder=""
        disabled={!visible.edit}
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome da Serviço"
        fieldCode="name"
      />
      <div className="p-d-flex p-my-2 p-jc-center">
        <small className="input-warn">Tempo Estimado do Serviço.</small>
      </div>
      <div className="p-grid p-mt-2">
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputForm
            pleaceHolder=""
            disabled={!visible.edit}
            item={estimatedTime}
            setItem={setEstimatedTime}
            formErr={formErr}
            fieldLabel="Tempo Estimado"
            fieldCode="estimatedTime"
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputDropForm
            filter={false}
            filterBy=""
            disabled={!visible.edit}
            item={estimatedType}
            setItem={setEstimatedType}
            options={estimatedTypeList}
            formErr={formErr}
            fieldLabel="Selecione o Tipo"
            fieldCode="estimatedType"
          />
        </div>
      </div>
      <div className=" p-mb-0 p-mt-3 p-d-flex p-flex-md-row p-jc-center">
        <Checkbox
          checked={useMaxPrice}
          onChange={() => setUseMaxPrice(!useMaxPrice)}
          className={
            useMaxPrice ? 'settings-checkbox-selected' : 'settings-checkbox'
          }
        />
        <p className="p-pl-2 p-m-0">Usar Preço Máximo</p>
      </div>
      <div className="p-grid p-mt-2">
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputFormCurrency
            disabled={false}
            item={minPrice}
            setItem={setMinPrice}
            formErr={formErr}
            fieldLabel="Preço Mínimo:"
            fieldCode="minPrice"
            floatLabel={false}
            iconStatus={() => <div />}
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputFormCurrency
            disabled={!useMaxPrice}
            item={maxPrice}
            setItem={setMaxPrice}
            formErr={formErr}
            fieldLabel="Preço Máximo:"
            fieldCode="maxPrice"
            floatLabel={false}
            iconStatus={() => <div />}
          />
        </div>
      </div>
      <div className="p-d-flex p-my-2 p-jc-center">
        <small className="input-warn">Anotações.</small>
      </div>
      <InputTextareaComponent
        disabled={!visible.edit}
        item={note}
        setItem={setNote}
        formErr={formErr}
        fieldLabel="Observações"
        fieldCode={note}
        rows={5}
        cols={30}
      />

      <InputImageForm
        visibleEdit={visible.edit}
        loadingImage={pendingAction}
        item={imageHeavy}
        setItem={setImageHeavy}
        fieldLabel="Imagem da Serviço"
      />
    </Dialog>
  );
};
export default EditServices;
