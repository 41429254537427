import firebase from 'firebase';
import {
  toasterError,
  toasterSuccess,
} from '../components/global/toaster.component';

import { salesGoalCollection } from './salesTarget';
import { ClientSalesTarget } from '../typings/salesTarget';

export const addClientSalesTarget = async (
  data: Partial<ClientSalesTarget>,
  salesTargetId: string,
  clientId: string,
) => {
  try {
    if (salesTargetId !== '') {
      await salesGoalCollection
        .doc(salesTargetId)
        .collection('FDV-ClientSalesTarget')
        .doc(clientId)
        .set({
          ...data,
          created_at: firebase.firestore.Timestamp.now(),
          updated_at: firebase.firestore.Timestamp.now(),
        });
      toasterSuccess('CRIADO', 'Meta do Cliente Aberta');
      return true;
    }
    throw new Error('Meta Inválida');
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua categoria!');
    return false;
  }
};

export const editClientSalesTarget = async (
  data: Partial<ClientSalesTarget>,
  salesTargetId: string,
  clientId: string,
) => {
  try {
    if (salesTargetId !== '') {
      await salesGoalCollection
        .doc(salesTargetId)
        .collection('FDV-ClientSalesTarget')
        .doc(clientId)
        .update({
          ...data,
          created_at: firebase.firestore.Timestamp.now(),
          updated_at: firebase.firestore.Timestamp.now(),
        });
      toasterSuccess('ATUALIZADO', 'Meta do Cliente Atualizado');
      return true;
    }
    throw new Error('Meta Inválida');
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua categoria!');
    return false;
  }
};
