// Importações Padrões
import React, { useContext } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Contexto
import { Auth } from '../../services/auth.context';

// Rotas
import listRoutes from '../../routes/list.routes';

// Componentes
import ItemMenuSubRoute from './itemMenuSubRoute.component';

// Funções
import { parseNameRoute } from '../../functions/header.function';

interface IItemMenuRouteSubListrops {
  pathname: string;
  pathNameRoute: string;
  subRoutes: Array<string>;
  icon: IconProp;
  extender: boolean;
  subItemExtended: boolean;
  setSubItemExtended: React.Dispatch<React.SetStateAction<boolean>>;
}

const ItemMenuRouteSubList = (props: IItemMenuRouteSubListrops) => {
  const { toggleMenuSize } = useContext(Auth);

  const {
    pathname,
    pathNameRoute,
    subRoutes,
    icon,
    extender,
    subItemExtended,
    setSubItemExtended,
  } = props;

  const [iconSubRoute] = listRoutes.filter(
    (routeName) => routeName.pathname === parseNameRoute(pathNameRoute),
  );

  const activeSubRoute =
    subRoutes.filter((routeName) => routeName === parseNameRoute(pathNameRoute))
      .length >= 1;

  const active = false;

  return (
    <div
      className={
        subItemExtended
          ? 'p-grid p-col p-d-flex p-m-0 p-p-0 item-display-sub-item'
          : 'p-grid p-col p-d-flex p-m-0 p-p-0 item-display'
      }
    >
      <div
        aria-hidden="true"
        className={
          subItemExtended && activeSubRoute && !extender
            ? 'p-d-flex item-display-main-sub-item-active'
            : 'p-d-flex item-display-main-sub-item'
        }
        onClick={() => {
          if (extender) {
            setSubItemExtended((state) => !state);
          }
        }}
      >
        <div
          aria-hidden="true"
          className={
            extender
              ? 'p-col-2 p-d-inline p-d-flex p-as-center'
              : 'p-col p-d-inline p-d-flex p-as-center'
          }
          onClick={() => {
            if (!extender) {
              toggleMenuSize && toggleMenuSize();
              setSubItemExtended(true);
            }
          }}
        >
          <FontAwesomeIcon
            icon={
              subItemExtended && activeSubRoute && !extender
                ? (iconSubRoute.icon as IconProp)
                : icon
            }
            className={
              active ? 'item-icon-active p-mx-auto' : 'item-icon p-mx-auto'
            }
          />
        </div>
        <div
          className={
            extender
              ? 'p-col-8 p-d-inline p-m-0 p-as-center p-px-0'
              : 'p-d-none p-d-inline p-m-0 p-as-center p-px-0'
          }
        >
          <p
            className={
              extender
                ? 'p-d-block p-m-0 item-text-extended'
                : 'p-d-block p-m-0 item-text-closed'
            }
          >
            {pathname}
          </p>
        </div>
        <div
          aria-hidden="true"
          className={extender ? 'p-m-0 p-as-center p-px-0' : 'p-d-none'}
        >
          {!subItemExtended ? (
            <FontAwesomeIcon
              icon="chevron-down"
              className="item-icon-arrow-sub-item p-mx-auto"
            />
          ) : (
            <FontAwesomeIcon
              icon="chevron-up"
              className="item-icon-arrow-sub-item p-mx-auto"
            />
          )}
        </div>
      </div>
      <div className="p-grid p-dir-col p-m-0 item-box-sub-item">
        {subItemExtended &&
          extender &&
          subRoutes.map((routeName) => {
            return listRoutes.map((subRoute) => {
              if (routeName === subRoute.pathname && subRoute.path !== '/') {
                return (
                  <ItemMenuSubRoute
                    path={subRoute.path}
                    pathname={subRoute.pathname}
                    icon={subRoute.icon}
                    active={
                      subRoute.path.toUpperCase() ===
                      pathNameRoute.toUpperCase()
                    }
                    extender={extender && extender ? extender : false}
                  />
                );
              }
              return null;
            });
          })}
      </div>
    </div>
  );
};

export default ItemMenuRouteSubList;
