import * as React from 'react';
import { SalesTargetProvider } from '../contexts/SalesTargetContextt';

import {
  feedbackBodyTemplate,
  formatGoalPotential,
  formatNotRealized,
  formatRealized,
  goalBodyTemplate,
} from './DataTableBody';

export const SelectedSellerHeader = () => {
  const { selectedSeller } = React.useContext(SalesTargetProvider);
  return (
    <React.Fragment>
      <div className="header">
        <div className="content">
          <span className="title">Vendedor:&nbsp;</span>
          <span className="answer">{selectedSeller!.name}</span>
        </div>
        <div className="content">
          <span className="title">Progresso:&nbsp;</span>
          <span className="answer">{`${(Number.isNaN(
            selectedSeller!.progress,
          ) || !Number.isFinite(selectedSeller!.progress)
            ? 0
            : selectedSeller!.progress
          ).toFixed(2)}%`}</span>
        </div>
      </div>
      <div className="header">
        <div className="content">
          <span className="title">Potencial:&nbsp;</span>
          <span className="answer">
            {' '}
            {goalBodyTemplate(formatGoalPotential(selectedSeller!.potential))}
          </span>
        </div>
        <div className="content">
          <span className="title">Meta:&nbsp;</span>
          <span className="answer">
            {goalBodyTemplate(formatGoalPotential(selectedSeller!.goal))}
          </span>
        </div>
        <div className="content">
          <span className="title">Realizado:&nbsp;</span>
          <span className="answer">
            {feedbackBodyTemplate(
              formatRealized(selectedSeller!.realized, selectedSeller!.goal),
            )}
          </span>
        </div>
        <div className="content">
          <span className="title">Ñ Realizado:&nbsp;</span>
          <span className="answer">
            {feedbackBodyTemplate(
              formatNotRealized(
                selectedSeller!.notRealized,
                selectedSeller!.goal,
              ),
            )}
          </span>
        </div>
      </div>
      <div className="header">
        <div className="content">
          <span className="title">Meta em Porcentagem:&nbsp;</span>
          <span className="answer">{`${(
            (selectedSeller!.goal * 100) /
            selectedSeller!.potential
          ).toFixed(2)}%`}</span>
        </div>
      </div>
    </React.Fragment>
  );
};
