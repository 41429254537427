// Importações Padrões
import React, { useEffect } from 'react';

// React Prime
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

interface IFilterBy {
  name: string;
  code: string;
}

interface IItemFilterProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setInputSearch: React.Dispatch<React.SetStateAction<string>>;
  searchVisible: boolean;
  setSearchVisible: React.Dispatch<React.SetStateAction<boolean>>;
  filterBy: Array<IFilterBy>;
}

const ItemFilter = (props: IItemFilterProps) => {
  const {
    search,
    setSearch,
    setInputSearch,
    searchVisible,
    setSearchVisible,
    filterBy,
  } = props;

  useEffect(() => {
    if (search === '') {
      setInputSearch('');
    }
  }, [search, setInputSearch]);

  return (
    <Dialog
      header="Filtrar"
      footer={() => {
        return (
          <div>
            <Button
              className="modal-cancel-button"
              label="Limpar"
              onClick={() => {
                setSearch('');
                setInputSearch('');
                setSearchVisible(false);
              }}
            />
            <Button
              className="accept-button"
              label="Aplicar"
              onClick={() => {
                setInputSearch(search);
                setSearchVisible(false);
              }}
            />
          </div>
        );
      }}
      position="top"
      visible={searchVisible}
      style={{ width: '20rem' }}
      onHide={() => setSearchVisible(false)}
    >
      <InputText
        className="p-d-md-none settings-input"
        placeholder="Pesquisar"
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearch(e.target.value);
        }}
      />
      <div className="p-field">
        <label className="p-my-0 p-mt-4">Tipo de usuário</label>
        <span className="p-d-flex p-input-icon-right">
          <Dropdown
            className="settings-input settings-dropdown"
            filterInputAutoFocus={false}
            value={filterBy}
            options={filterBy}
            optionLabel="name"
            filter
            filterBy="name"
            placeholder="Selecione o Tipo"
          />
        </span>
      </div>
    </Dialog>
  );
};

export default ItemFilter;
