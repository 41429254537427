// Importações Padrões
import React, { useCallback, useContext, useEffect, useState } from 'react';

// Icones
import { FaTimes } from 'react-icons/fa';

// React Prime
import { Avatar } from 'primereact/avatar';

// Contexto
import { Auth } from '../../services/auth.context';

// Rotas
import listRoutes from '../../routes/list.routes';
import listStockistRoutes from '../../routes/listStockist.routes';

// Componentes
import ItemMenu from './itemMenu.component';

// Funções
import { parseUserFunction } from '../../functions/user.function';
import { generatorImageURL } from '../../functions/images.function';

// Estilos
import '../../styles/global/sideMenu.scss';

const SideMenu: React.FC = () => {
  const {
    currentUser,
    system3cData,
    userData,
    systemData,
    extender,
    pathName,
    toggleMenuSize,
  } = useContext(Auth);
  const [listRouter, setListRouter] = useState(
    system3cData?.fdvSystemServices
      ? listRoutes
      : listRoutes.filter((rt) => rt.pathname !== 'Serviços'),
  );

  const [ternaryExtender, setTernaryExtender] = useState<string>();
  const [ternaryBlock, setTernaryBlock] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const toggleTernary = useCallback(() => {
    if (windowWidth < 768 && extender) {
      setTernaryExtender('sideMenu-above p-shadow-1 p-d-md-inline');
      setTernaryBlock(true);
    } else if (windowWidth > 767 && extender) {
      setTernaryExtender('sideMenu-extended p-shadow-1 p-d-none p-d-md-inline');
      setTernaryBlock(false);
    } else {
      setTernaryExtender('sideMenu-closed p-shadow-1 p-d-none p-d-md-inline');
      setTernaryBlock(false);
    }
  }, [windowWidth, extender]);

  window.addEventListener('resize', () => {
    const newWidth = window.innerWidth;
    setWindowWidth(newWidth);
  });

  useEffect(() => {
    toggleTernary();
  }, [toggleTernary]);

  useEffect(() => {
    if (systemData) {
      switch (systemData.function) {
        case 'administrator':
          setListRouter(
            system3cData?.fdvSystemServices
              ? listRoutes
              : listRoutes.filter((rt) => rt.pathname !== 'Serviços'),
          );
          break;
        case 'stockist':
          setListRouter(
            system3cData?.fdvSystemServices
              ? listStockistRoutes
              : listStockistRoutes.filter((rt) => rt.pathname !== 'Serviços'),
          );
          break;
        default:
          setListRouter(
            system3cData?.fdvSystemServices
              ? listRoutes
              : listRoutes.filter((rt) => rt.pathname !== 'Serviços'),
          );
          break;
      }
    }
  }, [currentUser, systemData, system3cData?.fdvSystemServices]);

  return currentUser ? (
    <>
      {ternaryBlock && (
        <div
          aria-hidden="true"
          className="sideMenu-blocker-above"
          onClick={() => {
            if (toggleMenuSize) toggleMenuSize();
          }}
        />
      )}
      <div className={ternaryExtender}>
        <div className="p-m-0 p-p-0 p-d-md-none" aria-hidden="true">
          <FaTimes className="p-ml-2 p-mr-auto p-my-2 sideMenu-button-close" />
        </div>
        <div className="p-grid p-m-0 p-py-3 user-info">
          <div
            className={
              extender
                ? 'p-d-block p-col-4 p-p-0 p-ml-2 p-mr-1 p-my-2 p-as-center'
                : 'p-d-block p-col-4 p-p-0 p-ml-2 p-mr-1 p-my-2 p-as-center'
            }
            style={{ height: '3.5rem', width: '3.5rem' }}
          >
            {userData?.heavyImage ? (
              <img
                src={generatorImageURL(userData?.heavyImage)}
                alt="userImg"
                className="p-d-block p-as-center p-as-center user-image"
              />
            ) : (
              <Avatar
                label={userData && userData.name[0]}
                className="user-image"
              />
            )}
          </div>
          <div
            className={
              extender
                ? 'p-d-block p-col-1 p-p-0 p-mr-0 p-as-center user-info-line-left-extended'
                : 'p-d-block p-p-0 p-m-0 p-as-center user-info-line-left-closed'
            }
          />

          <div
            className={
              extender
                ? 'p-d-block p-col p-as-center p-p-0 user-info-data-extended'
                : 'p-d-block p-p-0 user-info-data-closed user-info-data-extended'
            }
          >
            <p
              className={
                extender
                  ? 'p-d-block p-my-0 user-info-name-extended'
                  : 'p-d-block p-my-0 user-info-name-closed'
              }
            >
              {userData?.name}
            </p>
            <p
              className={
                extender
                  ? 'p-d-block p-my-0 user-info-function-extended'
                  : 'p-d-block p-my-0 user-info-function-closed'
              }
            >
              {parseUserFunction(systemData?.function)}
            </p>
          </div>
          <div
            className={
              extender
                ? 'p-d-block user-info-line-bottom-extended'
                : 'p-d-block p-mx-auto user-info-line-bottom-closed'
            }
          />
        </div>
        <div className="p-grid p-dir-col p-m-0">
          {listRouter
            .filter((route) => route.type !== 'sub-route')
            .map((itemMenu, index) => (
              <ItemMenu
                key={index.toString()}
                itemMenu={itemMenu}
                pathName={pathName}
                extender={extender}
              />
            ))}
          <div style={{ height: '80px' }} />
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default SideMenu;
