// Importações Padrões
import { useContext } from 'react';

// Contexto
import { Auth } from '../../services/auth.context';

interface IItemColProps {
  content: string | number;
  colGrid: number;
}

const ItemCol = (props: IItemColProps) => {
  const { content, colGrid } = props;

  const { windowWidth } = useContext(Auth);

  return (
    <div
      className={`p-col-${colGrid} p-p-0 p-my-2 item-box-col p-text-center p-as-center p-d-flex`}
    >
      <p
        className={`${
          windowWidth && windowWidth > 900
            ? 'p-text-center p-col-12 p-m-0'
            : 'p-col-9 p-text-center width100'
        } p-d-inline p-my-0 p-p-0 p-mr-0`}
      >
        {content}
      </p>
    </div>
  );
};

export default ItemCol;
