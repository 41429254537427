export interface IService {
  id: string;
  name: string;
  minPrice: number;
  maxPrice: number;
  useMaxPrice: boolean;
  note: string;
  estimatedTime: string;
  estimatedType: string;
  code3c_id: string;
  imageLight: string;
  imageHeavy: string;

  listEnabled?: Array<string>;
  listMeta?: {
    [string: string]: IListMeta;
  };
}

export interface IListMeta {
  listPrice: number;
}

export interface IEditService {
  item: IService;
  edit: boolean;
  visible: boolean;
}

export interface IDeleteService {
  id: string;
  name: string;
  visible: boolean;
}

export const initialService: IService = {
  id: '',
  name: '',
  minPrice: 0,
  maxPrice: 0,
  useMaxPrice: false,
  note: '',
  estimatedTime: '',
  estimatedType: '',
  code3c_id: '',
  imageLight: '',
  imageHeavy: '',
};
