// Importações Padrões
import React, { useState } from 'react';

// Icones
import { FaTrash } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

// Types
import { IDeleteCity } from '../../typings/cities.types';

// Controladores
import { deleteCity } from '../../controllers/city.controller';

interface IDeleteCityProps {
  visible: IDeleteCity;
  setVisible: React.Dispatch<React.SetStateAction<IDeleteCity>>;
}

const DeleteCity = (props: IDeleteCityProps) => {
  const { visible, setVisible } = props;

  const [pendingAction, setPendingAction] = useState(false);

  const initialState = () => {
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
    setVisible({ id: '', name: '', visible: false });
  };

  const handleDeleteCity = async () => {
    setPendingAction((state) => !state);
    const response = await deleteCity(visible.id, visible.name);
    if (response) {
      initialState();
    } else {
      setPendingAction((state) => !state);
    }
  };

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-d-flex">
              <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
                <FaTrash className="p-p-1 p-mx-auto p-as-center modal-header-icon" />
              </div>
              <span className="p-my-auto users-button-text">Apagar Cidade</span>
            </div>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '33rem' }}
      footer={() => {
        return (
          <div className="p-d-flex p-mx-auto p-jc-center">
            <Button
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              className="modal-save-button"
              disabled={pendingAction}
              label="Apagar"
              onClick={() => {
                handleDeleteCity();
              }}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <div className="p-text-center">
        <p className="p-m-2 p-p-0">
          <b className="p-m-0">
            Tem certeza que quer apagar a cidade: {visible.name}?
          </b>
        </p>
      </div>
    </Dialog>
  );
};

export default DeleteCity;
