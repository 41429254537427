// Importações Padrões
import { maskBr } from 'js-brasil';
import { pt } from 'yup-locale-pt';
import * as yup from 'yup';

yup.setLocale(pt);

export const dataSchema = yup.object().shape({
  code3c_id: yup.string(),
  name: yup.string().label('Nome').min(3).required(),
});

export function formatCurrency(currency: string, price: number) {
  switch (true) {
    case currency === 'BRL': {
      return maskBr.currency(price / 100);
    }
    case currency === 'USD': {
      return maskBr.currency(price / 100).replace('R$', '$');
    }
    default: {
      return `${(price / 100).toFixed(2)}`;
    }
  }
}
