// Importações Padrões
import React from 'react';

// React Prime
import { InputTextarea } from 'primereact/inputtextarea';

// Types
import { IValidationError } from '../../typings/yup.types';

// Componentes
import { catchErrors, catchInvalid } from '../global/formErrors.component';

interface IInputTextareaProps {
  disabled: boolean;
  item: string;
  setItem: React.Dispatch<React.SetStateAction<string>>;
  formErr: Array<IValidationError>;
  fieldLabel: string;
  fieldCode: string;
  rows: number;
  cols: number;
}

const InputTextArea = (props: IInputTextareaProps) => {
  const {
    disabled,
    item,
    setItem,
    formErr,
    fieldLabel,
    fieldCode,
    rows,
    cols,
  } = props;
  return (
    <div className="p-field">
      <label className="p-my-0 p-mt-4">{fieldLabel}</label>
      <span className="p-d-flex p-input-icon-right">
        {catchInvalid(formErr, fieldCode) && (
          <i className="pi pi-times input-invalid" />
        )}
        <InputTextarea
          autoResize
          disabled={disabled}
          rows={rows}
          cols={cols}
          className="settings-input"
          value={item}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setItem(event.target.value);
          }}
        />
      </span>
      {catchErrors(formErr, fieldCode)}
    </div>
  );
};

export default InputTextArea;
