// Importações Padrões
import React, { useContext, useState } from 'react';

// Icones
import { FaPlus } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import { IBranding } from '../../typings/branding.types';
import { IValidationError } from '../../typings/yup.types';

// Controladores
import { addBranding } from '../../controllers/branding.controller';

// Componentes
import InputForm from '../input/inputForm.component';
import InputImageForm from '../input/inputImageForm.component';

// Funções
import { dataSchema } from '../../functions/branding.function';
import { getURLFromDB } from '../../functions/image.function';

interface IAddBrandingProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddBrandings = (props: IAddBrandingProps) => {
  const { visible, setVisible } = props;

  const { code3cData } = useContext(Auth);

  const [name, setName] = useState<string>('');
  const [imageHeavy, setImageHeavy] = useState<string>('');
  const [imageLight, setImageLight] = useState<string>('');

  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  const initialState = () => {
    setName('');
    setImageHeavy('');
    setImageLight('');
    setVisible(false);
    setFormErr([]);
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
  };

  async function handleAddBranding() {
    setPendingAction((state) => !state);
    let urlImage = '';
    if (imageHeavy !== '') {
      urlImage = await getURLFromDB(
        false,
        imageHeavy,
        imageLight,
        code3cData?.id ? code3cData.id : '',
        'FDV-category',
      );
    }

    const dataValidation: Partial<IBranding> = {
      name,
      code3c_id: code3cData?.id,
      imageHeavy: urlImage,
      imageLight: urlImage,
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await addBranding(dataValidation);
        if (response) {
          setFormErr([]);
          setPendingAction((state) => !state);
          initialState();
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              <FaPlus className="p-mx-auto p-as-center modal-header-icon" />
            </div>
            <span className="p-my-auto users-button-text">Nova Marca</span>
          </div>
        );
      }}
      visible={visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label="Salvar"
              disabled={pendingAction}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() => handleAddBranding()}
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <InputForm
        pleaceHolder=""
        disabled={false}
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome da Marca"
        fieldCode="name"
      />

      <InputImageForm
        visibleEdit
        loadingImage={pendingAction}
        item={imageHeavy}
        setItem={setImageHeavy}
        fieldLabel="Imagem da Marca"
      />
    </Dialog>
  );
};

export default AddBrandings;
