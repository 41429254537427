import { useCallback, useContext } from 'react';
import 'moment/locale/pt-br';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import {
  progressBodyTemplate,
  feedbackBodyTemplate,
  goalBodyTemplate,
  parseGoal,
  parseRealized,
  parseNotRealized,
} from './DataTableBody';
import { SalesTargetProvider } from '../contexts/SalesTargetContextt';
import { SelectedSalesTargetHeader } from './SelectedSalesTargetHeader';

import './SellerSalesTarget.scss';
import { filterApplyTemplate, filterClearTemplate } from './DataTableFilters';

export function SellerSalesTarget() {
  const { clients, sellers, goal, realized, setSelectedSeller, toggleView, setToggleView } =
    useContext(SalesTargetProvider);

  const renderClientSalesTarget = useCallback(() => {
    return (
      <div className="seller-sales-target">
        <SelectedSalesTargetHeader
          headerGoal={goal || 0}
          headerRealized={realized || 0}
          toggleView={toggleView!}
          setToggleView={setToggleView!}
        />
        <div className="card">
          <DataTable
            value={sellers}
            stripedRows
            showGridlines
            rowHover
            removableSort
            emptyMessage="Nenhum Vendedor"
            sortField="name"
            sortOrder={1}
          >
            <Column
              field="name"
              header="Nome"
              sortable
              filter
              filterPlaceholder="Pesquisar Nome"
              filterClear={filterClearTemplate}
              filterApply={filterApplyTemplate}
              filterMatchMode="contains"
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              maxConstraints={1}
            />
            <Column
              field="potential"
              header="Potencial"
              sortable
              body={(rowData) => {
                return goalBodyTemplate(parseGoal(rowData.potential));
              }}
            />
            <Column
              field="goal"
              header="Meta"
              sortable
              body={(rowData) => {
                return goalBodyTemplate(parseGoal(rowData.goal));
              }}
            />
            <Column
              field="realized"
              header="Realizado"
              sortable
              body={(rowData) => {
                return feedbackBodyTemplate(parseRealized(rowData.realized, rowData.goal));
              }}
            />
            <Column
              field="notRealized"
              header="Não Realizado"
              sortable
              body={(rowData) => {
                return feedbackBodyTemplate(parseNotRealized(rowData.notRealized, rowData.goal));
              }}
            />
            <Column
              field="progress"
              header="Progresso"
              body={(rowData) => {
                return progressBodyTemplate(rowData.progress);
              }}
              sortable
            />
            <Column
              body={(rowData) => {
                return (
                  <div className="p-d-flex p-jc-center">
                    <Button
                      className="button"
                      onClick={() => {
                        if (setSelectedSeller && setToggleView) {
                          setSelectedSeller(rowData);
                          setToggleView('SELECTED_SELLER');
                        }
                      }}
                    >
                      Abrir
                    </Button>
                  </div>
                );
              }}
            />
          </DataTable>
        </div>
      </div>
    );
  }, [clients]);

  return renderClientSalesTarget();
}
