import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { IValidationError } from '../../../typings/yup.types';
import { ErrorsInput } from '../../input/errors-input/ErrorsInput';
import { MandatoryAsterisk } from '../../input/mandatory-asterisk/MandatoryAsterisk';

import './StylizedDropDown.scss';

export interface Option {
  name: string;
  code: string;
}

export interface StylizedDropDownProps {
  selectedOption: Option;
  options: Array<Option>;
  // eslint-disable-next-line
  action: (any: any) => void;
  label: string;
  placeHolder: string;
  className?: string;
  mandatory: boolean;
  errors?: Array<IValidationError>;
  errorCode?: string;
}

export const StylizedDropDown = (props: StylizedDropDownProps) => {
  const {
    selectedOption,
    options,
    action,
    label,
    placeHolder,
    className,
    mandatory,
    errors,
    errorCode,
  } = props;

  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    if (
      errors &&
      errors.filter((error) => error.path === errorCode).length >= 1
    ) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
  }, [errors]);

  return (
    <div className="stylized-drop-down">
      <label className={`${isInvalid && 'invalid'}`}>
        {label} {mandatory && <MandatoryAsterisk />}
      </label>
      <Dropdown
        value={selectedOption}
        options={options}
        onChange={(e) => {
          action(e.target.value);
        }}
        optionLabel="name"
        placeholder={placeHolder}
        className={`input-dropdown ${className} ${isInvalid && 'invalid'}`}
      />
      {errors
        ?.filter((error) => error.path === errorCode)
        .map((errorComponent, index) => {
          return (
            <ErrorsInput
              key={String(index)}
              errorMessage={errorComponent.errors}
            />
          );
        })}
    </div>
  );
};
