// Importações Padrões
import firebase from 'firebase';

export interface ICode3c {
  id: string;
  typeOf?: ITypeOfCode3c;
  parent_id?: string;
  subsidiary_ids?: Array<string>;
  province_id: string;
  name: string;
  socialID: string;
  phone: string;
  email: string;
  stateSubscription: string;
  address: IAddress;
  systems: Array<ISystemsCode3c>;
  imageLight: string;
  imageHeavy: string;
  updated_at?: Date;
  created_at?: Date;
}
export interface IAddress {
  postalCode: string;
  province: string;
  city: string;
  neighborhood: string;
  street: string;
  houseNumber: string;
}

export interface ISystemsCode3c {
  systemId: string;
  systemName: string;
  systemSegment: string;
  systemActive: boolean;
  systemBilling: ISystemBilling;
  fdvSystemServices?: boolean;
}

export interface ISystemBilling {
  systemQuotations?: string;
  systemPlan?: string;
  systemDateBill?: firebase.firestore.Timestamp;
  systemAccounts?: string;
  systemMonthlyBill?: string;
}

export type ITypeOfCode3c = 'parent' | 'subsidiary';
export type ISystemId = 'CMR' | 'FAGRO' | 'CFDV' | 'CGA' | 'CFA';

export type ISystemName =
  | 'Cansho Meu Restaurante'
  | 'Cansho Força Agro'
  | 'Cansho Força De Venda'
  | 'Cansho Guardian';

export const initialCode3c = {
  id: '',
  province_id: '',
  name: '',
  socialID: '',
  phone: '',
  email: '',
  stateSubscription: '',
  address: {
    postalCode: '',
    province: '',
    city: '',
    neighborhood: '',
    street: '',
    houseNumber: '',
  },
  systems: [
    {
      systemId: 'CFDV',
      systemName: 'Cansho Força de Vendas',
      systemSegment: '',
      systemActive: false,
      systemBilling: {
        systemQuotations: '',
        systemPlan: '',
        systemAccounts: '',
        systemMonthlyBill: '',
      },
    },
  ],
  imageLight: '',
  imageHeavy: '',
};

export const initialSystemCode3c = {
  systemId: 'CFDV',
  systemName: 'Cansho Força de Vendas',
  systemSegment: '',
  systemActive: false,
  systemBilling: {
    systemQuotations: '',
    systemPlan: '',
    systemAccounts: '',
    systemMonthlyBill: '',
  },
};

export const initialAddress = {
  index: 1,
  postalCode: '',
  province: '',
  city: '',
  neighborhood: '',
  street: '',
  houseNumber: '',
};
