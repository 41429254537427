import { useEffect, useState } from 'react';

import moment from 'moment';
import 'moment/locale/pt-br';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';

import { IOrder } from '../../../typings/order.types';

import './RecentOrders.scss';
import { Link } from 'react-router-dom';

export interface RecentOrdersProps {
  ordersList: Array<IOrder>;
}

export const RecentOrders = (props: RecentOrdersProps) => {
  const { ordersList } = props;

  // eslint-disable-next-line
  const [orders, setOrders] = useState<Array<any>>([]);

  useEffect(() => {
    const response = ordersList.map((item) => {
      return {
        client_name: item.client_name,
        user_name: item.user_name,
        created_at: item.created_at,
        created: item.status.created,
        processing: item.status.processing,
        processed: item.status.processed,
        sended: item.status.sended,
        finished: item.status.finished,
        cancelled: item.status.cancelled,
      };
    });
    if (response) {
      setOrders(response);
    }
  }, [ordersList]);

  const actionBodyTemplate = () => {
    return (
      <div>
        <Link to={`/pedidos`} className="p-mx-auto item-menu-action">
          <Button
            type="button"
            icon="pi pi-search"
            className="p-button-success"
          />
        </Link>
      </div>
    );
  };

  // eslint-disable-next-line
  const statusBodyTemplate = (rowData: any) => {
    if (rowData.cancelled !== 'Pendente') {
      return <span className="order-badge-order-pending p-p-1">Cancelado</span>;
    } else if (rowData.processing === 'Pendente') {
      return <span className="order-badge-order-created p-p-1">Criado</span>;
    } else if (rowData.processed === 'Pendente') {
      return (
        <span className="order-badge-order-processing p-p-1">Processando</span>
      );
    } else if (rowData.sended === 'Pendente') {
      return (
        <span className="order-badge-order-processed p-p-1">Processado</span>
      );
    } else if (rowData.finished === 'Pendente') {
      return <span className="order-badge-order-sended p-p-1">Expedido</span>;
    } else {
      return (
        <span className="order-badge-order-pending p-p-1">Finalizado</span>
      );
    }
  };

  return (
    <div
      id="map"
      className="width100"
      style={{
        background: '#ffffff',
        border: '1px solid #EEEEEE',
        height: '25.1rem',
      }}
    >
      <div className="p-px-3 p-py-3 dashboard-properties-title">
        Pedidos em Aberto
      </div>

      <div className="p-px-3" style={{ height: '20rem', overflow: 'auto' }}>
        <DataTable
          value={orders}
          stripedRows
          showGridlines
          rowHover
          removableSort
          emptyMessage="Nenhum Pedido"
          sortField="name"
          sortOrder={1}
        >
          <Column
            field="client"
            header="Cliente"
            sortable
            body={(rowData) => {
              return <b>{rowData.client_name}</b>;
            }}
            filter
            filterPlaceholder="Pesquisar Nome"
            filterMatchMode="contains"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            maxConstraints={1}
          />
          <Column
            field="seller"
            header="Vendedor"
            sortable
            body={(rowData) => {
              return <span>{rowData.user_name}</span>;
            }}
          />
          <Column
            field="date"
            header="Data"
            sortable
            body={(rowData) => {
              return <span>{moment(rowData.created_at).format('L')}</span>;
            }}
          />
          <Column field="status" header="Status" body={statusBodyTemplate} />
          <Column field="action" header="Ação" body={actionBodyTemplate} />
        </DataTable>
      </div>
    </div>
  );
};
export default RecentOrders;
