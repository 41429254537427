// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';

// Icones
import { FaPlus } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

// Contexto
import { Auth } from '../../services/auth.context';

// Hooks
import { useUserslist } from '../../hooks/useUsersList';

// Types
import { IUser, ISystemFunction } from '../../typings/users.types';
import { IValidationError } from '../../typings/yup.types';

// Controladores
import { addUser } from '../../controllers/user.controller';

// Componentes
import InputForm from '../input/inputForm.component';
import InputFormPercentageAlt from '../input/inputFormPercentageAlt.component';
import InputPassForm from '../input/inputPassForm.component';
import InputDropForm from '../input/inputDropForm.component';

// Funções
import {
  dataSchemaWithPass,
  parseUserFunction,
} from '../../functions/user.function';

interface IAddUserProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddUser = (props: IAddUserProps) => {
  const { usersList } = useUserslist();
  const { code3cData, accessToken } = useContext(Auth);
  const { visible, setVisible } = props;

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordValidation, setPasswordValidation] = useState<string>('');
  const [commission, setCommission] = useState<number>(0);

  const systemActive = true;
  const systemId = 'CFDV';
  const systemName = 'Cansho Força de Vendas';
  const [systemFunction, setSystemFunction] = useState<ISystemFunction>({
    name: '',
    code: '',
  });

  const [peekPass, setPeekPass] = useState<boolean>(false);

  const systemFunctionList: Array<ISystemFunction> = [
    { name: 'Administrador', code: 'administrator' },
    { name: 'Estoquista', code: 'stockist' },
    { name: 'Vendedor', code: 'seller' },
  ];

  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);
  const [emailErr, setEmailErr] = useState<boolean>(false);

  const [pendingAction, setPendingAction] = useState<boolean>(false);

  const initialState = () => {
    setName('');
    setEmail('');
    setPassword('');
    setPasswordValidation('');
    setSystemFunction({ name: 'Usuário', code: 'user' });
    setPeekPass(false);
    setFormErr([]);
    setPendingAction(false);
    setVisible(false);
    setCommission(0);
  };

  function handleAddUser() {
    setPendingAction((state) => !state);
    const dataValidation: Partial<IUser> = {
      code3c_id: code3cData?.id,
      name,
      email,
      password,
      passwordValidation,
      systems: [
        {
          systemActive,
          systemId,
          systemName,
          function: parseUserFunction(systemFunction.code),
        },
      ],
      commission,
    };

    dataSchemaWithPass
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        delete dataValidation.passwordValidation;
        if (accessToken) {
          const response = await addUser(
            {
              ...dataValidation,
              systems: [
                {
                  systemActive,
                  systemId,
                  systemName,
                  function: systemFunction.code,
                },
              ],
            },
            accessToken,
          );
          if (response) {
            setFormErr([]);
            setPendingAction((state) => !state);
            initialState();
          } else {
            setFormErr([]);
            setPendingAction((state) => !state);
          }
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  useEffect(() => {
    if (usersList.filter((user) => user.email === email).length >= 1) {
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }
  }, [email, usersList]);

  useEffect(() => {
    if (commission < 0) {
      setCommission(0);
    } else if (commission > 100) {
      setCommission(100);
    }
  }, [commission]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              <FaPlus className="p-mx-auto p-as-center modal-header-icon" />
            </div>
            <span className="p-my-auto users-button-text">Novo Usuário</span>
          </div>
        );
      }}
      visible={visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label="Salvar"
              disabled={pendingAction || emailErr}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() => handleAddUser()}
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <InputForm
        pleaceHolder=""
        disabled={false}
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome do usuário"
        fieldCode="name"
      />
      <InputForm
        pleaceHolder=""
        disabled={false}
        item={email}
        setItem={setEmail}
        formErr={formErr}
        fieldLabel="Email do usuário"
        fieldCode="email"
      />
      <small className="p-d-block input-invalid">
        {emailErr && <p>Esse email já está cadastrado</p>}
      </small>
      <InputPassForm
        disabled={false}
        item={password}
        setItem={setPassword}
        formErr={formErr}
        fieldLabel="Senha do usuário"
        fieldCode="password"
        peekPass={peekPass}
        setPeekPass={setPeekPass}
      />
      <InputPassForm
        disabled={false}
        item={passwordValidation}
        setItem={setPasswordValidation}
        formErr={formErr}
        fieldLabel="Confirmação de Senha do usuário"
        fieldCode="passwordValidation"
        peekPass={peekPass}
        setPeekPass={setPeekPass}
      />
      <InputDropForm
        filter={false}
        filterBy=""
        disabled={false}
        item={systemFunction}
        setItem={setSystemFunction}
        options={systemFunctionList}
        formErr={formErr}
        fieldLabel="Tipo de usuário"
        fieldCode="systems[0].function"
      />
      {systemFunction.code === 'seller' && (
        <InputFormPercentageAlt
          pleaceHolder=""
          disabled={false}
          item={commission}
          setItem={setCommission}
          formErr={[]}
          fieldLabel="Comissão do Vendedor (%)"
          fieldCode="commission"
        />
      )}
    </Dialog>
  );
};

export default AddUser;
