// Importações Padrões
import firebase from 'firebase';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Types
import { IList, IListProduct } from '../typings/list.types';

// Componentes
import {
  toasterError,
  toasterSuccess,
} from '../components/global/toaster.component';

const listCollection = firebaseConfig.firestore().collection('FDV-list');

async function addList(data: Partial<IList>) {
  try {
    const response = await listCollection.add({
      ...data,
      created_at: firebase.firestore.Timestamp.now(),
      updated_at: firebase.firestore.Timestamp.now(),
    });
    if (response.id) {
      toasterSuccess('CRIAR', `Lista ${data.name} Criada`);
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua lista!');
    return false;
  }
}

async function editList(data: Partial<IList>, id: string) {
  try {
    const dataDBExists = await listCollection.doc(id).get();
    if (!dataDBExists) {
      return [];
    }
    // Corrigir
    await listCollection.doc(id).update({
      ...data,
      updated_at: firebase.firestore.Timestamp.now(),
    });
    toasterSuccess('EDITAR', `Lista ${data.name} Editada!`);
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua lista!');
    return false;
  }
}

async function updateProductList(data: Array<IListProduct>, id: string) {
  try {
    await listCollection.doc(id).update({
      productList: data,
      updated_at: firebase.firestore.Timestamp.now(),
    });
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua lista!');
    return false;
  }
}

async function deleteList(id: string, name: string) {
  try {
    await listCollection.doc(id).delete();
    toasterSuccess('APAGAR', `Lista ${name} Apagada!`);
    return true;
  } catch (err) {
    console.log(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua lista!');
    return false;
  }
}

async function searchList(id: string) {
  try {
    const data = await listCollection.doc(id).get();
    return <IList>{ ...data.data(), id };
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Não foi possível ler os dados.');
    return null;
  }
}

export {
  listCollection,
  addList,
  editList,
  updateProductList,
  searchList,
  deleteList,
};
