// Importações Padrões
import React, { useContext } from 'react';
import { Redirect, Link } from 'react-router-dom';

// React Prime
import { Button } from 'primereact/button';

// Contexto
import { Auth } from '../services/auth.context';

// Estilos
import '../styles/global/erro404.scss';

const Erro404: React.FC = () => {
  const { currentUser } = useContext(Auth);
  return (
    <>
      <Redirect to="/erro404" />
      <div
        className={
          currentUser
            ? 'p-text-center erro404-display-login'
            : 'p-text-center erro404-display-logout'
        }
      >
        <p className="p-my-0 erro404-title">404</p>
        <p className="p-my-0 erro404-divisor" />
        <p className="p-my-0 erro404-subTitle">A Página </p>
        <p className="p-mt-0 erro404-underSubTitle">Não Foi Encontrada</p>
        <Link className="erro404-link" to="/">
          <Button
            label="Voltar"
            icon="pi pi-arrow-left"
            className="erro404-btn"
          />
        </Link>
      </div>
    </>
  );
};

export default Erro404;
