import { SalesTargetContext } from './contexts/SalesTargetContextt';
import SalesTarget from './SalesTarget';

const SalesTargetPage: React.FC = () => {
  return (
    <SalesTargetContext>
      <SalesTarget />
    </SalesTargetContext>
  );
};

export default SalesTargetPage;
