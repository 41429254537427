// Types
import { IAddress } from './code3c.types';

export interface IProvider {
  id: string;
  name: string;
  systemId: string;
  socialID: string;
  stateSubscription: string;
  phone: string;
  email: string;
  typeOf: string;
  note: string;
  address: IAddress;
  code3c_id: string;
  imageLight: string;
  imageHeavy: string;
}

export interface IEditProvider {
  item: IProvider;
  edit: boolean;
  visible: boolean;
}

export interface IDeleteProvider {
  id: string;
  name: string;
  visible: boolean;
}

export const initialProvider = {
  id: '',
  systemId: '',
  name: '',
  socialID: '',
  stateSubscription: '',
  phone: '',
  email: '',
  note: '',
  address: {
    postalCode: '',
    province: '',
    city: '',
    neighborhood: '',
    street: '',
    houseNumber: '',
  },
  typeOf: '',
  code3c_id: '',
  imageLight: '',
  imageHeavy: '',
};
