import { useContext, useState } from 'react';
import { Button } from 'primereact/button';
import moment from 'moment';

import { DateInput } from '../../../components/input/range-date-input/RangeDateInput';

import { SalesTarget } from '../../../typings/salesTarget';
import { createSalesTargetSchema } from '../validations/createSalesTarget';
import { IValidationError } from '../../../typings/yup.types';
import { Auth } from '../../../services/auth.context';
import { addSalesTarget } from '../../../controllers/salesTarget';

import './CreateSalesTarget.scss';

export const CreateSalesTarget = () => {
  const { code3cData } = useContext(Auth);

  const [dates, setDates] = useState<Date[] | undefined>([
    moment().dayOfYear(1).toDate(),
    moment([moment().year()]).isLeapYear()
      ? moment().dayOfYear(366).toDate()
      : moment().dayOfYear(365).toDate(),
  ]);

  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  const createNewSalesTarget = () => {
    const newSalesTarget = {
      code3c_id: code3cData!.id,
      startDate: dates ? dates[0] : null,
      endDate: dates ? dates[1] : null,
    };

    createSalesTargetSchema
      .validate(newSalesTarget, { abortEarly: false })
      .then(async () => {
        const response = await addSalesTarget(
          newSalesTarget as Partial<SalesTarget>,
        );
        if (response) {
          setFormErr([]);
        } else {
          setFormErr([]);
        }
      })
      .catch((error: IValidationError) => {
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  };

  return (
    <div className="create-sales-target">
      <div className="header" />
      <div className="box">
        <h4>Você ainda não possui nenhuma Meta!</h4>
        <DateInput
          label="Defina um Período para a Meta"
          dates={dates}
          setDates={setDates}
          errorCode="code3c_id"
          errors={formErr}
          mandatory
        />
        <Button
          className="button"
          onClick={() => {
            createNewSalesTarget();
          }}
        >
          Criar Nova Meta
        </Button>
      </div>
    </div>
  );
};
