// Importações Padrões
import React, { useState, useEffect, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import firebase from 'firebase';
import moment from 'moment';
import 'moment/locale/pt-br';

// Types
import ContextProps from '../typings/context.types';
import { ISystemsUser, IUser } from '../typings/users.types';
import {
  ICode3c,
  initialCode3c,
  initialSystemCode3c,
  ISystemsCode3c,
} from '../typings/code3c.types';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Controladores
import { searchUser, editUser } from '../controllers/user.controller';
import { searchCode3c } from '../controllers/code3c.controller';

// Paginas
import Billing from '../pages/billing';

// Componentes
import { ProgressSpinnerSys } from '../components/global/progressSpinner.component';
import { toasterWarn } from '../components/global/toaster.component';

// Funções
import { setLimitSize } from '../functions/auth.function';

const Auth = React.createContext<Partial<ContextProps>>({});

const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
  const [accessToken, setAccessToken] = useState<string>('');

  const [pending, setPending] = useState<boolean>(true);

  const [extender, setExtender] = useState<boolean>(false);
  const [expireTime, setExpireTime] = useState<boolean>(false);
  const [expireSystem, setExpireSystem] = useState<boolean>(false);

  const [pathName, setPathName] = useState<string>('');

  const [userData, setUserData] = useState<IUser>();
  const [code3cData, setCode3cData] = useState<ICode3c>(initialCode3c);
  const [systemData, setSystemData] = useState<ISystemsUser>();
  const [system3cData, setSystem3cData] =
    useState<ISystemsCode3c>(initialSystemCode3c);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [limitItems, setLimitItems] = useState<number>(
    setLimitSize(window.innerWidth),
  );

  window.addEventListener('resize', () => {
    const newWidth = window.innerWidth;
    setWindowWidth(newWidth);
    setLimitItems(setLimitSize(newWidth));
  });

  const clearContext = () => {
    setPending(true);
    setUserData(undefined);
    setCode3cData(initialCode3c);
    setSystemData(undefined);
    setSystem3cData(initialSystemCode3c);
  };

  const handleSettingUser = async ({
    name,
    email,
    password,
  }: Partial<IUser>) => {
    if (userData) {
      let newUserRequest: Partial<IUser> = {
        ...userData,
        name,
        email,
      };
      if (password) {
        newUserRequest = { name, email, password };
      }
      const signOutConfirmation = () => {
        firebaseConfig.auth().signOut();
        return <Redirect to="/login" />;
      };
      delete newUserRequest.id;
      const response = await editUser(newUserRequest, userData.id, accessToken);
      if (response) {
        setUserData(response);
        if (password) signOutConfirmation();
        return true;
      }
    }
    return false;
  };

  const toggleMenuSize = () => {
    const extendMenu = !extender;
    localStorage.setItem('EXTENDER', JSON.stringify(extendMenu));
    setExtender(extendMenu);
  };

  const peekSizeMenu = useCallback(() => {
    if (localStorage.getItem('EXTENDER') === null) {
      localStorage.setItem('EXTENDER', JSON.stringify(extender));
    } else if (localStorage.getItem('EXTENDER') !== JSON.stringify(extender)) {
      setExtender(!extender);
    }
  }, [extender]);

  useEffect(() => {
    peekSizeMenu();
    firebaseConfig.auth().onAuthStateChanged(async (user) => {
      try {
        if (user) {
          const userDataRequest = await searchUser(user.uid);
          if (userDataRequest) {
            setUserData(userDataRequest);
            const code3cDataRequest = await searchCode3c(
              userDataRequest.code3c_id,
            );
            if (code3cDataRequest) {
              setCode3cData(code3cDataRequest);
              const [system3cDataFilter] = code3cDataRequest.systems.filter(
                (stm: ISystemsCode3c) => stm.systemId === 'CFDV',
              );
              const [systemDataFilter] = userDataRequest.systems.filter(
                (stm: ISystemsUser) => stm.systemId === 'CFDV',
              );
              if (system3cDataFilter && systemDataFilter) {
                setSystem3cData(system3cDataFilter);
                setSystemData(systemDataFilter);
                if (
                  system3cDataFilter.systemActive &&
                  systemDataFilter.systemActive
                ) {
                  const dateBill =
                    system3cDataFilter.systemBilling.systemDateBill;
                  if (moment().isAfter(dateBill?.toDate())) {
                    setExpireTime(true);
                    const dateBillMoment = moment(dateBill?.toDate()).add(
                      15,
                      'd',
                    );
                    if (moment().isAfter(dateBillMoment)) {
                      setExpireSystem(true);
                    }
                  }
                  if (systemDataFilter.function === 'seller') {
                    setCurrentUser(null);
                    clearContext();
                    await firebaseConfig.auth().signOut();
                    throw new Error('Módulo Desativado');
                  }
                  const userToken = await user.getIdTokenResult(false);
                  setAccessToken(userToken.token);
                  setCurrentUser(user);
                } else {
                  setCurrentUser(null);
                  clearContext();
                  await firebaseConfig.auth().signOut();
                  throw new Error('Módulo Desativado');
                }
              } else {
                setCurrentUser(null);
                clearContext();
                await firebaseConfig.auth().signOut();
                throw new Error('Módulo não habilitado');
              }
            }
          }
        } else {
          setCurrentUser(null);
        }
        // eslint-disable-next-line
      } catch (error: any) {
        toasterWarn(error.message, 'Você não possui acesso a esse módulo.');
        console.error(error);
      }
      setPending(false);
    });
  }, [peekSizeMenu]);

  if (pending) {
    return (
      <div className="p-d-flex p-jc-center vh-100">
        <ProgressSpinnerSys />
      </div>
    );
  }

  if (expireSystem) {
    return <Billing />;
  }

  return (
    <Auth.Provider
      value={{
        currentUser,
        setCurrentUser,
        accessToken,
        userData,
        code3cData,
        systemData,
        system3cData,
        extender,
        setExtender,
        pathName,
        setPathName,
        windowWidth,
        limitItems,
        expireTime,
        pending,
        handleSettingUser,
        toggleMenuSize,
      }}
    >
      {children}
    </Auth.Provider>
  );
};

export { Auth, AuthProvider };
