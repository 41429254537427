// Importações Padrões
import React, { useEffect, useContext, useState, useMemo } from 'react';
import firebase from 'firebase';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useLocation } from 'react-router-dom';

// Icones
import { CgClose } from 'react-icons/cg';
import { BiTime } from 'react-icons/bi';
import { FaCheck } from 'react-icons/fa';

// React Prime
import { Dropdown } from 'primereact/dropdown';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listDay from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';

// Hooks
import { useVisitsList } from '../hooks/useVisitsList';
import { useClientsList } from '../hooks/useClientsList';
import { useUserslist } from '../hooks/useUsersList';

// Controladores
import { editVisit } from '../controllers/visit.controller';

// Componentes
import AddVisit from '../components/visits/addVisit.component';
import EditVisit from '../components/visits/editVisit.component';
import { toasterError } from '../components/global/toaster.component';

// Types
import { IInput } from '../typings/input.types';
import {
  IVisit,
  IAddVisit,
  IEditVisit,
  initialVisit,
} from '../typings/visit.types';

// Contexto
import { Auth } from '../services/auth.context';

// Estilos
import '../styles/global/visits.scss';

const Calendar: React.FC = () => {
  const { setPathName, windowWidth } = useContext(Auth);

  const [visitsList] = useVisitsList();
  const { clientsList } = useClientsList();
  const { usersList } = useUserslist();

  // eslint-disable-next-line
  const [visitEvents, setVisitEvents] = useState<any>([]);

  const [showHideAddVisit, setShowHideAddVisit] = useState<IAddVisit>({
    item: initialVisit,
    edit: false,
    visible: false,
  });

  const [showHideEditVisit, setShowHideEditVisit] = useState<IEditVisit>({
    item: initialVisit,
    edit: false,
    visible: false,
  });

  const [sellerFilter, setSellerFilter] = useState<Array<IInput>>([
    {
      name: '',
      code: '',
    },
  ]);

  const [search, setSearch] = useState<IInput>({
    name: 'Todas as Visitas',
    code: 'all',
  });

  const location = useLocation();

  const newEvent = useMemo(() => {
    return visitsList.map((item) => {
      const [clientSelected] = clientsList.filter(
        (system) => system.id === item.client_id,
      );
      const [sellerSelected] = usersList.filter(
        (system) => system.id === item.seller_id,
      );
      if (item.cancelled) {
        return {
          className: 'cancelledVisitColor',
          backgroundColor: 'red',
          borderColor: 'red',
          textColor: 'white',
          startDate: item.startDate || firebase.firestore.Timestamp.now(),
          client_id: clientSelected ? clientSelected.id : 'Cliente Apagado',
          seller_id: sellerSelected ? sellerSelected.id : 'Vendedor Apagado',
          note: item.note,
          id: item.id,
          title: clientSelected ? clientSelected.name : 'Cliente Apagado',
          start: item.startDate,
          allDay: item.allDay,
        };
      }
      if (item.visitEnd) {
        return {
          className: 'endVisitColor',
          backgroundColor: 'green',
          borderColor: 'green',
          textColor: 'white',
          startDate: item.startDate,
          client_id: clientSelected ? clientSelected.id : 'Cliente Apagado',
          seller_id: sellerSelected ? sellerSelected.id : 'Vendedor Apagado',
          note: item.note,
          sellerNote: item.sellerNote,
          id: item.id,
          title: clientSelected ? clientSelected.name : 'Cliente Apagado',
          start: item.startDate,
          allDay: item.allDay,
        };
      }
      return {
        className: 'VisitColor',
        backgroundColor: 'orange',
        borderColor: 'orange',
        textColor: 'white',
        startDate: item.startDate,
        client_id: clientSelected ? clientSelected.id : 'Cliente Apagado',
        seller_id: sellerSelected ? sellerSelected.id : 'Vendedor Apagado',
        note: item.note,
        id: item.id,
        title: clientSelected ? clientSelected.name : 'Cliente Apagado',
        start: item.startDate,
        allDay: item.allDay,
      };
    });
  }, [clientsList, usersList, visitsList]);

  const handleEditVisit = async (id: string, date: Date) => {
    if (moment().isSameOrBefore(moment(date), 'day')) {
      const dataValidation: Partial<IVisit> = {
        startDate: date,
      };
      await editVisit(dataValidation, id);
    } else {
      setVisitEvents([]);
      setVisitEvents(newEvent);
      toasterError(
        'ERRO',
        'Não é possivel modificar uma visita para um dia anterior ao dia de hoje!',
      );
    }
  };

  const handleSeller: Array<IInput> = useMemo(() => {
    const newUsersList = usersList.map((item) => {
      const [userSystem] = item.systems.filter(
        (system) => system.function === 'seller',
      );
      if (userSystem) {
        return { name: item.name, code: item.id };
      }
      return null;
    });
    return newUsersList.filter((userList) => userList !== null) as Array<
      IInput
    >;
  }, [usersList]);

  useEffect(() => {
    setSellerFilter([
      { name: 'Todas as Visitas', code: 'all' },
      ...handleSeller,
    ]);
  }, [handleSeller]);

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  useEffect(() => {
    setVisitEvents(newEvent);
  }, [visitsList, clientsList, usersList, newEvent]);

  return (
    <div className="dashboard-display p-py-4 p-px-3 ">
      <AddVisit visible={showHideAddVisit} setVisible={setShowHideAddVisit} />
      <EditVisit
        visible={showHideEditVisit}
        setVisible={setShowHideEditVisit}
      />
      <div className="p-py-3 p-px-2 p-text-justify white">
        {windowWidth && windowWidth > 0 && windowWidth <= 900 ? (
          <>
            <div className="p-d-flex p-ai-center p-jc-center">
              <FaCheck className="p-m-1" style={{ color: 'green' }} />
              <span className="p-m-2">Visita Finalizada</span>
            </div>
            <div className="p-d-flex p-ai-center p-jc-center">
              <BiTime className="p-m-1" style={{ color: 'orange' }} />
              <span className="p-m-2">Visita Pendente</span>
            </div>
            <div className="p-d-flex p-ai-center p-jc-center">
              <CgClose className="p-m-1" style={{ color: 'red' }} />
              <span className="p-m-2">Visita Cancelada</span>
            </div>

            <Dropdown
              filter
              filterBy="name"
              optionLabel="name"
              className="visits-input settings-dropdown width100"
              options={sellerFilter}
              value={search}
              onChange={(e) => {
                setSearch(e.value);
              }}
            />
          </>
        ) : (
          <div className="p-p-2 p-d-flex p-ai-center p-jc-between">
            <span className="p-d-flex p-ai-center">
              <FaCheck className="p-m-1" style={{ color: 'green' }} />
              <span className="p-m-2">Visita Finalizada</span>
              <BiTime className="p-m-1" style={{ color: 'orange' }} />
              <span className="p-m-2">Visita Pendente</span>
              <CgClose className="p-m-1" style={{ color: 'red' }} />
              <span className="p-m-2">Visita Cancelada</span>
            </span>
            <Dropdown
              filter
              filterBy="name"
              optionLabel="name"
              className="visits-input settings-dropdown"
              options={sellerFilter}
              value={search}
              onChange={(e) => {
                setSearch(e.value);
              }}
            />
          </div>
        )}

        {windowWidth && windowWidth > 0 && windowWidth <= 900 ? (
          <FullCalendar
            locale={ptBrLocale}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listDay,
            ]}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'dayGridMonth,listDay',
            }}
            initialView="dayGridMonth"
            dateClick={(date) => {
              setShowHideAddVisit({
                ...showHideAddVisit,
                item: { visitStart: date.date },
                visible: true,
              });
            }}
            eventClick={(date) => {
              setShowHideEditVisit({
                ...showHideEditVisit,
                item: {
                  client_id: date.event.extendedProps.client_id,
                  seller_id: date.event.extendedProps.seller_id,
                  note: date.event.extendedProps.note,
                  sellerNote: date.event.extendedProps.sellerNote,
                  id: date.event.id,
                  allDay: date.event.allDay,
                  startDate: date.event.extendedProps.startDate,
                },
                visible: true,
              });
            }}
            events={visitEvents.filter((item: IVisit) => {
              if (search.code === 'all') {
                return true;
              }
              if (search.code === item.seller_id) {
                return true;
              }
              return null;
            })}
            editable
            eventDrop={(date) => {
              handleEditVisit(
                date.event.id,
                date.event.start ? date.event.start : new Date(),
              );
            }}
          />
        ) : (
          <FullCalendar
            locale={ptBrLocale}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listDay,
            ]}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'dayGridMonth,listDay',
            }}
            initialView="dayGridMonth"
            dateClick={(date) => {
              setShowHideAddVisit({
                ...showHideAddVisit,
                item: { visitStart: date.date },
                visible: true,
              });
            }}
            eventClick={(date) => {
              setShowHideEditVisit({
                ...showHideEditVisit,
                item: {
                  client_id: date.event.extendedProps.client_id,
                  seller_id: date.event.extendedProps.seller_id,
                  note: date.event.extendedProps.note,
                  sellerNote: date.event.extendedProps.sellerNote,
                  id: date.event.id,
                  allDay: date.event.allDay,
                  startDate: date.event.extendedProps.startDate,
                },
                visible: true,
              });
            }}
            events={visitEvents.filter((item: IVisit) => {
              if (search.code === 'all') {
                return true;
              }
              if (search.code === item.seller_id) {
                return true;
              }
              return null;
            })}
            editable
            eventDrop={(date) => {
              handleEditVisit(
                date.event.id,
                date.event.start ? date.event.start : new Date(),
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Calendar;
