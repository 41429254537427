// Configurações
import firebaseConfig from '../config/config.firebase';

// Types
import { ICode3c } from '../typings/code3c.types';
import { initialTotalOrders, ITotalOrders } from '../typings/order.types';

// Componentes
import { toasterError } from '../components/global/toaster.component';

const code3cCollection = firebaseConfig.firestore().collection('All-code3c');

async function searchCode3c(id: string) {
  try {
    const data = await code3cCollection.doc(id).get();
    return <ICode3c>{ ...data.data(), id };
  } catch (err) {
    console.error(err);
    toasterError('Erro', 'Não foi possível ler os dados.');
    return null;
  }
}

async function getTotalOrders(id: string) {
  try {
    const data = await code3cCollection
      .doc(id)
      .collection('FDV-shard-totalOrders')
      .get();
    const allData: Array<ITotalOrders> = [];
    data.docs.forEach((item) => {
      allData.push({ ...item.data() } as ITotalOrders);
    });
    const [newData] = allData;
    return newData;
  } catch (err) {
    console.error(err);
    toasterError('Erro', 'Não foi possível ler os dados.');
    return initialTotalOrders;
  }
}

export { code3cCollection, searchCode3c, getTotalOrders };
