import React, { useContext, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';

// Contexto
import { Auth } from '../../services/auth.context';

import useOnScreen from '../../hooks/useOnScreen';

// Types
import {
  IEditAmount,
  IProduct,
  IEditProduct,
  IDeleteProduct,
  IEditButton,
} from '../../typings/product.types';

// Componentes
import ItemCol from '../defaultBox/itemCol.component';
import ItemImage from '../defaultBox/itemImage.component';
import ItemAction, { IEdit } from '../defaultBox/itemAction.component';
import ResponsiveItemAction from '../defaultBox/responsiveItemAction.component';

// Funções
import { parseUnitAbbrev } from '../../functions/product.function';

interface IITemMenu {
  product: IProduct;
  index: number;

  showHideEditAmount: IEditAmount;
  setShowHideEditAmount: React.Dispatch<
    React.SetStateAction<IEditButton<IProduct>>
  >;

  showHideEditProduct: IEditProduct;
  setShowHideEditProduct: React.Dispatch<React.SetStateAction<IEdit<IProduct>>>;
  showHideDeleteProduct: IDeleteProduct;
  setShowHideDeleteProduct: React.Dispatch<
    React.SetStateAction<IDeleteProduct>
  >;
}

const defaultImage =
  'https://i.pinimg.com/originals/61/3c/ea/613ceaae3543acdf381af20da47612dc.png';

const ItemProduct = ({
  product,
  index,
  showHideEditProduct,
  setShowHideEditProduct,
  showHideDeleteProduct,
  setShowHideDeleteProduct,
  showHideEditAmount,
  setShowHideEditAmount,
}: IITemMenu) => {
  const { windowWidth } = useContext(Auth);

  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const isVisible = useOnScreen(ref);

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      if (!isVisible) {
        return <div ref={ref} style={{ height: '225px' }} />;
      }
      return (
        <div
          ref={ref}
          className="p-mt-1 p-mx-auto p-p-3 p-grid item-box width100"
        >
          <p className="p-col-9 width100 p-d-inline p-my-0 p-p-0 p-mr-0">
            #{index}
          </p>
          <div className="p-px-1 p-as-center p-mx-auto">
            <ItemImage
              colGrid={4}
              content={product.imageHeavy}
              useProductBase={product.useProductBase}
              imageDefault={defaultImage}
            />
          </div>
          <ItemCol colGrid={4} field="Nome" content={product.name} />
          <ItemCol colGrid={4} field="EAN" content={product.barCode} />
          <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center">
            <Button
              className="p-mx-auto p-as-center p-jc-center item-menu-action-responsive p-d-flex"
              label={String(
                ` Em estoque: ${product.productAmount} ${parseUnitAbbrev(
                  product.unit,
                )}`,
              )}
              onClick={() => {
                setShowHideEditAmount({
                  item: product,
                  visible: true,
                });
              }}
            />
          </div>
          <ResponsiveItemAction
            item={product}
            actionEdit={showHideEditProduct}
            setActionEdit={setShowHideEditProduct}
            actionDelete={showHideDeleteProduct}
            setActionDelete={setShowHideDeleteProduct}
          />
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      if (!isVisible) {
        return <div ref={ref} style={{ height: '80px' }} />;
      }
      return (
        <div
          ref={ref}
          className="p-mt-1 p-mx-auto p-p-1 p-grid item-box width100"
          style={{
            border: '1px solid #e9ecef',
            borderWidth: '0 0 1px 0',
            backgroundColor: 'white',
          }}
        >
          <ItemCol colGrid={1} field="Id" content={String(index)} />
          <div className="p-px-1 p-as-center p-mx-auto">
            <ItemImage
              colGrid={4}
              content={product.imageHeavy}
              useProductBase={product.useProductBase}
              imageDefault={defaultImage}
            />
          </div>
          <ItemCol colGrid={3} field="Produto" content={product.name} />
          <ItemCol colGrid={2} field="EAN" content={product.barCode} />
          <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center">
            <Button
              className="p-mx-auto p-as-center p-jc-center item-menu-action p-d-flex"
              label={String(
                `${product.productAmount} ${parseUnitAbbrev(product.unit)}`,
              )}
              onClick={() => {
                setShowHideEditAmount({
                  item: product,
                  visible: true,
                });
              }}
            />
          </div>
          <ItemAction
            item={product}
            actionEdit={showHideEditProduct}
            setActionEdit={setShowHideEditProduct}
            actionDelete={showHideDeleteProduct}
            setActionDelete={setShowHideDeleteProduct}
            colGrid={2}
          />
        </div>
      );
    }
    return <div />;
  }

  useEffect(() => {
    if (
      showHideEditAmount.visible &&
      showHideEditAmount.item.id === product.id
    ) {
      setShowHideEditAmount({
        item: product,
        visible: true,
      });
    }
    if (
      showHideEditProduct.visible &&
      showHideEditProduct.item.id === product.id
    ) {
      setShowHideEditProduct({
        item: product,
        edit: false,
        visible: true,
      });
    }
  }, [product]);

  return changeBreakpoints();
};

export default ItemProduct;
