// Importações Padrões
import firebase from 'firebase';

export interface IProduct {
  barCode: string;
  minPrice: number;
  maxPrice: number;
  productAmount: number;
  saleAvailable: number;
  productAmortization?: number;
  reservationAvailable: number;
  productReservation: number;
  category_id: string;
  branding_id: string;
  manufacturer_id: string;
  moreInformation: boolean;
  moreInformationList: IMoreInformation;
  unit: string;
  id: string;
  useMaxPrice: boolean;
  name: string;
  code3c_id: string;
  imageLight: string;
  imageHeavy: string;
  listEnabled?: Array<string>;
  listMeta?: { [string: string]: IListMeta };
  useProductBase: boolean;
}

export interface IListMeta {
  listPrice: number;
}

export interface IMoreInformation {
  provider_id: string;
  minAmount: number;
  costPrice: number;
}

export interface IEditProduct {
  item: IProduct;
  edit: boolean;
  visible: boolean;
}

export interface IDeleteProduct {
  id: string;
  name: string;
  visible: boolean;
}

export interface IEditAmount {
  item: IProduct;
  visible: boolean;
}

export interface IExportProducts {
  item: Array<IProduct> | [];
  visible: boolean;
}

export interface ILogAmountProduct {
  user_name: string;
  user_id: string;
  productAmount: number;
  saleAvailable: number;
  reservationAvailable: number;
  productReservation: number;
  oldProductAmount: number;
  oldSaleAvailable: number;
  oldReservationAvailable: number;
  oldProductReservation: number;
  created_at?: firebase.firestore.Timestamp;
  updated_at?: firebase.firestore.Timestamp;
}

export const initialProduct: IProduct = {
  barCode: '',
  minPrice: 0,
  maxPrice: 0,
  productAmount: 0,
  saleAvailable: 0,
  productAmortization: 0,
  productReservation: 0,
  reservationAvailable: 0,
  category_id: '',
  branding_id: '',
  manufacturer_id: '',
  moreInformation: false,
  moreInformationList: {
    provider_id: '',
    minAmount: 0,
    costPrice: 0,
  },

  unit: '',
  id: '',
  name: '',
  useMaxPrice: true,
  code3c_id: '',
  imageLight: '',
  imageHeavy: '',
  useProductBase: false,
};

export interface IData {
  id: string;
  name: string;
}

export interface IEditButton<Type> {
  item: IData & Type;
  visible: boolean;
}
