// Importações Padrões
import React, { useEffect } from 'react';

// React Prime
import { InputText } from 'primereact/inputtext';

// Types
import { IValidationError } from '../../typings/yup.types';

// Componentes
import { catchErrors, catchInvalid } from '../global/formErrors.component';

interface IInputFormProps {
  disabled: boolean;
  item: number;
  setItem: React.Dispatch<React.SetStateAction<number>>;
  formErr: Array<IValidationError>;
  fieldLabel: string;
  fieldCode: string;
  pleaceHolder: string;
}

const InputFormPercentageAlt = (props: IInputFormProps) => {
  const {
    disabled,
    item,
    setItem,
    formErr,
    fieldLabel,
    fieldCode,
    pleaceHolder,
  } = props;

  useEffect(() => {
    if (item > 100) {
      setItem(100);
    }
  }, [item, setItem]);

  return (
    <div className="p-field p-mx-auto width100">
      <label className="p-my-0 p-mt-4">{fieldLabel}</label>
      <span className="p-d-flex p-input-icon-right">
        {catchInvalid(formErr, fieldCode) && (
          <i className="pi pi-times input-invalid" />
        )}
        <InputText
          disabled={disabled}
          className={
            catchInvalid(formErr, fieldCode)
              ? 'settings-input-invalid'
              : 'settings-input'
          }
          type="number"
          step=".1"
          value={item}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setItem(parseFloat(event.target.value));
          }}
          placeholder={pleaceHolder}
        />
      </span>
      {catchErrors(formErr, fieldCode)}
    </div>
  );
};

export default InputFormPercentageAlt;
