// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Icones
import { FaPlus, FaSearch } from 'react-icons/fa';

// React Prime
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { confirmDialog } from 'primereact/confirmdialog';

// Contexto
import { Auth } from '../../services/auth.context';

// Componentes
import ItemFilter from '../defaultBox/itemFilter.component';

interface INavCategoryProps {
  pending: boolean;
  addFieldName: string;
  addButtonDisabled: boolean;
  actionAdd: boolean;
  setActionAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setInputSearch: React.Dispatch<React.SetStateAction<string>>;
  activeAdd: boolean;
  placeHolder: string;
}

const NavUser = (props: INavCategoryProps) => {
  const { windowWidth, system3cData } = useContext(Auth);

  const [searchVisible, setSearchVisible] = useState(false);
  const [search, setSearch] = useState('');

  const supLink = 'https://tawk.to/chat/5c04c5b340105007f37aaeee/1ctpap7pm';

  const {
    pending,
    addFieldName,
    addButtonDisabled,
    actionAdd,
    setActionAdd,
    setInputSearch,
    activeAdd,
    placeHolder,
  } = props;

  const MaxUsers = (url: string) => {
    confirmDialog({
      message: () => {
        return (
          <div>
            <p className="p-m-0 default-box-header-search-clear">
              Você chegou ao máximo.
              <br />O seu plano é de{' '}
              {system3cData?.systemBilling.systemAccounts} usuários.
            </p>
            <p className="p-m-0">Para adicionar novos vá ao suporte.</p>
          </div>
        );
      },
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'accept-button',
      rejectClassName: 'reject-button',
      rejectLabel: 'Voltar',
      accept: () => {
        window.open(url, '_blank');
      },
      acceptLabel: 'Ir',
    });
  };

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-d-grid">
          <div className="p-col-12 p-p-0">
            <Button
              className="p-jc-center width100 height2-5Rem button-confirmation"
              onClick={() => {
                if (!pending) {
                  if (addButtonDisabled) {
                    MaxUsers(supLink);
                  } else {
                    setActionAdd(!actionAdd);
                  }
                }
              }}
            >
              <FaPlus className="p-mx-2" />
              {addFieldName}
            </Button>
          </div>
          <div className="p-col-12 p-p-0 p-mt-2">
            <span className="p-input-icon-right width85">
              {search !== '' && (
                <i
                  aria-hidden="true"
                  className="p-d-md-inline-flex pi pi-times default-box-header-search-clear"
                  onClick={() => {
                    setSearch('');
                    setInputSearch('');
                  }}
                />
              )}
              <InputText
                onKeyPress={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    setInputSearch(search);
                  }
                }}
                className="width100 height2-5Rem input-text-muted"
                placeholder={placeHolder}
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(e.target.value);
                }}
              />
            </span>
            <Button
              className="p-jc-center p-p-0 width15 height2-5Rem button-primary"
              onClick={() => {
                setInputSearch(search);
              }}
            >
              <FaSearch className="fontSize1-5Rem" />
            </Button>
          </div>
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <>
          <div className="p-d-flex">
            <ItemFilter
              search={search}
              setSearch={setSearch}
              setInputSearch={setInputSearch}
              searchVisible={searchVisible}
              setSearchVisible={setSearchVisible}
              filterBy={[{ name: 'Tipo de Usuário', code: 'function' }]}
            />
            {activeAdd && (
              <div
                className="p-d-flex p-mb-2 cursor-pointer"
                aria-hidden="true"
                onClick={() => {
                  if (!pending) {
                    if (addButtonDisabled) {
                      MaxUsers(supLink);
                    } else {
                      setActionAdd(!actionAdd);
                    }
                  }
                }}
              >
                <Button
                  disabled={pending}
                  className="p-p-0 p-mr-2 users-add-button"
                >
                  <FaPlus className="p-mx-auto users-button-icon" />
                </Button>

                <span className="p-my-auto users-button-text">
                  {addFieldName}
                </span>
              </div>
            )}

            <div className="p-d-flex p-ml-auto">
              <div style={{ width: '20rem' }}>
                <span className="p-input-icon-right width90">
                  {search !== '' && (
                    <i
                      aria-hidden="true"
                      className="p-d-none p-d-md-inline-flex pi pi-times default-box-header-search-clear"
                      onClick={() => {
                        setSearch('');
                        setInputSearch('');
                      }}
                    />
                  )}
                  <InputText
                    placeholder="Buscar por Usuários..."
                    className="editList-input"
                    value={search}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearch(e.target.value);
                    }}
                  />
                </span>
                <Button
                  icon="pi pi-search"
                  className="editList-select-all width10"
                  onClick={() => setInputSearch(search)}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
    return <div />;
  }

  useEffect(() => {
    if (search === '') {
      setInputSearch('');
    }
  }, [search, setInputSearch]);

  return changeBreakpoints();
};

export default NavUser;
