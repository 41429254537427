// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';

// Icones
import { FaPlus } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';

// Contexto
import { Auth } from '../../services/auth.context';

// Hooks
import { useCitiesList } from '../../hooks/useCitiesList';
import { useCitiesIbgeList } from '../../hooks/useCitiesIbgeList';

// Types
import { IValidationError } from '../../typings/yup.types';
import { ICity } from '../../typings/cities.types';

// Controladores
import { addCity } from '../../controllers/city.controller';

// Componentes
import InputForm from '../input/inputForm.component';

// Funções
import { dataSchema } from '../../functions/category.function';
import { parseProvinceID } from '../../functions/province.function';

interface IAddCityProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  provinceId: string;
}

const AddCity = (props: IAddCityProps) => {
  const { code3cData } = useContext(Auth);
  const { visible, setVisible, provinceId } = props;

  const [searchIbge, setSearchIbge] = useState(false);

  const [citiesList] = useCitiesList(provinceId);
  const [citiesIbgeList, pending] = useCitiesIbgeList(
    parseProvinceID(provinceId),
  );

  const [name, setName] = useState<string>('');
  const [nameOption, setNameOption] = useState({
    name: '',
    code: '',
  });

  const [cityExits, setCityExits] = useState(false);

  const [pendingAction, setPendingAction] = useState<boolean>(false);

  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  const initialState = () => {
    setFormErr([]);
    setPendingAction(false);
    setName('');
    setNameOption({
      name: '',
      code: '',
    });
    setSearchIbge(false);
    setCityExits(false);
    setVisible(false);
  };

  async function handleAddCategory() {
    setPendingAction((state) => !state);

    const dataValidation: Partial<ICity> = {
      name,
      code3c_id: code3cData?.id,
      province_id: provinceId,
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await addCity(dataValidation);
        if (response) {
          setFormErr([]);
          setPendingAction((state) => !state);
          initialState();
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  useEffect(() => {
    setNameOption({
      name: '',
      code: '',
    });
    setName('');
  }, [searchIbge]);

  useEffect(() => {
    setCityExits(citiesList.filter((item) => item.name === name).length >= 1);
  }, [name, citiesList]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              <FaPlus className="p-mx-auto p-as-center modal-header-icon" />
            </div>
            <span className="p-my-auto users-button-text">Nova Cidade</span>
          </div>
        );
      }}
      visible={visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label="Salvar"
              disabled={pendingAction || cityExits}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() => handleAddCategory()}
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <>
        <label>Nome da Cidade</label>
        <Dropdown
          filter
          disabled={searchIbge && !pending}
          options={citiesIbgeList}
          value={nameOption}
          onChange={(e) => {
            setName(e.value.name);
            setNameOption(e.value);
          }}
          optionLabel="name"
          filterBy="name"
          placeholder="Selecione um Estado"
          className="p-p-0 p-my-2 settings-input settings-dropdown"
        />
        <Checkbox
          onChange={() => {
            setSearchIbge((state) => !state);
          }}
          checked={searchIbge}
          className={
            searchIbge ? 'settings-checkbox-selected' : 'settings-checkbox'
          }
        />
        <label className="p-ml-2">Não está na lista</label>
        <div className="p-my-2" />
        {searchIbge && (
          <InputForm
            pleaceHolder=""
            disabled={false}
            item={name}
            setItem={setName}
            formErr={formErr}
            fieldLabel="Nome da Cidade"
            fieldCode="name"
          />
        )}
        {cityExits && (
          <small className="delete-description">
            Essa cidade já está cadastrada
          </small>
        )}
      </>
    </Dialog>
  );
};

export default AddCity;
