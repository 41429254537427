import { Dropdown } from 'primereact/dropdown';

import './BasicDropDown.scss';

export interface Option {
  name: string;
  code: string;
}

export interface BasicDropDownProps {
  selectedOption: Option;
  options: Array<Option>;
  // eslint-disable-next-line
  action: (any: any) => void;
  placeHolder: string;
  className?: string;
}

export const BasicDropDown = (props: BasicDropDownProps) => {
  const { selectedOption, options, action, placeHolder, className } = props;
  return (
    <Dropdown
      value={selectedOption}
      options={options}
      onChange={(e) => {
        action(e.target.value);
      }}
      optionLabel="name"
      placeholder={placeHolder}
      className={`basic-drop-down ${className}`}
    />
  );
};
