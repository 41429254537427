// Importações Padrões
import firebase from 'firebase';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Types
import { IClient } from '../typings/client.types';

// Componentes
import {
  toasterError,
  toasterSuccess,
} from '../components/global/toaster.component';

// Funções
import { deleteImage } from '../functions/image.function';

const clientCollection = firebaseConfig.firestore().collection('All-client');

async function addClient(data: Partial<IClient>) {
  try {
    const response = await clientCollection.add({
      ...data,
      created_at: firebase.firestore.Timestamp.now(),
      updated_at: firebase.firestore.Timestamp.now(),
    });
    if (response.id) {
      toasterSuccess('CRIAR', `Cliente ${data.name} Criado`);
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a seu cliente!');
    return false;
  }
}

async function editClient(data: Partial<IClient>, id: string) {
  try {
    const dataDBExists = await clientCollection.doc(id).get();
    if (!dataDBExists) {
      return [];
    }
    await clientCollection.doc(id).update({
      ...data,
      updated_at: firebase.firestore.Timestamp.now(),
    });
    toasterSuccess('EDITAR', `Cliente ${data.name} Editado!`);
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a seu cliente!');
    return false;
  }
}
async function deleteClient(id: string, name: string) {
  try {
    const response = await clientCollection.doc(id).get();
    if (response.data()) {
      const item = response.data() as IClient;
      deleteImage(item.imageHeavy);
    }
    await clientCollection.doc(id).delete();
    toasterSuccess('APAGAR', `Cliente ${name} Apagado!`);
    return true;
  } catch (err) {
    console.log(err);
    toasterError('ERRO', 'Parece que tem algo errado com a seu cliente!');
    return false;
  }
}

async function searchClient(id: string) {
  try {
    const data = await clientCollection.doc(id).get();
    return <IClient>{ ...data.data(), id };
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Não foi possível ler os dados.');
    return null;
  }
}

export { clientCollection, addClient, editClient, searchClient, deleteClient };
