import React, { useContext, useState } from 'react';

import { FaPlus } from 'react-icons/fa';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

import { Auth } from '../../services/auth.context';

import { IList } from '../../typings/list.types';
import { IValidationError } from '../../typings/yup.types';

import { addList } from '../../controllers/list.controller';

import InputForm from '../input/inputForm.component';

import { dataSchema } from '../../functions/list.function';
import CitiesDropSelect from '../cities/citiesDropSelect.component';
import { ISystemFunction } from '../../typings/users.types';
import ListCitiesDropSelect from './listCitiesDropSelect.component';

interface IAddListProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddList = (props: IAddListProps) => {
  const { visible, setVisible } = props;

  const { code3cData } = useContext(Auth);

  const [name, setName] = useState<string>('');

  const [optionalActions, setOptionalActions] = useState<boolean>(false);
  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  const [province, setProvince] = useState<ISystemFunction>({
    name: '',
    code: '',
  });

  const [city, setCity] = useState<ISystemFunction>({
    name: '',
    code: '',
  });

  const initialState = () => {
    setName('');
    setOptionalActions(false);
    setPendingAction(false);
    setProvince({
      name: '',
      code: '',
    });
    setCity({
      name: '',
      code: '',
    });
    setVisible(false);
    setFormErr([]);
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
  };

  async function handleAddList() {
    setPendingAction((state) => !state);

    let dataValidation: Partial<IList>;

    if (optionalActions) {
      dataValidation = {
        name,
        code3c_id: code3cData?.id,
        province_id: province.code,
        city_id: city.code,
      };
    } else {
      dataValidation = {
        name,
        code3c_id: code3cData?.id,
      };
    }

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await addList(dataValidation);
        if (response) {
          setFormErr([]);
          setPendingAction((state) => !state);
          initialState();
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  function handleDisabled() {
    if (pendingAction) {
      return true;
    }
    if (optionalActions && city.code === '') {
      return true;
    }
    return false;
  }

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              <FaPlus className="p-mx-auto p-as-center modal-header-icon" />
            </div>
            <span className="p-my-auto users-button-text">Nova Lista</span>
          </div>
        );
      }}
      visible={visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label="Salvar"
              disabled={handleDisabled()}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() => handleAddList()}
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <InputForm
        pleaceHolder=""
        disabled={false}
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome da Lista"
        fieldCode="name"
      />
      <Checkbox
        checked={optionalActions}
        onChange={() => {
          setOptionalActions((state) => !state);
        }}
        className={`p-p-0 p-mx-auto p-as-center ${
          optionalActions ? 'settings-checkbox-selected' : 'settings-checkbox'
        }`}
      />
      <label className="p-ml-2">Inserir mais informações</label>
      {optionalActions && (
        <div>
          <div className="p-d-flex p-jc-center p-mt-2">
            <CitiesDropSelect
              province={province}
              setProvince={setProvince}
              onPage={false}
              disabled={false}
            />
          </div>
          <div className="p-d-flex p-jc-center p-mt-2">
            <ListCitiesDropSelect
              city={city}
              setCity={setCity}
              UF={province.code}
              disabled={false}
            />
          </div>
        </div>
      )}
      <div className="p-d-flex p-mt-3">
        <i className="p-mr-2 pi pi-exclamation-circle input-warn p-as-center" />
        <small className=" input-warn">
          A lista será cadastrada sem produtos ou serviços.
        </small>
      </div>
    </Dialog>
  );
};

export default AddList;
