import { useContext } from 'react';
import { maskBr } from 'js-brasil';
import { Avatar } from 'primereact/avatar';

import { Auth } from '../../../services/auth.context';
import { ITypeOfCode3c } from '../../../typings/code3c.types';
import { formatCurrency } from '../../../functions/list.function';
import { generatorAllImageURL } from '../../../functions/image.function';
import { SalesTargetProvider } from '../contexts/SalesTargetContextt';

import './SalesTargetHeader.scss';
import { PendingSalesTarget } from './PendingSalesTarget';

export const SalesTargetHeader = () => {
  const { code3cData } = useContext(Auth);
  const { pendingInfo, goal, realized } = useContext(SalesTargetProvider);

  const parseSocialID = (socialNumber: string) => {
    if (socialNumber.length > 11) {
      return maskBr.cnpj(socialNumber);
    }
    return maskBr.cpf(socialNumber);
  };

  const parseSubordination = (subordinationType: ITypeOfCode3c | undefined) => {
    if (subordinationType && subordinationType === 'subsidiary') {
      return 'Filial';
    }
    return 'Matriz';
  };

  const toggleSalesTargetHeader = () => {
    if (pendingInfo) {
      return (
        <div>
          <PendingSalesTarget />
        </div>
      );
    } else {
      return (
        <div className="sales-target-header">
          {code3cData!.imageLight ? (
            <img
              alt="bussines logo"
              className="header-img"
              src={generatorAllImageURL(code3cData!.imageLight || '')}
            />
          ) : (
            <Avatar
              label={code3cData && code3cData.name[0]}
              className="header-img"
            />
          )}
          <div className="business-info">
            <p>
              <b>{code3cData?.name}</b>
            </p>
            <p>{`${parseSocialID(code3cData!.socialID)} - ${parseSubordination(
              code3cData?.typeOf,
            )}`}</p>
          </div>
          <div className="sales-info">
            <div className="box alpha-text">
              <h3>Meta em Vigor:</h3>
              <h1>{formatCurrency('USD', goal || 0)}</h1>
            </div>
            <div className="box">
              <h3>Meta Alcançada:</h3>
              <h1>{formatCurrency('USD', realized || 0)}</h1>
            </div>
          </div>
        </div>
      );
    }
  };

  return toggleSalesTargetHeader();
};
