import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { useCallback, useContext, useEffect, useState } from 'react';
import { maskBr } from 'js-brasil';
import { initialProduct, IProduct } from '../../typings/product.types';
import { toasterWarn } from '../global/toaster.component';
import { editProductNoRes } from '../../controllers/product.controller';
import { IListProduct } from '../../typings/list.types';
import { emptyValidationError } from '../../typings/yup.types';
import InputFormCurrency from '../input/inputFormCurrency.component';
import ItemCol from '../defaultBox/itemCol.component';
import { Auth } from '../../services/auth.context';

interface IProductListProps {
  listId: string;
  product: IProduct;
  productList: Array<IListProduct>;
  addChangerList: (data: IListProduct) => void;
  remChangerList: (data: Partial<IListProduct>) => void;
}

const ProductList = (props: IProductListProps) => {
  const {
    listId,
    product,
    productList,
    addChangerList,
    remChangerList,
  } = props;
  const { windowWidth } = useContext(Auth);

  const [productItem, setProductItem] = useState<IProduct>(initialProduct);
  const [listPrice, setListPrice] = useState<number>(
    product.listMeta && product.listMeta[listId]
      ? product.listMeta[listId].listPrice
      : product.minPrice,
  );
  const [productActive, setProductActive] = useState(false);

  function productListCreate(productCreate: IProduct) {
    if (productCreate.listEnabled && productCreate.listMeta) {
      const onList = productCreate.listEnabled.filter(
        (list) => list === listId,
      );
      const [listToDB] = productList.filter(
        (item) => item.product_id === productCreate.id,
      );

      if (onList.length === 1) {
        setProductActive(true);
        setListPrice(
          listToDB
            ? listToDB.listPrice
            : productCreate.listMeta[listId].listPrice,
        );
        addChangerList({
          product_id: product.id,
          productReference: product,
          listPrice: productCreate.listMeta[listId].listPrice,
          changer: false,
        });
      }
    }
    setProductItem(productCreate);
  }

  async function handleAddProductList() {
    let response;
    if (product.listEnabled && product.listMeta) {
      response = await editProductNoRes(
        {
          listEnabled: [...product.listEnabled, listId],
          listMeta: { ...product.listMeta, [listId]: { listPrice } },
        },
        product.id,
      );
    } else {
      response = await editProductNoRes(
        {
          listEnabled: [listId],
          listMeta: { [listId]: { listPrice } },
        },
        product.id,
      );
    }
    if (response) {
      setProductActive(true);
    }
  }

  async function handleRemProductList() {
    let response;
    if (product.listEnabled && product.listMeta) {
      delete product.listMeta[listId];
      response = await editProductNoRes(
        {
          listEnabled: product.listEnabled.filter(
            (listDelete) => listDelete !== listId,
          ),
          listMeta: product.listMeta,
        },
        product.id,
      );
    }
    if (response) {
      setListPrice(product.minPrice);
      setProductActive(false);
      remChangerList({
        product_id: product.id,
      });
    }
  }

  const inputPriceStatus = useCallback(() => {
    if (
      (listPrice > productItem.maxPrice && productItem.useMaxPrice) ||
      listPrice < productItem.minPrice
    ) {
      return (
        <>
          <i
            className="pi pi-exclamation-circle text-danger question-1"
            data-pr-tooltip="O preço do produto não condiz com os parâmetros de
            preço mínimo e máximo, e não será salvo."
          />
          <Tooltip
            target=".question-1"
            mouseTrack
            mouseTrackLeft={10}
            className="p-text-justify"
          />
        </>
      );
    }
    if (product.listMeta && product.listMeta[listId]) {
      if (product.listMeta[listId].listPrice !== listPrice) {
        return (
          <>
            <i
              question-1aria-hidden="true"
              className="pi pi-pencil question-2"
              data-pr-tooltip="O preço do produto foi alterado,
              por favor clique em Salvar, para aplicar a alteração."
            />
            <Tooltip
              target=".question-2"
              mouseTrack
              mouseTrackLeft={10}
              className="p-text-justify"
            />
          </>
        );
      }
      if (product.listMeta[listId].listPrice === listPrice) {
        return (
          <>
            <i
              question-1aria-hidden="true"
              className="pi pi-save question-3"
              data-pr-tooltip="O preço do produto está salvo na lista."
            />
            <Tooltip
              target=".question-3"
              mouseTrack
              mouseTrackLeft={10}
              className="p-text-justify"
            />
          </>
        );
      }
    }
    return <></>;
    // eslint-disable-next-line
  }, [listPrice, product, productItem, listId]);

  useEffect(() => {
    if (product.listEnabled && product.listMeta) {
      const onList = product.listEnabled.filter((list) => list === listId);
      if (onList.length === 1) {
        const hasCond =
          (listPrice > productItem.maxPrice && !productItem.useMaxPrice) ||
          listPrice < productItem.minPrice;
        if (!hasCond) {
          addChangerList({
            product_id: product.id,
            productReference: product,
            listPrice,
            changer: true,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [listPrice]);

  useEffect(() => {
    productListCreate(product);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="p-grid p-mx-0 p-my-1 p-p-2 p-justify-center editListItems-product-item"
      style={{ width: '100%' }}
    >
      <div className="p-col-1 p-my-0 p-as-center p-d-flex">
        <Checkbox
          checked={productActive}
          onChange={() => {
            if (
              (listPrice > productItem.maxPrice && productItem.useMaxPrice) ||
              listPrice < productItem.minPrice
            ) {
              toasterWarn('PREÇO', 'O preço está fora do escopo.');
            } else if (!productActive) {
              handleAddProductList();
            } else {
              handleRemProductList();
            }
          }}
          className={`p-mx-auto ${
            productActive ? 'settings-checkbox-selected' : 'settings-checkbox'
          }`}
        />
      </div>
      <ItemCol colGrid={3} field="Nome" content={product.name} />
      <ItemCol colGrid={3} field="EAN" content={product.barCode} />
      <div
        className={`p-col-3 p-p-0 p-my-3 item-box-col p-text-center p-as-center ${
          windowWidth && windowWidth < 900 && 'p-grid'
        }`}
      >
        <p
          className={`${
            windowWidth && windowWidth > 900
              ? 'p-text-center p-col-12 p-m-0'
              : 'p-ml-1 p-col-9'
          } p-d-inline p-my-0 p-p-0 p-mr-0 p-mx-auto`}
        >
          <span className="p-float-label p-input-icon-right">
            <InputFormCurrency
              disabled={false}
              item={listPrice}
              setItem={setListPrice}
              formErr={[emptyValidationError]}
              fieldLabel="Referência de Preço"
              fieldCode="minPrice"
              floatLabel
              iconStatus={inputPriceStatus}
            />
          </span>
        </p>
      </div>
      <div
        className={`p-col-2 p-p-0 p-my-2 item-box-col p-text-center p-as-center ${
          windowWidth && windowWidth < 900 && 'p-grid'
        }`}
      >
        <p
          className={`${
            windowWidth && windowWidth > 900
              ? 'p-text-center p-col-12 p-m-0'
              : 'p-ml-1 p-col-9'
          } p-d-inline p-my-0 p-p-0 p-mr-0`}
        >
          {productItem.useMaxPrice ? (
            <small className="p-p-0 p-my-0 p-as-center p-mx-auto">
              {`${maskBr.currency(
                productItem.minPrice / 100,
              )}~${maskBr.currency(productItem.maxPrice / 100)}`}
            </small>
          ) : (
            <small className="p-p-0 p-my-0 p-as-center">
              {`> ${maskBr.currency(productItem.minPrice / 100)}
              `}
            </small>
          )}
        </p>
      </div>
    </div>
  );
};

export default ProductList;
