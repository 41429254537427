import { Skeleton } from 'primereact/skeleton';
import React from 'react';

import './Loading.scss';

export const Loading = () => {
  return (
    <React.Fragment>
      <div className="p-grid p-mb-3">
        <div className="p-col-12 p-md-6 p-lg-3">
          <Skeleton height="6rem" />
        </div>
        <div className="p-col-12 p-md-6 p-lg-3">
          <Skeleton height="6rem" />
        </div>
        <div className="p-col-12 p-md-6 p-lg-3">
          <Skeleton height="6rem" />
        </div>
        <div className="p-col-12 p-md-6 p-lg-3">
          <Skeleton height="6rem" />
        </div>
      </div>
      <div className="p-grid p-mb-3">
        <div className="p-col-12 p-md-12 p-lg-7">
          <Skeleton height="25rem" />
        </div>
        <div className="p-col-12 p-md-12 p-lg-5">
          <Skeleton height="25rem" />
        </div>
      </div>
      <div className="p-grid p-mb-3">
        <div className="p-col-12 p-md-6 p-lg-6">
          <Skeleton height="25rem" />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <Skeleton height="25rem" />
        </div>
      </div>
      <div className="p-grid p-mb-3">
        <div className="p-col-12 p-md-12 p-lg-12">
          <Skeleton height="25rem" />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Loading;
