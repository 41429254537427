// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Icones
import { FaSearch } from 'react-icons/fa';
import { Button } from 'primereact/button';

// React Prime
import { InputText } from 'primereact/inputtext';

// Contexto
import { Auth } from '../../services/auth.context';

interface INavCategoryProps {
  setInputSearch: React.Dispatch<React.SetStateAction<string>>;
  pleaceHolder: string;
}

const NavProvince = (props: INavCategoryProps) => {
  const { windowWidth } = useContext(Auth);

  const [search, setSearch] = useState('');

  const { setInputSearch, pleaceHolder } = props;

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-d-grid">
          <div className="p-col-12 p-p-0 p-mt-2">
            <span className="p-input-icon-right width85">
              {search !== '' && (
                <i
                  aria-hidden="true"
                  className="p-d-md-inline-flex pi pi-times default-box-header-search-clear"
                  onClick={() => {
                    setSearch('');
                    setInputSearch('');
                  }}
                />
              )}
              <InputText
                onKeyPress={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    setInputSearch(search);
                  }
                }}
                className="width100 height2-5Rem input-text-muted"
                placeholder={pleaceHolder}
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(e.target.value);
                }}
              />
            </span>
            <Button
              className="p-jc-center p-p-0 width15 height2-5Rem button-primary"
              onClick={() => {
                setInputSearch(search);
              }}
            >
              <FaSearch className="fontSize1-5Rem" />
            </Button>
          </div>
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div className="p-d-flex">
          <div className="p-d-flex p-ml-auto">
            <div style={{ width: '20rem' }}>
              <span className="p-input-icon-right width90">
                {search !== '' && (
                  <i
                    aria-hidden="true"
                    className="p-d-none p-d-md-inline-flex pi pi-times default-box-header-search-clear"
                    onClick={() => {
                      setSearch('');
                      setInputSearch('');
                    }}
                  />
                )}
                <InputText
                  placeholder="Buscar por Estados..."
                  className="editList-input"
                  value={search}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearch(e.target.value);
                  }}
                />
              </span>
              <Button
                icon="pi pi-search"
                className="editList-select-all width10"
                onClick={() => setInputSearch(search)}
              />
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }

  useEffect(() => {
    if (search === '') {
      setInputSearch('');
    }
  }, [search, setInputSearch]);

  return changeBreakpoints();
};

export default NavProvince;
