import { InputText } from 'primereact/inputtext';

import './TextInput.scss';

export interface TextInputProps {
  label: string;
  value: string;
  className?: string;
}
export const NCTextInput = (props: TextInputProps) => {
  const { label, value, className } = props;
  return (
    <div className="text-input">
      <label>{label}</label>
      <InputText value={value} disabled className={`disabled ${className}`} />
    </div>
  );
};
