// Importações Padrões
import React, { useCallback, useContext, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

// React Prime
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

// Types
import ISignInData from '../typings/login.types';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Componentes
import ConfirmNewTab from '../components/global/confirmNewTab.component';
import { toasterError } from '../components/global/toaster.component';

// Contexto
import { Auth } from '../services/auth.context';

// Imagens
import systemLogo from '../assets/images/system-logo.svg';

// Estilos
import '../styles/global/login.scss';

const Login: React.FC = () => {
  const { currentUser } = useContext(Auth);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [peekPass, setPeePass] = useState<boolean>(true);

  const [pendingAction, setPendingAction] = useState<boolean>(false);

  const politiciesLink =
    'https://site-institucional.web.app/politicasdeprivacidade/politicas.html';

  const history = useHistory();

  const handleLogin = useCallback(
    async (data: ISignInData) => {
      setPendingAction(true);
      try {
        await firebaseConfig
          .auth()
          .signInWithEmailAndPassword(data.email, data.password);
        if (currentUser) {
          setPendingAction(true);
          history.push('/');
          return;
        }
        return;
      } catch (error) {
        toasterError('Login Inválido', 'Existem erros nas suas credênciais.');
        setPendingAction(false);
        console.error(error);
      }
    },
    [history, currentUser],
  );

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div className="p-grid p-p-0 p-m-0 p-col login-display">
      <div className="p-col p-m-0 p-d-none p-d-md-inline-flex login-background" />
      <div className="p-col p-m-0 p-as-center box">
        <div className="box">
          <img
            className="p-d-flex p-mx-auto"
            src={systemLogo}
            alt="system-logo"
            style={{ width: '12rem' }}
          />
          <div className="p-d-flex">
            <span className="p-input-icon-left p-mx-auto">
              <i className="pi pi-envelope" />
              <InputText
                className="p-d-flex p-my-2 login-input-text"
                placeholder="Email"
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value);
                }}
              />
            </span>
          </div>
          <div className="p-d-flex p-inputwrapper">
            <span className="p-input-icon-left p-input-icon-right p-mx-auto">
              <i className="pi pi-lock" />
              <InputText
                type={peekPass ? 'password' : 'text'}
                className="p-d-flex p-my-2 login-input-text"
                placeholder="Senha"
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value);
                }}
              />
              {peekPass ? (
                <i
                  aria-hidden="true"
                  className="pi pi-eye link"
                  onMouseDown={(e) => {
                    e.buttons === 1 && setPeePass(!peekPass);
                  }}
                />
              ) : (
                <i
                  aria-hidden="true"
                  className="pi pi-eye-slash link"
                  onMouseDown={(e) => {
                    e.buttons === 1 && setPeePass(!peekPass);
                  }}
                />
              )}
            </span>
          </div>

          <Button
            className="p-d-flex p-mx-auto p-mt-3 p-px-6 p-py-2 login-button"
            label="Entrar"
            disabled={pendingAction}
            icon={pendingAction ? 'pi pi-spin pi-spinner' : 'pi pi-sign-in'}
            iconPos="right"
            onClick={() => {
              handleLogin({ email, password });
            }}
          />
          {/* <p className="p-text-center p-mb-0">
            <small className="link">Esqueci minha Senha</small>
          </p> */}
          <p className="p-text-center p-my-0 ">
            <small
              className="link"
              aria-hidden="true"
              onPointerDown={(e) => {
                e.buttons === 1 && ConfirmNewTab(politiciesLink);
              }}
            >
              Termos e Politicas de Uso
            </small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
