// Importações Padrões
import React, { useContext } from 'react';
import firebase from 'firebase';
import { maskBr } from 'js-brasil';

// Icones
import { BiCheckbox } from 'react-icons/bi';
import { FaChevronRight, FaTrashAlt, FaChevronDown } from 'react-icons/fa';

// React Prime
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

// Componentes
import ItemImage from '../../defaultBox/itemImage.component';
import ItemColOrder from '../../defaultBox/itemColOrder.component';
import { toasterError } from '../../global/toaster.component';

// Types
import { IOrder, IServiceOrder } from '../../../typings/order.types';

// Contexto
import { Auth } from '../../../services/auth.context';
import { FiHelpCircle } from 'react-icons/fi';
import { Tooltip } from 'primereact/tooltip';

interface IFormOrderInfoProps {
  service: IServiceOrder;
  visible: IOrder;
  processing: string;
  pending: boolean;
  open: { open: boolean; index: number };
  setOpen: React.Dispatch<
    React.SetStateAction<{ open: boolean; index: number }>
  >;
  handleCheckBoxService(
    index: number,
    toDo: string | firebase.firestore.Timestamp,
    done: string | firebase.firestore.Timestamp,
    doing: string | firebase.firestore.Timestamp,
  ): void;
  handleCheckBoxServiceResponsive: (
    index: number,
    toDo: string | firebase.firestore.Timestamp,
    done: string | firebase.firestore.Timestamp,
    doing: string | firebase.firestore.Timestamp,
  ) => void;
}

const Product = (props: IFormOrderInfoProps) => {
  const {
    service,
    visible,
    processing,
    pending,
    open,
    setOpen,
    handleCheckBoxService,
    handleCheckBoxServiceResponsive,
  } = props;

  const { windowWidth } = useContext(Auth);

  const sumProduct = visible.productOrder.filter(
    (product) => product.service_index === service.index,
  ).length;

  const defaultImage =
    'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Tag_font_awesome.svg/512px-Tag_font_awesome.svg.png';

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-mt-1 p-mx-auto p-p-3 p-grid item-box-service width100">
          <p className="p-col-9 width100 p-d-inline p-my-0 p-p-0 p-mr-0">
            #{service.index}
          </p>
          <div className="p-px-1 p-as-center p-mx-auto">
            <ItemImage
              colGrid={12}
              content={service.imageHeavy}
              imageDefault={defaultImage}
            />
          </div>
          <ItemColOrder colGrid={2} content={service.service_name} />
          <ItemColOrder
            colGrid={2}
            content={maskBr.currency(service.price / 100)}
          />

          <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center">
            <div className="p-d-inline p-col p-jc-center">
              {processing !== 'selected' ? (
                <Button
                  className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive p-d-flex"
                  label="SEPARAR"
                  onClick={() =>
                    toasterError(
                      'ERRO',
                      'Só é modificar esse serviço na fase de Processamento!',
                    )
                  }
                />
              ) : (
                <>
                  {visible.status.cancelled !== 'Pendente' ? (
                    <Button
                      className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive p-d-flex"
                      label="SEPARAR"
                      onClick={() =>
                        toasterError(
                          'ERRO',
                          'Não é possível modificar esse serviço pois esse pedido foi cancelado!',
                        )
                      }
                    />
                  ) : (
                    <>
                      {pending ? (
                        <ProgressSpinner
                          className="p-as-center p-d-flex p-jc-center"
                          style={{ width: '35px', height: '35px' }}
                          strokeWidth="8"
                          fill="#EEEEEE"
                          animationDuration=".5s"
                        />
                      ) : (
                        <>
                          {handleCheckBoxServiceResponsive(
                            service.index,
                            service.status.toDo,
                            service.status.done,
                            service.status.doing,
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="p-col-1 p-p-0 item-box-col p-text-center p-as-center p-my-3">
            <Tooltip target=".custom-target-icon" />

            <i
              className="custom-target-icon p-d-flex p-jc-center p-as-center"
              data-pr-tooltip={service.note}
              data-pr-position="top"
            >
              {service.note !== '' ? (
                <FiHelpCircle className="order-envelop-icon-responsive" />
              ) : (
                <FiHelpCircle className="order-envelop-icon-disabled-responsive" />
              )}
            </i>
          </div>
          {sumProduct === 0 ? (
            <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center p-d-flex p-jc-center">
              <FaChevronRight className="order-service-chevron-disabled" />
            </div>
          ) : (
            <>
              {open.open && service.index === open.index ? (
                <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center p-d-flex p-jc-center">
                  <FaChevronDown
                    className="order-service-chevron"
                    onClick={() =>
                      setOpen({ open: false, index: service.index })
                    }
                  />
                </div>
              ) : (
                <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center p-d-flex p-jc-center">
                  <FaChevronRight
                    className="order-service-chevron"
                    onClick={() =>
                      setOpen({ open: true, index: service.index })
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div className="p-mt-1 p-mx-auto p-p-0 p-grid item-box-service width100">
          <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center">
            <div className="p-d-inline p-col p-jc-center">
              <FaTrashAlt
                className={
                  !service.disabled
                    ? 'order-trash-icon'
                    : 'order-trash-icon-disabled'
                }
                onClick={() =>
                  toasterError(
                    'ERRO',
                    'Ainda não é possível remover produtos, essa função esta em desenvolvimento!',
                  )
                }
              />
            </div>
          </div>
          {visible.status.cancelled !== 'Pendente' ? (
            <div className="p-col-2 p-px-0 p-as-center p-mx-auto order-product-img-disabled">
              <ItemImage
                colGrid={12}
                content={service.imageHeavy}
                imageDefault={defaultImage}
              />
            </div>
          ) : (
            <div className="p-col-2 p-px-0 p-as-center p-mx-auto">
              <ItemImage
                colGrid={12}
                content={service.imageHeavy}
                imageDefault={defaultImage}
              />
            </div>
          )}
          <ItemColOrder colGrid={2} content={service.service_name} />
          <ItemColOrder
            colGrid={2}
            content={maskBr.currency(service.price / 100)}
          />

          <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center">
            <div className="p-d-inline p-col p-jc-center">
              {processing !== 'selected' ? (
                <BiCheckbox
                  className="order-checkbox"
                  onClick={() =>
                    toasterError(
                      'ERRO',
                      'Só é modificar esse serviço na fase de Processamento!',
                    )
                  }
                />
              ) : (
                <>
                  {visible.status.cancelled !== 'Pendente' ? (
                    <BiCheckbox
                      className="order-checkbox"
                      onClick={() =>
                        toasterError(
                          'ERRO',
                          'Não é possível modificar esse serviço pois esse pedido foi cancelado!',
                        )
                      }
                    />
                  ) : (
                    <>
                      {pending ? (
                        <ProgressSpinner
                          className="p-as-center"
                          style={{ width: '35px', height: '35px' }}
                          strokeWidth="8"
                          fill="#EEEEEE"
                          animationDuration=".5s"
                        />
                      ) : (
                        <>
                          {handleCheckBoxService(
                            service.index,
                            service.status.toDo,
                            service.status.done,
                            service.status.doing,
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="p-col-1 p-p-0 item-box-col p-text-center p-as-center p-my-3">
            <Tooltip target=".custom-target-icon" />

            <i
              className="custom-target-icon p-d-flex p-jc-center p-as-center"
              data-pr-tooltip={service.note}
              data-pr-position="top"
            >
              {service.note !== '' ? (
                <FiHelpCircle className="order-envelop-icon-responsive" />
              ) : (
                <FiHelpCircle className="order-envelop-icon-disabled-responsive" />
              )}
            </i>
          </div>
          {sumProduct === 0 ? (
            <div className="p-col-1 p-p-0 item-box-col p-text-center p-as-center">
              <FaChevronRight className="order-service-chevron-disabled" />
            </div>
          ) : (
            <>
              {open.open && service.index === open.index ? (
                <div className="p-col-1 p-p-0 item-box-col p-text-center p-as-center">
                  <FaChevronDown
                    className="order-service-chevron"
                    onClick={() =>
                      setOpen({ open: false, index: service.index })
                    }
                  />
                </div>
              ) : (
                <div className="p-col-1 p-p-0 item-box-col p-text-center p-as-center">
                  <FaChevronRight
                    className="order-service-chevron"
                    onClick={() =>
                      setOpen({ open: true, index: service.index })
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      );
    }
    return <div />;
  }

  return changeBreakpoints();
};

export default Product;
