import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { FaEye } from 'react-icons/fa';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {
  IEditList,
  initialList,
  IListProduct,
  IListService,
} from '../../typings/list.types';
import { IValidationError } from '../../typings/yup.types';
import InputForm from '../input/inputForm.component';
import CitiesDropSelect from '../cities/citiesDropSelect.component';
import ListCitiesDropSelect from './listCitiesDropSelect.component';
import { IInput, initialInputOptions } from '../../typings/input.types';
import { useProvincesList } from '../../hooks/useProvincesList';
import { useCitiesList } from '../../hooks/useCitiesList';
import { useProductList } from '../../hooks/useProductsList';
import '../../styles/global/list.scss';
import { useServicesList } from '../../hooks/useServicesList';
import ProductList from './productList.component';
import EditListFilter from './editListFilter.component';
import { editProductNoRes } from '../../controllers/product.controller';
import ServiceList from './serviceList.component';
import { editServiceNoRes } from '../../controllers/service.controller';
import { IProduct } from '../../typings/product.types';
import { IService } from '../../typings/service.types';

interface IEditListProps {
  visible: IEditList;
  setVisible: React.Dispatch<React.SetStateAction<IEditList>>;
}

const EditListItens = (props: IEditListProps) => {
  const { visible, setVisible } = props;

  const [name, setName] = useState<string>('');
  const [province, setProvince] = useState<IInput>(initialInputOptions);
  const [city, setCity] = useState<IInput>(initialInputOptions);

  const [optionalActions, setOptionalActions] = useState<boolean>(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);
  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [productList, setProductList] = useState<Array<IListProduct>>([]);
  const [serviceList, setServiceList] = useState<Array<IListService>>([]);

  const [toggleList, setToggleList] = useState(false);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [search, setSearch] = useState('');
  const [inputSearch, setInputSearch] = useState('');

  const [onListFilter, setOnListFilter] = useState(false);
  const [onListType, setOnListType] = useState('all');

  const [basePriceFilter, setBasePriceFilter] = useState(false);
  const [baseMinPrice, setBaseMinPrice] = useState(0);
  const [baseMaxPrice, setBaseMaxPrice] = useState(0);

  const [referencePriceFilter, setReferencePriceFilter] = useState(false);
  const [referenceMinPrice, setReferenceMinPrice] = useState(0);
  const [referenceMaxPrice, setReferenceMaxPrice] = useState(0);

  const [productsList] = useProductList();
  const [servicesList] = useServicesList();
  const [provincesList] = useProvincesList();
  const [citiesList] = useCitiesList(province.code);

  const filterCount = useCallback(() => {
    if (onListFilter && basePriceFilter && referencePriceFilter) {
      return 3;
    }
    if (
      (onListFilter && basePriceFilter) ||
      (onListFilter && referencePriceFilter) ||
      (basePriceFilter && referencePriceFilter)
    ) {
      return 2;
    }
    if (onListFilter || basePriceFilter || referencePriceFilter) {
      return 1;
    }
    return 0;
  }, [onListFilter, basePriceFilter, referencePriceFilter]);

  function filterOnList(itemList: IProduct | IService) {
    if (onListFilter) {
      switch (true) {
        case onListType === 'inList': {
          if (
            itemList.listEnabled?.find((listID) => listID === visible.item.id)
          ) {
            return true;
          }
          break;
        }
        default: {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  function filterBasePrice(itemList: IProduct | IService) {
    if (basePriceFilter) {
      if (baseMinPrice > 0 && baseMaxPrice > 0) {
        if (itemList.useMaxPrice) {
          if (
            itemList.minPrice >= baseMinPrice &&
            itemList.maxPrice <= baseMaxPrice
          ) {
            return true;
          }
          return false;
        }
        if (
          itemList.minPrice >= baseMinPrice &&
          itemList.minPrice <= baseMaxPrice
        ) {
          return true;
        }
        return false;
      }
      if (baseMinPrice > 0) {
        if (itemList.minPrice >= baseMinPrice) {
          return true;
        }
        return false;
      }
      if (baseMaxPrice > 0) {
        if (itemList.useMaxPrice) {
          if (itemList.maxPrice <= baseMaxPrice) {
            return true;
          }
          return false;
        }
        if (itemList.minPrice <= baseMaxPrice) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  }

  function filterReferencePrice(itemList: IProduct | IService) {
    const hasReferencePrice =
      itemList.listMeta && itemList.listMeta[visible.item.id]
        ? itemList.listMeta[visible.item.id].listPrice
        : itemList.minPrice;
    if (referencePriceFilter) {
      if (referenceMinPrice > 0 && referenceMaxPrice > 0) {
        if (
          referenceMinPrice <= hasReferencePrice &&
          hasReferencePrice <= referenceMaxPrice
        ) {
          return true;
        }
        return false;
      }
      if (referenceMinPrice > 0) {
        if (referenceMinPrice <= hasReferencePrice) {
          return true;
        }
        return false;
      }
      if (referenceMaxPrice > 0) {
        if (hasReferencePrice <= referenceMaxPrice) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  }

  function filterApply(itemList: IProduct | IService) {
    if (filterCount() > 0) {
      if (filterCount() === 1) {
        if (filterOnList(itemList)) {
          return true;
        }
        if (filterBasePrice(itemList)) {
          return true;
        }
        if (filterReferencePrice(itemList)) {
          return true;
        }
        return false;
      }
      if (filterCount() === 2) {
        if (filterOnList(itemList) && filterBasePrice(itemList)) {
          return true;
        }
        if (filterReferencePrice(itemList) && filterBasePrice(itemList)) {
          return true;
        }
        if (filterReferencePrice(itemList) && filterOnList(itemList)) {
          return true;
        }
        return false;
      }
      if (filterCount() === 3) {
        if (
          filterOnList(itemList) &&
          filterReferencePrice(itemList) &&
          filterBasePrice(itemList)
        ) {
          return true;
        }
        return false;
      }
    }
    return true;
  }

  const editListItemsCreate = useCallback(
    (visibles: IEditList) => {
      setName(visibles.item.name);
      const hasActionOptions =
        visibles.item.province_id &&
        visibles.item.city_id &&
        visibles.item.province_id !== '' &&
        visibles.item.city_id !== '';
      if (hasActionOptions) {
        setOptionalActions(true);
        const [selectedProvince] = provincesList.filter(
          (provinces) => provinces.id === visibles.item.province_id,
        );
        const [selectedCity] = citiesList.filter(
          (cities) => cities.id === visibles.item.city_id,
        );
        setProvince({
          name: selectedProvince.name,
          code: selectedProvince.id,
        });
        selectedCity &&
          setCity({
            name: selectedCity.name,
            code: selectedCity.id,
          });
      }
    },
    [provincesList, citiesList],
  );

  function editListItemsDestroy() {
    const emptyString = '';
    setName(emptyString);
    setProductList([]);
    setServiceList([]);
    setProvince(initialInputOptions);
    setCity(initialInputOptions);
    setFormErr([]);
    setOptionalActions(false);
    setPendingAction(false);
    setToggleList(false);
    setDisplayFilter(false);
    setSearch('');
    setInputSearch('');
    setOnListFilter(false);
    setOnListType('all');
    setBasePriceFilter(false);
    setBaseMinPrice(0);
    setBaseMaxPrice(0);
    setReferencePriceFilter(false);
    setReferenceMinPrice(0);
    setReferenceMaxPrice(0);
    setOptionalActions(false);
    setVisible({ visible: false, item: initialList, edit: false });
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
  }

  function addChangerList(data: IListProduct) {
    if (productList.find((item) => item.product_id === data.product_id)) {
      setProductList(
        productList.map((productOnList) => {
          if (productOnList.product_id === data.product_id) {
            return {
              ...productOnList,
              listPrice: data.listPrice,
              productReference: data.productReference,
              changer: true,
            };
          }
          return productOnList;
        }),
      );
    } else {
      productList.push(data);
    }
  }

  function remChangerList(data: Partial<IListProduct>) {
    setProductList(
      productList.filter((item) => item.product_id !== data?.product_id),
    );
  }

  function addChangerServiceList(data: IListService) {
    if (serviceList.find((item) => item.service_id === data.service_id)) {
      setServiceList(
        serviceList.map((serviceOnList) => {
          if (serviceOnList.service_id === data.service_id) {
            return {
              ...serviceOnList,
              listPrice: data.listPrice,
              serviceReference: data.serviceReference,
              changer: true,
            };
          }
          return serviceOnList;
        }),
      );
    } else {
      serviceList.push(data);
    }
  }

  function remChangerServiceList(data: Partial<IListService>) {
    setServiceList(
      serviceList.filter((item) => item.service_id !== data.service_id),
    );
  }

  async function handleSaveList() {
    const newProductList = productList.filter((item) => item.changer);
    const newServiceList = serviceList.filter((item) => item.changer);
    newProductList.map(async (doc) => {
      if (doc.productReference && doc.changer && !Number.isNaN(doc.listPrice)) {
        await editProductNoRes(
          {
            listMeta: {
              ...doc.productReference.listMeta,
              [visible.item.id]: { listPrice: doc.listPrice },
            },
          },
          doc.product_id,
        );
      }
    });
    newServiceList.map(async (doc) => {
      if (doc.serviceReference && doc.changer && !Number.isNaN(doc.listPrice)) {
        await editServiceNoRes(
          {
            listMeta: {
              ...doc.serviceReference.listMeta,
              [visible.item.id]: { listPrice: doc.listPrice },
            },
          },
          doc.service_id,
        );
      }
    });
    setProductList([]);
    setServiceList([]);
  }

  useEffect(() => {
    editListItemsCreate(visible);
  }, [visible, editListItemsCreate]);

  useEffect(() => {
    setInputSearch('');
    setSearch('');
  }, [toggleList]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              <FaEye className="p-mx-auto p-as-center modal-header-icon" />
            </div>
            <span className="p-my-auto users-button-text">
              Ver Lista Completa
            </span>
          </div>
        );
      }}
      maximized
      maximizable
      visible={visible.visible}
      breakpoints={{ '960px': '100vw' }}
      style={{ width: '75vw' }}
      footer={() => {
        return (
          <div className="p-grid">
            <div className="p-col-8">
              <p className="p-p-0 p-m-0 p-text-left">
                <i className="p-as-start p-mr-2 pi pi-exclamation-circle input-warn" />
                <small className="p-as-start input-warn">
                  Lembre-se sempre de salvar a lista para salvar as alterações.
                </small>
              </p>
            </div>
            <div className="p-col-4 p-pb-0">
              <Button
                className="modal-save-button"
                label="Salvar"
                disabled={pendingAction}
                icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
                iconPos="right"
                onClick={() => handleSaveList()}
                autoFocus={false}
              />
            </div>
          </div>
        );
      }}
      onHide={() => editListItemsDestroy()}
      baseZIndex={0}
    >
      <EditListFilter
        onListFilter={onListFilter}
        onListType={onListType}
        basePriceFilter={basePriceFilter}
        referencePriceFilter={referencePriceFilter}
        setReferencePriceFilter={setReferencePriceFilter}
        setOnListFilter={setOnListFilter}
        setOnListType={setOnListType}
        setBasePriceFilter={setBasePriceFilter}
        displayFilter={displayFilter}
        setDisplayFilter={setDisplayFilter}
        baseMinPrice={baseMinPrice}
        setBaseMinPrice={setBaseMinPrice}
        baseMaxPrice={baseMaxPrice}
        setBaseMaxPrice={setBaseMaxPrice}
        referenceMinPrice={referenceMinPrice}
        setReferenceMinPrice={setReferenceMinPrice}
        referenceMaxPrice={referenceMaxPrice}
        setReferenceMaxPrice={setReferenceMaxPrice}
      />
      <InputForm
        pleaceHolder=""
        disabled
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome da Lista"
        fieldCode="name"
      />
      {optionalActions && (
        <div>
          <label>Mais informações</label>
          <div className="p-d-flex p-jc-center p-mt-3">
            <CitiesDropSelect
              province={province}
              setProvince={setProvince}
              onPage={false}
              disabled
            />
          </div>
          <div className="p-d-flex p-jc-center p-mt-2">
            <ListCitiesDropSelect
              city={city}
              setCity={setCity}
              UF={province.code}
              disabled
            />
          </div>
        </div>
      )}
      <div className="p-grid p-px-2 p-pt-2">
        <div
          className={`p-col-6 p-d-flex ${
            toggleList ? 'editListItems-tab' : 'editListItems-active-tab'
          }`}
          aria-hidden="true"
          onClick={() => {
            setToggleList(false);
          }}
        >
          <p className="p-mx-auto">Produtos</p>
        </div>
        <div
          className={`p-col-6 p-d-flex ${
            toggleList ? 'editListItems-active-tab' : 'editListItems-tab'
          }`}
          aria-hidden="true"
          onClick={() => {
            setToggleList(true);
          }}
        >
          <p className="p-mx-auto">Serviços</p>
        </div>
      </div>
      <div>
        <div className="p-grid p-mt-2 p-justify-between">
          <div className="p-col-12 p-md-12 p-lg-6 p-d-flex">
            <Button
              onClick={() => {
                setDisplayFilter(true);
              }}
              icon="pi pi-filter"
              label="Adicionar Filtros"
              className="filter-add-button p-mr-1"
            />
            <p className="input-warn">
              {filterCount() > 0
                ? `${filterCount()} Filtro(s) Ativos`
                : 'Nenhum Filtro Ativo'}
            </p>
          </div>
          <div className="p-col-12 p-md-12 p-lg-6 p-jc-end">
            <div className="width100">
              <span className="p-input-icon-right width90">
                {search !== '' && (
                  <i
                    aria-hidden="true"
                    className="p-d-none p-d-md-inline-flex pi pi-times default-box-header-search-clear"
                    onClick={() => {
                      setSearch('');
                      setInputSearch('');
                    }}
                  />
                )}
                <InputText
                  placeholder="Pesquisar..."
                  className="editList-input"
                  value={search}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearch(e.target.value);
                  }}
                />
              </span>
              <Button
                icon="pi pi-search"
                className="editList-select-all width10"
                onClick={() => setInputSearch(search)}
              />
            </div>
          </div>
        </div>
        {toggleList ? (
          <div>
            <div className="p-grid p-my-1 p-mx-auto p-d-none p-d-md-inline-flex default-box-header">
              <div className="p-col-1 p-text-center p-as-center">Inserir</div>
              <div className="p-col-6 p-text-center p-as-center">Nome</div>
              <div className="p-col-3 p-text-center p-as-center">
                Referência
              </div>
              <div className="p-col-2 p-text-center p-as-center">Preço</div>
            </div>
            {servicesList
              .filter(
                (item) =>
                  item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                  -1,
              )
              .map((service, index) => {
                const serviceComponent = (
                  <ServiceList
                    key={String(index)}
                    listId={visible.item.id}
                    service={service}
                    serviceList={serviceList}
                    addChangerServiceList={addChangerServiceList}
                    remChangerServiceList={remChangerServiceList}
                  />
                );
                if (filterApply(service)) {
                  return serviceComponent;
                }
                return <></>;
              })}
          </div>
        ) : (
          <div>
            <div className="p-grid p-my-1 p-mx-auto p-d-none p-d-md-inline-flex default-box-header">
              <div className="p-col-1 p-text-center p-as-center">Inserir</div>
              <div className="p-col-3 p-text-center p-as-center">Nome</div>
              <div className="p-col-3 p-text-center p-as-center">EAN</div>
              <div className="p-col-3 p-text-center p-as-center">
                Referência
              </div>
              <div className="p-col-2 p-text-center p-as-center">Preço</div>
            </div>
            {productsList
              .filter(
                (item) =>
                  item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                  -1,
              )
              .map((product, index) => {
                const productComponent = (
                  <ProductList
                    key={String(index)}
                    listId={visible.item.id}
                    product={product}
                    productList={productList}
                    addChangerList={addChangerList}
                    remChangerList={remChangerList}
                  />
                );
                if (filterApply(product)) {
                  return productComponent;
                }
                return <></>;
              })}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default EditListItens;
