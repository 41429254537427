// Importações Padrões
import firebase from 'firebase';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Types
import { ICity } from '../typings/cities.types';

// Componentes
import {
  toasterError,
  toasterSuccess,
} from '../components/global/toaster.component';

const citiesCollection = firebaseConfig.firestore().collection('All-city');

async function addCity(data: Partial<ICity>) {
  try {
    const response = await citiesCollection.add({
      ...data,
      created_at: firebase.firestore.Timestamp.now(),
      updated_at: firebase.firestore.Timestamp.now(),
    });
    if (response.id) {
      toasterSuccess('CRIAR', `Cidade ${data.name} Criada`);
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com sua requisição!');
    return false;
  }
}

async function editCity(data: Partial<ICity>, id: string) {
  try {
    const dataDBExists = await citiesCollection.doc(id).get();
    if (!dataDBExists) {
      return [];
    }
    await citiesCollection.doc(id).update({
      ...data,
      updated_at: firebase.firestore.Timestamp.now(),
    });
    toasterSuccess('EDITAR', `Cidade ${data.name} Editada!`);
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua requisição!');
    return false;
  }
}
async function deleteCity(id: string, name: string) {
  try {
    await citiesCollection.doc(id).delete();
    toasterSuccess('APAGAR', `Cidade ${name} Apagada!`);
    return true;
  } catch (err) {
    console.log(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua requisição!');
    return false;
  }
}

export { citiesCollection, addCity, editCity, deleteCity };
