// Importações Padrões
import React, { useState, useEffect, useContext } from 'react';

// Icones
import {
  FaPlus,
  FaMinus,
  FaRegQuestionCircle,
  FaHistory,
  FaArrowLeft,
} from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import {
  IProduct,
  IEditAmount,
  ILogAmountProduct,
} from '../../typings/product.types';
import { IValidationError } from '../../typings/yup.types';

// Controladores
import {
  editProduct,
  logAmountProduct,
  getLogAmountProduct,
} from '../../controllers/product.controller';
import { getOrders } from '../../controllers/order.controller';

// Componentes
import { catchErrors, catchInvalid } from '../global/formErrors.component';

// Funções
import { dataSchemaAmount, parseUnit } from '../../functions/product.function';
import ProductTitle from './productTitle.component';

import '../../styles/components/products/editAmount.scss';
import ProductLog from './productLog.component';

interface IEditProductAmountProps {
  visible: IEditAmount;
  setVisible: React.Dispatch<React.SetStateAction<IEditAmount>>;
}

const EditAmount = (props: IEditProductAmountProps) => {
  const { visible, setVisible } = props;
  const { code3cData, userData } = useContext(Auth);

  const [pendingAction, setPendingAction] = useState<boolean>(false);

  const [oldProductAmount, setOldProductAmount] = useState(0);
  const [oldProductReservation, setOldProductReservation] = useState(0);
  const [productAmount, setProductAmount] = useState(0);
  const [productReservation, setProductReservation] = useState(0);
  const [productAmortization, setProductAmortization] = useState(0);

  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  const [hiddenLogs, setHiddenLogs] = useState(true);

  const [logsList, setLogsList] = useState<Array<ILogAmountProduct>>([]);

  const initialState = () => {
    setFormErr([]);
    setVisible({ ...visible, visible: false });
    setOldProductAmount(0);
    setHiddenLogs(true);
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
  };

  function handleAddAmount(n: number) {
    setProductAmount(productAmount + n);
  }
  function handleDeleteAmount(n: number) {
    setProductAmount(productAmount - n);
  }

  function handleAddReservationAmount(n: number) {
    setProductReservation(productReservation + n);
  }
  function handleRemReservationAmount(n: number) {
    setProductReservation(productReservation - n);
  }

  async function handleEditAmount() {
    setPendingAction((state) => !state);
    const dataValidation: Partial<IProduct> = {
      productAmount,
      saleAvailable: productAmount - productAmortization,
      productReservation,
      reservationAvailable:
        productReservation -
        oldProductReservation +
        visible.item.reservationAvailable,
    };
    dataSchemaAmount
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await editProduct(
          {
            ...dataValidation,
            saleAvailable:
              dataValidation.saleAvailable && dataValidation.saleAvailable < 0
                ? 0
                : dataValidation.saleAvailable,
          },
          visible.item.id,
        );
        if (response) {
          await logAmountProduct(
            {
              user_name: userData?.name || '',
              user_id: userData?.id || '',
              productAmount: dataValidation.productAmount || 0,
              saleAvailable: dataValidation.saleAvailable || 0,
              reservationAvailable: dataValidation.reservationAvailable || 0,
              productReservation: dataValidation.productReservation || 0,
              oldProductAmount,
              oldSaleAvailable: visible.item.saleAvailable,
              oldReservationAvailable: visible.item.reservationAvailable,
              oldProductReservation,
            },
            visible.item.id,
          );
          setFormErr([]);
          initialState();
          setPendingAction(false);
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  async function getOpenedOrders() {
    const openedOrdersList = await getOrders(
      code3cData?.id || '',
      visible.item.id,
    );

    const productReservedAmount: Array<number> = [];

    for (let i = 0; i < openedOrdersList.length; i += 1) {
      const newProductReservedAmount = openedOrdersList[i].productOrder
        .filter((pOF) => pOF.product_id === visible.item.id)
        .map((pO) => {
          if (pO.separated) return 0;
          return pO.quantity;
        });

      for (let j = 0; j < newProductReservedAmount.length; j += 1) {
        productReservedAmount.push(newProductReservedAmount[j]);
      }
    }

    if (productReservedAmount.length > 0) {
      setProductAmortization(
        productReservedAmount.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
        ),
      );
    } else {
      setProductAmortization(0);
    }
  }

  async function handleLogAmount() {
    setHiddenLogs((state) => !state);
    setLogsList(await getLogAmountProduct(visible.item.id));
  }

  useEffect(() => {
    setProductAmount(visible.item.productAmount);
    setOldProductAmount(visible.item.productAmount);
    setProductReservation(visible.item.productReservation);
    setOldProductReservation(visible.item.productReservation);
    getOpenedOrders();
  }, [visible]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              <FaPlus className="p-mx-auto p-as-center modal-header-icon" />
            </div>
            <span className="p-my-auto users-button-text">
              Estoque em {parseUnit(visible.item.unit).toUpperCase()}
            </span>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '35rem' }}
      footer={() => {
        return (
          <div className="p-p-0 p-m-0 p-grid p-d-flex">
            <div className="p-col-6 p-justify-start p-d-flex p-as-center">
              <Button
                className="p-p-0 button-icon-square"
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  borderRadius: '0px',
                }}
                onClick={() => handleLogAmount()}
              >
                {hiddenLogs ? (
                  <FaHistory className="p-mx-auto users-button-icon" />
                ) : (
                  <FaArrowLeft className="p-mx-auto users-button-icon" />
                )}
              </Button>
            </div>
            <div className="p-col-6">
              {hiddenLogs && (
                <Button
                  className="p-m-0 modal-save-button"
                  label="Salvar"
                  disabled={pendingAction}
                  icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
                  iconPos="right"
                  onClick={() => handleEditAmount()}
                  autoFocus={false}
                />
              )}
            </div>
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      {hiddenLogs ? (
        <div>
          <ProductTitle
            name={visible.item.name}
            image={visible.item.imageHeavy}
            useProductBase={visible.item.useProductBase}
          />
          <div className="p-grid">
            <div className="p-col-6 p-field p-mx-0 p-mt-2 p-mb-0">
              <label className="p-p-0 p-mb-3">
                Disponível para Venda &nbsp;
                <FaRegQuestionCircle
                  className="question-1 link"
                  data-pr-tooltip="Esse é a quantidade de produtos disponíveis para a
            venda, não é preciso alterar, pois ela segue de acordo com suas
            vendas e entradas de produto."
                />
                <Tooltip
                  target=".question-1"
                  mouseTrack
                  mouseTrackLeft={10}
                  className="p-text-justify"
                />
              </label>
              <span className="p-d-flex">
                <InputText
                  disabled
                  className="settings-input p-text-center"
                  value={visible.item.saleAvailable}
                />
              </span>
            </div>
            <div className="p-col-6 p-field p-mx-0 p-mt-2 p-mb-0">
              <label className="p-p-0 p-mb-3">
                Estoque em Pedidos &nbsp;
                <FaRegQuestionCircle
                  className="question-1 link"
                  data-pr-tooltip="Esse é a quantidade de produtos
            que estão sob uso em pedidos, esses valores
            são abatidos da disponibilidade de venda."
                />
                <Tooltip
                  target=".question-1"
                  mouseTrack
                  mouseTrackLeft={10}
                  className="p-text-justify"
                />
              </label>
              <span className="p-d-flex">
                <InputText
                  disabled
                  className="settings-input p-text-center"
                  value={productAmortization}
                />
              </span>
            </div>
          </div>
          <div className="p-field p-m-0">
            <label className="p-p-0 p-mt-3">
              Modificar Estoque&nbsp;
              <FaRegQuestionCircle
                className="question-2 link"
                data-pr-tooltip="Esse é a quantidade de produtos que estão no seu
            estoque, inclui produtos vendidos que não foram separados, toda vez
            que um ou mais produtos são separados esse valor diminui."
              />
              <Tooltip
                target=".question-2"
                mouseTrack
                mouseTrackLeft={10}
                className="p-text-justify"
              />
            </label>
            <span className="p-d-flex p-input-icon-right">
              <Button
                className="p-p-0 input-on-button"
                onClick={() => handleDeleteAmount(1)}
              >
                <FaMinus className="p-mx-auto users-button-icon" />
              </Button>
              {catchInvalid(formErr, 'amount') && (
                <i className="pi pi-times input-invalid" />
              )}
              <InputText
                className="settings-input p-text-center "
                value={productAmount}
                type={
                  visible.item.unit === 'kg' || visible.item.unit === 'liter'
                    ? 'number'
                    : 'text'
                }
                step={
                  visible.item.unit === 'kg' || visible.item.unit === 'liter'
                    ? '.1'
                    : '0'
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (!event.target.value) {
                    setProductAmount(0);
                  } else if (
                    visible.item.unit !== 'kg' &&
                    visible.item.unit !== 'liter'
                  ) {
                    setProductAmount(parseInt(event.target.value, 10));
                  } else {
                    setProductAmount(parseFloat(event.target.value));
                  }
                }}
              />
              <Button
                className="p-p-0 input-on-button"
                onClick={() => handleAddAmount(1)}
              >
                <FaPlus className="p-mx-auto users-button-icon" />
              </Button>
            </span>
            {catchErrors(formErr, 'amount')}
            <span
              className="p-d-flex p-mt-2 "
              style={{ width: '100%', height: '2.3rem' }}
            >
              <Button
                style={{ width: '100%' }}
                label="+ 10"
                className="p-p-0 p-mr-2 input-on-button"
                onClick={() => handleAddAmount(10)}
              />
              <Button
                style={{ width: '100%' }}
                label="+ 50"
                className="p-p-0 p-mr-2 input-on-button"
                onClick={() => handleAddAmount(50)}
              />
              <Button
                style={{ width: '100%' }}
                label="+ 100"
                className="p-p-0 input-on-button"
                onClick={() => handleAddAmount(100)}
              />
            </span>
          </div>
          <div className="p-grid p-mt-3">
            <div className="p-col-6 p-m-0">
              <label className="p-p-0 p-mt-3">
                Disponível para Reserva&nbsp;
                <FaRegQuestionCircle
                  className="question-3 link"
                  data-pr-tooltip="Esse é a quantidade de produtos disponíveis para a
            reserva, não é preciso alterar, pois ela segue de acordo com suas
            vendas e entradas de reservas de produto."
                />
                <Tooltip
                  target=".question-3"
                  mouseTrack
                  mouseTrackLeft={10}
                  className="p-text-justify"
                />
              </label>
              <span className="p-d-flex">
                <InputText
                  disabled
                  className="settings-input p-text-center"
                  value={visible.item.reservationAvailable}
                />
              </span>
            </div>
            <div className="p-col-6 p-m-0">
              <label className="p-p-0 p-mt-4">
                Modificar Limite de Reserva&nbsp;
                <FaRegQuestionCircle
                  className="question-4 link"
                  data-pr-tooltip="Esse é o limite disponível para
            reserva, só é preciso alterar uma vez que o limite estipulado de
            reserva seja modificado."
                />
                <Tooltip
                  target=".question-3"
                  mouseTrack
                  mouseTrackLeft={10}
                  className="p-text-justify"
                />
              </label>
              <span className="p-d-flex p-input-icon-right">
                <Button
                  className="p-p-0 input-on-button"
                  onClick={() => handleRemReservationAmount(1)}
                >
                  <FaMinus className="p-mx-auto users-button-icon" />
                </Button>
                {catchInvalid(formErr, 'amount') && (
                  <i className="pi pi-times input-invalid" />
                )}
                <InputText
                  className="settings-input p-text-center "
                  value={productReservation}
                  type={
                    visible.item.unit === 'kg' || visible.item.unit === 'liter'
                      ? 'number'
                      : 'text'
                  }
                  step={
                    visible.item.unit === 'kg' || visible.item.unit === 'liter'
                      ? '.1'
                      : '0'
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (!event.target.value) {
                      setProductReservation(0);
                    } else if (
                      visible.item.unit !== 'kg' &&
                      visible.item.unit !== 'liter'
                    ) {
                      setProductReservation(parseInt(event.target.value, 10));
                    } else {
                      setProductReservation(parseFloat(event.target.value));
                    }
                  }}
                />
                <Button
                  className="p-p-0 input-on-button"
                  onClick={() => handleAddReservationAmount(1)}
                >
                  <FaPlus className="p-mx-auto users-button-icon" />
                </Button>
              </span>
            </div>
          </div>
          <div className="p-d-flex p-mt-3">
            <i className="p-mr-2 pi pi-exclamation-circle input-warn p-as-center" />
            <small className=" input-warn">
              As modificações de estoque são irreversíveis, manipule com
              cuidado.
            </small>
          </div>
        </div>
      ) : (
        <div>
          {logsList.map((lL, index) => {
            return (
              <ProductLog
                key={String(index)}
                AmountProductLog={lL}
                Product={visible.item}
              />
            );
          })}
        </div>
      )}
    </Dialog>
  );
};

export default EditAmount;
