// Importações Padrões
import React, { useContext } from 'react';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import {
  IBranding,
  IEditBranding,
  IDeleteBranding,
} from '../../typings/branding.types';

// Componentes
import ItemCol from '../defaultBox/itemCol.component';
import ItemImage from '../defaultBox/itemImage.component';
import ItemAction, { IEdit } from '../defaultBox/itemAction.component';
import ResponsiveItemAction from '../defaultBox/responsiveItemAction.component';

interface IITemMenu {
  branding: IBranding;
  index: number;
  showHideEditBranding: IEditBranding;
  setShowHideEditBranding: React.Dispatch<
    React.SetStateAction<IEdit<IBranding>>
  >;
  showHideDeleteBranding: IDeleteBranding;
  setShowHideDeleteBranding: React.Dispatch<
    React.SetStateAction<IDeleteBranding>
  >;
}

const defaultImage = 'https://image.flaticon.com/icons/png/512/35/35073.png';

const ItemBrandings = ({
  branding,
  index,
  showHideEditBranding,
  setShowHideEditBranding,
  showHideDeleteBranding,
  setShowHideDeleteBranding,
}: IITemMenu) => {
  const { windowWidth } = useContext(Auth);

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-mt-1 p-mx-auto p-p-3 p-grid item-box width100">
          <p className="p-col-9 width100 p-d-inline p-my-0 p-p-0 p-mr-0">
            #{index}
          </p>
          <div className="p-px-1 p-as-center p-mx-auto">
            <ItemImage
              colGrid={4}
              content={branding.imageHeavy}
              imageDefault={defaultImage}
            />
          </div>
          <ItemCol colGrid={4} field="Nome" content={branding.name} />
          <ResponsiveItemAction
            item={branding}
            actionEdit={showHideEditBranding}
            setActionEdit={setShowHideEditBranding}
            actionDelete={showHideDeleteBranding}
            setActionDelete={setShowHideDeleteBranding}
          />
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div
          className="p-mt-1 p-mx-auto p-px-3 p-grid item-box width100"
          style={{
            border: '1px solid #e9ecef',
            borderWidth: '0 0 1px 0',
            backgroundColor: 'white',
          }}
        >
          <ItemCol colGrid={1} field="Id" content={String(index)} />
          <div className="p-col-4">
            <ItemImage
              colGrid={12}
              content={branding.imageHeavy}
              imageDefault={defaultImage}
            />
          </div>
          <ItemCol colGrid={4} field="Nome" content={branding.name} />
          <ItemAction
            item={branding}
            actionEdit={showHideEditBranding}
            setActionEdit={setShowHideEditBranding}
            actionDelete={showHideDeleteBranding}
            setActionDelete={setShowHideDeleteBranding}
            colGrid={3}
          />
        </div>
      );
    }
    return <div />;
  }

  return changeBreakpoints();
};

export default ItemBrandings;
