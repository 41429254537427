export interface IBranding {
  id: string;
  name: string;
  code3c_id: string;
  imageLight: string;
  imageHeavy: string;
}

export interface IEditBranding {
  item: IBranding;
  edit: boolean;
  visible: boolean;
}

export interface IDeleteBranding {
  id: string;
  name: string;
  visible: boolean;
}

export const initialBranding: IBranding = {
  id: '',
  name: '',
  code3c_id: '',
  imageHeavy: '',
  imageLight: '',
};
