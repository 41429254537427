// Importações Padrões
import React from 'react';

// Icones
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// React Prime
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

// Types
import { IValidationError } from '../../typings/yup.types';

// Componentes
import { catchErrors, catchInvalid } from '../global/formErrors.component';

interface IInputFormPassProps {
  disabled: boolean;
  item: string;
  setItem: React.Dispatch<React.SetStateAction<string>>;
  formErr: Array<IValidationError>;
  fieldLabel: string;
  fieldCode: string;
  peekPass: boolean;
  setPeekPass: React.Dispatch<React.SetStateAction<boolean>>;
}

const InputPassForm = (props: IInputFormPassProps) => {
  const {
    disabled,
    item,
    setItem,
    formErr,
    fieldLabel,
    fieldCode,
    peekPass,
    setPeekPass,
  } = props;
  return (
    <div className="p-field">
      <label className="p-my-0 p-mt-4">{fieldLabel}</label>
      <span className="p-d-flex p-input-icon-right">
        {catchInvalid(formErr, fieldCode) && (
          <i className="pi pi-times input-invalid" />
        )}
        <InputText
          disabled={disabled}
          type={!peekPass ? 'password' : 'text'}
          className={
            catchInvalid(formErr, fieldCode)
              ? 'settings-input-invalid'
              : 'settings-input'
          }
          value={item}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setItem(event.target.value);
          }}
        />
        <Button
          className="p-p-0 input-on-button"
          onClick={() => {
            setPeekPass(!peekPass);
          }}
        >
          {peekPass ? (
            <FaEyeSlash className="p-mx-auto users-button-icon" />
          ) : (
            <FaEye className="p-mx-auto users-button-icon" />
          )}
        </Button>
      </span>
      {catchErrors(formErr, fieldCode)}
    </div>
  );
};

export default InputPassForm;
