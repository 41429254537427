// Importações Padrões
import React, { useContext, useState } from 'react';
import { maskBr, validateBr } from 'js-brasil';
import * as yup from 'yup';

// Icones
import { FaPlus, FaToggleOff, FaToggleOn } from 'react-icons/fa';

// React Prime
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import { IInput } from '../../typings/input.types';
import { IValidationError } from '../../typings/yup.types';
import {
  IClient,
  IClientAddress,
  initialClientAddress,
} from '../../typings/client.types';

// Componentes
import InputDropForm from '../input/inputDropForm.component';
import InputForm from '../input/inputForm.component';
import InputFormFunction from '../input/inputFormFunction.component';
import InputImageForm from '../input/inputImageForm.component';
import InputTextareaComponent from '../input/inputTextarea.component';
import AddressList from './addressList.component';

// Controladores
import { addClient } from '../../controllers/client.controller';

// Funções
import { dataSchema } from '../../functions/client.function';
import { getURLFromDB } from '../../functions/image.function';

interface IAddClientProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddClient = (props: IAddClientProps) => {
  const { visible, setVisible } = props;
  const { code3cData, systemData } = useContext(Auth);

  const initialClientOption = {
    name: 'Cliente Completo',
    code: 'complexClient',
  };

  const [name, setName] = useState<string>('');
  const [socialID, setSocialID] = useState<string>('');
  const [stateSubscription, setStateSubscription] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [imageHeavy, setImageHeavy] = useState<string>('');
  const [imageLight, setImageLight] = useState<string>('');
  const [addressList, setAddressList] = useState<Array<IClientAddress>>([
    initialClientAddress,
  ]);
  const [cpfValid, setCpfValid] = useState(false);
  const [cnpjValid, setCnpjValid] = useState(false);

  const [typeOf, setTypeOf] = useState<IInput>(initialClientOption);
  const typeOfList: Array<IInput> = [
    { name: 'Cliente Completo', code: 'complexClient' },
    { name: 'Cliente Orçamento', code: 'basicClient' },
  ];

  const [docType, setDocType] = useState(true);
  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  function addClientDestroy() {
    const emptyString = '';
    setName(emptyString);
    setSocialID(emptyString);
    setStateSubscription(emptyString);
    setPhone(emptyString);
    setEmail(emptyString);
    setImageHeavy(emptyString);
    setImageLight(emptyString);
    setNote(emptyString);
    setCpfValid(false);
    setCnpjValid(false);
    setPendingAction(false);
    setVisible(false);
    setFormErr([]);
    setTypeOf(initialClientOption);
  }

  function changeDocType() {
    setCpfValid(false);
    setCnpjValid(false);
    setSocialID('');
    setDocType(!docType);
  }

  function formatPhone(Phone: string) {
    setPhone(Phone);
    if (validateBr.telefone(Phone) && Phone.length >= 10) {
      setPhone(maskBr.telefone(Phone));
    } else if (!validateBr.telefone(Phone)) {
      setPhone(Phone.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''));
    }
  }

  function formatSocialID(CNPJ_CPF: string) {
    if (docType) {
      setSocialID(CNPJ_CPF);
      if (validateBr.cnpj(CNPJ_CPF) && CNPJ_CPF.length === 14) {
        setSocialID(maskBr.cnpj(CNPJ_CPF));
        setCnpjValid(false);
      } else if (CNPJ_CPF.length === 14) {
        setSocialID(maskBr.cnpj(CNPJ_CPF));
        setCnpjValid(true);
      } else {
        setSocialID(CNPJ_CPF.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''));
        setCnpjValid(false);
      }
    } else {
      setSocialID(CNPJ_CPF);
      if (validateBr.cpf(CNPJ_CPF) && CNPJ_CPF.length === 11) {
        setSocialID(maskBr.cpf(CNPJ_CPF));
        setCpfValid(false);
      } else if (CNPJ_CPF.length === 11) {
        setSocialID(maskBr.cpf(CNPJ_CPF));
        setCpfValid(true);
      } else {
        setSocialID(CNPJ_CPF.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''));
        setCpfValid(false);
      }
    }
  }

  async function handleAddClient() {
    setPendingAction((state) => !state);
    let urlImage = '';
    if (imageHeavy !== '') {
      urlImage = await getURLFromDB(
        false,
        imageHeavy,
        imageLight,
        code3cData?.id ? code3cData.id : '',
        'All-client',
      );
    }

    let dataValidation: Partial<IClient> = {
      typeOf: typeOf.code,
      systemId: systemData?.systemId,
      code3c_id: code3cData?.id,
      name,
      phone: phone.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''),
      email,
      note,
      address: addressList,
      imageHeavy: urlImage,
      imageLight: urlImage,
    };

    const dataPassSchema: yup.AnySchema = dataSchema;
    if (typeOf.code === 'complexClient') {
      dataValidation = {
        ...dataValidation,
        socialID: socialID.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''),
        stateSubscription,
      };
    }

    dataPassSchema
      .validate(dataValidation, {
        abortEarly: false,
        context: { docType },
      })
      .then(async () => {
        const response = await addClient(dataValidation);
        if (response) {
          setFormErr([]);
          setPendingAction((state) => !state);
          addClientDestroy();
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              <FaPlus className="p-mx-auto p-as-center modal-header-icon" />
            </div>
            <span className="p-my-auto users-button-text">Novo Cliente</span>
          </div>
        );
      }}
      visible={visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label="Salvar"
              disabled={pendingAction}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() => handleAddClient()}
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => addClientDestroy()}
      baseZIndex={0}
    >
      <InputDropForm
        filter={false}
        filterBy=""
        disabled={false}
        item={typeOf}
        setItem={setTypeOf}
        options={typeOfList}
        formErr={formErr}
        fieldLabel="Tipo do Cliente"
        fieldCode="typeOf"
      />
      <InputForm
        pleaceHolder=""
        disabled={false}
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome do Cliente"
        fieldCode="name"
      />
      {typeOf.code === 'complexClient' && (
        <>
          <label>
            {docType ? (
              <div className="p-d-flex p-mb-2 p-as-center">
                <FaToggleOff
                  className="p-as-center"
                  style={{ fontSize: '1.8rem', cursor: 'pointer' }}
                  onClick={() => changeDocType()}
                />
                <div className="p-ml-2 p-as-center">CNPJ</div>
              </div>
            ) : (
              <div className="p-d-flex p-mb-2 p-as-center">
                <FaToggleOn
                  style={{ fontSize: '1.8rem', cursor: 'pointer' }}
                  onClick={() => changeDocType()}
                />
                <div className="p-ml-2 p-as-center">CPF</div>
              </div>
            )}
          </label>
          <InputFormFunction
            maxLength={docType ? 18 : 11}
            disabled={false}
            item={socialID}
            setFunction={formatSocialID}
            formErr={formErr}
            fieldCode="socialID"
          />
          {cpfValid && (
            <div className="p-d-flex p-mb-3">
              <i className="p-mr-2 pi pi-exclamation-circle input-warn p-as-center" />
              <small className=" input-warn">
                O CPF inserido não é valido!.
              </small>
            </div>
          )}
          {cnpjValid && (
            <div className="p-d-flex p-mb-3">
              <i className="p-mr-2 pi pi-exclamation-circle input-warn p-as-center" />
              <small className=" input-warn">
                O CNPJ inserido não é valido!.
              </small>
            </div>
          )}
          <InputForm
            pleaceHolder=""
            disabled={false}
            item={stateSubscription}
            setItem={setStateSubscription}
            formErr={formErr}
            fieldLabel="Inscrição Estadual"
            fieldCode="stateSubscription"
          />
        </>
      )}

      <div className="p-d-flex p-my-2 p-jc-center">
        <small className="input-warn">Informações de Contato.</small>
      </div>
      <div className="p-grid p-mt-2">
        <div className="p-col-12 p-md-6 p-lg-6">
          <label className="p-my-0 p-mt-4">Telefone</label>
          <InputFormFunction
            maxLength={15}
            disabled={false}
            item={phone}
            setFunction={formatPhone}
            formErr={formErr}
            fieldCode="phone"
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputForm
            pleaceHolder=""
            disabled={false}
            item={email}
            setItem={setEmail}
            formErr={formErr}
            fieldLabel="Email"
            fieldCode="email"
          />
        </div>
      </div>
      <div className="p-d-flex p-my-2 p-jc-center">
        <small className="input-warn">Informações de Endereço.</small>
      </div>
      {addressList.map((address, index) => {
        return (
          <AddressList
            key={String(index)}
            disabled={false}
            formErr={formErr}
            address={address}
            addressList={addressList}
            setAddressList={setAddressList}
          />
        );
      })}
      <Button
        className="modal-save-button"
        label="Adicionar Novo Endereço"
        onClick={() => {
          setAddressList([
            ...addressList,
            { ...initialClientAddress, index: addressList.length + 1 },
          ]);
        }}
      />
      <div className="p-d-flex p-my-2 p-jc-center">
        <small className="input-warn">Anotações.</small>
      </div>
      <InputTextareaComponent
        disabled={false}
        item={note}
        setItem={setNote}
        formErr={formErr}
        fieldLabel="Observações"
        fieldCode={note}
        rows={5}
        cols={30}
      />
      <InputImageForm
        visibleEdit
        loadingImage={pendingAction}
        item={imageHeavy}
        setItem={setImageHeavy}
        fieldLabel="Imagem do Cliente"
      />
    </Dialog>
  );
};

export default AddClient;
