// Importações Padrões
import React from 'react';
import { Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IItemMenuRouteProps {
  path: string;
  pathname: string;
  icon: IconProp;
  active: boolean;
  extender: boolean;
}

const ItemMenuRoute = (props: IItemMenuRouteProps) => {
  const { path, pathname, icon, active, extender } = props;

  return (
    <Link to={path} className="no-decoration">
      <div
        className={
          active
            ? 'p-grid p-col p-d-flex p-m-0 p-p-0 item-display-active'
            : 'p-grid p-col p-d-flex p-m-0 p-p-0 item-display'
        }
      >
        <div
          className={
            extender
              ? 'p-col-2 p-d-inline p-d-flex p-as-center'
              : 'p-col p-d-inline p-d-flex p-as-center'
          }
        >
          <FontAwesomeIcon
            icon={icon}
            className={
              active ? 'item-icon-active p-mx-auto' : 'item-icon p-mx-auto'
            }
          />
        </div>
        <div
          className={
            extender
              ? 'p-col-10 p-d-inline p-m-0 p-as-center p-px-0'
              : 'p-d-none p-d-inline p-m-0 p-as-center p-px-0'
          }
        >
          <p
            className={
              extender
                ? 'p-d-block p-m-0 item-text-extended'
                : 'p-d-block p-m-0 item-text-closed'
            }
          >
            {pathname}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ItemMenuRoute;
