import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Button } from 'primereact/button';

import { BasicModal } from '../../../components/modals/BasicModal';
import { formatCurrency } from '../../../functions/list.function';

import { ClientSalesTarget } from '../../../typings/salesTarget';

import {
  addClientSalesTarget,
  editClientSalesTarget,
} from '../../../controllers/clientSalesTarget';
import { ClientSalesGoal } from '../contexts/SalesTargetContextInterface';
import { SalesTargetProvider } from '../contexts/SalesTargetContextt';
import { NCTextInput } from '../../../components/input/text-input/NCTextInput';

import '../../../components/modals/BasicModal.scss';
import './ModalClientSelected.scss';
import { SliderInput } from '../../../components/input/slider-input/SliderInput';
import { SliderValueType } from 'primereact/slider';
import { Option } from '../../../components/dropdown/basic-dropdown/BasicDropDown';
import { StylizedDropDown } from '../../../components/dropdown/stylized-dropdown/StylizedDropdown';
import { useUserslist } from '../../../hooks/useUsersList';
import { AreaInput } from '../../../components/input/area-input/AreaInput';
import { IValidationError } from '../../../typings/yup.types';
import { createClientSalesTargetSchema } from '../validations/createClientSalesTarget';
import { CurrencyInput } from '../../../components/input/currency-input/CurrencyInput';

export interface ModalClientSelectedProps {
  displayModal: boolean;
  setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
  client: ClientSalesGoal;
  selectedClientSalesTarget: ClientSalesTarget | null | undefined;
  mainSalesTarget: string;
}

export const ModalClientSelected = (props: ModalClientSelectedProps) => {
  const { selectedClient } = useContext(SalesTargetProvider);
  const {
    displayModal,
    setDisplayModal,
    selectedClientSalesTarget,
    mainSalesTarget,
  } = props;

  const { usersList } = useUserslist();

  const [percentage, setPercentage] = useState<SliderValueType>(
    selectedClientSalesTarget ? selectedClientSalesTarget.percentage : 0,
  );
  const [potential, setPotential] = useState(0);
  const [note, setNote] = useState<string>('');

  const selectedSeller = usersList.find(
    (seller) => seller.id === selectedClientSalesTarget?.seller_id,
  );

  const [selectedOption, setSelectedOption] = useState<Option>(
    selectedSeller
      ? { name: selectedSeller.name, code: selectedSeller.id }
      : {
          name: '',
          code: '',
        },
  );

  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  const options = usersList
    .filter(
      (seller) => !!seller.systems.find((sys) => sys.function === 'seller'),
    )
    .map((user) => {
      return { name: user.name, code: user.id };
    });

  const handleToggleSeller = (value: Option) => {
    setSelectedOption(value);
  };

  const handleOpen = async () => {
    const clientSalesTargetData = {
      potential: 0,
      percentage: 0,
      note: '',
      finished: false,
    };
    await addClientSalesTarget(
      clientSalesTargetData,
      mainSalesTarget,
      selectedClient!.id,
    );
  };

  const handleUpdate = async () => {
    const clientSalesTargetData: Partial<ClientSalesTarget> = {
      potential: Number(potential),
      percentage: Number(percentage),
      note,
      seller_id: selectedOption.code,
      finished: false,
    };

    createClientSalesTargetSchema
      .validate(clientSalesTargetData, { abortEarly: false })
      .then(async () => {
        const response = await editClientSalesTarget(
          clientSalesTargetData,
          mainSalesTarget,
          selectedClient!.id,
        );
        if (response) {
          setDisplayModal(false);
          setFormErr([]);
        }
      })
      .catch((error: IValidationError) => {
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  };

  const onHide = () => {
    setDisplayModal(false);
    setFormErr([]);
  };

  const renderHeader = () => {
    return <span>Gerenciar Meta do Cliente</span>;
  };

  const renderFooter = () => {
    return (
      <div className="basic-modal-footer">
        <Button
          label="Salvar"
          icon="pi pi-check"
          onClick={() => handleUpdate()}
          className="button-accept"
          autoFocus
        />
      </div>
    );
  };

  useEffect(() => {
    if (selectedClient?.potential && selectedClient?.potential >= 0) {
      setPotential(selectedClient?.potential);
    } else {
      setPotential(0);
    }
  }, [selectedClient]);

  const renderModal = useCallback(
    (clientCreated: ClientSalesTarget | null | undefined) => {
      if (clientCreated) {
        return (
          <React.Fragment>
            <div>
              <NCTextInput label="Cliente" value={selectedClient!.name} />
              <CurrencyInput
                label="Potencial"
                currency="BRL"
                value={potential}
                setValue={setPotential}
                mandatory
                errorCode="potential"
                errors={formErr}
              />
              <NCTextInput
                label="Meta"
                value={formatCurrency(
                  'USD',
                  potential * (Number(percentage) / 100),
                )}
                className="bottom-square"
              />
              <SliderInput
                label="Porcentagem"
                value={percentage}
                setValue={setPercentage}
                mandatory
                errorCode="percentage"
                errors={formErr}
              />
              <StylizedDropDown
                selectedOption={selectedOption}
                options={options}
                action={handleToggleSeller}
                label="Vendedores"
                placeHolder="Visualizar..."
                className="dropdown"
                mandatory
                errorCode="seller_id"
                errors={formErr}
              />
              <AreaInput
                label="Observações"
                value={note}
                setValue={setNote}
                rows={3}
              />
            </div>
          </React.Fragment>
        );
      }
      return (
        <div className="create-client-target">
          <h4>Antes de gerenciar você precisa abrir a Meta</h4>
          <Button
            label="Abrir Meta"
            className="button-accept"
            onClick={() => {
              handleOpen();
            }}
          />
        </div>
      );
    },
    [
      selectedClientSalesTarget,
      mainSalesTarget,
      selectedClient,
      percentage,
      selectedOption,
      options,
      note,
      selectedSeller,
    ],
  );

  useEffect(() => {
    if (selectedClientSalesTarget) {
      const selectedSeller = usersList.find(
        (seller) => seller.id === selectedClientSalesTarget?.seller_id,
      );
      setPercentage(selectedClientSalesTarget.percentage);
      setNote(selectedClientSalesTarget.note);
      if (selectedSeller) {
        setSelectedOption({
          name: selectedSeller.name,
          code: selectedSeller.id,
        });
      }
    }
  }, [selectedClientSalesTarget, selectedSeller]);

  return (
    <BasicModal
      displayModal={displayModal}
      onHide={onHide}
      renderHeader={renderHeader}
      renderFooter={renderFooter}
    >
      {renderModal(selectedClientSalesTarget)}
    </BasicModal>
  );
};
