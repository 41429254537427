import { useContext, useState, useEffect } from 'react';

import { Auth } from '../../../services/auth.context';
import { ClientSalesTarget } from '../../../typings/salesTarget';
import { salesGoalCollection } from '../../../controllers/salesTarget';
import { useSalesTarget } from './useSalesTarget';

export interface UseClientSalesTarget {
  listClientSalesTarget: Array<ClientSalesTarget>;
  selectedClientSalesTarget: ClientSalesTarget | null | undefined;
  pending: boolean;
}

export const useClientSalesTarget = (
  selectedClient?: string,
): UseClientSalesTarget => {
  const { mainSalesTarget } = useSalesTarget();
  const { code3cData } = useContext(Auth);

  const [listClientSalesTarget, setListClientSalesTarget] = useState<
    Array<ClientSalesTarget>
  >([]);
  const [selectedClientSalesTarget, setSelectedClientSalesTarget] = useState<
    ClientSalesTarget | null | undefined
  >(null);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    if (
      selectedClient &&
      selectedClient !== '' &&
      listClientSalesTarget.length > 0
    ) {
      setSelectedClientSalesTarget(
        listClientSalesTarget.find((client) => client.id === selectedClient),
      );
    }
  }, [selectedClient, listClientSalesTarget]);

  useEffect(() => {
    if (mainSalesTarget?.id) {
      const unsubscribe = salesGoalCollection
        .doc(mainSalesTarget.id)
        .collection('FDV-ClientSalesTarget')
        .onSnapshot((response) => {
          const list: Array<ClientSalesTarget> = [];
          response.docs.forEach((doc) => {
            const documentData = {
              ...doc.data(),
              id: doc.id,
            } as ClientSalesTarget;
            list.push(documentData);
          });
          setListClientSalesTarget(list);
        });
      return () => {
        unsubscribe();
        setTimeout(() => {
          setPending(false);
        }, 400);
      };
    }
    return () => {
      setTimeout(() => {
        setPending(false);
      }, 400);
    };
  }, [code3cData, mainSalesTarget]);

  return {
    listClientSalesTarget,
    selectedClientSalesTarget,
    pending,
  };
};
