// Importações Padrões
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { validateBr, maskBr } from 'js-brasil';

// Icones
import { FaEdit, FaEye, FaToggleOff, FaToggleOn } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import { IInput } from '../../typings/input.types';
import {
  IClient,
  IClientAddress,
  IEditClient,
  initialClient,
  initialClientAddress,
} from '../../typings/client.types';
import { IValidationError } from '../../typings/yup.types';

// Controladores
import { editClient } from '../../controllers/client.controller';

// Componentes
import InputImageForm from '../input/inputImageForm.component';
import InputForm from '../input/inputForm.component';
import InputFormFunction from '../input/inputFormFunction.component';
import InputTextareaComponent from '../input/inputTextarea.component';
import InputDropForm from '../input/inputDropForm.component';
import AddressList from './addressList.component';

// Funções
import { dataSchema, dataSchemaBasic } from '../../functions/client.function';
import { getURLFromDB } from '../../functions/image.function';

interface IEditClientProps {
  visible: IEditClient;
  setVisible: React.Dispatch<React.SetStateAction<IEditClient>>;
}

const EditClient = (props: IEditClientProps) => {
  const { visible, setVisible } = props;
  const { code3cData } = useContext(Auth);

  const initialClientOption = {
    name: 'Cliente Completo',
    code: 'complexClient',
  };

  const [name, setName] = useState<string>('');
  const [socialID, setSocialID] = useState<string>('');
  const [stateSubscription, setStateSubscription] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [addressList, setAddressList] = useState<Array<IClientAddress>>([
    initialClientAddress,
  ]);
  const [note, setNote] = useState<string>('');
  const [imageHeavy, setImageHeavy] = useState<string>('');
  const [imageLight, setImageLight] = useState<string>('');

  const [cpfValid, setCpfValid] = useState(false);
  const [cnpjValid, setCnpjValid] = useState(false);

  const [typeOf, setTypeOf] = useState<IInput>(initialClientOption);

  const typeOfList: Array<IInput> = useMemo(() => {
    return [
      { name: 'Cliente Completo', code: 'complexClient' },
      { name: 'Cliente Orçamento', code: 'basicClient' },
    ];
  }, []);

  const [docType, setDocType] = useState(true);
  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  const editClientCreate = useCallback(() => {
    if (visible.item.socialID && visible.item.socialID.length === 14) {
      setSocialID(maskBr.cnpj(visible.item.socialID));
      setDocType(true);
      setCnpjValid(!validateBr.cnpj(visible.item.socialID));
    }
    if (visible.item.socialID && visible.item.socialID.length === 11) {
      setSocialID(maskBr.cpf(visible.item.socialID));
      setDocType(false);
      setCpfValid(!validateBr.cpf(visible.item.socialID));
    }

    setName(visible.item.name);
    setAddressList(visible.item.address);
    setNote(visible.item.note);
    setStateSubscription(visible.item.stateSubscription);
    setPhone(visible.item.phone);
    setEmail(visible.item.email);
    setImageHeavy(visible.item.imageHeavy);
    setImageLight(visible.item.imageLight);

    const [typeSelected] = typeOfList.filter(
      (system) => system.code === visible.item.typeOf,
    );
    typeSelected &&
      setTypeOf({
        name: typeSelected.name,
        code: visible.item.typeOf,
      });
  }, [visible.item, typeOfList]);

  function editClientDestroy() {
    const emptyString = '';
    setName(emptyString);
    setSocialID(emptyString);
    setStateSubscription(emptyString);
    setPhone(emptyString);
    setEmail(emptyString);
    setImageHeavy(emptyString);
    setImageLight(emptyString);
    setNote(emptyString);
    setCpfValid(false);
    setCnpjValid(false);
    setPendingAction(false);
    setVisible({ ...visible, item: initialClient, visible: false });
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
    setFormErr([]);
    setTypeOf(initialClientOption);
  }

  function changeDocType() {
    setSocialID('');
    setDocType(!docType);
    setCpfValid(false);
    setCnpjValid(false);
  }

  function formatPhone(Phone: string) {
    setPhone(Phone);
    if (validateBr.telefone(Phone) && Phone.length >= 10) {
      setPhone(maskBr.telefone(Phone));
    } else if (!validateBr.telefone(Phone)) {
      setPhone(Phone.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''));
    }
  }

  function formatSocialID(CNPJ_CPF: string) {
    if (docType) {
      setSocialID(CNPJ_CPF);
      if (validateBr.cnpj(CNPJ_CPF) && CNPJ_CPF.length === 14) {
        setSocialID(maskBr.cnpj(CNPJ_CPF));
        setCnpjValid(false);
      } else if (CNPJ_CPF.length === 14) {
        setSocialID(maskBr.cnpj(CNPJ_CPF));
        setCnpjValid(true);
      } else {
        setSocialID(CNPJ_CPF.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''));
        setCnpjValid(false);
      }
    } else {
      setSocialID(CNPJ_CPF);
      if (validateBr.cpf(CNPJ_CPF) && CNPJ_CPF.length === 11) {
        setSocialID(maskBr.cpf(CNPJ_CPF));
        setCpfValid(false);
      } else if (CNPJ_CPF.length === 11) {
        setSocialID(maskBr.cpf(CNPJ_CPF));
        setCpfValid(true);
      } else {
        setSocialID(CNPJ_CPF.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''));
        setCpfValid(false);
      }
    }
  }

  async function handleEditClient() {
    setPendingAction((state) => !state);
    let urlImage = '';
    if (imageHeavy !== '') {
      urlImage = await getURLFromDB(
        true,
        imageHeavy,
        imageLight,
        code3cData?.id ? code3cData.id : '',
        'All-client',
      );
    }
    const dataValidation: Partial<IClient> =
      typeOf.code === 'basicClient'
        ? {
            typeOf: typeOf.code,
            name,
            phone: phone.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''),
            email,
            note,
            address: addressList,
            imageHeavy: urlImage,
            imageLight: urlImage,
          }
        : {
            typeOf: typeOf.code,
            name,
            socialID: socialID.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''),
            stateSubscription,
            phone: phone.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''),
            email,
            note,
            address: addressList,
            imageHeavy: urlImage,
            imageLight: urlImage,
          };

    const letSchema =
      typeOf.code === 'basicClient' ? dataSchemaBasic : dataSchema;

    letSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await editClient(dataValidation, visible.item.id);
        if (response) {
          setFormErr([]);
          editClientDestroy();
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  useEffect(() => {
    editClientCreate();
  }, [editClientCreate]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              {visible.edit ? (
                <FaEdit className="p-mx-auto p-as-center modal-header-icon" />
              ) : (
                <FaEye className="p-mx-auto p-as-center modal-header-icon" />
              )}
            </div>
            <span className="p-my-auto users-button-text">
              {visible.edit ? 'Editar ' : 'Ver '}Cliente
            </span>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label={visible.edit ? 'Salvar' : 'Editar'}
              disabled={pendingAction}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() =>
                visible.edit
                  ? handleEditClient()
                  : setVisible({ ...visible, edit: true })
              }
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => editClientDestroy()}
      baseZIndex={0}
    >
      <InputDropForm
        filter={false}
        filterBy=""
        disabled={!visible.edit}
        item={typeOf}
        setItem={setTypeOf}
        options={typeOfList}
        formErr={formErr}
        fieldLabel="Tipo do Cliente"
        fieldCode="typeOf"
      />
      <InputForm
        pleaceHolder=""
        disabled={!visible.edit}
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome do Cliente"
        fieldCode="name"
      />
      {typeOf.code === 'complexClient' && (
        <>
          <label>
            {docType ? (
              <div className="p-d-flex p-mb-2 p-as-center">
                <FaToggleOff
                  className="p-as-center"
                  style={{ fontSize: '1.8rem', cursor: 'pointer' }}
                  onClick={() => visible.edit && changeDocType()}
                />
                <div className="p-ml-2 p-as-center">CNPJ</div>
              </div>
            ) : (
              <div className="p-d-flex p-mb-2 p-as-center">
                <FaToggleOn
                  style={{ fontSize: '1.8rem', cursor: 'pointer' }}
                  onClick={() => visible.edit && changeDocType()}
                />
                <div className="p-ml-2 p-as-center">CPF</div>
              </div>
            )}
          </label>
          <InputFormFunction
            maxLength={docType ? 14 : 11}
            disabled={!visible.edit}
            item={socialID}
            setFunction={formatSocialID}
            formErr={formErr}
            fieldCode="socialID"
          />
          {cpfValid && (
            <div className="p-d-flex p-mb-3">
              <i className="p-mr-2 pi pi-exclamation-circle input-warn p-as-center" />
              <small className=" input-warn">
                O CPF inserido não é valido!.
              </small>
            </div>
          )}
          {cnpjValid && (
            <div className="p-d-flex p-mb-3">
              <i className="p-mr-2 pi pi-exclamation-circle input-warn p-as-center" />
              <small className=" input-warn">
                O CNPJ inserido não é valido!.
              </small>
            </div>
          )}
          <InputForm
            pleaceHolder=""
            disabled={!visible.edit}
            item={stateSubscription}
            setItem={setStateSubscription}
            formErr={formErr}
            fieldLabel="Inscrição Estadual"
            fieldCode="stateSubscription"
          />
        </>
      )}

      <div className="p-d-flex p-my-2 p-jc-center">
        <small className="input-warn">Informações de Contato.</small>
      </div>
      <div className="p-grid p-mt-2">
        <div className="p-col-12 p-md-6 p-lg-6">
          <label className="p-my-0 p-mt-4">Telefone</label>
          <InputFormFunction
            maxLength={15}
            disabled={!visible.edit}
            item={phone}
            setFunction={formatPhone}
            formErr={formErr}
            fieldCode="phone"
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputForm
            pleaceHolder=""
            disabled={!visible.edit}
            item={email}
            setItem={setEmail}
            formErr={formErr}
            fieldLabel="Email"
            fieldCode="email"
          />
        </div>
      </div>
      <div className="p-d-flex p-my-2 p-jc-center">
        <small className="input-warn">Informações de Endereço.</small>
      </div>
      {addressList.map((address, index) => {
        return (
          <AddressList
            key={String(index)}
            disabled={!visible.edit}
            formErr={formErr}
            address={address}
            addressList={addressList}
            setAddressList={setAddressList}
          />
        );
      })}
      {visible.edit && (
        <Button
          className="modal-save-button"
          label="Adicionar Novo Endereço"
          onClick={() => {
            setAddressList([
              ...addressList,
              { ...initialClientAddress, index: addressList.length + 1 },
            ]);
          }}
        />
      )}
      <div className="p-d-flex p-my-2 p-jc-center">
        <small className="input-warn">Anotações.</small>
      </div>
      <InputTextareaComponent
        disabled={!visible.edit}
        item={note}
        setItem={setNote}
        formErr={formErr}
        fieldLabel="Observações"
        fieldCode={note}
        rows={5}
        cols={30}
      />
      <InputImageForm
        visibleEdit={visible.edit}
        loadingImage={pendingAction}
        item={imageHeavy}
        setItem={setImageHeavy}
        fieldLabel="Imagem do Cliente"
      />
    </Dialog>
  );
};

export default EditClient;
