// Configurações
import consultCepAPI from '../config/api.viaCep1';

async function consultCep(CEP: string) {
  try {
    const response = await consultCepAPI.get(`ws/${CEP}/json/`);
    if (response.data.erro) {
      return {
        cep: CEP,
        uf: 'BA',
        localidade: '',
        bairro: '',
        logradouro: '',
      };
    }
    return response.data;
  } catch (error) {
    return [];
  }
}

export { consultCep };
