import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import '../../styles/global/filter.scss';
import { emptyValidationError } from '../../typings/yup.types';
import InputFormCurrency from '../input/inputFormCurrency.component';

interface IEditListFilterProps {
  displayFilter: boolean;
  onListFilter: boolean;
  onListType: string;
  basePriceFilter: boolean;
  referencePriceFilter: boolean;
  setReferencePriceFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplayFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setOnListFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setOnListType: React.Dispatch<React.SetStateAction<string>>;
  setBasePriceFilter: React.Dispatch<React.SetStateAction<boolean>>;
  baseMinPrice: number;
  setBaseMinPrice: React.Dispatch<React.SetStateAction<number>>;
  baseMaxPrice: number;
  setBaseMaxPrice: React.Dispatch<React.SetStateAction<number>>;
  referenceMinPrice: number;
  setReferenceMinPrice: React.Dispatch<React.SetStateAction<number>>;
  referenceMaxPrice: number;
  setReferenceMaxPrice: React.Dispatch<React.SetStateAction<number>>;
}

const EditListFilter = (props: IEditListFilterProps) => {
  const {
    displayFilter,
    setDisplayFilter,
    onListFilter,
    onListType,
    basePriceFilter,
    referencePriceFilter,
    setReferencePriceFilter,
    setOnListFilter,
    setOnListType,
    setBasePriceFilter,
    baseMinPrice,
    setBaseMinPrice,
    baseMaxPrice,
    setBaseMaxPrice,
    referenceMinPrice,
    setReferenceMinPrice,
    referenceMaxPrice,
    setReferenceMaxPrice,
  } = props;

  function clearFilter() {
    setDisplayFilter(false);
    setOnListFilter(false);
    setBasePriceFilter(false);
    setReferencePriceFilter(false);
    setOnListType('all');
    setBaseMinPrice(0);
    setBaseMaxPrice(0);
    setReferenceMinPrice(0);
    setReferenceMaxPrice(0);
  }

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Limpar"
          onClick={() => clearFilter()}
          className="p-button-text modal-cancel-button"
        />
        <Button
          label="Aplicar"
          onClick={() => setDisplayFilter(false)}
          className="modal-save-button"
          autoFocus
        />
      </div>
    );
  };

  return (
    <Dialog
      header="Adicionar Filtros"
      visible={displayFilter}
      style={{ width: '75vw' }}
      footer={renderFooter()}
      onHide={() => setDisplayFilter(false)}
    >
      {/* Filtro por Status na Lista */}
      <div className="p-grid p-mt-3">
        <Checkbox
          checked={onListFilter}
          onChange={() => setOnListFilter((state) => !state)}
          className={`p-mr-2  ${
            onListFilter ? 'settings-checkbox-selected' : 'settings-checkbox'
          }`}
        />
        Filtrar Por Status na Lista
      </div>
      {onListFilter && (
        <div className="p-grid p-mt-3">
          <div className="p-field-radiobutton p-col-6 p-p-0">
            <RadioButton
              inputId="option1"
              name="option"
              value="all"
              onChange={() => setOnListType('all')}
              checked={onListType === 'all'}
              className={`${
                onListType === 'all'
                  ? 'editList-radio-button-selected'
                  : 'editList-radio-button'
              }`}
            />
            <label htmlFor="option1">Mostrar Todos</label>
          </div>
          <div className="p-field-radiobutton p-col-6 p-p-0">
            <RadioButton
              inputId="option2"
              name="option"
              value="inList"
              checked={onListType === 'inList'}
              onChange={() => setOnListType('inList')}
              className={`${
                onListType === 'inList'
                  ? 'editList-radio-button-selected'
                  : 'editList-radio-button'
              }`}
            />
            <label htmlFor="option2">Já na Lista</label>
          </div>
          {/* <div className="p-field-radiobutton p-col-4 p-p-0">
            <RadioButton
              inputId="option3"
              name="option"
              value="outList"
              onChange={() => setOnListType('outList')}
              checked={onListType === 'outList'}
              className={`${
                onListType === 'outList'
                  ? 'editList-radio-button-selected'
                  : 'editList-radio-button'
              }`}
            />
            <label htmlFor="option3">Fora da Lista</label>
          </div> */}
        </div>
      )}
      {/* Filtro por Preço base */}
      <div className="p-grid p-mt-3">
        <Checkbox
          checked={basePriceFilter}
          onChange={() => setBasePriceFilter((state) => !state)}
          className={`p-mr-2  ${
            basePriceFilter ? 'settings-checkbox-selected' : 'settings-checkbox'
          }`}
        />
        Filtrar Por Preço Base
      </div>
      {basePriceFilter && (
        <div className="p-grid p-mt-4">
          <div className="p-col p-p-0">
            <span className="p-float-label ">
              <InputFormCurrency
                disabled={false}
                item={baseMinPrice}
                setItem={setBaseMinPrice}
                formErr={[emptyValidationError]}
                fieldLabel="Preço Base Mínimo"
                fieldCode=""
                floatLabel
                iconStatus={() => <div />}
              />
            </span>
          </div>
          <div className="p-col p-as-center p-text-center p-p-0 p-mb-3">
            Entre
          </div>
          <div className="p-col p-p-0">
            <span className="p-float-label">
              <InputFormCurrency
                disabled={false}
                item={baseMaxPrice}
                setItem={setBaseMaxPrice}
                formErr={[emptyValidationError]}
                fieldLabel="Preço Base Máximo"
                fieldCode=""
                floatLabel
                iconStatus={() => <div />}
              />
            </span>
          </div>
        </div>
      )}
      {/* Filtro por Preço Referência */}
      <div className="p-grid p-mt-3">
        <Checkbox
          checked={referencePriceFilter}
          onChange={() => setReferencePriceFilter((state) => !state)}
          className={`p-mr-2  ${
            referencePriceFilter
              ? 'settings-checkbox-selected'
              : 'settings-checkbox'
          }`}
        />
        Filtrar Por Preço Referência
      </div>
      {referencePriceFilter && (
        <div className="p-grid p-mt-4">
          <div className="p-col p-p-0">
            <span className="p-float-label ">
              <InputFormCurrency
                disabled={false}
                item={referenceMinPrice}
                setItem={setReferenceMinPrice}
                formErr={[emptyValidationError]}
                fieldLabel="Preço Referência Mínimo"
                fieldCode=""
                floatLabel
                iconStatus={() => <div />}
              />
            </span>
          </div>
          <div className="p-col p-as-center p-text-center p-p-0 p-mb-3">
            Entre
          </div>
          <div className="p-col p-p-0">
            <span className="p-float-label">
              <InputFormCurrency
                disabled={false}
                item={referenceMaxPrice}
                setItem={setReferenceMaxPrice}
                formErr={[emptyValidationError]}
                fieldLabel="Preço Referência Máximo"
                fieldCode=""
                floatLabel
                iconStatus={() => <div />}
              />
            </span>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default EditListFilter;
