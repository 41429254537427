// Importações Padrões
import React from 'react';

// Icones
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';

// React Prime
import { Button } from 'primereact/button';

interface IData {
  id: string;
  name: string;
}
export interface IEdit<Type> {
  item: IData & Type;
  edit: boolean;
  visible: boolean;
}
export interface IDelete {
  id: string;
  name: string;
  visible: boolean;
}

export interface IItem {
  id: string;
  name: string;
}
interface IItemColProps<Type> {
  item: IItem & Type;
  actionEdit: IEdit<Type>;
  setActionEdit: React.Dispatch<React.SetStateAction<IEdit<Type>>>;
  actionDelete: IDelete;
  setActionDelete: React.Dispatch<React.SetStateAction<IDelete>>;
  colGrid: number;
}

function ItemAction<Type>({
  item,
  colGrid,
  actionEdit,
  setActionEdit,
  actionDelete,
  setActionDelete,
}: IItemColProps<Type>) {
  return (
    <div
      className={`p-col-${colGrid} p-p-0 item-box-col p-text-center p-as-center`}
    >
      <Button
        className="p-p-0 p-mr-2 item-see-button"
        onClick={() => {
          setActionEdit({
            item,
            edit: false,
            visible: !actionEdit.visible,
          });
        }}
      >
        <FaEye className="p-mx-auto item-action-button-icon" />
      </Button>
      <Button
        className="p-p-0 p-mr-2 item-edit-button"
        onClick={() => {
          setActionEdit({
            item,
            edit: true,
            visible: !actionEdit.visible,
          });
        }}
      >
        <FaEdit className="p-mx-auto item-action-button-icon" />
      </Button>
      <Button
        className="p-p-0 p-mr-2 item-delete-button"
        onClick={() => {
          setActionDelete({
            id: item.id,
            name: item.name,
            visible: !actionDelete.visible,
          });
        }}
      >
        <FaTrash className="p-mx-auto item-action-button-icon" />
      </Button>
    </div>
  );
}

export default ItemAction;
