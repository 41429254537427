import { useContext, useEffect, useState } from 'react';

import { Auth } from '../../../services/auth.context';

import { IProduct } from '../../../typings/product.types';

import { productCollection } from '../../../controllers/product.controller';

export function useProductList(): [Array<IProduct>, boolean] {
  const { code3cData } = useContext(Auth);

  const [productsList, setProductsList] = useState<Array<IProduct>>([]);
  const [pendingProduct, setPendingProduct] = useState<boolean>(true);

  useEffect(() => {
    setPendingProduct(true);
    const unsubscribe = productCollection
      .where('code3c_id', '==', code3cData?.id)
      .orderBy('productAmount')
      .onSnapshot((response) => {
        const list: Array<IProduct> = [];
        response.docs.forEach((doc) => {
          const documentData = { ...doc.data(), id: doc.id } as IProduct;

          const minAction =
            documentData && documentData.moreInformationList && documentData.moreInformationList.minAmount
              ? documentData.moreInformationList.minAmount
              : documentData.productAmount - 1;

          if (documentData.productAmount <= minAction) {
            list.push(documentData);
          }
        });
        setProductsList(list);
        setPendingProduct(false);
      });

    return () => {
      unsubscribe();
    };
  }, [code3cData?.id]);

  return [productsList, pendingProduct];
}
