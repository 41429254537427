function parseProvinceID(provinceId: string) {
  switch (provinceId) {
    case '01':
      return 'AC';
    case '02':
      return 'AL';
    case '03':
      return 'AP';
    case '04':
      return 'AM';
    case '05':
      return 'BA';
    case '06':
      return 'CE';
    case '07':
      return 'DF';
    case '08':
      return 'ES';
    case '09':
      return 'GO';
    case '10':
      return 'MA';
    case '11':
      return 'MT';
    case '12':
      return 'MS';
    case '13':
      return 'MG';
    case '14':
      return 'PA';
    case '15':
      return 'PB';
    case '16':
      return 'PR';
    case '17':
      return 'PE';
    case '18':
      return 'PI';
    case '19':
      return 'RJ';
    case '20':
      return 'RN';
    case '21':
      return 'RS';
    case '22':
      return 'RO';
    case '23':
      return 'RR';
    case '24':
      return 'SC';
    case '25':
      return 'SP';
    case '26':
      return 'SE';
    case '27':
      return 'TO';

    default:
      return '';
  }
}

export { parseProvinceID };
