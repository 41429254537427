import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

export const createClientSalesTargetSchema = yup.object().shape({
  potential: yup.number().min(1).label('Potencial').required(),
  percentage: yup.number().min(1).max(100).label('Porcentagem').required(),
  seller_id: yup.string().label('Vendedor').required(),
  note: yup.string().max(500).label('Descrição'),
  finished: yup.boolean().label('Finalizado').required(),
});
