// React Prime
import { Dropdown } from 'primereact/dropdown';

// Types
import { ISystemFunction } from '../../typings/users.types';
import { IValidationError } from '../../typings/yup.types';

// Componentes
import { catchErrors, catchInvalid } from '../global/formErrors.component';

interface IInputDropFormProps {
  disabled: boolean;
  item: ISystemFunction;
  // eslint-disable-next-line
  setItem: React.Dispatch<React.SetStateAction<ISystemFunction>> | any;
  options: Array<ISystemFunction>;
  formErr: Array<IValidationError>;
  fieldLabel: string;
  fieldCode: string;
  filter: boolean;
  filterBy: string;
}

const InputDropForm = (props: IInputDropFormProps) => {
  const {
    disabled,
    item,
    setItem,
    options,
    formErr,
    fieldLabel,
    fieldCode,
    filter,
    filterBy,
  } = props;
  return (
    <div className="p-field">
      <label className="p-my-0 p-mt-4">{fieldLabel}</label>
      <span className="p-d-flex p-input-icon-right">
        {catchInvalid(formErr, fieldCode) && (
          <i className="pi pi-times input-invalid" />
        )}
        <Dropdown
          disabled={disabled}
          className={
            catchInvalid(formErr, fieldCode)
              ? 'settings-input-invalid settings-dropdown-invalid'
              : 'settings-input settings-dropdown'
          }
          filterInputAutoFocus={false}
          value={item}
          options={options}
          onChange={(e) => {
            setItem(e.value);
          }}
          optionLabel="name"
          filter={filter}
          filterBy={filterBy}
          placeholder="Selecionar..."
        />
      </span>
      {catchErrors(formErr, fieldCode)}
    </div>
  );
};

export default InputDropForm;
