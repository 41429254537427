export interface IClient {
  id: string;
  name: string;
  systemId: string;
  socialID: string;
  stateSubscription: string;
  phone: string;
  email: string;
  typeOf: string;
  note: string;
  address: Array<IClientAddress>;
  code3c_id: string;
  imageLight: string;
  imageHeavy: string;
}

export interface IClientAddress {
  index: number;
  postalCode: string;
  province: string;
  city: string;
  neighborhood: string;
  street: string;
  houseNumber: string;
}

export interface IEditClient {
  item: IClient;
  edit: boolean;
  visible: boolean;
}

export interface IDeleteClient {
  id: string;
  name: string;
  visible: boolean;
}

export const initialClientAddress = {
  index: 1,
  postalCode: '',
  province: '',
  city: '',
  neighborhood: '',
  street: '',
  houseNumber: '',
};

export const initialClient = {
  id: '',
  systemId: '',
  name: '',
  socialID: '',
  stateSubscription: '',
  phone: '',
  email: '',
  note: '',
  address: [initialClientAddress],
  typeOf: '',
  code3c_id: '',
  imageLight: '',
  imageHeavy: '',
};
