import moment from 'moment';
import {
  FaBookReader,
  FaBox,
  FaBoxOpen,
  FaCheck,
  FaCheckDouble,
  FaRegTimesCircle,
  FaTruck,
} from 'react-icons/fa';
import { ITotalOrders } from '../../../typings/order.types';

export interface AbstractOrdersProps {
  totalOrdersList: ITotalOrders;
}

export const AbstractOrders = (props: AbstractOrdersProps) => {
  const { totalOrdersList } = props;
  return (
    <div
      id="map"
      className="width100"
      style={{ background: '#ffffff', border: '1px solid #EEEEEE' }}
    >
      <div className="p-px-3 p-py-3 dashboard-properties-title">
        Resumo de Pedidos de <b>{moment().year()}</b>
      </div>
      <div className="p-px-3">
        <div className="p-p-0 p-m-0 dashboard-box-graphics">
          <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
            <div className="p-col-10 p-as-center dashboard-box-order-status">
              <FaBookReader className="p-mr-2" />
              TODOS OS PEDIDOS
            </div>
            <div className="p-col-2 p-text-center p-as-center dashboard-box-order-numbers">
              {totalOrdersList.totalOrders}
            </div>
          </div>

          <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
            <div className="p-col-10 p-as-center dashboard-box-order-status">
              <FaCheck className="p-mr-2" />
              PEDIDOS CRIADOS
            </div>
            <div className="p-col-2 p-text-center p-as-center dashboard-box-order-numbers">
              {totalOrdersList.totalCreated}
            </div>
          </div>
          <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
            <div className="p-col-10 p-as-center dashboard-box-order-status">
              <FaBoxOpen className="p-mr-2" />
              PEDIDOS PROCESSANDO
            </div>
            <div className="p-col-2 p-text-center p-as-center dashboard-box-order-numbers">
              {totalOrdersList.totalProcessing}
            </div>
          </div>
          <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
            <div className="p-col-10 p-as-center dashboard-box-order-status">
              <FaBox className="p-mr-2" />
              PEDIDOS PROCESSADOS
            </div>
            <div className="p-col-2 p-text-center p-as-center dashboard-box-order-numbers">
              {totalOrdersList.totalProcessed}
            </div>
          </div>
          <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
            <div className="p-col-10 p-as-center dashboard-box-order-status">
              <FaTruck className="p-mr-2" />
              PEDIDOS EXPEDIDOS
            </div>
            <div className="p-col-2 p-text-center p-as-center dashboard-box-order-numbers">
              {totalOrdersList.totalSended}
            </div>
          </div>
          <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
            <div className="p-col-10 p-as-center dashboard-box-order-status">
              <FaCheckDouble className="p-mr-2" />
              PEDIDOS FINALIZADOS
            </div>
            <div className="p-col-2 p-text-center p-as-center dashboard-box-order-numbers">
              {totalOrdersList.totalFinished}
            </div>
          </div>
          <div className="p-col-12 p-grid p-p-0 p-mx-0 p-my-auto dashboard-box-order">
            <div className="p-col-10 p-as-center dashboard-box-order-status">
              <FaRegTimesCircle className="p-mr-2" />
              PEDIDOS CANCELADOS
            </div>
            <div className="p-col-2 p-text-center p-as-center dashboard-box-order-numbers">
              {totalOrdersList.totalCancelled}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AbstractOrders;
