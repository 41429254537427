// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollTop } from 'primereact/scrolltop';
import * as yup from 'yup';

// Icones
import { FaUserCog } from 'react-icons/fa';

// React Prime
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useUserslist } from '../hooks/useUsersList';

// Types
import { IUser } from '../typings/users.types';
import { IValidationError } from '../typings/yup.types';

// Componentes
import InputForm from '../components/input/inputForm.component';
import InputPassForm from '../components/input/inputPassForm.component';

// Funções
import {
  parseUserFunction,
  dataSettingsSchema,
  dataSettingsWithPass,
} from '../functions/user.function';

// Estilos
import '../styles/global/settings.scss';

const Settings: React.FC = () => {
  const { usersList } = useUserslist();
  const {
    systemData,
    userData,
    setPathName,
    handleSettingUser,
    windowWidth,
  } = useContext(Auth);

  const [changePassword, setChangePassword] = useState(false);

  const [name, setName] = useState(
    userData && userData.name ? userData.name : '',
  );
  const [email, setEmail] = useState(
    userData && userData.email ? userData.email : '',
  );
  const [password, setPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState<string>('');

  const [seePass, setSeePass] = useState(false);

  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);
  const [emailErr, setEmailErr] = useState<boolean>(false);

  const [pendingAction, setPendingAction] = useState<boolean>(false);

  const location = useLocation();

  async function editUser() {
    setPendingAction(true);
    let dataValidation: Partial<IUser> = { name, email };
    let dataPassSchema: yup.AnySchema = dataSettingsSchema;

    if (
      usersList.filter(
        (user) =>
          user.id === userData?.id &&
          name === user.name &&
          email === user.email,
      ).length >= 1 &&
      !changePassword
    ) {
      setPendingAction(false);
      return;
    }

    if (changePassword) {
      dataValidation = { name, email, password, passwordValidation };
      dataPassSchema = dataSettingsWithPass;
    }
    dataPassSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        if (handleSettingUser) {
          const response = await handleSettingUser(dataValidation);
          if (response) {
            setPendingAction((state) => !state);
          } else {
            setPendingAction(false);
          }
        }
        setFormErr([]);
      })
      .catch((error: IValidationError) => {
        setPendingAction(false);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  useEffect(() => {
    setPathName(location.pathname);
    if (
      usersList.filter(
        (user) => user.email === email && user.id !== userData?.id,
      ).length >= 1
    ) {
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }
  }, [email, userData?.id, usersList, location, setPathName]);

  return (
    <div>
      <ScrollTop className="button-scroll-top" />
      <div className="p-py-4 p-px-3">
        <div className="p-p-3  p-text-justify dashboard-display white">
          {windowWidth && windowWidth > 0 && windowWidth <= 400 ? (
            <div className="p-d-flex p-mt-0 p-mb-2">
              <div className="p-d-flex p-my-0 p-mr-2 settings-icon-background">
                <FaUserCog className="p-my-auto p-mx-auto settings-icon " />
              </div>
              <div className="p-d-flex settings-title">
                Informações de <br /> usuário
              </div>
            </div>
          ) : (
            <div className="p-d-flex p-mt-0 p-mb-2">
              <div className="p-d-flex p-my-0 p-mr-2 settings-icon-background">
                <FaUserCog className="p-my-auto p-mx-auto settings-icon " />
              </div>
              <span className="p-my-auto settings-title">
                Informações de usuário
              </span>
            </div>
          )}

          <InputForm
            pleaceHolder=""
            disabled={false}
            item={name}
            setItem={setName}
            formErr={formErr}
            fieldLabel="Nome do usuário"
            fieldCode="name"
          />
          <InputForm
            pleaceHolder=""
            disabled={false}
            item={email}
            setItem={setEmail}
            formErr={formErr}
            fieldLabel="Email do usuário"
            fieldCode="email"
          />

          <div>
            <p className="p-mb-0 p-mt-2">Tipo do usuário</p>
            <InputText
              disabled
              className="settings-input"
              defaultValue={parseUserFunction(systemData?.function)}
            />
          </div>
          <div className=" p-mb-1 p-mt-3 p-d-flex p-flex-md-row">
            <Checkbox
              checked={changePassword}
              onChange={() => setChangePassword(!changePassword)}
              className={
                changePassword
                  ? 'settings-checkbox-selected'
                  : 'settings-checkbox'
              }
            />
            <p className="p-pl-2 p-mt-0">Alterar Senha</p>
          </div>
          {changePassword && (
            <>
              <InputPassForm
                disabled={false}
                item={password}
                setItem={setPassword}
                formErr={formErr}
                fieldLabel="Senha do usuário"
                fieldCode="password"
                peekPass={seePass}
                setPeekPass={setSeePass}
              />
              <InputPassForm
                disabled={false}
                item={passwordValidation}
                setItem={setPasswordValidation}
                formErr={formErr}
                fieldLabel="Confirmação de Senha do usuário"
                fieldCode="passwordValidation"
                peekPass={seePass}
                setPeekPass={setSeePass}
              />
            </>
          )}
          <div className="p-d-flex p-jc-end">
            <Button
              disabled={pendingAction || emailErr}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              className="p-mt-3 settings-save-button"
              label="Salvar"
              aria-hidden="true"
              onPointerDown={(e) => {
                e.buttons === 1 &&
                  confirmDialog({
                    message: !changePassword ? (
                      <p>
                        Você tem certeza que deseja salvar as configurações?
                      </p>
                    ) : (
                      <p className="p-m-0">
                        <div className="p-m-0">
                          Você tem certeza que deseja salvar as configurações?
                        </div>
                        <br />
                        <div className="p-m-0 alert-password">
                          Voce será deslogado!
                        </div>
                      </p>
                    ),
                    icon: 'pi pi-save',
                    acceptClassName: 'accept-button',
                    rejectClassName: 'reject-button',
                    rejectLabel: 'Voltar',
                    acceptLabel: 'Salvar',
                    accept: () => {
                      editUser();
                    },
                  });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
