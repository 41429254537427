// Importações Padrões
import React, { useContext } from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';

// Contexto
import { Auth } from '../services/auth.context';

interface IPrivateRoutes extends RouteProps {
  component: React.FC<RouteComponentProps>;
}

const PrivateRoutes: React.FC<IPrivateRoutes> = ({
  component: RouteComponent,
  ...rest
}) => {
  const { currentUser } = useContext(Auth);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!currentUser ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoutes;
