// Importações Padrões
import React from 'react';

// React Prime
import { InputText } from 'primereact/inputtext';

// Types
import { IValidationError } from '../../typings/yup.types';

// Componentes
import { catchErrors, catchInvalid } from '../global/formErrors.component';

interface IInputFormProps {
  disabled: boolean;
  item: number;
  setItem: React.Dispatch<React.SetStateAction<number>>;
  formErr: Array<IValidationError>;
  fieldLabel: string;
  fieldCode: string;
}

const InputFormNumber = (props: IInputFormProps) => {
  const { disabled, item, setItem, formErr, fieldLabel, fieldCode } = props;
  return (
    <div className="p-field">
      <label className="p-my-0 p-mt-4">{fieldLabel}</label>
      <span className="p-d-flex p-input-icon-right">
        {catchInvalid(formErr, fieldCode) && (
          <i className="pi pi-times input-invalid" />
        )}
        <InputText
          disabled={disabled}
          type="number"
          step=".1"
          className={
            catchInvalid(formErr, fieldCode)
              ? 'settings-input-invalid'
              : 'settings-input'
          }
          value={item}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setItem(parseFloat(event.target.value));
          }}
        />
      </span>
      {catchErrors(formErr, fieldCode)}
    </div>
  );
};

export default InputFormNumber;
