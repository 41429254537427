// Importações Padrões
import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

const dataSchema = yup.object().shape({
  code3c_id: yup.string(),
  name: yup.string().label('Nome').required(),
  socialID: yup
    .string()
    .label('CNPJ')
    .when(
      '$docType',
      (docType: yup.DateSchema, dataSchemaValidation: yup.StringSchema) => {
        if (docType) {
          return dataSchemaValidation.label('CNPJ').min(14);
        }
        return dataSchemaValidation.label('CPF').min(11);
      },
    ),
  address: yup.array(
    yup.object({
      postalCode: yup.string().min(7).max(10).label('CEP'),
      province: yup.string().label('Estado').required(),
      city: yup.string().label('Cidade').required(),
      neighborhood: yup.string().label('Bairro').required(),
      street: yup.string().label('Rua').required(),
      houseNumber: yup.string().label('Numero').required(),
    }),
  ),
});

const dataSchemaBasic = yup.object().shape({
  code3c_id: yup.string(),
  name: yup.string().label('Nome').required(),
  phone: yup.string().label('Telefone'),
  email: yup.string().email().label('Email'),
  address: yup.array(
    yup.object({
      postalCode: yup.string().min(7).max(10).label('CEP'),
      province: yup.string().label('Estado').required(),
      city: yup.string().label('Cidade').required(),
      neighborhood: yup.string().label('Bairro').required(),
      street: yup.string().label('Rua').required(),
      houseNumber: yup.string().label('Numero').required(),
    }),
  ),
});

export { dataSchema, dataSchemaBasic };
