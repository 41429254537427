// Importações Padrões
import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

import { addLocale } from 'primereact/api';

yup.setLocale(pt);

export const dataSchema = yup.object().shape({
  code3c_id: yup.string(),
  client_id: yup.string().label('Cliente').required(),
  seller_id: yup.string().label('Vendedor').required(),
});

export default addLocale('pt', {
  closeText: 'Fechar',
  prevText: 'Anterior',
  nextText: 'Próximo',
  currentText: 'Começo',
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthNamesShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
  dayNames: [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  weekHeader: 'Semana',
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: '',
  timeOnlyTitle: 'Só Horas',
  timeText: 'Tempo',
  hourText: 'Hora',
  minuteText: 'Minuto',
  secondText: 'Segundo',
  ampm: false,
  month: 'Mês',
  week: 'Semana',
  day: 'Dia',
  allDayText: 'Todo Dia',
  today: 'Hoje',
  clear: 'Limpar',
});
