// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollTop } from 'primereact/scrolltop';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useProductList } from '../hooks/useProductsList';

// Types
import {
  IEditAmount,
  IProduct,
  IDeleteProduct,
  IEditProduct,
  initialProduct,
  IExportProducts,
} from '../typings/product.types';

// Componentes
import ItemProduct from '../components/products/itemProduct.component';
import EditAmount from '../components/products/editAmount.component';
import AddProduct from '../components/products/addProduct.component';
import EditProduct from '../components/products/editProduct.component';
import DeleteProduct from '../components/products/deleteProduct.component';
import NavProduct from '../components/products/navProduct.component';
import ExportProducts from '../components/products/exportProducts.component';

const Products: React.FC = () => {
  const { setPathName, windowWidth } = useContext(Auth);
  const [productsList, pending] = useProductList();

  const [inputSearch, setInputSearch] = useState('');

  const [showHideAddProduct, setShowHideAddProduct] = useState(false);
  const [showHideEditProduct, setShowHideEditProduct] = useState<IEditProduct>({
    item: initialProduct,
    edit: false,
    visible: false,
  });
  const [showHideDeleteProduct, setShowHideDeleteProduct] =
    useState<IDeleteProduct>({ id: '', name: '', visible: false });

  const [showHideEditAmount, setShowHideEditAmount] = useState<IEditAmount>({
    item: initialProduct,
    visible: false,
  });

  const [showHideExportProducts, setShowHideExportProducts] =
    useState<IExportProducts>({
      item: [],
      visible: false,
    });

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  useEffect(() => {
    if (
      showHideAddProduct ||
      showHideEditProduct.visible ||
      showHideDeleteProduct.visible ||
      showHideEditAmount.visible ||
      showHideExportProducts.visible
    ) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [
    showHideAddProduct,
    showHideEditProduct,
    showHideDeleteProduct,
    showHideEditAmount,
    showHideExportProducts,
  ]);

  return (
    <div className="p-py-3 p-px-3">
      <ScrollTop className="button-scroll-top" />
      <ExportProducts
        exports={showHideExportProducts}
        setExports={setShowHideExportProducts}
      />
      <EditAmount
        visible={showHideEditAmount}
        setVisible={setShowHideEditAmount}
      />
      <AddProduct
        visible={showHideAddProduct}
        setVisible={setShowHideAddProduct}
      />
      <EditProduct
        visible={showHideEditProduct}
        setVisible={setShowHideEditProduct}
      />
      <DeleteProduct
        visible={showHideDeleteProduct}
        setVisible={setShowHideDeleteProduct}
      />
      <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
        <NavProduct
          placeHolder="Pesquisar produtos..."
          pending={!pending}
          addFieldName="Novo Produto"
          actionAdd={showHideAddProduct}
          setActionAdd={setShowHideAddProduct}
          actionExport={showHideExportProducts}
          setActionExport={setShowHideExportProducts}
          setInputSearch={setInputSearch}
          productsArray={productsList
            .filter(
              (item) =>
                item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                  -1 ||
                item.barCode
                  .toUpperCase()
                  .indexOf(inputSearch.toUpperCase()) !== -1,
            )
            .map((product: IProduct) => {
              return product;
            })}
          activeAdd
        />
        <div className="p-d-flex p-dir-col p-mt-2 p-mb-auto width100">
          {windowWidth && windowWidth > 900 && (
            <div
              className="p-grid p-my-1 p-px-3 p-mx-auto p-d-none p-d-md-inline-flex default-box-header"
              style={{
                backgroundColor: '#f8f9fa',
                border: '1px solid #e9ecef',
              }}
            >
              <div className="p-col-1 p-text-center p-as-center">Id</div>
              <div className="p-col-2 p-text-center p-as-center">Imagem</div>
              <div className="p-col-3 p-text-center p-as-center">Produto</div>
              <div className="p-col-2 p-text-center p-as-center">EAN</div>
              <div className="p-col-2 p-text-center p-as-center">Estoque</div>
              <div className="p-col-2 p-text-center p-as-center">Ação</div>
            </div>
          )}
          {productsList
            .filter(
              (item) =>
                item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                  -1 ||
                item.barCode
                  .toUpperCase()
                  .indexOf(inputSearch.toUpperCase()) !== -1,
            )
            .map((product: IProduct, index: number) => {
              return (
                <ItemProduct
                  key={String(index)}
                  index={index + 1}
                  product={product}
                  showHideEditProduct={showHideEditProduct}
                  setShowHideEditProduct={setShowHideEditProduct}
                  showHideDeleteProduct={showHideDeleteProduct}
                  setShowHideDeleteProduct={setShowHideDeleteProduct}
                  showHideEditAmount={showHideEditAmount}
                  setShowHideEditAmount={setShowHideEditAmount}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Products;
