// Funções
import {
  generatorImageURL,
  generatorProductBase,
} from '../../functions/image.function';

interface IItemColProps extends Partial<ITeste> {
  imageDefault: string;
  content: string;
  colGrid: number;
}

interface ITeste {
  useProductBase: boolean;
}

const ItemCol = (props: IItemColProps) => {
  const { colGrid, content, imageDefault, useProductBase } = props;
  return (
    <div
      className={`p-col-${colGrid} p-p-0 item-box-col p-text-center p-as-center`}
    >
      {content === '' ? (
        <img className="item-box-image" alt="" src={imageDefault} />
      ) : (
        <>
          {useProductBase ? (
            <div className="item-spacing-image p-mx-auto p-d-flex">
              <img
                className="item-box-image p-mx-auto p-as-center"
                alt=""
                src={generatorProductBase(content)}
              />
            </div>
          ) : (
            <div className="item-spacing-image p-mx-auto p-d-flex">
              <img
                className="item-box-image p-mx-auto p-as-center"
                alt=""
                src={generatorImageURL(content)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ItemCol;
