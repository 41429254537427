import { useCallback, useContext, useState } from 'react';
import { Button } from 'primereact/button';

import {
  BasicDropDown,
  Option,
} from '../../../components/dropdown/basic-dropdown/BasicDropDown';

import { SalesTarget } from '../../../typings/salesTarget';

import { SellerSalesGoal } from '../contexts/SalesTargetContextInterface';
import { SalesTargetProvider } from '../contexts/SalesTargetContextt';
import { SelectedSellerHeader } from './SelectedSellerHeader';
import { SellerClientsTable } from './SellerClientsTable';

import './SelectedClientTarget.scss';

export type SelectedSellerView = 'CLIENTS' | 'CULTIVATIONS';

export interface SelectedSellerTargetProps {
  mainSalesTarget: SalesTarget | null | undefined;
}

export const SelectedSellerTarget = (props: SelectedSellerTargetProps) => {
  const { selectedClientSalesTarget, selectedSeller, setToggleView } =
    useContext(SalesTargetProvider);
  const { mainSalesTarget } = props;

  const [selectedOption, setSelectedOption] = useState<Option>({
    name: 'Clientes',
    code: 'CLIENTS',
  });

  const options = [{ name: 'Clientes', code: 'CLIENTS' }];

  const handleToggleSelectedClientView = (value: Option) => {
    setSelectedOption(value);
  };

  const toggleSellerView = (clientView: SelectedSellerView) => {
    switch (clientView) {
      case 'CLIENTS': {
        return <SellerClientsTable clients={selectedSeller!.clients} />;
      }
    }
  };

  const renderClient = useCallback(
    (seller: SellerSalesGoal | null) => {
      if (seller) {
        return (
          <div className="selected-client-target">
            <div className="header">
              <Button
                className="button"
                onClick={() => {
                  if (setToggleView) {
                    setToggleView('SELLERS');
                  }
                }}
              >
                Voltar
              </Button>
            </div>
            <div className="info">
              <SelectedSellerHeader />
              <div className="menu">
                <BasicDropDown
                  selectedOption={selectedOption}
                  options={options}
                  action={handleToggleSelectedClientView}
                  placeHolder="Visualizar..."
                  className="dropdown"
                />
              </div>
              {toggleSellerView(selectedOption.code as SelectedSellerView)}
            </div>
          </div>
        );
      }
      return <div>Sem Dados</div>;
    },
    [selectedClientSalesTarget, selectedOption, mainSalesTarget],
  );

  return renderClient(selectedSeller!);
};
