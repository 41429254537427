import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

import { IList, IEditList, IDeleteList } from '../../typings/list.types';

import ItemCol from '../defaultBox/itemCol.component';
import ItemAction, { IEdit } from '../defaultBox/itemAction.component';
import { Auth } from '../../services/auth.context';
import ResponsiveItemAction from '../defaultBox/responsiveItemAction.component';

interface IITemMenu {
  list: IList;
  index: number;
  showHideEditList: IEditList;
  setShowHideEditList: React.Dispatch<React.SetStateAction<IEdit<IList>>>;
  showHideDeleteList: IDeleteList;
  setShowHideDeleteList: React.Dispatch<React.SetStateAction<IDeleteList>>;
}

const ItemList = ({
  list,
  index,
  showHideEditList,
  setShowHideEditList,
  showHideDeleteList,
  setShowHideDeleteList,
}: IITemMenu) => {
  const { windowWidth } = useContext(Auth);

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-mt-1 p-mx-auto p-p-3 p-grid item-box width100">
          <p className="p-col-9 width100 p-d-inline p-my-0 p-p-0 p-mr-0">
            #{index}
          </p>
          <ItemCol colGrid={5} field="Nome" content={list.name} />

          <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center">
            <Link
              to={`/lista-selecionada/${list.id}`}
              className="p-mx-auto item-menu-action"
            >
              <Button
                className="p-mx-auto p-as-center p-jc-center item-menu-list-responsive p-d-flex"
                label="Ver Lista"
              />
            </Link>
          </div>

          <ResponsiveItemAction
            item={list}
            actionEdit={showHideEditList}
            setActionEdit={setShowHideEditList}
            actionDelete={showHideDeleteList}
            setActionDelete={setShowHideDeleteList}
          />
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div
          className="p-mt-1 p-mx-auto p-p-3 p-grid item-box width100"
          style={{
            border: '1px solid #e9ecef',
            borderWidth: '0 0 1px 0',
            backgroundColor: 'white',
          }}
        >
          <ItemCol colGrid={1} field="Id" content={String(index)} />
          <ItemCol colGrid={5} field="Nome" content={list.name} />

          <div className="p-col-3 p-d-flex">
            <Link
              to={`/lista-selecionada/${list.id}`}
              className="p-mx-auto item-menu-list"
            >
              <Button className="p-mx-auto item-menu-list" label="Ver Lista" />
            </Link>
          </div>
          <ItemAction
            item={list}
            actionEdit={showHideEditList}
            setActionEdit={setShowHideEditList}
            actionDelete={showHideDeleteList}
            setActionDelete={setShowHideDeleteList}
            colGrid={3}
          />
        </div>
      );
    }
    return <div />;
  }

  return changeBreakpoints();
};

export default ItemList;
