import { useEffect, useState } from 'react';

import moment from 'moment';
import 'moment/locale/pt-br';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import {
  ColumnFilterApplyTemplateOptions,
  ColumnFilterClearTemplateOptions,
} from 'primereact/column';

import { IVisit } from '../../../typings/visit.types';

import './RecentVisits.scss';
import { Link } from 'react-router-dom';

export interface RecentVisitsProps {
  visitList: Array<IVisit>;
}

export const RecentVisits = (props: RecentVisitsProps) => {
  const { visitList } = props;

  const [visits, setVisits] = useState<Array<IVisit>>([]);

  useEffect(() => {
    setVisits(visitList);
  }, [visitList]);

  const statusBodyTemplate = () => {
    return <span className="order-badge-order-pending p-p-1">Pendente</span>;
  };

  const actionBodyTemplate = () => {
    return (
      <div>
        <Link to={`/agenda`} className="p-mx-auto item-menu-action">
          <Button
            type="button"
            icon="pi pi-search"
            className="p-button-success"
          />
        </Link>
      </div>
    );
  };

  const filterApplyTemplate = (options: ColumnFilterApplyTemplateOptions) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={options.filterApplyCallback}
        className="filter-apply-template"
      ></Button>
    );
  };

  const filterClearTemplate = (options: ColumnFilterClearTemplateOptions) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={options.filterClearCallback}
        className="filter-clear-template"
      ></Button>
    );
  };

  return (
    <div
      id="map"
      className="width100"
      style={{
        background: '#ffffff',
        border: '1px solid #EEEEEE',
        height: '25.1rem',
      }}
    >
      <div className="p-px-3 p-py-3 dashboard-properties-title">
        Visitas em Aberto
      </div>
      <div className="p-px-3" style={{ height: '20rem', overflow: 'auto' }}>
        <DataTable
          value={visits}
          stripedRows
          showGridlines
          rowHover
          removableSort
          emptyMessage="Nenhuma Pedido"
          sortField="name"
          sortOrder={1}
        >
          <Column
            field="visit"
            header="Visitante"
            sortable
            body={(rowData) => {
              return <b>{rowData.seller_name}</b>;
            }}
            filter
            filterPlaceholder="Pesquisar Visitante"
            filterClear={filterClearTemplate}
            filterApply={filterApplyTemplate}
            filterMatchMode="contains"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            maxConstraints={1}
          />
          <Column
            field="date"
            header="Data"
            sortable
            body={(rowData) => {
              return <span>{moment(rowData.startDate).format('L')}</span>;
            }}
          />

          <Column field="status" header="Status" body={statusBodyTemplate} />
          <Column field="action" header="Ação" body={actionBodyTemplate} />
        </DataTable>
      </div>
    </div>
  );
};
export default RecentVisits;
