// Configurações
import consultIbgeAPI from '../config/api.ibge';

interface ICitiesIbgeGet {
  id: number;
  nome: string;
}

export interface ICitiesIbge {
  name: string;
  code: string;
}

async function searchCitiesByUF(UF: string) {
  try {
    const response = await consultIbgeAPI.get(
      `localidades/estados/${UF}/municipios`,
    );
    if (response) {
      return response.data.map((city: ICitiesIbgeGet) => {
        return {
          name: city.nome,
          code: String(city.id),
        } as ICitiesIbge;
      });
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export { searchCitiesByUF };
