// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Contexto
import { Auth } from '../services/auth.context';

// Types
import { IVisit } from '../typings/visit.types';

// Controladores
import { visitCollection } from '../controllers/visit.controller';

export function useVisitsList(): [Array<IVisit>, boolean, Array<IVisit>] {
  const { code3cData } = useContext(Auth);

  const [visitsList, setVisitsList] = useState<Array<IVisit>>([]);
  const [pending, setPending] = useState<boolean>(true);
  const [pendingVisitsList, setPendingVisitsList] = useState<Array<IVisit>>([]);

  useEffect(() => {
    setPending((state) => !state);
    const unsubscribe = visitCollection
      .where('code3c_id', '==', code3cData?.id)
      .onSnapshot((response) => {
        const list: Array<IVisit> = [];
        const pendingList: Array<IVisit> = [];

        response.docs.forEach((doc) => {
          const newDocument = doc.data();
          const documentData = {
            ...doc.data(),
            id: doc.id,
            startDate: newDocument.startDate.toDate(),
          } as IVisit;
          list.push(documentData);
          //  Falta especificar os cancelados -> && !documentData.cancelled
          if (!documentData.visitEnd && !documentData.cancelled) {
            pendingList.push(documentData);
          }
        });
        setVisitsList(list);
        setPendingVisitsList(pendingList);
      });
    setPending(false);

    return () => {
      unsubscribe();
      setPending(false);
    };
  }, [code3cData?.id]);

  return [visitsList, pending, pendingVisitsList];
}
