export interface IInput {
  name: string;
  code: string;
  imageHeavy?: string;
  imageLight?: string;
}

export const initialInputOptions = {
  name: '',
  code: '',
  imageHeavy: '',
  imageLight: '',
};
