import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { formatCurrency } from '../../../functions/list.function';

import { ClientSalesGoal } from '../contexts/SalesTargetContextInterface';

import './ClientPropertiesTable.scss';
import {
  feedbackBodyTemplate,
  parseNotRealized,
  parseRealized,
  parseStatus,
  progressBodyTemplate,
  statusBodyTemplate,
} from './DataTableBody';
import { filterApplyTemplate, filterClearTemplate } from './DataTableFilters';

export interface SellerClientsTableProps {
  clients: Array<ClientSalesGoal>;
}
export const SellerClientsTable = (props: SellerClientsTableProps) => {
  const { clients } = props;

  return (
    <DataTable
      value={clients}
      stripedRows
      showGridlines
      rowHover
      removableSort
      emptyMessage="Nenhuma Propriedade"
      sortField="name"
      sortOrder={1}
    >
      <Column
        field="name"
        header="Nome"
        sortable
        body={(rowData) => {
          return <b>{rowData.name}</b>;
        }}
        filter
        filterPlaceholder="Pesquisar Nome"
        filterClear={filterClearTemplate}
        filterApply={filterApplyTemplate}
        filterMatchMode="contains"
        showFilterMatchModes={false}
        showFilterMenuOptions={false}
        maxConstraints={1}
      />
      <Column
        field="area"
        header="Área em Safra"
        sortable
        body={(rowData) => {
          return <span>{rowData.area}ha</span>;
        }}
      />
      <Column
        field="potential"
        header="Potencial"
        sortable
        body={(rowData) => {
          return <span>{formatCurrency('USD', rowData.potential)}</span>;
        }}
      />
      <Column
        field="goal"
        header="Meta"
        sortable
        body={(rowData) => {
          return <span>{formatCurrency('USD', rowData.goal)}</span>;
        }}
      />
      <Column
        field="realized"
        header="Realizado"
        sortable
        body={(rowData) => {
          return feedbackBodyTemplate(
            parseRealized(rowData.realized, rowData.goal),
          );
        }}
      />
      <Column
        field="notRealized"
        header="Não Realizado"
        sortable
        body={(rowData) => {
          return feedbackBodyTemplate(
            parseNotRealized(rowData.notRealized, rowData.goal),
          );
        }}
      />
      <Column
        field="status"
        header="Status"
        sortable
        body={(rowData) => {
          return statusBodyTemplate(parseStatus(rowData.status));
        }}
      />
      <Column
        field="progress"
        header="Progresso"
        sortable
        body={(rowData) => {
          return progressBodyTemplate(rowData.progress);
        }}
      />
    </DataTable>
  );
};
