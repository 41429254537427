import * as React from 'react';
import { ClientSalesGoal } from '../contexts/SalesTargetContextInterface';
import { SalesTargetProvider } from '../contexts/SalesTargetContextt';

import {
  feedbackBodyTemplate,
  formatGoalPotential,
  formatNotRealized,
  formatRealized,
  goalBodyTemplate,
  parseStatus,
  statusTemplate,
} from './DataTableBody';

export interface SelectedClientHeaderProps {
  client: ClientSalesGoal;
  percentage: string;
}
export const SelectedClientHeader = (props: SelectedClientHeaderProps) => {
  const { selectedClient } = React.useContext(SalesTargetProvider);
  const { client, percentage } = props;
  return (
    <React.Fragment>
      <div className="header">
        <div className="content">
          <span className="title">Cliente:&nbsp;</span>
          <span className="answer">{selectedClient!.name}</span>
        </div>
        <div className="content">
          <span className="title">Vendedor:&nbsp;</span>
          <span className="answer">
            {client.seller === '---' ? 'NÃO CRIADO' : client.seller}
          </span>
        </div>
        <div className="content">
          <span className="title">Progresso:&nbsp;</span>
          <span className="answer">
            {client.status === 'NÃO CRIADO'
              ? 'NÃO CRIADO'
              : `${(Number.isNaN(client.progress) ||
                !Number.isFinite(client.progress)
                  ? 0
                  : client.progress
                ).toFixed(2)}%`}
          </span>
        </div>
        <div className="content">
          <span className="title">Status:&nbsp;</span>
          <span className="answer">
            {statusTemplate(parseStatus(selectedClient!.status))}
          </span>
        </div>
      </div>
      <div className="header">
        <div className="content">
          <span className="title">Potencial:&nbsp;</span>
          <span className="answer">
            {' '}
            {goalBodyTemplate(formatGoalPotential(client.potential))}
          </span>
        </div>
        <div className="content">
          <span className="title">Meta:&nbsp;</span>
          <span className="answer">
            {goalBodyTemplate(formatGoalPotential(client.goal))}
          </span>
        </div>
        <div className="content">
          <span className="title">Realizado:&nbsp;</span>
          <span className="answer">
            {feedbackBodyTemplate(formatRealized(client.realized, client.goal))}
          </span>
        </div>
        <div className="content">
          <span className="title">Ñ Realizado:&nbsp;</span>
          <span className="answer">
            {feedbackBodyTemplate(
              formatNotRealized(client.notRealized, client.goal),
            )}
          </span>
        </div>
      </div>
      <div className="header">
        <div className="content">
          <span className="title">Meta em Porcentagem:&nbsp;</span>
          <span className="answer">{percentage}</span>
        </div>
      </div>
    </React.Fragment>
  );
};
