// Importações Padrões
import React, { useContext } from 'react';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import { IDeleteUser, IEditUser, IUser } from '../../typings/users.types';

// Componentes
import ItemCol from '../defaultBox/itemCol.component';
import ItemAction, { IDelete, IEdit } from '../defaultBox/itemAction.component';
import ResponsiveItemAction from '../defaultBox/responsiveItemAction.component';

// Funções
import { parseUserFunction } from '../../functions/user.function';

interface IITemMenu {
  user: IUser;
  index: number;
  showHideEditUser: IEditUser;
  setShowHideEditUser: React.Dispatch<React.SetStateAction<IEdit<IUser>>>;
  showHideDeleteUser: IDeleteUser;
  setShowHideDeleteUser: React.Dispatch<React.SetStateAction<IDelete>>;
}

const ItemUser = ({
  user,
  index,
  showHideEditUser,
  setShowHideEditUser,
  showHideDeleteUser,
  setShowHideDeleteUser,
}: IITemMenu) => {
  const [systemUser] = user.systems.filter(
    (system) => system.systemId === 'CFDV',
  );

  const { windowWidth } = useContext(Auth);

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-mt-1 p-mx-auto p-p-3 p-grid item-box width100">
          <p className="p-col-9 width100 p-d-inline p-my-0 p-p-0 p-mr-0">
            #{index}
          </p>

          <ItemCol colGrid={3} field="Nome" content={user.name} />
          <ItemCol colGrid={4} field="Email" content={user.email} />
          <ItemCol
            colGrid={2}
            field="Tipo"
            content={
              systemUser ? parseUserFunction(systemUser.function) : 'parse'
            }
          />
          <ResponsiveItemAction
            item={user}
            actionEdit={showHideEditUser}
            setActionEdit={setShowHideEditUser}
            actionDelete={showHideDeleteUser}
            setActionDelete={setShowHideDeleteUser}
          />
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div
          className="p-mt-1 p-mx-auto p-px-3 p-grid item-box width100"
          style={{
            border: '1px solid #e9ecef',
            borderWidth: '0 0 1px 0',
            backgroundColor: 'white',
          }}
        >
          <ItemCol colGrid={1} field="Id" content={String(index)} />

          <ItemCol colGrid={3} field="Nome" content={user.name} />
          <ItemCol colGrid={4} field="Email" content={user.email} />
          <ItemCol
            colGrid={2}
            field="Tipo"
            content={
              systemUser ? parseUserFunction(systemUser.function) : 'parse'
            }
          />
          <ItemAction
            item={user}
            actionEdit={showHideEditUser}
            setActionEdit={setShowHideEditUser}
            actionDelete={showHideDeleteUser}
            setActionDelete={setShowHideDeleteUser}
            colGrid={2}
          />
        </div>
      );
    }
    return <div />;
  }

  return changeBreakpoints();
};

export default ItemUser;
