// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollTop } from 'primereact/scrolltop';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useServicesList } from '../hooks/useServicesList';

// Types
import {
  IService,
  IDeleteService,
  IEditService,
  initialService,
} from '../typings/service.types';

// Componentes\
import ItemService from '../components/services/itemService.component';
import AddService from '../components/services/addService.component';
import EditService from '../components/services/editService.component';
import DeleteService from '../components/services/deleteService.component';
import NavService from '../components/services/navService.component';

const Services: React.FC = () => {
  const { setPathName, windowWidth } = useContext(Auth);

  const [servicesList, pending] = useServicesList();

  const [showHideAddService, setShowHideAddService] = useState(false);
  const [showHideDeleteService, setShowHideDeleteService] =
    useState<IDeleteService>({ id: '', name: '', visible: false });
  const [showHideEditService, setShowHideEditService] = useState<IEditService>({
    item: initialService,
    edit: false,
    visible: false,
  });

  const [inputSearch, setInputSearch] = useState('');

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  useEffect(() => {
    if (
      showHideAddService ||
      showHideEditService.visible ||
      showHideDeleteService.visible
    ) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHideAddService, showHideEditService, showHideDeleteService]);

  return (
    <div className="p-py-3 p-px-3">
      <ScrollTop className="button-scroll-top" />
      <AddService
        visible={showHideAddService}
        setVisible={setShowHideAddService}
      />
      <EditService
        visible={showHideEditService}
        setVisible={setShowHideEditService}
      />
      <DeleteService
        visible={showHideDeleteService}
        setVisible={setShowHideDeleteService}
      />
      <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
        <NavService
          placeHolder="Pesquisar serviços..."
          pending={!pending}
          addFieldName="Novo Serviço"
          actionAdd={showHideAddService}
          setActionAdd={setShowHideAddService}
          setInputSearch={setInputSearch}
          activeAdd
        />

        <div className="p-d-flex p-dir-col p-mb-auto width100">
          {windowWidth && windowWidth > 900 && (
            <div
              className="p-grid p-my-1 p-px-3 p-mx-auto p-d-none p-d-md-inline-flex default-box-header"
              style={{
                backgroundColor: '#f8f9fa',
                border: '1px solid #e9ecef',
              }}
            >
              <div className="p-col-1 p-text-center p-as-center">Id</div>
              <div className="p-col-4 p-text-center p-as-center">Imagem</div>
              <div className="p-col-4 p-text-center p-as-center">Serviço</div>
              <div className="p-col-3 p-text-center p-as-center">Ações</div>
            </div>
          )}
          {servicesList
            .filter(
              (item) =>
                item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                -1,
            )
            .map((service: IService, index: number) => {
              return (
                <ItemService
                  key={String(index)}
                  index={index + 1}
                  service={service}
                  showHideEditService={showHideEditService}
                  setShowHideEditService={setShowHideEditService}
                  showHideDeleteService={showHideDeleteService}
                  setShowHideDeleteService={setShowHideDeleteService}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Services;
