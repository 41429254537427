// Importações Padrões
import React from 'react';

// Icones
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';

// React Prime
import { Button } from 'primereact/button';

interface IData {
  id: string;
  name: string;
}
export interface IEdit<Type> {
  item: IData & Type;
  edit: boolean;
  visible: boolean;
}
export interface IDelete {
  id: string;
  name: string;
  visible: boolean;
}

export interface IItem {
  id: string;
  name: string;
}
interface IItemColProps<Type> {
  item: IItem & Type;
  actionEdit: IEdit<Type>;
  setActionEdit: React.Dispatch<React.SetStateAction<IEdit<Type>>>;
  actionDelete: IDelete;
  setActionDelete: React.Dispatch<React.SetStateAction<IDelete>>;
}

function ResponsiveItemAction<Type>({
  item,
  actionEdit,
  setActionEdit,
  actionDelete,
  setActionDelete,
}: IItemColProps<Type>) {
  return (
    <div className="p-grid p-p-0 p-mt-2 p-mx-auto">
      <Button
        className="p-mr-1 fontSize1Rem height2Rem button-contest"
        onClick={() => {
          setActionEdit({
            item,
            edit: false,
            visible: !actionEdit.visible,
          });
        }}
      >
        <FaEye className="p-mx-auto" />
        &nbsp; Ver
      </Button>
      <Button
        className="p-mx-1 p-px-2 fontSize1Rem height2Rem button-confirmation"
        onClick={() => {
          setActionEdit({
            item,
            edit: true,
            visible: !actionEdit.visible,
          });
        }}
      >
        <FaEdit className="p-mx-auto" />
        &nbsp; Editar
      </Button>
      <Button
        className="p-ml-1 p-px-2 fontSize1Rem height2Rem button-contradict"
        onClick={() => {
          setActionDelete({
            id: item.id,
            name: item.name,
            visible: !actionDelete.visible,
          });
        }}
      >
        <FaTrash className="p-mx-auto" />
        &nbsp; Apagar
      </Button>
    </div>
  );
}

export default ResponsiveItemAction;
