// Importações Padrões
import React, { useContext } from 'react';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import {
  IService,
  IEditService,
  IDeleteService,
} from '../../typings/service.types';

// Componentes
import ItemCol from '../defaultBox/itemCol.component';
import ItemImage from '../defaultBox/itemImage.component';
import ItemAction, { IEdit } from '../defaultBox/itemAction.component';
import ResponsiveItemAction from '../defaultBox/responsiveItemAction.component';

interface IITemMenu {
  service: IService;
  index: number;
  showHideEditService: IEditService;
  setShowHideEditService: React.Dispatch<React.SetStateAction<IEdit<IService>>>;
  showHideDeleteService: IDeleteService;
  setShowHideDeleteService: React.Dispatch<
    React.SetStateAction<IDeleteService>
  >;
}

const defaultImage = 'https://image0.flaticon.com/icons/png/512/953/953761.png';

const ItemServices = ({
  service,
  index,
  showHideEditService,
  setShowHideEditService,
  showHideDeleteService,
  setShowHideDeleteService,
}: IITemMenu) => {
  const { windowWidth } = useContext(Auth);

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-mt-1 p-mx-auto p-p-3 p-grid item-box width100">
          <p className="p-col-9 width100 p-d-inline p-my-0 p-p-0 p-mr-0">
            #{index}
          </p>
          <div className="p-px-1 p-as-center p-mx-auto">
            <ItemImage
              colGrid={4}
              content={service.imageHeavy}
              imageDefault={defaultImage}
            />
          </div>
          <ItemCol colGrid={4} field="Nome" content={service.name} />
          <ResponsiveItemAction
            item={service}
            actionEdit={showHideEditService}
            setActionEdit={setShowHideEditService}
            actionDelete={showHideDeleteService}
            setActionDelete={setShowHideDeleteService}
          />
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div
          className="p-mt-1 p-mx-auto p-px-3 p-py-1 p-grid item-box width100"
          style={{
            border: '1px solid #e9ecef',
            borderWidth: '0 0 1px 0',
            backgroundColor: 'white',
          }}
        >
          <ItemCol colGrid={1} field="Id" content={String(index)} />
          <div className="p-col-4">
            <ItemImage
              colGrid={12}
              content={service.imageHeavy}
              imageDefault={defaultImage}
            />
          </div>
          <ItemCol colGrid={4} field="Nome" content={service.name} />
          <ItemAction
            item={service}
            actionEdit={showHideEditService}
            setActionEdit={setShowHideEditService}
            actionDelete={showHideDeleteService}
            setActionDelete={setShowHideDeleteService}
            colGrid={3}
          />
        </div>
      );
    }
    return <div />;
  }

  return changeBreakpoints();
};

export default ItemServices;
