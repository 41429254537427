// Types
import { IValidationError } from '../../typings/yup.types';

const catchErrors = (formErr: Array<IValidationError>, errorField: string) => {
  return (
    <small className="p-d-block input-invalid ">
      {formErr.map((err) => {
        if (err.path === errorField) {
          return <p>{err.errors}</p>;
        }
        return null;
      })}
    </small>
  );
};

const catchInvalid = (formErr: Array<IValidationError>, errorField: string) => {
  if (formErr.filter((err) => err.path === errorField).length >= 1) {
    return true;
  }
  return false;
};

export { catchErrors, catchInvalid };
