// Importações Padrões
import React, { useContext } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

// Icones
import { FaCircle, FaRegTimesCircle, FaTimes } from 'react-icons/fa';

// Contexto
import { Auth } from '../../services/auth.context';

// Estilos
import '../../styles/global/notificationBox.scss';

interface IVisible {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationBox = ({ visible, setVisible }: IVisible) => {
  const { expireTime, system3cData } = useContext(Auth);

  const dateBill = system3cData?.systemBilling.systemDateBill;

  return visible ? (
    <div>
      <div className="notification-box-position">
        <div className="notification-arrow" />
        <div className="notification-box-display">
          <div className="p-grid p-pt-2 p-px-2 p-mb-2">
            <h1 className="p-my-auto p-ml-2 p-p-0 p-d-inline p-ascenter notification-text-header">
              NOTIFICAÇÕES
            </h1>
            <FaTimes
              className="p-my-auto p-ml-5 p-mr-2 notification-text-header notification-times"
              onClick={() => {
                setVisible(!visible);
              }}
            />
          </div>
          {expireTime ? (
            <div className="p-px-2 p-my-2 notification">
              <div className="p-grid p-as-center">
                <div className="p-col-10 p-pr-0 notification-expire">
                  Devido a falta de pagamento, o sistema sera desativado em{' '}
                  <b>{moment(dateBill?.toDate()).add(15, 'd').format('LLL')}</b>
                  .
                </div>
                <div className="p-col-2 p-px-0 p-text-center p-as-center">
                  <FaCircle className="notification-badge" />
                </div>
              </div>
              <div className="p-pr-0 notification-expire">
                {moment().from(moment())}
              </div>
            </div>
          ) : (
            <div className="p-d-flex p-grid p-dir-col p-m-0">
              <div className="p-col">
                <h1 className="p-text-center  p-mb-0">
                  <FaRegTimesCircle />
                </h1>
                <h3 className="p-text-center p-mt-0">Sem Notificações</h3>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default NotificationBox;
