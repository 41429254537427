// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Contexto
import { Auth } from '../services/auth.context';

// Types
import { IDayOrders } from '../typings/order.types';

// Controladores
import { code3cCollection } from '../controllers/code3c.controller';

export function useDayOrdersList(): [Array<IDayOrders>, boolean] {
  const { code3cData } = useContext(Auth);

  const [dayOrdersList, setDayOrdersList] = useState<Array<IDayOrders>>([]);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    if (code3cData && code3cData.id) {
      setPending((state) => !state);
      const unsubscribe = code3cCollection
        .doc(code3cData.id)
        .collection('FDV-shard-dayOrders')
        .onSnapshot((response) => {
          const list: Array<IDayOrders> = [];
          response.docs.forEach((item) => {
            list.push({ ...item.data() } as IDayOrders);
          });
          if (list.length === 0) {
            setDayOrdersList([]);
          } else {
            setDayOrdersList(list);
          }
        });
      setPending((state) => !state);

      return () => {
        unsubscribe();
        setPending(false);
      };
    }
    return () => {
      setPending(false);
    };
  }, [code3cData, code3cData?.id]);

  return [dayOrdersList, pending];
}
