// Importações Padrões
import React from 'react';

// React Prime
import { InputText } from 'primereact/inputtext';

// Types
import { IValidationError } from '../../typings/yup.types';

// Componentes
import { catchErrors, catchInvalid } from '../global/formErrors.component';

interface IInputFormFunctionProps {
  disabled: boolean;
  item: string | number;
  // eslint-disable-next-line
  setFunction: any;
  maxLength: number;
  formErr: Array<IValidationError>;
  fieldCode: string;
}

const InputFormFunction = (props: IInputFormFunctionProps) => {
  const { disabled, item, setFunction, formErr, fieldCode, maxLength } = props;
  return (
    <div className="p-field">
      <span className="p-d-flex p-input-icon-right">
        {catchInvalid(formErr, fieldCode) && (
          <i className="pi pi-times input-invalid" />
        )}
        <InputText
          disabled={disabled}
          maxLength={maxLength}
          className={
            catchInvalid(formErr, fieldCode)
              ? 'settings-input-invalid'
              : 'settings-input'
          }
          value={item}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFunction(event.target.value);
          }}
        />
      </span>
      {catchErrors(formErr, fieldCode)}
    </div>
  );
};

export default InputFormFunction;
