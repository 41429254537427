import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

export const createSalesTargetSchema = yup.object().shape({
  code3c_id: yup.string().label('Código do Cliente').min(3).required(),
  startDate: yup.date().label('Ínicio da Meta').required(),
  endDate: yup.date().label('Final da Meta').required(),
});
