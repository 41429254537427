export interface ICity {
  id: string;
  name: string;

  code3c_id: string;

  province_id: string;

  created_at?: Date;
  update_at?: Date;
}

export interface IEditCity {
  item: ICity;
  edit: boolean;
  visible: boolean;
}

export interface IDeleteCity {
  id: string;
  name: string;
  visible: boolean;
}

export const initialCity: ICity = {
  id: '',
  name: '',

  code3c_id: '',

  province_id: '',
};
