import React, { ReactNode, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';

export interface BasicModalProps {
  displayModal: boolean;
  // setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
  onHide: () => void;
  renderHeader: () => JSX.Element;
  renderFooter: () => JSX.Element;
  children?: ReactNode;
}

export const BasicModal = (props: BasicModalProps) => {
  const { displayModal, onHide, renderHeader, renderFooter, children } = props;

  useEffect(() => {
    if (displayModal) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  });

  return (
    <Dialog
      header={renderHeader()}
      visible={displayModal}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={renderFooter()}
      onHide={() => onHide()}
    >
      {children}
    </Dialog>
  );
};
