// Importações Padrões
import React, { useState, useEffect, useContext } from 'react';
import { validateBr, maskBr } from 'js-brasil';
import * as yup from 'yup';

// Icones
import { FaEdit, FaEye, FaToggleOff, FaToggleOn } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import { IInput } from '../../typings/input.types';
import { IProvider, IEditProvider } from '../../typings/provider.types';
import { IValidationError } from '../../typings/yup.types';

// Controladores
import { editProvider } from '../../controllers/provider.controller';
import { consultCep } from '../../controllers/cep.controller';

// Componentes
import InputImageForm from '../input/inputImageForm.component';
import InputForm from '../input/inputForm.component';
import InputFormFunction from '../input/inputFormFunction.component';
import InputTextareaComponent from '../input/inputTextarea.component';
import InputDropForm from '../input/inputDropForm.component';

// Funções
import {
  dataSchema,
  dataSchemaSimple,
} from '../../functions/provider.function';
import { getURLFromDB } from '../../functions/image.function';

interface IEditProviderProps {
  visible: IEditProvider;
  setVisible: React.Dispatch<React.SetStateAction<IEditProvider>>;
}

const EditProvider = (props: IEditProviderProps) => {
  const { visible, setVisible } = props;

  const { code3cData, systemData } = useContext(Auth);
  const [name, setName] = useState<string>('');
  const [socialID, setSocialID] = useState<string>('');
  const [stateSubscription, setStateSubscription] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [province, setProvince] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [neighborhood, setNeighborhood] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [houseNumber, setHouseNumber] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [imageHeavy, setImageHeavy] = useState<string>('');
  const [imageLight, setImageLight] = useState<string>('');

  const [cpfValid, setCpfValid] = useState(false);
  const [cnpjValid, setCnpjValid] = useState(false);

  const [typeOf, setTypeOf] = useState<IInput>({
    name: 'Fornecedor Completo',
    code: 'complexProvider',
  });

  const typeOfList: Array<IInput> = [
    { name: 'Fornecedor Completo', code: 'complexProvider' },
    { name: 'Fornecedor Simples', code: 'simpleProvider' },
  ];

  const [docType, setDocType] = useState(true);
  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  const initialState = () => {
    setName('');
    setSocialID('');
    setStateSubscription('');
    setPhone('');
    setEmail('');
    setPostalCode('');
    setProvince('');
    setCity('');
    setNeighborhood('');
    setStreet('');
    setHouseNumber('');
    setImageHeavy('');
    setImageLight('');
    setFormErr([]);
    setPendingAction(false);
    setCpfValid(false);
    setCnpjValid(false);
    setVisible({ ...visible, visible: false });
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
  };

  async function getAddress(CEP: string) {
    setPostalCode(CEP);
    if (CEP.replace('-', '').length === 8) {
      const AddressInfo = await consultCep(CEP);
      setPostalCode(AddressInfo.cep);
      setProvince(AddressInfo.uf);
      setCity(AddressInfo.localidade);
      setNeighborhood(AddressInfo.bairro);
      setStreet(AddressInfo.logradouro);
    }
  }

  function changeDocType() {
    setSocialID('');
    setDocType(!docType);
    setCpfValid(false);
    setCnpjValid(false);
  }

  function formatPhone(Phone: string) {
    setPhone(Phone);
    if (validateBr.telefone(Phone) && Phone.length >= 10) {
      setPhone(maskBr.telefone(Phone));
    } else if (!validateBr.telefone(Phone)) {
      setPhone(Phone.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''));
    }
  }

  function formatSocialID(CNPJ_CPF: string) {
    if (docType) {
      setSocialID(CNPJ_CPF);
      if (validateBr.cnpj(CNPJ_CPF) && CNPJ_CPF.length === 14) {
        setSocialID(maskBr.cnpj(CNPJ_CPF));
        setCnpjValid(false);
      } else if (CNPJ_CPF.length === 14) {
        setSocialID(maskBr.cnpj(CNPJ_CPF));
        setCnpjValid(true);
      } else {
        setSocialID(CNPJ_CPF.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''));
        setCnpjValid(false);
      }
    } else {
      setSocialID(CNPJ_CPF);
      if (validateBr.cpf(CNPJ_CPF) && CNPJ_CPF.length === 11) {
        setSocialID(maskBr.cpf(CNPJ_CPF));
        setCpfValid(false);
      } else if (CNPJ_CPF.length === 11) {
        setSocialID(maskBr.cpf(CNPJ_CPF));
        setCpfValid(true);
      } else {
        setSocialID(CNPJ_CPF.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''));
        setCpfValid(false);
      }
    }
  }

  async function handleEditProvider() {
    setPendingAction((state) => !state);
    let urlImage = '';
    if (imageHeavy !== '') {
      urlImage = await getURLFromDB(
        true,
        imageHeavy,
        imageLight,
        code3cData?.id ? code3cData.id : '',
        'FDV-Provider',
      );
    }
    let dataValidation: Partial<IProvider> = {
      typeOf: typeOf.code,
      systemId: systemData?.systemId,
      code3c_id: code3cData?.id,
      name,
      note,
      imageHeavy: urlImage,
      imageLight: urlImage,
    };

    let dataPassSchema: yup.AnySchema = dataSchemaSimple;
    if (typeOf.code === 'complexProvider') {
      dataValidation = {
        ...dataValidation,
        socialID: socialID.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''),
        stateSubscription,
        phone: phone.replace(/[&\\#,+()$~%.'":*?<>{}-\s/]/g, ''),
        email,
        address: {
          postalCode,
          province,
          city,
          neighborhood,
          street,
          houseNumber,
        },
      };
      dataPassSchema = dataSchema;
    }
    dataPassSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await editProvider(dataValidation, visible.item.id);
        if (response) {
          setFormErr([]);
          initialState();
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  useEffect(() => {
    if (
      visible.item.socialID &&
      validateBr.cpf(visible.item.socialID) &&
      visible.item.socialID.length === 11
    ) {
      setSocialID(maskBr.cpf(visible.item.socialID));
      setDocType(false);
      setCpfValid(false);
    } else if (visible.item.socialID && visible.item.socialID.length === 11) {
      setSocialID(maskBr.cpf(visible.item.socialID));
      setDocType(false);
      setCpfValid(true);
    } else {
      setCpfValid(false);
    }
  }, [visible]);

  useEffect(() => {
    if (
      visible.item.socialID &&
      validateBr.cnpj(visible.item.socialID) &&
      visible.item.socialID.length === 14
    ) {
      setSocialID(maskBr.cnpj(visible.item.socialID));
      setDocType(true);
      setCnpjValid(false);
    } else if (visible.item.socialID && visible.item.socialID.length === 14) {
      setSocialID(maskBr.cnpj(visible.item.socialID));
      setDocType(true);
      setCnpjValid(true);
    } else {
      setCnpjValid(false);
    }
  }, [visible]);

  useEffect(() => {
    setName(visible.item.name);
    setNote(visible.item.note);
    setStateSubscription(visible.item.stateSubscription);
    setPhone(visible.item.phone);
    setEmail(visible.item.email);
    if (visible.item.address) {
      setPostalCode(visible.item.address.postalCode);

      setProvince(visible.item.address.province);
      setCity(visible.item.address.city);
      setNeighborhood(visible.item.address.neighborhood);
      setStreet(visible.item.address.street);
      setHouseNumber(visible.item.address.houseNumber);
    }

    setImageHeavy(visible.item.imageHeavy);
    setImageLight(visible.item.imageLight);

    const [typeSelected] = typeOfList.filter(
      (system) => system.code === visible.item.typeOf,
    );
    if (typeSelected) {
      setTypeOf({
        name: typeSelected.name,
        code: visible.item.typeOf,
      });
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              {visible.edit ? (
                <FaEdit className="p-mx-auto p-as-center modal-header-icon" />
              ) : (
                <FaEye className="p-mx-auto p-as-center modal-header-icon" />
              )}
            </div>
            <span className="p-my-auto users-button-text">
              {visible.edit ? 'Editar ' : 'Ver '}Fornecedor
            </span>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label={visible.edit ? 'Salvar' : 'Editar'}
              disabled={pendingAction}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() =>
                visible.edit
                  ? handleEditProvider()
                  : setVisible({ ...visible, edit: true })
              }
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <InputDropForm
        filter={false}
        filterBy=""
        disabled={!visible.edit}
        item={typeOf}
        setItem={setTypeOf}
        options={typeOfList}
        formErr={formErr}
        fieldLabel="Tipo do Fornecedor"
        fieldCode="typeOf"
      />
      <InputForm
        pleaceHolder=""
        disabled={!visible.edit}
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome do Fornecedor"
        fieldCode="name"
      />
      {typeOf.code === 'complexProvider' && (
        <>
          <label>
            {docType ? (
              <div className="p-d-flex p-mb-2 p-as-center">
                <FaToggleOff
                  className="p-as-center"
                  style={{ fontSize: '1.8rem', cursor: 'pointer' }}
                  onClick={() => visible.edit && changeDocType()}
                />
                <div className="p-ml-2 p-as-center">CNPJ</div>
              </div>
            ) : (
              <div className="p-d-flex p-mb-2 p-as-center">
                <FaToggleOn
                  style={{ fontSize: '1.8rem', cursor: 'pointer' }}
                  onClick={() => visible.edit && changeDocType()}
                />
                <div className="p-ml-2 p-as-center">CPF</div>
              </div>
            )}
          </label>
          <InputFormFunction
            maxLength={docType ? 14 : 11}
            disabled={!visible.edit}
            item={socialID}
            setFunction={formatSocialID}
            formErr={formErr}
            fieldCode="socialID"
          />
          {cpfValid && (
            <div className="p-d-flex p-mb-3">
              <i className="p-mr-2 pi pi-exclamation-circle input-warn p-as-center" />
              <small className=" input-warn">
                O CPF inserido não é valido!.
              </small>
            </div>
          )}
          {cnpjValid && (
            <div className="p-d-flex p-mb-3">
              <i className="p-mr-2 pi pi-exclamation-circle input-warn p-as-center" />
              <small className=" input-warn">
                O CNPJ inserido não é valido!.
              </small>
            </div>
          )}
          <InputForm
            pleaceHolder=""
            disabled={!visible.edit}
            item={stateSubscription}
            setItem={setStateSubscription}
            formErr={formErr}
            fieldLabel="Inscrição Estadual"
            fieldCode="stateSubscription"
          />
        </>
      )}
      {typeOf.code === 'complexProvider' && (
        <>
          {' '}
          <div className="p-d-flex p-my-2 p-jc-center">
            <small className="input-warn">Informações de Contato.</small>
          </div>
          <div className="p-grid p-mt-2">
            <div className="p-col-12 p-md-6 p-lg-6">
              <label className="p-my-0 p-mt-4">Telefone</label>
              <InputFormFunction
                maxLength={15}
                disabled={!visible.edit}
                item={phone}
                setFunction={formatPhone}
                formErr={formErr}
                fieldCode="phone"
              />
            </div>
            <div className="p-col-12 p-md-6 p-lg-6">
              <InputForm
                pleaceHolder=""
                disabled={!visible.edit}
                item={email}
                setItem={setEmail}
                formErr={formErr}
                fieldLabel="Email"
                fieldCode="email"
              />
            </div>
          </div>
          <div className="p-d-flex p-my-2 p-jc-center">
            <small className="input-warn">Informações de Endereço.</small>
          </div>
          <div className="p-grid p-mt-2">
            <div className="p-col-12 p-md-6 p-lg-6">
              <label className="p-my-0 p-mt-4">CEP</label>
              <InputFormFunction
                maxLength={11}
                disabled={!visible.edit}
                item={postalCode}
                setFunction={getAddress}
                formErr={formErr}
                fieldCode="address.postalCode"
              />
            </div>
            <div className="p-col-12 p-md-6 p-lg-6">
              <InputForm
                pleaceHolder="Insira o CEP"
                disabled
                item={province}
                setItem={setProvince}
                formErr={formErr}
                fieldLabel="Estado"
                fieldCode="address.province"
              />
            </div>
          </div>
          <div className="p-grid p-mt-2">
            <div className="p-col-12 p-md-6 p-lg-6">
              <InputForm
                pleaceHolder="Insira o CEP"
                disabled
                item={city}
                setItem={setCity}
                formErr={formErr}
                fieldLabel="Cidade"
                fieldCode="address.city"
              />
            </div>
            <div className="p-col-12 p-md-6 p-lg-6">
              <InputForm
                pleaceHolder=""
                disabled={!visible.edit}
                item={neighborhood}
                setItem={setNeighborhood}
                formErr={formErr}
                fieldLabel="Bairro"
                fieldCode="address.neighborhood"
              />
            </div>
          </div>
          <div className="p-grid p-mt-2">
            <div className="p-col-12 p-md-10 p-lg-10">
              <InputForm
                pleaceHolder=""
                disabled={!visible.edit}
                item={street}
                setItem={setStreet}
                formErr={formErr}
                fieldLabel="Rua"
                fieldCode="address.street"
              />
            </div>
            <div className="p-col-12 p-md-2 p-lg-2">
              <InputForm
                pleaceHolder=""
                disabled={!visible.edit}
                item={houseNumber}
                setItem={setHouseNumber}
                formErr={formErr}
                fieldLabel="Nº"
                fieldCode="address.houseNumber"
              />
            </div>
          </div>
        </>
      )}

      <div className="p-d-flex p-my-2 p-jc-center">
        <small className="input-warn">Anotações.</small>
      </div>
      <InputTextareaComponent
        disabled={!visible.edit}
        item={note}
        setItem={setNote}
        formErr={formErr}
        fieldLabel="Observações"
        fieldCode={note}
        rows={5}
        cols={30}
      />
      <InputImageForm
        visibleEdit={visible.edit}
        loadingImage={pendingAction}
        item={imageHeavy}
        setItem={setImageHeavy}
        fieldLabel="Imagem do Fornecedor"
      />
    </Dialog>
  );
};

export default EditProvider;
