import { maskBr } from 'js-brasil';
import { Tooltip } from 'primereact/tooltip';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { useCallback, useContext, useState, useEffect, useRef } from 'react';
import useOnScreen from '../../hooks/useOnScreen';
import { useParams } from 'react-router-dom';
import { Auth } from '../../services/auth.context';
import { formatCurrency } from '../../functions/list.function';
import { editServiceNoRes } from '../../controllers/service.controller';
import { IListPrice, IListProducts } from '../../typings/list.types';
import { toasterWarn } from '../global/toaster.component';
import { IService } from '../../typings/service.types';

interface IServiceOnListProps {
  index: number;
  item: IService;
  servicesOnList: IListProducts;
  setServicesOnList: React.Dispatch<React.SetStateAction<IListProducts>>;
  inputSearch: string;
}

interface IListParams {
  ListId: string;
}

const ServiceOnList = (props: IServiceOnListProps) => {
  const { windowWidth } = useContext(Auth);
  const { ListId }: IListParams = useParams();
  const { item, servicesOnList, setServicesOnList, inputSearch } = props;
  const [serviceItem, setServiceItem] = useState(item);

  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const isVisible = useOnScreen(ref);

  const handleProductActive = useCallback(
    (service: IService) => {
      if (service.listEnabled && service.listMeta) {
        const onList = service.listEnabled.filter((list) => list === ListId);
        if (onList.length >= 1) {
          return true;
        }
      }
      return false;
    },
    [ListId],
  );
  const [productActive, setProductActive] = useState(handleProductActive(item));
  const [listPrice, setListPrice] = useState<number>(
    item.listMeta && item.listMeta[ListId]
      ? item.listMeta[ListId].listPrice
      : item.minPrice,
  );

  const inputPriceStatus = useCallback(() => {
    if (
      (listPrice > serviceItem.maxPrice && serviceItem.useMaxPrice) ||
      listPrice < serviceItem.minPrice
    ) {
      return (
        <>
          <i
            className="pi pi-exclamation-circle text-danger question-1"
            data-pr-tooltip="O preço do produto não condiz com os parâmetros de
            preço mínimo e máximo, e não será salvo."
          />
          <Tooltip
            target=".question-1"
            mouseTrack
            mouseTrackLeft={10}
            className="p-text-justify"
          />
        </>
      );
    }
    if (item.listMeta && item.listMeta[ListId]) {
      if (item.listMeta[ListId].listPrice !== listPrice) {
        return (
          <>
            <i
              question-1aria-hidden="true"
              className="pi pi-pencil question-2"
              data-pr-tooltip="O preço do produto foi alterado,
              por favor clique em Salvar, para aplicar a alteração."
            />
            <Tooltip
              target=".question-2"
              mouseTrack
              mouseTrackLeft={10}
              className="p-text-justify"
            />
          </>
        );
      }
      if (item.listMeta[ListId].listPrice === listPrice) {
        return (
          <>
            <i
              question-1aria-hidden="true"
              className="pi pi-save question-3"
              data-pr-tooltip="O preço do produto está salvo na lista."
            />
            <Tooltip
              target=".question-3"
              mouseTrack
              mouseTrackLeft={10}
              className="p-text-justify"
            />
          </>
        );
      }
    }
    return <></>;
  }, [listPrice, item, serviceItem, ListId, inputSearch]);

  const addProductOnList = useCallback(
    async (priceOnList: number) => {
      let response;
      const hasCond =
        (priceOnList > serviceItem.maxPrice && serviceItem.useMaxPrice) ||
        priceOnList < serviceItem.minPrice;
      if (!hasCond) {
        if (item.listEnabled && item.listMeta) {
          response = await editServiceNoRes(
            {
              listEnabled: [...item.listEnabled, ListId],
              listMeta: {
                ...item.listMeta,
                [ListId]: { listPrice: priceOnList },
              },
            },
            item.id,
          );
        } else {
          response = await editServiceNoRes(
            {
              listEnabled: [ListId],
              listMeta: { [ListId]: { listPrice: priceOnList } },
            },
            item.id,
          );
        }
        if (response) {
          setProductActive(true);
          setServiceItem(item);
        }
      } else {
        toasterWarn('Erro', 'Preço está fora do Escopo');
      }
      // Checar
    },
    [
      ListId,
      item,
      serviceItem.maxPrice,
      serviceItem.minPrice,
      serviceItem.useMaxPrice,
    ],
  );

  const remProductOnList = useCallback(async () => {
    if (item.listEnabled && item.listMeta) {
      const newListMeta = item.listMeta;
      if (newListMeta[ListId]) {
        delete newListMeta[ListId];
      }
      const newProduct: Partial<IService> = {
        listEnabled: item.listEnabled.filter((list) => list !== ListId),
        listMeta: newListMeta,
      };
      const response = await editServiceNoRes(newProduct, item.id);
      if (response) {
        setProductActive(false);
        setListPrice(item.minPrice);
        setServiceItem(item);
      }
      const newServiceOnList = servicesOnList;
      delete newServiceOnList[item.id];
      setServicesOnList(newServiceOnList);
    } else {
      console.log('O serviço não está na lista');
    }
  }, [ListId, item, servicesOnList, setServicesOnList]);

  useEffect(() => {
    if (productActive) {
      const newProductList: IListPrice = {
        listPrice,
        listEnabled: item.listEnabled,
        listMeta: item.listMeta,
      };
      const hasCond =
        (listPrice > serviceItem.maxPrice && serviceItem.useMaxPrice) ||
        listPrice < serviceItem.minPrice;
      if (!hasCond) {
        if (!servicesOnList[item.id]) {
          if (item.listMeta && listPrice !== item.listMeta[ListId].listPrice) {
            setServicesOnList({ ...servicesOnList, [item.id]: newProductList });
          }
        } else {
          setServicesOnList({ ...servicesOnList, [item.id]: newProductList });
        }
      }
    }
    // eslint-disable-next-line
  }, [listPrice]);

  useEffect(() => {
    if (servicesOnList[item.id]) {
      setListPrice(servicesOnList[item.id].listPrice);
    } else {
      setListPrice(
        item.listMeta && item.listMeta[ListId]
          ? item.listMeta[ListId].listPrice
          : item.minPrice,
      );
    }

    setProductActive(handleProductActive(item));
    setServiceItem(item);
  }, [item.id, servicesOnList, inputSearch]);

  if (!isVisible) {
    return <div ref={ref} style={{ height: '225px' }} />;
  }

  return (
    <div ref={ref} className="p-grid p-d-flex p-pt-2 p-my-2 p-mx-1 item-box">
      <div className="p-col-12 p-md-1 p-d-flex p-justify-center p-as-center">
        <Checkbox
          checked={productActive}
          className={`p-mx-auto ${
            productActive ? 'settings-checkbox-selected' : 'settings-checkbox'
          }`}
          onChange={() => {
            if (productActive) {
              remProductOnList();
            } else {
              addProductOnList(listPrice);
            }
          }}
        />
      </div>
      <div className="p-col-12 p-md-6 p-d-flex p-justify-center p-text-center p-as-center">
        {item.name}
      </div>
      <div className="p-col-12 p-md-3 p-d-flex p-justify-center p-as-center">
        <span className="p-float-label p-input-icon-right">
          {inputPriceStatus()}
          <InputText
            id="righticon"
            value={formatCurrency('BRL', listPrice)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setListPrice(
                parseInt(e.target.value.replace(/([^\d])+/gim, ''), 10),
              );
            }}
          />
          <label htmlFor="righticon" className="p-my-0 p-mt-4">
            Preço Referência
          </label>
        </span>
      </div>
      <div
        className={`p-col-12 p-md-2 p-p-0 p-my-2 item-box-col p-text-center p-as-center ${
          windowWidth && windowWidth < 900 && 'p-grid'
        }`}
      >
        <p
          className={`${
            windowWidth && windowWidth > 900
              ? 'p-text-center p-col-12 p-m-0 p-d-flex'
              : 'p-ml-1 p-col-9 width100'
          } p-text-center p-d-inline p-my-0 p-p-0 p-mr-0`}
        >
          {item.useMaxPrice ? (
            <small className="p-p-0 p-my-0 p-as-center p-mx-auto">
              {`${maskBr.currency(item.minPrice / 100)}~${maskBr.currency(
                item.maxPrice / 100,
              )}`}
            </small>
          ) : (
            <small className="p-p-0 p-my-0 p-as-center p-mx-auto">
              {`> ${maskBr.currency(item.minPrice / 100)}`}
            </small>
          )}
        </p>
      </div>
    </div>
  );
};

export default ServiceOnList;
