// Importações Padrões
import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

const parseUserFunction = (userFunction: string | undefined) => {
  switch (userFunction) {
    case 'administrator':
      return 'Administrador';

    case 'stockist':
      return 'Estoquista';

    case 'seller':
      return 'Vendedor';

    default:
      return '';
  }
};

const dataSettingsSchema = yup.object().shape({
  name: yup.string().label('Nome').min(3).required(),
  email: yup.string().label('Email').email().required(),
});

const dataSettingsWithPass = yup.object().shape({
  name: yup.string().label('Nome').min(3),
  email: yup.string().label('Email').email().required(),
  password: yup.string().label('Senha').min(8).required(),
  passwordValidation: yup
    .string()
    .label('Confirmação de Senha')
    .oneOf([yup.ref('password'), null], 'As senhas precisam ser iguais.')
    .required(),
});

const dataSchema = yup.object().shape({
  code3c_id: yup.string().min(1).required(),
  name: yup.string().label('Nome').min(3),
  email: yup.string().label('Email').email().required(),
  systems: yup.array(
    yup.object({
      systemActive: yup.boolean().required(),
      systemId: yup.string().label('Id do Sistema').min(1).required(),
      systemName: yup.string().label('Nome do Sistema').required(),
      function: yup
        .string()
        .oneOf(['Administrador', 'Estoquista', 'Vendedor'])
        .label('Função')
        .required(),
    }),
  ),
});

const dataSchemaWithPass = yup.object().shape({
  code3c_id: yup.string().min(1).required(),
  name: yup.string().label('Nome').min(3),
  email: yup.string().label('Email').email().required(),
  password: yup.string().label('Senha').min(8).required(),
  passwordValidation: yup
    .string()
    .label('Confirmação de Senha')
    .oneOf([yup.ref('password'), null], 'As senhas precisam ser iguais.')
    .required(),
  systems: yup.array(
    yup.object({
      systemActive: yup.boolean().required(),
      systemId: yup.string().label('Id do Sistema').min(1).required(),
      systemName: yup.string().label('Nome do Sistema').required(),
      function: yup
        .string()
        .oneOf(['Administrador', 'Estoquista', 'Vendedor'])
        .label('Função')
        .required(),
    }),
  ),
});

export {
  parseUserFunction,
  dataSettingsSchema,
  dataSettingsWithPass,
  dataSchema,
  dataSchemaWithPass,
};
