// Importações Padrões
import React from 'react';

// Icones
import { CgDanger } from 'react-icons/cg';

// React Prime
import { Button } from 'primereact/button';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Componentes
import ConfirmNewTab from '../components/global/confirmNewTab.component';

// Estilos
import '../styles/global/billing.scss';

const Billing: React.FC = () => {
  const supLink = 'https://tawk.to/chat/5c04c5b340105007f37aaeee/1ctpap7pm';

  return (
    <div className="p-mx-auto p-px-1 p-d-flex billing-display">
      <div className="p-d-flex p-mx-auto p-text-center billing-icon-display">
        <CgDanger className="p-mx-auto billing-icon" />
      </div>
      <div className="p-text-center">
        <p className="p-mt-0 p-mb-3 billing-title">Sistema Suspenso</p>
        <p className="billing-divisor" />
        <p className="p-mt-2 p-mb-3 billing-subTitle">
          Para mais informacoes entre em contato conosco.{' '}
        </p>
        <div className="billing-nav">
          <div className="p-mb-2">
            <Button
              label="Fale Conosco"
              className="p-button-success billing-button-contact"
              onPointerDown={(e) => {
                e.buttons === 1 && ConfirmNewTab(supLink);
              }}
            />
          </div>
          <div>
            <Button
              label="Sair"
              icon="pi pi-sign-out"
              className="p-button-danger"
              onClick={async () => {
                await firebaseConfig.auth().signOut();
                window.location.reload();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
