// Importações Padrões
import React, { useState, useEffect, useContext, useMemo } from 'react';
import * as yup from 'yup';

// Icones
import { FaEdit, FaEye } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

// Contexto
import { Auth } from '../../services/auth.context';

// Hooks
import { useCategoriesList } from '../../hooks/useCategoriesList';
import { useBrandingsList } from '../../hooks/useBrandingsList';
import { useManufacturerList } from '../../hooks/useManufacturerList';
import { useProvidersList } from '../../hooks/useProvidersList';

// Types
import { IInput } from '../../typings/input.types';
import { IProduct, IEditProduct } from '../../typings/product.types';
import { IValidationError } from '../../typings/yup.types';

// Controladores
import {
  editProduct,
  searchCodeBar,
  searchProductBase,
} from '../../controllers/product.controller';

// Componentes
import InputImageForm from '../input/inputImageForm.component';
import InputForm from '../input/inputForm.component';
import InputFormNumber from '../input/inputFormNumber.component';
import InputDropForm from '../input/inputDropForm.component';
import InputFormCurrency from '../input/inputFormCurrency.component';

// Funções
import { dataSchema } from '../../functions/product.function';
import { getURLFromDB } from '../../functions/image.function';
import InputFormFunction from '../input/inputFormFunction.component';

interface IEditProductProps {
  visible: IEditProduct;
  setVisible: React.Dispatch<React.SetStateAction<IEditProduct>>;
}

const EditProduct = (props: IEditProductProps) => {
  const { visible, setVisible } = props;

  const { code3cData } = useContext(Auth);

  const [categoriesList] = useCategoriesList();
  const [brandingsList] = useBrandingsList();
  const [manufacturersList] = useManufacturerList();
  const [providersList] = useProvidersList();

  const [barCode, setBarCode] = useState('');
  const [name, setName] = useState<string>('');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [costPrice, setCostPrice] = useState(0);
  const [minAmount, setMinAmount] = useState(0);
  const [useMaxPrice, setUseMaxPrice] = useState(visible.item.useMaxPrice);
  const [imageHeavy, setImageHeavy] = useState<string>('');
  const [imageLight, setImageLight] = useState<string>('');

  const [moreInformation, setMoreInformation] = useState<boolean>(false);
  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  const [codeBarErr, setCodeBarErr] = useState<boolean>(false);

  const [minAmountErr, setMinAmountErr] = useState<boolean>(false);
  const [maxPriceErr, setMaxPriceErr] = useState<boolean>(false);

  const [useProductBase, setUseProductBase] = useState(false);

  const [category, setCategory] = useState<IInput>({
    name: '',
    code: '',
  });

  const [branding, setBranding] = useState<IInput>({
    name: '',
    code: '',
  });

  const [manufacturer, setManufacturer] = useState<IInput>({
    name: '',
    code: '',
  });

  const [typeUnit, setTypeUnit] = useState<IInput>({
    name: '',
    code: '',
  });

  const [provider, setProvider] = useState<IInput>({
    name: '',
    code: '',
  });

  const typeUnitList: Array<IInput> = useMemo(() => {
    return [
      { name: 'Unidade', code: 'unit' },
      { name: 'Pacote', code: 'pack' },
      { name: 'Caixa', code: 'box' },
      { name: 'Kilograma', code: 'kg' },
      { name: 'Litro', code: 'liter' },
      { name: 'Dúzia', code: 'dozen' },
    ];
  }, []);

  const initialState = () => {
    setBranding({ name: '', code: '' });
    setCategory({ name: '', code: '' });
    setManufacturer({ name: '', code: '' });
    setProvider({ name: '', code: '' });
    setTypeUnit({
      name: '',
      code: '',
    });
    setBarCode('');
    setMinPrice(0);
    setMaxPrice(0);
    setUseMaxPrice(false);
    setFormErr([]);
    setName('');
    setImageHeavy('');
    setImageLight('');
    setVisible({ ...visible, visible: false });
    setCodeBarErr(false);
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
    setUseProductBase(false);
    setCostPrice(0);
    setMinAmount(0);
  };

  async function handleEditProduct() {
    setPendingAction((state) => !state);
    let urlImage = '';
    if (imageHeavy !== '' && !useProductBase) {
      urlImage = await getURLFromDB(
        true,
        imageHeavy,
        imageLight,
        code3cData?.id ? code3cData.id : '',
        'FDV-product',
      );
    }
    if (useProductBase) {
      urlImage = imageHeavy;
    }

    let dataValidation: Partial<IProduct> = {
      barCode,
      minPrice,
      maxPrice,
      useMaxPrice,
      category_id: category.code,
      branding_id: branding.code,
      manufacturer_id: manufacturer.code,
      unit: typeUnit.code,
      name,
      moreInformation,
      code3c_id: code3cData?.id,
      imageHeavy: urlImage,
      imageLight: urlImage,
      useProductBase: !!useProductBase,
    };

    const dataPassSchema: yup.AnySchema = dataSchema;
    if (moreInformation) {
      dataValidation = {
        ...dataValidation,
        moreInformationList: {
          provider_id: provider.code,
          minAmount,
          costPrice,
        },
      };
    }

    dataPassSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await editProduct(dataValidation, visible.item.id);
        if (response) {
          setFormErr([]);
          initialState();
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  useEffect(() => {
    setName(visible.item.name);
    setImageHeavy(visible.item.imageHeavy);
    setImageLight(visible.item.imageLight);
    setBarCode(visible.item.barCode);
    setMinPrice(visible.item.minPrice);
    setMaxPrice(visible.item.maxPrice);
    if (visible.item.moreInformationList) {
      setMinAmount(visible.item.moreInformationList.minAmount);
    } else {
      setMinAmount(0);
    }
    if (
      visible.item.moreInformationList &&
      visible.item.moreInformationList.costPrice
    ) {
      setCostPrice(visible.item.moreInformationList.costPrice);
    } else {
      setCostPrice(0);
    }

    setUseProductBase(visible.item.useProductBase);
    setUseMaxPrice(visible.item.useMaxPrice);
    setMoreInformation(visible.item.moreInformation);
    const [categorySelected] = categoriesList.filter(
      (system) => system.id === visible.item.category_id,
    );
    if (categorySelected) {
      setCategory({
        name: categorySelected.name,
        code: visible.item.category_id,
      });
    }
    if (visible.item.moreInformationList) {
      const [providerSelected] = providersList.filter(
        (system) => system.id === visible.item.moreInformationList.provider_id,
      );
      if (providerSelected) {
        setProvider({
          name: providerSelected.name,
          code: visible.item.moreInformationList.provider_id,
        });
      } else {
        setProvider({
          name: '',
          code: '',
        });
      }
    }

    const [manufacturerSelected] = manufacturersList.filter(
      (system) => system.id === visible.item.manufacturer_id,
    );
    if (manufacturerSelected) {
      setManufacturer({
        name: manufacturerSelected.name,
        code: visible.item.manufacturer_id,
      });
    }

    const [brandingSelected] = brandingsList.filter(
      (system) => system.id === visible.item.branding_id,
    );
    if (brandingSelected) {
      setBranding({
        name: brandingSelected.name,
        code: visible.item.branding_id,
      });
    }

    const [typeSelected] = typeUnitList.filter(
      (system) => system.code === visible.item.unit,
    );
    if (typeSelected) {
      setTypeUnit({
        name: typeSelected.name,
        code: visible.item.unit,
      });
    }
  }, [
    visible,
    categoriesList,
    brandingsList,
    manufacturersList,
    typeUnitList,
    providersList,
  ]);

  async function getProdutBase(id: string) {
    setBarCode(id);
    if (id.length === 13) {
      const response = await searchProductBase(id);
      if (response) {
        setBarCode(response.barCode);
        setName(response.name);
        setImageHeavy(response.imageHeavy);
        setImageLight(response.imageLight);
        setUseProductBase(true);
      }
    }
    if (
      id.length >= 1 &&
      code3cData &&
      code3cData.id &&
      id !== visible.item.barCode
    ) {
      const response = await searchCodeBar(id, code3cData.id);
      if (response) {
        setCodeBarErr(true);
      } else {
        setCodeBarErr(false);
      }
    }
  }

  function changeUseMaxPrice() {
    setUseMaxPrice(!useMaxPrice);
    setMaxPrice(0);
  }

  useEffect(() => {
    if (minPrice > maxPrice && useMaxPrice === true) {
      setMaxPriceErr(true);
    } else {
      setMaxPriceErr(false);
    }
  }, [minPrice, maxPrice, useMaxPrice]);

  useEffect(() => {
    if (
      typeUnit.code !== 'kg' &&
      typeUnit.code !== 'liter' &&
      minAmount % 1 !== 0 &&
      moreInformation
    ) {
      setMinAmountErr(true);
    } else {
      setMinAmountErr(false);
    }
  }, [minAmount, typeUnit, moreInformation]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              {visible.edit ? (
                <FaEdit className="p-mx-auto p-as-center modal-header-icon" />
              ) : (
                <FaEye className="p-mx-auto p-as-center modal-header-icon" />
              )}
            </div>
            <span className="p-my-auto users-button-text">
              {visible.edit ? 'Editar ' : 'Ver '}Produto
            </span>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label={visible.edit ? 'Salvar' : 'Editar'}
              disabled={pendingAction || minAmountErr || maxPriceErr}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() =>
                visible.edit
                  ? handleEditProduct()
                  : setVisible({ ...visible, edit: true })
              }
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <label className="p-my-0 p-mt-4">Código de Barras</label>
      <InputFormFunction
        disabled={false}
        maxLength={13}
        item={barCode}
        setFunction={getProdutBase}
        formErr={formErr}
        fieldCode="barCode"
      />
      <small className="p-d-block input-invalid">
        {codeBarErr && <p>Já existe um Produto com esse Código de Barras</p>}
      </small>
      <InputForm
        pleaceHolder=""
        disabled={!visible.edit}
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome do Produto"
        fieldCode="name"
      />
      <InputDropForm
        filter
        filterBy="name"
        disabled={!visible.edit}
        item={category}
        setItem={setCategory}
        options={categoriesList.map((categories) => {
          return { name: categories.name, code: categories.id };
        })}
        formErr={formErr}
        fieldLabel="Selecione uma Categoria"
        fieldCode="category_id"
      />
      <InputDropForm
        filter
        filterBy="name"
        disabled={!visible.edit}
        item={branding}
        setItem={setBranding}
        options={brandingsList.map((brandings) => {
          return { name: brandings.name, code: brandings.id };
        })}
        formErr={formErr}
        fieldLabel="Selecione uma Marca"
        fieldCode="branding_id"
      />
      <InputDropForm
        filter
        filterBy="name"
        disabled={!visible.edit}
        item={manufacturer}
        setItem={setManufacturer}
        options={manufacturersList.map((manufacturers) => {
          return { name: manufacturers.name, code: manufacturers.id };
        })}
        formErr={formErr}
        fieldLabel="Selecione um Fabricante"
        fieldCode="manufacturer_id"
      />
      {visible.edit && (
        <div className=" p-mb-0 p-mt-3 p-d-flex p-flex-md-row p-jc-center">
          <Checkbox
            checked={useMaxPrice}
            onChange={() => changeUseMaxPrice()}
            className={
              useMaxPrice ? 'settings-checkbox-selected' : 'settings-checkbox'
            }
          />
          <p className="p-pl-2 p-m-0">Usar Preço Máximo</p>
        </div>
      )}
      <div className="p-grid p-mt-2">
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputFormCurrency
            disabled={false}
            item={minPrice}
            setItem={setMinPrice}
            formErr={formErr}
            fieldLabel="Preço Mínimo:"
            fieldCode="minPrice"
            floatLabel={false}
            iconStatus={() => <div />}
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputFormCurrency
            disabled={!useMaxPrice}
            item={maxPrice}
            setItem={setMaxPrice}
            formErr={formErr}
            fieldLabel="Preço Máximo:"
            fieldCode="maxPrice"
            floatLabel={false}
            iconStatus={() => <div />}
          />
          <small className="p-d-block input-invalid">
            {maxPriceErr && (
              <p>
                Ao usar Preço Maximo, ele deve ser maior que o Preço Mínimo.
              </p>
            )}
          </small>
        </div>
      </div>
      <InputDropForm
        filter={false}
        filterBy=""
        disabled={!visible.edit}
        item={typeUnit}
        setItem={setTypeUnit}
        options={typeUnitList}
        formErr={formErr}
        fieldLabel="Tipo de Unidade"
        fieldCode="unit"
      />

      <div className="p-my-3">
        <Checkbox
          className={
            moreInformation
              ? 'p-p-0 p-mx-auto p-as-center settings-checkbox-selected'
              : 'p-p-0 p-mx-auto p-as-center settings-checkbox'
          }
          checked={moreInformation}
          onChange={() => setMoreInformation(!moreInformation)}
        />
        <label className="p-ml-2">Inserir mais informações</label>
      </div>
      {moreInformation && (
        <>
          <InputDropForm
            filter
            filterBy="name"
            disabled={false}
            item={provider}
            setItem={setProvider}
            options={providersList.map((providers) => {
              return { name: providers.name, code: providers.id };
            })}
            formErr={formErr}
            fieldLabel="Selecione um Fornecedor"
            fieldCode="provider_id"
          />
          <InputFormNumber
            disabled={false}
            item={minAmount}
            setItem={setMinAmount}
            formErr={formErr}
            fieldLabel="Estoque mínimo"
            fieldCode="minAmount"
          />
          <small className="p-d-block input-invalid">
            {minAmountErr && (
              <p>
                Devido ao Tipo de Unídade Selecionada, A Quantidade de mínima
                estoque deve ser um Número Inteiro
              </p>
            )}
          </small>
          <InputFormCurrency
            disabled={false}
            item={costPrice}
            setItem={setCostPrice}
            formErr={formErr}
            fieldLabel="Preço de Custo:"
            fieldCode="costPrice"
            floatLabel={false}
            iconStatus={() => <div />}
          />
        </>
      )}
      <InputImageForm
        visibleEdit={visible.edit}
        loadingImage={pendingAction}
        item={imageHeavy}
        setItem={setImageHeavy}
        fieldLabel="Imagem do Produto"
        useProductBase={useProductBase}
        setUseProductBase={setUseProductBase}
      />
    </Dialog>
  );
};

export default EditProduct;
