// Importações Padrões
import React, { useContext, useState } from 'react';

// Icones
import { FaPlus } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import { IInput } from '../../typings/input.types';
import { IService } from '../../typings/service.types';
import { IValidationError } from '../../typings/yup.types';

// Controladores
import { addService } from '../../controllers/service.controller';

// Componentes
import InputForm from '../input/inputForm.component';
import InputImageForm from '../input/inputImageForm.component';
import InputTextareaComponent from '../input/inputTextarea.component';
import InputDropForm from '../input/inputDropForm.component';
import InputFormCurrency from '../input/inputFormCurrency.component';

// Funções
import { dataSchema } from '../../functions/service.function';
import { getURLFromDB } from '../../functions/image.function';

interface IAddServiceProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddServices = (props: IAddServiceProps) => {
  const { visible, setVisible } = props;

  const { code3cData } = useContext(Auth);

  const [name, setName] = useState<string>('');
  const [useMaxPrice, setUseMaxPrice] = useState(false);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [note, setNote] = useState<string>('');
  const [imageHeavy, setImageHeavy] = useState<string>('');
  const [imageLight, setImageLight] = useState<string>('');

  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);

  const [estimatedTime, setEstimatedTime] = useState<string>('');

  const [estimatedType, setEstimatedType] = useState<IInput>({
    name: '',
    code: '',
  });

  const estimatedTypeList: Array<IInput> = [
    { name: 'Minutos', code: 'minutes' },
    { name: 'Horas', code: 'hours' },
    { name: 'Dias', code: 'days' },
    { name: 'Semanas', code: 'weeks' },
    { name: 'Meses', code: 'months' },
  ];

  const initialState = () => {
    setName('');
    setUseMaxPrice(false);
    setMinPrice(0);
    setMaxPrice(0);
    setEstimatedTime('');
    setEstimatedType({ name: '', code: '' });
    setNote('');
    setImageHeavy('');
    setImageLight('');
    setVisible(false);
    setFormErr([]);
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
  };

  async function handleAddService() {
    setPendingAction((state) => !state);
    let urlImage = '';
    if (imageHeavy !== '') {
      urlImage = await getURLFromDB(
        false,
        imageHeavy,
        imageLight,
        code3cData?.id ? code3cData.id : '',
        'FDV-category',
      );
    }

    const dataValidation: Partial<IService> = {
      name,
      useMaxPrice,
      minPrice,
      maxPrice,
      note,
      estimatedTime,
      estimatedType: estimatedType.code,
      code3c_id: code3cData?.id,
      imageHeavy: urlImage,
      imageLight: urlImage,
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await addService(dataValidation);
        if (response) {
          setFormErr([]);
          setPendingAction((state) => !state);
          initialState();
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              <FaPlus className="p-mx-auto p-as-center modal-header-icon" />
            </div>
            <span className="p-my-auto users-button-text">Novo Serviço</span>
          </div>
        );
      }}
      visible={visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label="Salvar"
              disabled={pendingAction}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() => handleAddService()}
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <InputForm
        pleaceHolder=""
        disabled={false}
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome do Serviço"
        fieldCode="name"
      />
      <div className="p-d-flex p-my-2 p-jc-center">
        <small className="input-warn">Tempo Estimado do Serviço.</small>
      </div>
      <div className="p-grid p-mt-2">
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputForm
            pleaceHolder=""
            disabled={false}
            item={estimatedTime}
            setItem={setEstimatedTime}
            formErr={formErr}
            fieldLabel="Tempo Estimado"
            fieldCode="estimatedTime"
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputDropForm
            filter={false}
            filterBy=""
            disabled={false}
            item={estimatedType}
            setItem={setEstimatedType}
            options={estimatedTypeList}
            formErr={formErr}
            fieldLabel="Selecione o Tipo"
            fieldCode="estimatedType"
          />
        </div>
      </div>
      <div className=" p-mb-0 p-mt-3 p-d-flex p-flex-md-row p-jc-center">
        <Checkbox
          checked={useMaxPrice}
          onChange={() => setUseMaxPrice(!useMaxPrice)}
          className={
            useMaxPrice ? 'settings-checkbox-selected' : 'settings-checkbox'
          }
        />
        <p className="p-pl-2 p-m-0">Usar Preço Máximo</p>
      </div>
      <div className="p-grid p-mt-2">
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputFormCurrency
            disabled={false}
            item={minPrice}
            setItem={setMinPrice}
            formErr={formErr}
            fieldLabel="Preço Mínimo:"
            fieldCode="minPrice"
            floatLabel={false}
            iconStatus={() => <div />}
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <InputFormCurrency
            disabled={!useMaxPrice}
            item={maxPrice}
            setItem={setMaxPrice}
            formErr={formErr}
            fieldLabel="Preço Máximo:"
            fieldCode="maxPrice"
            floatLabel={false}
            iconStatus={() => <div />}
          />
        </div>
      </div>
      <div className="p-d-flex p-my-2 p-jc-center">
        <small className="input-warn">Anotações.</small>
      </div>
      <InputTextareaComponent
        disabled={false}
        item={note}
        setItem={setNote}
        formErr={formErr}
        fieldLabel="Observações"
        fieldCode={note}
        rows={5}
        cols={30}
      />

      <InputImageForm
        visibleEdit
        loadingImage={pendingAction}
        item={imageHeavy}
        setItem={setImageHeavy}
        fieldLabel="Imagem do Serviço"
      />
    </Dialog>
  );
};

export default AddServices;
