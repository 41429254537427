// Importações Padrões
import React from 'react';

// React Prime
import { InputTextarea } from 'primereact/inputtextarea';

interface IInputTextareaProps {
  disabled: boolean;
  item: string;
  fieldLabel: string;
  rows: number;
  cols: number;
}

const NewInputTextAreaSimple = (props: IInputTextareaProps) => {
  const { disabled, item, fieldLabel, rows, cols } = props;
  return (
    <div className="p-field">
      <label className="p-my-0 p-px-1 new-input-text">{fieldLabel}</label>
      <span className="p-d-flex p-input-icon-right">
        <InputTextarea
          autoResize
          disabled={disabled}
          rows={rows}
          cols={cols}
          className="new-input-size"
          value={item}
        />
      </span>
    </div>
  );
};

export default NewInputTextAreaSimple;
