import { ScrollTop } from 'primereact/scrolltop';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFreightsList } from '../../hooks/useFreightsList';

import { Auth } from '../../services/auth.context';
import AddFreights from './components/addFreight';
import DeleteFreights from './components/deleteFreight';
import EditFreights from './components/editFreight';
import ItemFreights from './components/ItemFreight';
import NavFreight from './components/NavFreight';

import {
  IDeleteFreight,
  IEditFreight,
  IFreight,
  initialFreight,
} from './types/Freight';

const FreightPage: React.FC = () => {
  const { setPathName, windowWidth } = useContext(Auth);

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  const [showHideAddFreight, setShowHideAddFreight] = useState(false);
  const [showHideDeleteFreight, setShowHideDeleteFreight] =
    useState<IDeleteFreight>({ id: '', name: '', visible: false });
  const [showHideEditFreight, setShowHideEditFreight] = useState<IEditFreight>({
    item: initialFreight,
    edit: false,
    visible: false,
  });

  useEffect(() => {
    if (
      showHideAddFreight ||
      showHideDeleteFreight.visible ||
      showHideEditFreight.visible
    ) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHideAddFreight, showHideDeleteFreight, showHideEditFreight]);

  const [inputSearch, setInputSearch] = useState('');

  const [freightsList, pending] = useFreightsList();

  return (
    <div className="p-py-3 p-px-3 ">
      <ScrollTop className="button-scroll-top" />
      <AddFreights
        visible={showHideAddFreight}
        setVisible={setShowHideAddFreight}
      />
      <EditFreights
        visible={showHideEditFreight}
        setVisible={setShowHideEditFreight}
      />
      <DeleteFreights
        visible={showHideDeleteFreight}
        setVisible={setShowHideDeleteFreight}
      />
      <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
        <NavFreight
          placeHolder="Pesquisar..."
          pending={!pending}
          addFieldName="Novo Frete"
          actionAdd={showHideAddFreight}
          setActionAdd={setShowHideAddFreight}
          setInputSearch={setInputSearch}
          activeAdd
        />
        <div className="p-d-flex p-dir-col p-mt-2 p-mb-auto width100">
          {windowWidth && windowWidth > 900 && (
            <div
              className="p-grid p-my-1 p-px-3 p-mx-auto p-d-none p-d-md-inline-flex default-box-header"
              style={{
                backgroundColor: '#f8f9fa',
                border: '1px solid #e9ecef',
              }}
            >
              <div className="p-col-1 p-text-center p-as-center">Id</div>
              <div className="p-col-4 p-text-center p-as-center">Imagem</div>
              <div className="p-col-4 p-text-center p-as-center">Frete</div>
              <div className="p-col-3 p-text-center p-as-center">Ações</div>
            </div>
          )}

          {freightsList
            .filter(
              (item) =>
                item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                -1,
            )
            .map((freight: IFreight, index: number) => {
              return (
                <ItemFreights
                  key={String(index)}
                  index={index + 1}
                  freight={freight}
                  showHideEditFreight={showHideEditFreight}
                  setShowHideEditFreight={setShowHideEditFreight}
                  showHideDeleteFreight={showHideDeleteFreight}
                  setShowHideDeleteFreight={setShowHideDeleteFreight}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default FreightPage;
