// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Contexto
import { Auth } from '../services/auth.context';

// Types
import { IManufacturer } from '../typings/manufacturer.types';

// Controladores
import { manufacturerCollection } from '../controllers/manufacturer.controller';

export function useManufacturerList(): [Array<IManufacturer>, boolean] {
  const { code3cData } = useContext(Auth);

  const [manufacturersList, setManufacturerList] = useState<
    Array<IManufacturer>
  >([]);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    setPending((state) => !state);
    const unsubscribe = manufacturerCollection
      .where('code3c_id', '==', code3cData?.id)
      .orderBy('name')
      .onSnapshot((response) => {
        const list: Array<IManufacturer> = [];

        response.docs.forEach((doc) => {
          const documentData = { ...doc.data(), id: doc.id } as IManufacturer;
          list.push(documentData);
        });
        setManufacturerList(list);
      });
    setPending((state) => !state);

    return () => {
      unsubscribe();
      setPending(false);
    };
  }, [code3cData?.id]);

  return [manufacturersList, pending];
}
