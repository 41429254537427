// Importações Padrões
import React, { useContext } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

// React Prime
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

// Icones
import {
  FaBox,
  FaBoxOpen,
  FaCheck,
  FaCheckDouble,
  FaTruck,
} from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';

// Types
import { IEditOrder } from '../../typings/order.types';

// Componentes
import { toasterError } from '../global/toaster.component';

// Contexto
import { Auth } from '../../services/auth.context';

interface IChangeOrderStatusProps {
  visible: IEditOrder;
  created: string;
  processing: string;
  processed: string;
  sended: string;
  finished: string;
  cancelled: string;
  processingDisabled: boolean;
  processedDisabled: boolean;
  sendedDisabled: boolean;
  finishedDisabled: boolean;
  sumProductSeparated: number;
  confirmDialogOrder(item: string, text: string): void;
  pending: boolean;
  sumTotalService: number;
  sumServiceFinished: number;
  SumFutureProductSeparated: number;
}

const FormOrderInfo = (props: IChangeOrderStatusProps) => {
  const { windowWidth } = useContext(Auth);

  const {
    visible,
    created,
    processing,
    processed,
    sended,
    finished,
    cancelled,
    processingDisabled,
    processedDisabled,
    sendedDisabled,
    finishedDisabled,
    sumProductSeparated,
    confirmDialogOrder,
    pending,
    sumTotalService,
    sumServiceFinished,
    SumFutureProductSeparated,
  } = props;

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <>
          {pending ? (
            <div className="p-my-5 p-d-flex p-as-center p-jc-center width100">
              <ProgressSpinner
                className="p-as-center"
                style={{ width: '35px', height: '35px' }}
                strokeWidth="8"
                fill="#EEEEEE"
                animationDuration=".5s"
              />
            </div>
          ) : (
            <>
              {visible.item.status.cancelled === 'Pendente' && (
                <>
                  <div className="width100 p-m-0">
                    {typeof visible.item.status.created !== 'string' ? (
                      <div className="p-mt-2">
                        {moment(visible.item.status.created.toDate()).format(
                          'DD/MM/YYYY hh:mm:ss',
                        )}
                      </div>
                    ) : (
                      <>
                        <>&nbsp;</>
                      </>
                    )}
                    <Button
                      className={`p-py-1 color-${created}-responsive`}
                      label="Criado"
                    />
                  </div>

                  <div className="width100 p-m-0">
                    {typeof visible.item.status.processing !== 'string' ? (
                      <div className="p-mt-2">
                        {moment(visible.item.status.processing.toDate()).format(
                          'DD/MM/YYYY hh:mm:ss',
                        )}
                      </div>
                    ) : (
                      <>&nbsp;</>
                    )}
                    <Button
                      className={`p-py-1 color-${processing}-responsive`}
                      label="Processando"
                      onClick={() =>
                        !processingDisabled &&
                        confirmDialogOrder(
                          'processing',
                          'Você tem certeza que deseja alterar o status do pedido para Processando?',
                        )
                      }
                    />
                  </div>

                  <div className="width100 p-m-0">
                    {typeof visible.item.status.processed !== 'string' ? (
                      <div className="p-mt-2">
                        {moment(visible.item.status.processed.toDate()).format(
                          'DD/MM/YYYY hh:mm:ss',
                        )}
                      </div>
                    ) : (
                      <>
                        <>&nbsp;</>
                      </>
                    )}

                    {sumProductSeparated === 0 &&
                    SumFutureProductSeparated === 0 &&
                    sumTotalService === sumServiceFinished ? (
                      <>
                        <Button
                          className={`p-py-1 color-${processed}-responsive`}
                          label="Processado"
                          onClick={() =>
                            !processedDisabled &&
                            confirmDialogOrder(
                              'processed',
                              'Você tem certeza que deseja alterar o status do pedido para Processado?',
                            )
                          }
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          className={`p-py-1 color-${processed}-responsive`}
                          label="Processado"
                          onClick={() =>
                            toasterError(
                              'ERRO',
                              'Só é possível atualizar o status do pedido para processado se todos os produtos estiverem separado e serviços finalizados!',
                            )
                          }
                        />
                      </>
                    )}
                  </div>

                  <div className="width100 p-m-0">
                    {typeof visible.item.status.sended !== 'string' ? (
                      <div className="p-mt-2">
                        {moment(visible.item.status.sended.toDate()).format(
                          'DD/MM/YYYY hh:mm:ss',
                        )}
                      </div>
                    ) : (
                      <>
                        <>&nbsp;</>
                      </>
                    )}
                    <Button
                      className={`p-py-1 color-${sended}-responsive`}
                      label="Expedir"
                      onClick={() =>
                        !sendedDisabled &&
                        confirmDialogOrder(
                          'sended',
                          'Você tem certeza que deseja alterar o status do pedido para Expedido?',
                        )
                      }
                    />
                  </div>

                  <div className="width100 p-m-0">
                    {typeof visible.item.status.finished !== 'string' ? (
                      <div className="p-mt-2">
                        {moment(visible.item.status.finished.toDate()).format(
                          'DD/MM/YYYY hh:mm:ss',
                        )}
                      </div>
                    ) : (
                      <>
                        <>&nbsp;</>
                      </>
                    )}
                    <Button
                      className={`p-py-1 color-${finished}-responsive`}
                      label="Finalizar"
                      onClick={() =>
                        !finishedDisabled &&
                        confirmDialogOrder(
                          'finished',
                          'Você tem certeza que deseja alterar o status do pedido para Finalizado?',
                        )
                      }
                    />
                  </div>

                  {visible.item.status.finished === 'Pendente' && (
                    <div className="width100 p-m-0">
                      {typeof visible.item.status.cancelled !== 'string' ? (
                        <>
                          <>&nbsp;</>
                        </>
                      ) : (
                        <>
                          <>&nbsp;</>
                        </>
                      )}
                      <Button
                        className={`p-py-1 color-${cancelled}-responsive`}
                        label="Cancelar"
                        onClick={() =>
                          confirmDialogOrder(
                            'cancelled',
                            'Você tem certeza que deseja Cancelar o pedido?',
                          )
                        }
                      />
                    </div>
                  )}
                </>
              )}
              {visible.item.status.cancelled !== 'Pendente' && (
                <div className="width100 p-m-0">
                  {typeof visible.item.status.cancelled !== 'string' ? (
                    <div className="p-mt-2">
                      {moment(visible.item.status.cancelled.toDate()).format(
                        'DD/MM/YYYY hh:mm:ss',
                      )}
                    </div>
                  ) : (
                    <>
                      &nbsp; <br /> &nbsp;
                    </>
                  )}
                  <Button
                    className={`p-py-1 color-${cancelled}-responsive`}
                    label="Pedido Cancelado"
                  />
                </div>
              )}
            </>
          )}
        </>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <>
          {pending ? (
            <div className="p-my-5 p-d-flex p-as-center p-jc-center width100">
              <ProgressSpinner
                className="p-as-center"
                style={{ width: '35px', height: '35px' }}
                strokeWidth="8"
                fill="#EEEEEE"
                animationDuration=".5s"
              />
            </div>
          ) : (
            <>
              {visible.item.status.cancelled === 'Pendente' && (
                <>
                  <div className="p-mb-4 p-d-flex p-as-center p-jc-center width100">
                    <div
                      className={
                        visible.item.status.finished === 'Pendente'
                          ? 'p-d-flex p-flex-column p-as-center p-jc-center width16-6'
                          : 'p-d-flex p-flex-column p-as-center p-jc-center width20'
                      }
                    >
                      <div className={`order-timeline-line-${created}`} />
                      <div
                        className={`p-d-flex p-flex-column p-as-center p-jc-center color-${created}`}
                      >
                        <div className="p-mb-2 p-d-flex p-as-center p-jc-center p-text-center">
                          {typeof visible.item.status.created !== 'string' ? (
                            <>
                              {moment(
                                visible.item.status.created.toDate(),
                              ).format('L')}
                              <br />
                              {moment(
                                visible.item.status.created.toDate(),
                              ).format('HH:mm:ss')}
                            </>
                          ) : (
                            <>
                              &nbsp; <br /> &nbsp;
                            </>
                          )}
                        </div>
                        <div
                          className={`p-d-flex p-as-center p-jc-center order-timeline-circle-${created}`}
                          aria-hidden="true"
                        >
                          <FaCheck className="p-d-flex p-as-center p-jc-center" />
                        </div>
                        <div
                          className={`p-mt-2 p-d-flex p-as-center p-jc-center color-${created}`}
                        >
                          Criado
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        visible.item.status.finished === 'Pendente'
                          ? 'p-d-flex p-flex-column p-as-center p-jc-center width16-6'
                          : 'p-d-flex p-flex-column p-as-center p-jc-center width20'
                      }
                    >
                      <div className={`order-timeline-line-${processing}`} />
                      <div
                        className={`p-d-flex p-flex-column p-as-center p-jc-center color-${processing}`}
                      >
                        <div className="p-mb-2 p-d-flex p-as-center p-jc-center p-text-center">
                          {typeof visible.item.status.processing !==
                          'string' ? (
                            <>
                              {moment(
                                visible.item.status.processing.toDate(),
                              ).format('L')}
                              <br />
                              {moment(
                                visible.item.status.processing.toDate(),
                              ).format('HH:mm:ss')}
                            </>
                          ) : (
                            <>
                              &nbsp; <br /> &nbsp;
                            </>
                          )}
                        </div>

                        <div
                          className={`p-d-flex p-as-center p-jc-center order-timeline-circle-${processing}`}
                          aria-hidden="true"
                          onClick={() =>
                            !processingDisabled &&
                            confirmDialogOrder(
                              'processing',
                              'Você tem certeza que deseja alterar o status do pedido para Processando?',
                            )
                          }
                        >
                          <FaBoxOpen className="p-d-flex p-as-center p-jc-center" />
                        </div>
                        <div
                          className={`p-mt-2 p-d-flex p-as-center p-jc-center color-${processing}`}
                        >
                          Processando
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        visible.item.status.finished === 'Pendente'
                          ? 'p-d-flex p-flex-column p-as-center p-jc-center width16-6'
                          : 'p-d-flex p-flex-column p-as-center p-jc-center width20'
                      }
                    >
                      <div className={`order-timeline-line-${processed}`} />
                      <div
                        className={`p-d-flex p-flex-column p-as-center p-jc-center color-${processed}`}
                      >
                        <div className="p-mb-2 p-d-flex p-as-center p-jc-center p-text-center">
                          {typeof visible.item.status.processed !== 'string' ? (
                            <>
                              {moment(
                                visible.item.status.processed.toDate(),
                              ).format('L')}
                              <br />
                              {moment(
                                visible.item.status.processed.toDate(),
                              ).format('HH:mm:ss')}
                            </>
                          ) : (
                            <>
                              &nbsp; <br /> &nbsp;
                            </>
                          )}
                        </div>

                        {sumProductSeparated === 0 &&
                        SumFutureProductSeparated === 0 &&
                        sumTotalService === sumServiceFinished ? (
                          <>
                            <div
                              className={`p-d-flex p-as-center p-jc-center order-timeline-circle-${processed}`}
                              aria-hidden="true"
                              onClick={() =>
                                !processedDisabled &&
                                confirmDialogOrder(
                                  'processed',
                                  'Você tem certeza que deseja alterar o status do pedido para Processado?',
                                )
                              }
                            >
                              <FaBox className="p-d-flex p-as-center p-jc-center" />
                            </div>
                            <div
                              className={`p-mt-2 p-d-flex p-as-center p-jc-center color-${processed}`}
                            >
                              Processado
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className={`p-d-flex p-as-center p-jc-center order-timeline-circle-${processed}`}
                              aria-hidden="true"
                              onClick={() =>
                                toasterError(
                                  'ERRO',
                                  'Só é possível atualizar o status do pedido para processado se todos os produtos estiverem separado e serviços finalizados!',
                                )
                              }
                            >
                              <FaBox className="p-d-flex p-as-center p-jc-center" />
                            </div>
                            <div
                              className={`p-mt-2 p-d-flex p-as-center p-jc-center color-${processed}`}
                            >
                              Processado
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        visible.item.status.finished === 'Pendente'
                          ? 'p-d-flex p-flex-column p-as-center p-jc-center width16-6'
                          : 'p-d-flex p-flex-column p-as-center p-jc-center width20'
                      }
                    >
                      <div className={`order-timeline-line-${sended}`} />
                      <div
                        className={`p-d-flex p-flex-column p-as-center p-jc-center color-${sended}`}
                      >
                        <div className="p-mb-2 p-d-flex p-as-center p-jc-center p-text-center">
                          {typeof visible.item.status.sended !== 'string' ? (
                            <>
                              {moment(
                                visible.item.status.sended.toDate(),
                              ).format('L')}
                              <br />
                              {moment(
                                visible.item.status.sended.toDate(),
                              ).format('HH:mm:ss')}
                            </>
                          ) : (
                            <>
                              &nbsp; <br /> &nbsp;
                            </>
                          )}
                        </div>
                        <div
                          className={`p-d-flex p-as-center p-jc-center order-timeline-circle-${sended}`}
                          aria-hidden="true"
                          onClick={() =>
                            !sendedDisabled &&
                            confirmDialogOrder(
                              'sended',
                              'Você tem certeza que deseja alterar o status do pedido para Expedido?',
                            )
                          }
                        >
                          <FaTruck className="p-d-flex p-as-center p-jc-center" />
                        </div>
                        <div className="p-mt-2 p-d-flex p-as-center p-jc-center">
                          Expedido
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        visible.item.status.finished === 'Pendente'
                          ? 'p-d-flex p-flex-column p-as-center p-jc-center width16-6'
                          : 'p-d-flex p-flex-column p-as-center p-jc-center width20'
                      }
                    >
                      <div className={`order-timeline-line-${finished}`} />
                      <div
                        className={`p-d-flex p-flex-column p-as-center p-jc-center color-${finished}`}
                      >
                        <div className="p-mb-2 p-d-flex p-as-center p-jc-center p-text-center">
                          {typeof visible.item.status.finished !== 'string' ? (
                            <>
                              {moment(
                                visible.item.status.finished.toDate(),
                              ).format('L')}
                              <br />
                              {moment(
                                visible.item.status.finished.toDate(),
                              ).format('HH:mm:ss')}
                            </>
                          ) : (
                            <>
                              &nbsp; <br /> &nbsp;
                            </>
                          )}
                        </div>
                        <div
                          className={`p-d-flex p-as-center p-jc-center order-timeline-circle-${finished}`}
                          aria-hidden="true"
                          onClick={() =>
                            !finishedDisabled &&
                            confirmDialogOrder(
                              'finished',
                              'Você tem certeza que deseja alterar o status do pedido para Finalizado?',
                            )
                          }
                        >
                          <FaCheckDouble className="p-d-flex p-as-center p-jc-center" />
                        </div>
                        <div className="p-mt-2 p-d-flex p-as-center p-jc-center">
                          Finalizado
                        </div>
                      </div>
                    </div>
                    {visible.item.status.finished === 'Pendente' && (
                      <div className="p-d-flex p-flex-column p-as-center p-jc-center width16-6">
                        <div className={`order-timeline-line-${cancelled}`} />
                        <div
                          className={`p-d-flex p-flex-column p-as-center p-jc-center color-${cancelled}`}
                        >
                          <div className="p-mb-2 p-d-flex p-as-center p-jc-center p-text-center">
                            {typeof visible.item.status.cancelled !==
                            'string' ? (
                              <>
                                &nbsp; <br /> &nbsp;
                              </>
                            ) : (
                              <>
                                &nbsp; <br /> &nbsp;
                              </>
                            )}
                          </div>
                          <div
                            className={`p-d-flex p-as-center p-jc-center order-timeline-circle-${cancelled}`}
                            aria-hidden="true"
                            onClick={() =>
                              confirmDialogOrder(
                                'cancelled',
                                'Você tem certeza que deseja Cancelar o pedido?',
                              )
                            }
                          >
                            <ImCancelCircle className="p-d-flex p-as-center p-jc-center" />
                          </div>
                          <div className="p-mt-2 p-d-flex p-as-center p-jc-center">
                            Cancelado
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              {visible.item.status.cancelled !== 'Pendente' && (
                <div className="p-mb-4 p-d-flex p-flex-column p-as-center p-jc-center width100">
                  <div className={`order-timeline-line-${cancelled}`} />
                  <div
                    className={`p-d-flex p-flex-column p-as-center p-jc-center color-${cancelled}`}
                  >
                    <div className="p-mb-2 p-d-flex p-as-center p-jc-center p-text-center">
                      {typeof visible.item.status.cancelled !== 'string' ? (
                        <>
                          {moment(
                            visible.item.status.cancelled.toDate(),
                          ).format('L')}
                          <br />
                          {moment(
                            visible.item.status.cancelled.toDate(),
                          ).format('HH:mm:ss')}
                        </>
                      ) : (
                        <>
                          &nbsp; <br /> &nbsp;
                        </>
                      )}
                    </div>
                    <div
                      className={`p-d-flex p-as-center p-jc-center order-timeline-circle-${cancelled}`}
                      aria-hidden="true"
                    >
                      <ImCancelCircle className="p-d-flex p-as-center p-jc-center" />
                    </div>
                    <div className="p-mt-2 p-d-flex p-as-center p-jc-center">
                      Cancelado
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      );
    }
    return <div />;
  }

  return changeBreakpoints();
};

export default FormOrderInfo;
