import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState, useContext } from 'react';
import { Auth } from '../../services/auth.context';
import { IListProducts } from '../../typings/list.types';
import ListItensProducts from './listItemsProducts.component';
import ListItemsServices from './listItemsServices.component';

interface IListItemsProps {
  productsOnList: IListProducts;
  setProductsOnList: React.Dispatch<React.SetStateAction<IListProducts>>;
  servicesOnList: IListProducts;
  setServicesOnList: React.Dispatch<React.SetStateAction<IListProducts>>;
}

const ListItems = (props: IListItemsProps) => {
  const {
    productsOnList,
    setProductsOnList,
    servicesOnList,
    setServicesOnList,
  } = props;
  const [toggleList, setToggleList] = useState(false);

  const { system3cData } = useContext(Auth);

  const [search, setSearch] = useState('');
  const [inputSearch, setInputSearch] = useState('');

  useEffect(() => {
    setInputSearch('');
    setSearch('');
  }, [toggleList]);

  return (
    <div>
      <div className="p-grid p-px-2 p-pt-2">
        <div
          className={`${
            system3cData?.fdvSystemServices ? 'p-col-6' : 'p-col-12'
          } p-d-flex ${
            toggleList ? 'editListItems-tab' : 'editListItems-active-tab'
          }`}
          aria-hidden="true"
          onClick={() => {
            setToggleList(false);
          }}
        >
          <p className="p-mx-auto">Produtos</p>
        </div>
        {system3cData?.fdvSystemServices && (
          <div
            className={`p-col-6 p-d-flex ${
              toggleList ? 'editListItems-active-tab' : 'editListItems-tab'
            }`}
            aria-hidden="true"
            onClick={() => {
              setToggleList(true);
            }}
          >
            <p className="p-mx-auto">Serviços</p>
          </div>
        )}
      </div>
      <div className="p-grid p-mt-2 p-justify-between">
        <div className="p-col-12 p-md-12 p-lg-6 p-jc-end">
          <div className="width100">
            <span className="p-input-icon-right width90">
              {search !== '' && (
                <i
                  aria-hidden="true"
                  className="p-d-none p-d-md-inline-flex pi pi-times
                  default-box-header-search-clear"
                  onClick={() => {
                    setSearch('');
                    setInputSearch('');
                  }}
                />
              )}
              <InputText
                onKeyPress={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    setInputSearch(search);
                  }
                }}
                placeholder="Pesquisar..."
                className="editList-input"
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(e.target.value);
                }}
              />
            </span>
            <Button
              icon="pi pi-search"
              className="editList-select-all width10"
              onClick={() => setInputSearch(search)}
            />
          </div>
        </div>
      </div>
      {toggleList ? (
        <ListItemsServices
          inputSearch={inputSearch}
          servicesOnList={servicesOnList}
          setServicesOnList={setServicesOnList}
        />
      ) : (
        <ListItensProducts
          inputSearch={inputSearch}
          productsOnList={productsOnList}
          setProductsOnList={setProductsOnList}
        />
      )}
    </div>
  );
};

export default ListItems;
