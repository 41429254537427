import { Dropdown } from 'primereact/dropdown';
import { useCitiesList } from '../../hooks/useCitiesList';

import { ISystemFunction } from '../../typings/users.types';

interface ICitiesDropSelect {
  city: ISystemFunction;
  setCity: React.Dispatch<React.SetStateAction<ISystemFunction>>;
  UF: string;
  disabled: boolean;
}

const ListCitiesDropSelect = ({
  city,
  setCity,
  UF,
  disabled,
}: ICitiesDropSelect) => {
  const [citiesList] = useCitiesList(UF);

  const citiesOption = citiesList.map((cities) => {
    return { name: cities.name, code: cities.id };
  });

  return (
    <Dropdown
      filter
      disabled={UF === '' || disabled}
      options={citiesOption}
      value={city}
      onChange={(e) => {
        setCity(e.value);
      }}
      optionLabel="name"
      filterBy="name"
      placeholder="Selecione uma Cidade"
      className="p-my-2 settings-input settings-dropdown"
    />
  );
};

export default ListCitiesDropSelect;
