import firebase from 'firebase';
import { useContext, useEffect, useState } from 'react';

import { Auth } from '../../../services/auth.context';

import { initialTotalOrders, ITotalOrders } from '../../../typings/order.types';

import { code3cCollection } from '../../../controllers/code3c.controller';
import moment from 'moment';

export function useTotalOrdersList(): [ITotalOrders, ITotalOrders, boolean] {
  const { code3cData } = useContext(Auth);

  const [totalOrdersList, setTotalOrdersList] =
    useState<ITotalOrders>(initialTotalOrders);
  const [pendingTotalOrders, setPendingTotalOrders] = useState<boolean>(true);

  const [fullOrdersList, setFullOrdersList] =
    useState<ITotalOrders>(initialTotalOrders);

  useEffect(() => {
    if (code3cData && code3cData.id) {
      setPendingTotalOrders(true);
      const unsubscribe = code3cCollection
        .doc(code3cData.id)
        .collection('FDV-shard-totalOrders')
        .onSnapshot((response) => {
          const list: Array<ITotalOrders> = [];
          response.docs.forEach((item) => {
            list.push({ ...item.data(), id: item.id } as ITotalOrders);
          });
          const newData = list.find(
            (item) => Number(item.id) === moment().year(),
          );

          const newFullData = list.reduce((acc, cur) => {
            if (cur) {
              const newAcc = {
                ...acc,
                totalOrders: cur.totalOrders + acc.totalOrders,
                totalCreated: cur.totalCreated + acc.totalCreated,
                totalProcessing: cur.totalProcessing + acc.totalProcessing,
                totalProcessed: cur.totalProcessed + acc.totalProcessed,
                totalSended: cur.totalSended + acc.totalSended,
                totalFinished: cur.totalFinished + acc.totalFinished,
                totalCancelled: cur.totalCancelled + acc.totalCancelled,
              };
              return newAcc;
            }
            return acc;
          }, initialTotalOrders);

          setFullOrdersList(newFullData);

          if (newData) {
            setTotalOrdersList(newData);
            setPendingTotalOrders(false);
          } else {
            setTotalOrdersList({
              id: '',
              totalOrders: 0,
              totalCreated: 0,
              totalProcessing: 0,
              totalProcessed: 0,
              totalSended: 0,
              totalFinished: 0,
              totalCancelled: 0,
              startDate: firebase.firestore.Timestamp.now(),
              endDate: firebase.firestore.Timestamp.now(),
            });
            setPendingTotalOrders(false);
          }
        });

      return () => {
        unsubscribe();
      };
    }
  }, [code3cData, code3cData?.id]);

  return [totalOrdersList, fullOrdersList, pendingTotalOrders];
}
