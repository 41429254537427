// Importações Padrões
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

// Icones
import { AiOutlineQuestionCircle, AiOutlineCopy } from 'react-icons/ai';

// Contexto
import { Auth } from '../services/auth.context';

// Componentes
import ConfirmNewTab from '../components/global/confirmNewTab.component';

// Estilos
import '../styles/global/help.scss';

const Help: React.FC = () => {
  const { userData, setPathName } = useContext(Auth);

  const location = useLocation();

  const supLink = 'https://tawk.to/chat/5c04c5b340105007f37aaeee/1ctpap7pm';

  const copyUserCode = () => {
    const copyText = document.getElementById('help-idClient');
    const textArea: HTMLTextAreaElement = document.createElement('textarea');
    if (copyText?.textContent) {
      textArea.value = copyText.textContent;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('Copy');
      textArea.remove();
    }
  };

  useEffect(() => {
    setPathName(location.pathname);
  }, [location, setPathName]);

  return (
    <div className="p-py-4 p-px-3">
      <div
        className="p-p-3  p-text-justify dashboard-display"
        style={{ backgroundColor: 'white' }}
      >
        <div className="p-d-flex p-mt-0 p-mb-2">
          <div className="p-d-flex p-my-0 p-mr-2 help-icon-background">
            <AiOutlineQuestionCircle className="p-my-auto p-mx-auto help-icon " />
          </div>
          <span className="p-my-auto help-title">Ajuda</span>
        </div>
        <div className="p-my-0 help-content">
          <div className="p-my-0 help-content-header">
            <p className="p-my-auto">Bem vindo a sua central de Ajuda!</p>
          </div>
          <div className="help-content-body">
            <p className="p-mb-auto">
              Estamos aqui para lhe ajudar, vamos a um passo a passo para
              entender melhor o seu problema:
            </p>
            <div className="p-mb-3">
              <p className="p-mb-2 p-pl-1">Passo 1:</p>
              <p className="p-my-auto p-pl-1">
                O seu problema é relacionado a algum dado ou interface do
                sistema? Se sim tente apertar as teclas ao mesmo tempo CTRL +
                SHIFT + R, se estiver no computador. Verifique se o seu erro foi
                solucionado. Caso não tenha solucionado, vá para o passo 2. Caso
                não seja dado ou interface do sistema, vá direto ao passo 3.
              </p>
            </div>
            <div className="p-mb-3">
              <p className="p-mb-2 p-pl-1">Passo 2:</p>
              <p className="p-my-auto p-pl-1">
                Verifique se a sua conexão com a internet está normal, abra uma
                outra página por exemplo o Youtube. Por esse{' '}
                <span
                  aria-hidden="true"
                  className="link"
                  onClick={() =>
                    ConfirmNewTab('https://www.youtube.com/watch?v=xcJtL7QggTI')
                  }
                >
                  link
                </span>{' '}
                e verifique se está carregado normalmente, depois realize um
                teste de velocidade, pode ser um teste de sua preferência, mas a
                nossa dica está nesse{' '}
                <span
                  aria-hidden="true"
                  className="link"
                  onClick={() => ConfirmNewTab('https://fast.com/pt/')}
                >
                  link
                </span>
                . Se caso estiver tudo bem com sua internet reinicie o seu
                navegador, seu computador ou mesmo seu roteador. Se caso nenhum
                desses resolveu, iremos para o próximo passo.
              </p>
            </div>
            <div className="p-mb-4">
              <p className="p-mb-2 p-pl-1">Passo 3:</p>
              <p className="p-my-auto p-pl-1">
                Com o seu código de cliente em mãos, que está logo abaixo, você
                será redirecionado para outra página, onde então irá selecionar
                o setor que precisa de ajuda, e inserir um comentário sobre o
                problema, não economize nas palavras, quanto mais sabermos
                melhor. E dessa forma, entrará em contato conosco.
              </p>
            </div>
          </div>
        </div>
        <div className="p-my-0 p-pt-1 help-navigation">
          <div className="p-mb-2 p-text-center ">
            <p className="p-my-auto help-ClientCode">
              Código do Cliente :
              <span className="p-ml-2" id="help-idClient">
                {userData?.code3c_id}
              </span>
              &nbsp;
              <AiOutlineCopy
                className="help-button-copyId"
                onClick={() => copyUserCode()}
              />{' '}
            </p>
          </div>
          <div className="p-d-flex">
            <button
              type="button"
              aria-hidden="true"
              onPointerDown={(e) => {
                e.buttons === 1 && ConfirmNewTab(supLink);
              }}
              className="p-mx-auto p-py-2 help-button"
            >
              {moment().isBetween(moment().hour(9), moment().hour(17))
                ? 'SUPORTE VIA CHAT'
                : 'SUPORTE VIA MENSAGEM'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
