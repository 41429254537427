// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Contexto
import { Auth } from '../services/auth.context';

// Types
import { IUser } from '../typings/users.types';

// Controladores
import { userCollection } from '../controllers/user.controller';

interface IUseClient {
  usersList: Array<IUser>;
  pending: boolean;
}

export function useUserslist(): IUseClient {
  const { code3cData } = useContext(Auth);

  const [usersList, setUsersList] = useState<Array<IUser>>([]);
  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {
    setPending((state) => !state);
    const unsubscribe = userCollection
      .where('code3c_id', '==', code3cData?.id)
      .orderBy('name')
      .onSnapshot((response) => {
        const list: Array<IUser> = [];

        response.docs.forEach((doc) => {
          const documentData = { ...doc.data(), id: doc.id } as IUser;
          const [hasSystem] = documentData.systems.filter(
            (system) => system.systemId === 'CFDV',
          );
          if (hasSystem) {
            list.push(documentData);
          }
        });
        setUsersList(list);
      });
    setPending((state) => !state);

    return () => {
      unsubscribe();
      setPending(false);
    };
  }, [code3cData?.id]);

  return { usersList, pending };
}
