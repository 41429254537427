// Importações Padrões
import { useCallback, useEffect, useState } from 'react';

// Controladores
import { ICitiesIbge, searchCitiesByUF } from '../controllers/ibge.controller';

export function useCitiesIbgeList(
  UF: string,
): [Array<ICitiesIbge> | undefined, boolean] {
  const [citiesIbgeList, setCitiesIbgeList] = useState<Array<ICitiesIbge>>();
  const [pending, setPending] = useState<boolean>(true);

  const getCitiesIbge = useCallback(async () => {
    const cities: Array<ICitiesIbge> | null | undefined =
      await searchCitiesByUF(UF);
    if (cities) {
      setCitiesIbgeList(cities);
      return true;
    }
    return false;
  }, [UF]);

  useEffect(() => {
    if (UF !== '') {
      setPending((state) => !state);
      if (getCitiesIbge) {
        const response = getCitiesIbge();
        !!response && setPending((state) => !state);
      }
    }
    return () => {
      setPending(false);
    };
  }, [UF, getCitiesIbge]);

  return [citiesIbgeList, pending];
}
