// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Icones
import { FaSearch } from 'react-icons/fa';

// React Prime
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import { IInput } from '../../typings/input.types';
import { IOrder } from '../../typings/order.types';

interface INavCategoryProps {
  setInputSearch: React.Dispatch<React.SetStateAction<string>>;
  pleaceHolder: string;
  selectedListType: () => IOrder[];
  selectedStatus: IInput;
  ordersStatus: Array<IInput>;
  setSelectedStatus: React.Dispatch<React.SetStateAction<IInput>>;
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  searchEndDate: Date;
  searchStartDate: Date;
  searchReport: () => void;
}

const NavOrders = (props: INavCategoryProps) => {
  const { windowWidth } = useContext(Auth);

  const [search, setSearch] = useState('');

  const {
    setInputSearch,
    pleaceHolder,
    selectedListType,
    selectedStatus,
    ordersStatus,
    setSelectedStatus,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    searchEndDate,
    searchStartDate,
    searchReport,
  } = props;

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <>
          <div className="p-d-grid">
            <div className="p-col-12 p-p-0 p-mt-2">
              <span className="p-input-icon-right width85">
                {search !== '' && (
                  <i
                    aria-hidden="true"
                    className="p-d-md-inline-flex pi pi-times default-box-header-search-clear"
                    onClick={() => {
                      setSearch('');
                      setInputSearch('');
                    }}
                  />
                )}
                <InputText
                  onKeyPress={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter') {
                      setInputSearch(search);
                    }
                  }}
                  className="width100 height2-5Rem input-text-muted"
                  placeholder={pleaceHolder}
                  value={search}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearch(e.target.value);
                  }}
                />
              </span>
              <Button
                className="p-jc-center p-p-0 width15 height2-5Rem button-primary"
                onClick={() => {
                  setInputSearch(search);
                }}
              >
                <FaSearch className="fontSize1-5Rem" />
              </Button>
            </div>
          </div>
          <small className="p-d-flex p-as-center p-mt-2">
            Total de Pedidos: {selectedListType().length}
          </small>
          <div className="p-d-flex width100">
            <Dropdown
              className="p-d-flex width100"
              value={selectedStatus}
              options={ordersStatus}
              onChange={(e) => {
                setSelectedStatus(e.value);
              }}
              optionLabel="name"
              placeholder="Pedidos em Aberto"
            />
          </div>
          <small className="p-d-flex p-as-center p-mt-2">
            Data Inicial&emsp;
          </small>
          <div className="p-d-flex width100">
            <Calendar
              className="visits-button p-d-flex width100"
              showIcon
              locale="pt"
              dateFormat="dd/mm/yy"
              value={startDate}
              onChange={(e) => setStartDate(new Date(e.target.value as Date))}
            />
          </div>
          <small className="p-d-flex p-as-center p-mt-2">
            Data Final&emsp;
          </small>
          <div className="p-d-flex width100">
            <Calendar
              className="visits-button p-d-flex width100"
              showIcon
              locale="pt"
              dateFormat="dd/mm/yy"
              value={endDate}
              onChange={(e) => setEndDate(new Date(e.target.value as Date))}
            />
          </div>
          <div className="p-d-flex nav-reports width100 p-my-3">
            <Button
              disabled={
                searchEndDate === endDate && searchStartDate === startDate
              }
              className="button-primary width100"
              onClick={() => searchReport()}
              label="Consultar Pedidos"
            />
          </div>
        </>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <>
          <div className="p-d-flex p-justify-end width100">
            <div style={{ width: '20rem' }}>
              <span className="p-input-icon-right width90">
                {search !== '' && (
                  <i
                    aria-hidden="true"
                    className="p-d-none p-d-md-inline-flex pi pi-times default-box-header-search-clear"
                    onClick={() => {
                      setSearch('');
                      setInputSearch('');
                    }}
                  />
                )}
                <InputText
                  placeholder="Buscar por Pedidos..."
                  className="editList-input"
                  value={search}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearch(e.target.value);
                  }}
                />
              </span>
              <Button
                icon="pi pi-search"
                className="editList-select-all width10"
                onClick={() => setInputSearch(search)}
              />
            </div>
          </div>
          <div className="p-d-flex">
            <div className="width25 p-px-2">
              <small className="p-d-flex p-as-center p-mt-1">
                Total de Pedidos: {selectedListType().length}
              </small>
              <div className="p-d-flex width100">
                <Dropdown
                  className="p-d-flex width100"
                  value={selectedStatus}
                  options={ordersStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.value);
                  }}
                  optionLabel="name"
                  placeholder="Pedidos em Aberto"
                />
              </div>
            </div>
            <div className="width25 p-px-2">
              <small className="p-d-flex p-as-center p-mt-1">
                Data Inicial&emsp;
              </small>
              <div className="p-d-flex width100">
                <Calendar
                  className="visits-button p-d-flex width100"
                  showIcon
                  locale="pt"
                  dateFormat="dd/mm/yy"
                  value={startDate}
                  onChange={(e) =>
                    setStartDate(new Date(e.target.value as Date))
                  }
                />
              </div>
            </div>
            <div className="width25 p-px-2">
              <small className="p-d-flex p-as-center p-mt-1">
                Data Final&emsp;
              </small>
              <div className="p-d-flex width100">
                <Calendar
                  className="visits-button p-d-flex width100"
                  showIcon
                  locale="pt"
                  dateFormat="dd/mm/yy"
                  value={endDate}
                  onChange={(e) => setEndDate(new Date(e.target.value as Date))}
                />
              </div>
            </div>
            <div className="width25 p-px-2">
              <small className="p-d-flex p-as-center p-mt-1">&emsp;</small>
              <div className="p-d-flex width100">
                <Button
                  disabled={
                    searchEndDate === endDate && searchStartDate === startDate
                  }
                  className="button-primary width100"
                  onClick={() => searchReport()}
                  label="Consultar Pedidos"
                />
              </div>
            </div>
          </div>
        </>
      );
    }
    return <div />;
  }

  useEffect(() => {
    if (search === '') {
      setInputSearch('');
    }
  }, [search, setInputSearch]);

  return changeBreakpoints();
};

export default NavOrders;
