// Importações Padrões
import { useState } from 'react';

// Types
import IRoute from '../../typings/routes.types';

// Componentes
import ItemMenuRoute from './ItemMenuRoute.component';
import ItemMenuRouteSubList from './ItemMenuRouteSubList.component';

// Estilos
import '../../styles/global/itemMenu.scss';

interface IItemMenu {
  itemMenu: IRoute;
  pathName: string | undefined;
  extender: boolean | undefined;
}

const ItemMenu = ({ itemMenu, pathName, extender }: IItemMenu) => {
  const { type, path, pathname, icon } = itemMenu;
  const [subItemExtended, setSubItemExtended] = useState(false);
  const active: boolean =
    itemMenu.path.toUpperCase() === pathName?.toUpperCase();

  return type === 'route' ? (
    <ItemMenuRoute
      path={path}
      pathname={pathname}
      icon={icon}
      active={active}
      extender={extender && extender ? extender : false}
    />
  ) : (
    <ItemMenuRouteSubList
      pathNameRoute={pathName && pathName ? pathName : ''}
      pathname={pathname}
      subRoutes={itemMenu.subroutes ? itemMenu.subroutes : ['']}
      icon={icon}
      extender={extender && extender ? extender : false}
      subItemExtended={subItemExtended}
      setSubItemExtended={setSubItemExtended}
    />
  );
};

export default ItemMenu;
