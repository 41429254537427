// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useUserslist } from '../hooks/useUsersList';

// Types
import {
  IUser,
  IDeleteUser,
  IEditUser,
  initialUser,
} from '../typings/users.types';

// Componentes
import ItemUser from '../components/users/itemUser.component';
import AddUser from '../components/users/addUser.component';
import EditUser from '../components/users/editUser.component';
import DeleteUser from '../components/users/deleteUser.component';
import NavUser from '../components/users/navUser.component';

// Estilos
import '../styles/global/users.scss';

const Users: React.FC = () => {
  const { setPathName, system3cData, windowWidth } = useContext(Auth);
  const { usersList, pending } = useUserslist();
  const [inputSearch, setInputSearch] = useState('');

  const [showHideAddUser, setShowHideAddUser] = useState(false);
  const [showHideDeleteUser, setShowHideDeleteUser] = useState<IDeleteUser>({
    id: '',
    name: '',
    visible: false,
  });

  const [showHideEditUser, setShowHideEditUser] = useState<IEditUser>({
    item: initialUser,
    edit: false,
    visible: false,
  });

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  useEffect(() => {
    if (
      showHideAddUser ||
      showHideEditUser.visible ||
      showHideDeleteUser.visible
    ) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHideAddUser, showHideEditUser, showHideDeleteUser]);

  return (
    <div className="p-py-3 p-px-3 ">
      <AddUser visible={showHideAddUser} setVisible={setShowHideAddUser} />
      <EditUser visible={showHideEditUser} setVisible={setShowHideEditUser} />
      <DeleteUser
        visible={showHideDeleteUser}
        setVisible={setShowHideDeleteUser}
      />
      <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
        <NavUser
          placeHolder="Pesquisar usuários..."
          pending={pending}
          addFieldName="Novo Usuário"
          addButtonDisabled={
            usersList.length >=
            (system3cData?.systemBilling.systemAccounts
              ? parseInt(system3cData.systemBilling.systemAccounts, 10)
              : 5)
          }
          actionAdd={showHideAddUser}
          setActionAdd={setShowHideAddUser}
          setInputSearch={setInputSearch}
          activeAdd
        />

        <div className="p-d-flex p-dir-col p-mt-2 p-mb-auto width100">
          {windowWidth && windowWidth > 900 && (
            <div
              className="p-grid p-my-1 p-px-3 p-mx-auto p-d-none p-d-md-inline-flex default-box-header"
              style={{
                backgroundColor: '#f8f9fa',
                border: '1px solid #e9ecef',
              }}
            >
              <div className="p-col-1 p-text-center p-as-center">Id</div>
              <div className="p-col-3 p-text-center p-as-center">Usuário</div>
              <div className="p-col-4 p-text-center p-as-center">Email</div>
              <div className="p-col-2 p-text-center p-as-center">Tipo</div>
              <div className="p-col-2 p-text-center p-as-center">Ações</div>
            </div>
          )}

          {usersList
            .filter((item) => item.name)
            .filter(
              (item) =>
                item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                -1,
            )
            .map((user: IUser, index: number) => {
              return (
                <ItemUser
                  key={index.toString()}
                  showHideEditUser={showHideEditUser}
                  setShowHideEditUser={setShowHideEditUser}
                  showHideDeleteUser={showHideDeleteUser}
                  setShowHideDeleteUser={setShowHideDeleteUser}
                  user={user}
                  index={index + 1}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Users;
