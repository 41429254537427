// Importações Padrões
import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

export const dataSchema = yup.object().shape({
  code3c_id: yup.string(),
  name: yup.string().label('Nome').min(3).required(),
  estimatedTime: yup.string().label('Tempo Estimado').required(),
  estimatedType: yup.string().label('Tipo do Tempo Estimado').required(),
});
