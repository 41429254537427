// Importações Padrões
import firebase from 'firebase';
import { IAddress } from './code3c.types';

export interface IOrder {
  id: string;
  name: string; // Remover Depois
  orderNumber?: number;
  budgetsNumber?: number;

  deliveryPrice?: number;

  freight_id?: string;
  freight_name?: string;

  status: IStatus;
  list_name: string;
  province_name: string;
  city_name: string;
  total: number;
  sumService: number;
  sumProduct: number;
  note: string;

  productOrder: Array<IProductOrder>;
  serviceOrder: Array<IServiceOrder>;

  isBonus: boolean;

  paymentOrder?: Array<IPaymentOrder>;

  client_name: string;
  user_name: string;
  created_at: Date;
  updated_at: Date;
  user_commission?: number;
  address?: { [string: string]: IAddress };
}
export interface IPaymentOrder {
  amountPay: number;
  payment_id: string;
  payment_name: string;
}

export interface IEditOrder {
  item: IOrder;
  edit: boolean;
  visible: boolean;
}

export interface IStatus {
  created: string | firebase.firestore.Timestamp;
  processing: string | firebase.firestore.Timestamp;
  processed: string | firebase.firestore.Timestamp;
  sended: string | firebase.firestore.Timestamp;
  finished: string | firebase.firestore.Timestamp;
  cancelled: string | firebase.firestore.Timestamp;
}

export interface ITotalOrders {
  id: string;
  totalOrders: number;
  totalCreated: number;
  totalProcessing: number;
  totalProcessed: number;
  totalSended: number;
  totalFinished: number;
  totalCancelled: number;
  startDate: firebase.firestore.Timestamp;
  endDate: firebase.firestore.Timestamp;
}

export interface IDayOrders {
  dayOrdersQuantity: number;
  dayOrdersTotal: number;
  dayOrdersDate: firebase.firestore.Timestamp;
  dayOrdersSellers: Array<IDayOrdersSellers>;
  dayOrdersClients: Array<IDayOrdersClients>;
  productList: Array<IDayOrdersProductList>;
  serviceList: Array<IDayOrdersServiceList>;
}

export interface IDayOrdersSellers {
  seller_id: string;
  seller_name: string;
  seller_quantity: number;
  seller_total: number;
}

export interface IDayOrdersClients {
  client_id: string;
  client_name: string;
  client_quantity: number;
  client_total: number;
}

export interface IDayOrdersProductList {
  product_id: string;
  product_name: string;
  totalAmount: number;
  totalPrice: number;
}

export interface IDayOrdersServiceList {
  service_id: string;
  service_name: string;
  totalAmount: number;
  totalPrice: number;
}

export interface IServiceOrder {
  disabled: boolean;
  index: number;
  service_id: string;
  service_name: string;
  service_note: string;
  estimatedTime: number;
  estimatedType: string;
  imageLight: string;
  imageHeavy: string;
  note: string;
  status: IStatusService;
  price: number;
}

export interface IStatusService {
  toDo: string | firebase.firestore.Timestamp;
  doing: string | firebase.firestore.Timestamp;
  done: string | firebase.firestore.Timestamp;
}

export interface IProductOrder {
  index: number;
  price: number;
  priceReservation: number;
  quantity: number;
  reservationQuantity: number;
  useProductReservation: boolean;
  product_id: string;
  product_name: string;
  branding_id: string;
  manufacturer_id: string;
  category_id: string;
  barCode: string;
  unitSell: string;
  imageLight: string;
  imageHeavy: string;
  separated: boolean;
  service_id?: string;
  service_index?: number;
  note: string;
  disabled: boolean;
  useProductBase: boolean;
  paymentOrder?: IPaymentOrder[];
}

export const initialOrder: IOrder = {
  status: {
    created: '',
    processing: '',
    processed: '',
    sended: '',
    finished: '',
    cancelled: '',
  },
  serviceOrder: [
    {
      disabled: false,
      index: 0,
      service_id: '',
      service_name: '',
      service_note: '',
      estimatedTime: 0,
      estimatedType: '',
      imageLight: '',
      imageHeavy: '',
      note: '',
      status: {
        toDo: '',
        doing: '',
        done: '',
      },
      price: 0,
    },
  ],
  productOrder: [
    {
      index: 0,
      price: 0,
      priceReservation: 0,
      quantity: 0,
      reservationQuantity: 0,
      useProductReservation: false,
      product_id: '',
      product_name: '',
      branding_id: '',
      manufacturer_id: '',
      category_id: '',
      barCode: '',
      unitSell: '',
      imageLight: '',
      imageHeavy: '',
      separated: false,
      service_id: '',
      note: '',
      disabled: false,
      useProductBase: false,
    },
  ],
  paymentOrder: [{ amountPay: 0, payment_id: '', payment_name: '' }],
  isBonus: false,
  note: '',
  province_name: '',
  city_name: '',
  list_name: '',
  total: 0,
  sumProduct: 0,
  sumService: 0,
  created_at: new Date(),
  updated_at: new Date(),
  client_name: '',
  user_name: '',
  id: '',
  name: '',
};

export const initialTotalOrders = {
  id: '',
  totalOrders: 0,
  totalCreated: 0,
  totalProcessing: 0,
  totalProcessed: 0,
  totalSended: 0,
  totalFinished: 0,
  totalCancelled: 0,
  startDate: firebase.firestore.Timestamp.now(),
  endDate: firebase.firestore.Timestamp.now(),
};

export const initialSalesDay = [
  {
    weekDays: 'SEGUNDA',
    vendas: 179,
  },
  {
    weekDays: 'TERÇA',
    vendas: 19,
  },
  {
    weekDays: 'QUARTA',
    vendas: 104,
  },
  {
    weekDays: 'QUINTA',
    vendas: 102,
  },
  {
    weekDays: 'SEXTA',
    vendas: 168,
  },
  {
    weekDays: 'SÁBADO',
    vendas: 37,
  },
  {
    weekDays: 'DOMINGO',
    vendas: 161,
  },
];

export const initialOptionsName = [
  'SEGUNDA',
  'TERÇA',
  'QUARTA',
  'QUINTA',
  'SEXTA',
  'SÁBADO',
  'DOMINGO',
];

export const initialOptionValue = [0, 0, 0, 0, 0, 0, 0];
