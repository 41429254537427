// Importações Padrões
import React, { useContext } from 'react';
import { maskBr } from 'js-brasil';

// Icones
import { BiCheckbox } from 'react-icons/bi';
import { FiHelpCircle } from 'react-icons/fi';

// React Prime
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { ProgressSpinner } from 'primereact/progressspinner';

// Componentes
import ItemColOrder from '../../defaultBox/itemColOrder.component';
import ItemColOrderTaken from '../../defaultBox/itemColOrderTaken.component';
import ItemImage from '../../defaultBox/itemImage.component';
import { toasterError } from '../../global/toaster.component';

// Funções
import { checkUnitSell } from '../../../functions/order.function';

// Types
import { IOrder, IProductOrder } from '../../../typings/order.types';

// Contexto
import { Auth } from '../../../services/auth.context';

interface IFormOrderInfoProps {
  product: IProductOrder;
  visible: IOrder;
  processing: string;
  pending: boolean;
  handleCheckBoxProduct(
    id: string,
    quantity: number,
    reservationQuantity: number,
    separated: boolean,
    useProductReservation: boolean,
    productIndex: number,
  ): void;
  handleCheckBoxProductResponsive(
    id: string,
    quantity: number,
    reservationQuantity: number,
    separated: boolean,
    useProductReservation: boolean,
    productIndex: number,
  ): void;
}

const Product = (props: IFormOrderInfoProps) => {
  const defaultImage =
    'https://i.pinimg.com/originals/61/3c/ea/613ceaae3543acdf381af20da47612dc.png';

  const { windowWidth } = useContext(Auth);

  const {
    product,
    visible,
    processing,
    handleCheckBoxProduct,
    handleCheckBoxProductResponsive,
    pending,
  } = props;

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-mt-1 p-mx-auto p-p-3 p-grid item-box width100">
          <p className="p-col-9 width100 p-d-inline p-my-0 p-p-0 p-mr-0">
            #{product.index}
          </p>
          <div className="p-px-1 p-as-center p-mx-auto">
            <ItemImage
              colGrid={12}
              content={product.imageHeavy}
              imageDefault={defaultImage}
              useProductBase={product.useProductBase}
            />
          </div>
          <ItemColOrder colGrid={2} content={product.product_name} />
          <ItemColOrder colGrid={2} content={product.barCode} />
          <div className="p-col-1 p-p-0 p-my-2 item-box-col p-text-center p-as-center">
            <p className="p-text-center p-col-12 p-m-0 p-d-inline p-my-0 p-p-0 p-mr-0">
              <Tooltip target={`.itemColOrderTaken-${product.index}`} />
              {product.quantity !== 0 ? (
                <u>
                  <i
                    className={`p-d-flex p-jc-center p-as-center cursor-pointer itemColOrderTaken-${product.index}`}
                    data-pr-tooltip={`Preço Unitário: ${maskBr.currency(
                      product.price / 100,
                    )}`}
                    data-pr-position="top"
                  >
                    {product.quantity} {checkUnitSell(product.unitSell)}{' '}
                    Compradas
                  </i>
                </u>
              ) : (
                <i className="p-d-flex p-jc-center p-as-center cursor-pointer">
                  {product.quantity} {checkUnitSell(product.unitSell)} Comprados
                </i>
              )}
            </p>
          </div>

          <div className="p-col-1 p-p-0 p-my-2 item-box-col p-text-center p-as-center">
            <p className="p-text-center p-col-12 p-m-0 p-d-inline p-my-0 p-p-0 p-mr-0">
              <Tooltip target={`.itemColOrderTaken-${product.index}`} />
              {product.reservationQuantity !== 0 ? (
                <u>
                  <i
                    className={`p-d-flex p-jc-center p-as-center cursor-pointer itemColOrderTaken-${product.index}`}
                    data-pr-tooltip={`Preço Unitário: ${maskBr.currency(
                      product.priceReservation / 100,
                    )}`}
                    data-pr-position="top"
                  >
                    {product.reservationQuantity}{' '}
                    {checkUnitSell(product.unitSell)} Reservados
                  </i>
                </u>
              ) : (
                <i className="p-d-flex p-jc-center p-as-center cursor-pointer">
                  {product.reservationQuantity}{' '}
                  {checkUnitSell(product.unitSell)} Reservados
                </i>
              )}
            </p>
          </div>

          <ItemColOrder
            colGrid={1}
            content={maskBr.currency(
              (product.price * product.quantity +
                product.price * product.reservationQuantity) /
                100,
            )}
          />

          <div className="p-col-1 p-p-0 item-box-col p-text-center p-as-center p-my-3">
            <Tooltip target=".custom-target-icon" />

            <i
              className="custom-target-icon p-d-flex p-jc-center p-as-center"
              data-pr-tooltip={product.note}
              data-pr-position="top"
            >
              {product.note !== '' ? (
                <FiHelpCircle className="order-envelop-icon-responsive" />
              ) : (
                <FiHelpCircle className="order-envelop-icon-disabled-responsive" />
              )}
            </i>
          </div>

          <div className="p-col-2 p-p-0 item-box-col p-text-center p-as-center">
            {processing !== 'selected' ? (
              <Button
                className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive p-d-flex"
                label="SEPARAR"
                onClick={() =>
                  toasterError(
                    'ERRO',
                    'Só é possível separar produtos na fase de Processamento!',
                  )
                }
              />
            ) : (
              <>
                {visible.status.cancelled !== 'Pendente' ? (
                  <Button
                    className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive p-d-flex"
                    label="SEPARAR"
                    onClick={() =>
                      toasterError(
                        'ERRO',
                        'Não é possível separar esse produto pois esse pedido foi cancelado!',
                      )
                    }
                  />
                ) : (
                  <>
                    {pending ? (
                      <ProgressSpinner
                        className="p-as-center p-d-flex p-jc-center"
                        style={{ width: '35px', height: '35px' }}
                        strokeWidth="8"
                        fill="#EEEEEE"
                        animationDuration=".5s"
                      />
                    ) : (
                      <>
                        {handleCheckBoxProductResponsive(
                          product.product_id,
                          product.quantity,
                          product.reservationQuantity,
                          product.separated,
                          product.useProductReservation,
                          product.index,
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div
          className={
            product.reservationQuantity === 0
              ? 'p-mt-1 p-mx-auto p-p-0 p-grid item-box width100'
              : 'p-mt-1 p-mx-auto p-p-0 p-grid item-box-future width100'
          }
          style={{
            border: '1px solid #e9ecef',
            borderWidth: '0 0 1px 0',
            backgroundColor: 'white',
          }}
        >
          <div className="p-col-1 p-p-0 item-box-col p-text-center p-as-center">
            <div className="p-d-inline p-col p-jc-center">{product.index}</div>
          </div>
          {visible.status.cancelled !== 'Pendente' ? (
            <div className="p-col-1 p-px-0 p-as-center p-mx-auto order-product-img-disabled">
              <ItemImage
                colGrid={12}
                content={product.imageHeavy}
                imageDefault={defaultImage}
                useProductBase={product.useProductBase}
              />
            </div>
          ) : (
            <div className="p-col-1 p-px-0 p-as-center p-mx-auto">
              <ItemImage
                colGrid={12}
                content={product.imageHeavy}
                imageDefault={defaultImage}
                useProductBase={product.useProductBase}
              />
            </div>
          )}
          <ItemColOrder colGrid={2} content={product.product_name} />
          <ItemColOrder colGrid={2} content={product.barCode} />

          <ItemColOrderTaken
            price={product.price}
            colGrid={1}
            content={product.quantity}
            productIndex={product.index}
          />

          <ItemColOrderTaken
            price={product.priceReservation}
            colGrid={1}
            content={product.reservationQuantity}
            productIndex={product.index}
          />
          <ItemColOrder colGrid={1} content={checkUnitSell(product.unitSell)} />
          <ItemColOrder
            colGrid={1}
            content={maskBr.currency(
              (product.price * product.quantity +
                product.price * product.reservationQuantity) /
                100,
            )}
          />
          <div className="p-col-1 p-p-0 item-box-col p-text-center p-as-center p-my-3">
            <Tooltip target=".custom-target-icon" />

            <i
              className="custom-target-icon p-d-flex p-jc-center p-as-center"
              data-pr-tooltip={product.note}
              data-pr-position="top"
            >
              {product.note !== '' ? (
                <FiHelpCircle className="order-envelop-icon-responsive" />
              ) : (
                <FiHelpCircle className="order-envelop-icon-disabled-responsive" />
              )}
            </i>
          </div>

          <div className="p-col-1 p-p-0 item-box-col p-text-center p-as-center">
            <div className="p-d-inline p-col p-jc-center">
              {processing !== 'selected' ? (
                <BiCheckbox
                  className="order-checkbox"
                  onClick={() =>
                    toasterError(
                      'ERRO',
                      'Só é possível separar produtos na fase de Processamento!',
                    )
                  }
                />
              ) : (
                <>
                  {visible.status.cancelled !== 'Pendente' ? (
                    <BiCheckbox
                      className="order-checkbox"
                      onClick={() =>
                        toasterError(
                          'ERRO',
                          'Não é possível separar esse produto pois esse pedido foi cancelado!',
                        )
                      }
                    />
                  ) : (
                    <>
                      {pending ? (
                        <ProgressSpinner
                          className="p-as-center p-d-flex p-jc-center"
                          style={{ width: '35px', height: '35px' }}
                          strokeWidth="8"
                          fill="#EEEEEE"
                          animationDuration=".5s"
                        />
                      ) : (
                        <>
                          {handleCheckBoxProduct(
                            product.product_id,
                            product.quantity,
                            product.reservationQuantity,
                            product.separated,
                            product.useProductReservation,
                            product.index,
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }

  return changeBreakpoints();
};

export default Product;
