import { useState, createContext, useEffect } from 'react';
import { useClientsList } from '../../../hooks/useClientsList';

import { useUserslist } from '../../../hooks/useUsersList';
import { getRealized } from '../functions/dayOrders';
import { useClientSalesTarget } from '../hooks/useClientSalesTarget';
import { useDayOrders } from '../hooks/useDayOrders';

import {
  ClientSalesGoal,
  ContextSalesTarget,
  SalesTargetView,
  SellerSalesGoal,
} from './SalesTargetContextInterface';

export const SalesTargetProvider = createContext<Partial<ContextSalesTarget>>(
  {},
);

// eslint-disable-next-line
export function SalesTargetContext({ children }: any) {
  const { clientsList } = useClientsList();
  const { listClientSalesTarget } = useClientSalesTarget();
  const { usersList } = useUserslist();
  const { listDayOrders } = useDayOrders();

  const [pendingInfo, setPendingInfo] = useState(true);

  const clients = clientsList.map((client) => {
    const clientSalesTarget = listClientSalesTarget.find(
      (clientSalesTarget) => clientSalesTarget.id === client.id,
    );
    const seller = usersList.find(
      (seller) => seller.id === clientSalesTarget?.seller_id,
    );
    const listClientRealized = getRealized(listDayOrders, client.id);
    const clientPotential = clientSalesTarget?.potential || -1;
    const clientGoal =
      clientSalesTarget && clientSalesTarget.potential
        ? clientSalesTarget.potential * (clientSalesTarget.percentage / 100)
        : 0;
    const clientRealized = listClientRealized
      ? listClientRealized.client_total
      : 0;

    const clientProgress = (100 * clientRealized) / clientGoal;

    return {
      id: client.id,
      name: client.name,
      seller_id: seller ? seller.id : '',
      seller: seller
        ? seller.name.charAt(0).toUpperCase() + seller.name.slice(1)
        : '---',
      potential: clientSalesTarget ? clientPotential : -1,
      goal: clientSalesTarget ? clientGoal : -1,
      realized: clientSalesTarget ? clientRealized : -1,
      notRealized: clientSalesTarget ? (clientGoal - clientRealized) * -1 : 0.1,
      status: clientSalesTarget ? 'ABERTO' : 'NÃO CRIADO',
      progress: clientSalesTarget ? clientProgress : 0,
    };
  });

  const goal = clients.reduce((acc, cur) => {
    if (cur && cur.goal >= 0) {
      return acc + cur.goal;
    }
    return acc;
  }, 0);

  const realized = clients.reduce((acc, cur) => {
    if (cur && cur.realized >= 0) {
      return acc + cur.realized;
    }
    return acc;
  }, 0);

  const [selectedClient, setSelectedClient] = useState<ClientSalesGoal | null>(
    null,
  );
  const [selectedSeller, setSelectedSeller] = useState<SellerSalesGoal | null>(
    null,
  );
  const [toggleView, setToggleView] = useState<SalesTargetView>('CLIENTS');

  const { selectedClientSalesTarget } = useClientSalesTarget(
    selectedClient?.id,
  );

  const sellers: Array<SellerSalesGoal> = usersList
    .filter(
      (seller) => !!seller.systems.find((sys) => sys.function === 'seller'),
    )
    .map((user) => {
      const sellerClients = clients.filter((cl) => cl.seller_id === user.id);

      const potential = sellerClients.reduce((acc, cur) => {
        if (cur && cur.potential) {
          return acc + cur.potential;
        }
        return acc;
      }, 0);

      const goal = sellerClients.reduce((acc, cur) => {
        if (cur && cur.goal) {
          return acc + cur.goal;
        }
        return acc;
      }, 0);

      const realized = sellerClients.reduce((acc, cur) => {
        if (cur && cur.realized) {
          return acc + cur.realized;
        }
        return acc;
      }, 0);

      const notRealized = sellerClients.reduce((acc, cur) => {
        if (cur && cur.notRealized) {
          return acc + cur.notRealized;
        }
        return acc;
      }, 0);

      const progress = (100 * realized) / goal;

      return {
        id: user.id,
        name: user.name,
        potential,
        goal,
        realized,
        notRealized,
        progress,
        clients: sellerClients as Array<ClientSalesGoal>,
      };
    });

  useEffect(() => {
    const newValue = clients.find(
      (client) => client.id === selectedClientSalesTarget?.id,
    );
    if (newValue) {
      setSelectedClient(newValue);
    }
  }, [selectedClientSalesTarget]);

  setTimeout(() => {
    setPendingInfo(false);
  }, 2000);

  return (
    <SalesTargetProvider.Provider
      value={{
        pendingInfo,
        clients,
        sellers,
        goal,
        realized,
        selectedClient,
        setSelectedClient,
        selectedSeller,
        setSelectedSeller,
        toggleView,
        setToggleView,
        selectedClientSalesTarget,
      }}
    >
      {children}
    </SalesTargetProvider.Provider>
  );
}
