// Importações Padrões
import React from 'react';

// React Prime
import { InputText } from 'primereact/inputtext';

interface INewInputFormProps {
  disabled: boolean;
  item: string;
  fieldLabel: string;
  pleaceHolder: string;
}

const NewInputFormSimple = (props: INewInputFormProps) => {
  const { disabled, item, fieldLabel, pleaceHolder } = props;

  return (
    <div className="p-field p-p-0 p-m-0">
      <label className="p-my-0 p-px-1 new-input-text">{fieldLabel}</label>
      <span className="p-d-flex p-input-icon-right">
        <InputText
          className="new-input-size"
          disabled={disabled}
          value={item}
          placeholder={pleaceHolder}
        />
      </span>
    </div>
  );
};

export default NewInputFormSimple;
