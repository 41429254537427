// Importações Padrões
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// Icones
import { FaArrowLeft, FaSave } from 'react-icons/fa';

// React Prime
import { InputText } from 'primereact/inputtext';
import { ScrollTop } from 'primereact/scrolltop';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useProvincesList } from '../hooks/useProvincesList';
import { useCitiesList } from '../hooks/useCitiesList';

// Types
import { IInput, initialInputOptions } from '../typings/input.types';
import { IList, IListProducts, initialList } from '../typings/list.types';

// Componentes
import CitiesDropSelect from '../components/cities/citiesDropSelect.component';
import ListCitiesDropSelect from '../components/lists/listCitiesDropSelect.component';
import ListItems from '../components/lists/listItems.component';
import { ProgressSpinnerSys } from '../components/global/progressSpinner.component';

// Controladores
import { editProductNoRes } from '../controllers/product.controller';
import { editServiceNoRes } from '../controllers/service.controller';
import { searchList } from '../controllers/list.controller';

interface IListParams {
  ListId: string;
}

const SelectedList = () => {
  const { ListId }: IListParams = useParams();
  const { setPathName, code3cData, windowWidth } = useContext(Auth);
  const location = useLocation();
  const history = useHistory();

  const [pending, setPending] = useState(true);
  const [list, setList] = useState<IList>(initialList);

  const [province, setProvince] = useState<IInput>(initialInputOptions);
  const [city, setCity] = useState<IInput>(initialInputOptions);

  const [productsOnList, setProductsOnList] = useState<IListProducts>({});
  const [servicesOnList, setServicesOnList] = useState<IListProducts>({});

  const [provincesList] = useProvincesList();
  const [citiesList] = useCitiesList(province.code);

  const saveList = useCallback(() => {
    const newProductOnList = Object.keys(productsOnList).map((item: string) => {
      return {
        product_id: item,
        product_listPrice: productsOnList[item].listPrice,
        product_listMeta: productsOnList[item].listMeta,
        product_listEnabled: productsOnList[item].listEnabled,
      };
    });
    newProductOnList.forEach(async (productToList) => {
      let response;
      if (productToList.product_listEnabled && productToList.product_listMeta) {
        response = await editProductNoRes(
          {
            listEnabled: [...productToList.product_listEnabled, ListId],
            listMeta: {
              ...productToList.product_listMeta,
              [ListId]: { listPrice: productToList.product_listPrice },
            },
          },
          productToList.product_id,
        );
      } else {
        response = await editProductNoRes(
          {
            listEnabled: [ListId],
            listMeta: {
              [ListId]: { listPrice: productToList.product_listPrice },
            },
          },
          productToList.product_id,
        );
      }
      if (response) {
        setProductsOnList({});
      }
    });

    const newServiceOnList = Object.keys(servicesOnList).map((item: string) => {
      return {
        service_id: item,
        service_listPrice: servicesOnList[item].listPrice,
        service_listMeta: servicesOnList[item].listMeta,
        service_listEnabled: servicesOnList[item].listEnabled,
      };
    });
    newServiceOnList.forEach(async (serviceOnList) => {
      let response;
      if (serviceOnList.service_listEnabled && serviceOnList.service_listMeta) {
        response = await editServiceNoRes(
          {
            listEnabled: [...serviceOnList.service_listEnabled, ListId],
            listMeta: {
              ...serviceOnList.service_listMeta,
              [ListId]: { listPrice: serviceOnList.service_listPrice },
            },
          },
          serviceOnList.service_id,
        );
      } else {
        response = await editServiceNoRes(
          {
            listEnabled: [ListId],
            listMeta: {
              [ListId]: { listPrice: serviceOnList.service_listPrice },
            },
          },
          serviceOnList.service_id,
        );
      }
      if (response) {
        setServicesOnList({});
      }
    });
  }, [productsOnList, servicesOnList, ListId]);

  const getLists = useCallback(async () => {
    if (ListId) {
      const newData = await searchList(ListId);
      if (newData && newData.name && newData.code3c_id === code3cData?.id) {
        setList(newData);

        const hasActionOptions =
          newData.province_id &&
          newData.city_id &&
          newData.province_id !== '' &&
          newData.city_id !== '';

        if (hasActionOptions) {
          const [selectedProvince] = provincesList.filter(
            (provinces) => provinces.id === newData.province_id,
          );
          const [selectedCity] = citiesList.filter(
            (cities) => cities.id === newData.city_id,
          );
          if (selectedProvince) {
            setProvince({
              name: selectedProvince.name,
              code: selectedProvince.id,
            });
            selectedCity &&
              setCity({
                name: selectedCity.name,
                code: selectedCity.id,
              });
          }
        }
      } else {
        history.push('/listas');
      }
    } else {
      history.push('/listas');
    }
  }, [provincesList, citiesList, ListId, code3cData?.id, history]);

  useEffect(() => {
    setPathName('Lista Selecionada');
    getLists();
    setTimeout(() => {
      setPending(false);
    }, 500);
  }, [location, setPathName, provincesList, citiesList]);

  const backConfirm = () => {
    confirmDialog({
      message: () => (
        <div>
          <b>Tem certeza que deseja voltar?</b>
          <br />
          <small>Você perderá todas as alterações não Salvas.</small>
        </div>
      ),
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'reject-button',
      rejectClassName: 'accept-button',
      acceptLabel: 'Voltar',
      rejectLabel: 'Permanecer',
      accept: () => history.push('/listas'),
    });
  };

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-py-4 p-px-3 width100">
          <ScrollTop className="button-scroll-top" />
          <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
            {pending ? (
              <div
                className="p-d-flex p-jc-center p-as-center"
                style={{ height: '70vh' }}
              >
                <ProgressSpinnerSys />
              </div>
            ) : (
              <div className="p-d-grid">
                <div className="p-col-12 p-p-0">
                  <Button
                    className="p-jc-center width100 height2-5Rem button-confirmation"
                    onClick={backConfirm}
                  >
                    <FaArrowLeft className="p-mx-2" />
                    Voltar
                  </Button>
                </div>
                <div className="p-col-12 p-p-0 p-mt-2">
                  <Button
                    className="p-jc-center width100 height2-5Rem button-savelist"
                    onClick={() => {
                      saveList();
                    }}
                  >
                    <FaSave className="p-mx-2" />
                    Salvar Lista
                  </Button>
                </div>
                <div className="p-mt-3">
                  <label className="p-my-0 p-mt-4 p-d-flex">
                    Nome da Lista
                  </label>
                  <InputText
                    disabled
                    className="p-mb-3 settings-input"
                    value={list.name}
                  />
                  {list.province_id && list.city_id && (
                    <div>
                      <label>Mais informações</label>
                      <div className="p-d-flex p-jc-center p-mt-2">
                        <CitiesDropSelect
                          province={province}
                          setProvince={setProvince}
                          onPage={false}
                          disabled
                        />
                      </div>
                      <div className="p-d-flex p-jc-center p-mt-1">
                        <ListCitiesDropSelect
                          city={city}
                          setCity={setCity}
                          UF={province.code}
                          disabled
                        />
                      </div>
                    </div>
                  )}
                </div>
                <ListItems
                  productsOnList={productsOnList}
                  setProductsOnList={setProductsOnList}
                  servicesOnList={servicesOnList}
                  setServicesOnList={setServicesOnList}
                />
              </div>
            )}
          </div>
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div className="p-py-4 p-px-3 width100">
          <ScrollTop className="button-scroll-top" />
          <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
            {pending ? (
              <div
                className="p-d-flex p-jc-center p-as-center"
                style={{ height: '70vh' }}
              >
                <ProgressSpinnerSys />
              </div>
            ) : (
              <div>
                <div className="p-grid p-mx-1 p-p-0 p-jc-between">
                  <div>
                    <Button
                      className="p-p-0 p-mr-2 users-add-button"
                      onClick={backConfirm}
                    >
                      <FaArrowLeft className="p-mx-auto users-button-icon" />
                    </Button>
                    <span className="p-my-auto users-button-text">Voltar</span>
                  </div>
                  <div>
                    <Button
                      className="p-px-2 p-ml-2 button-confirmation"
                      onClick={() => {
                        saveList();
                      }}
                    >
                      <span className="p-mx-1 users-button-text">
                        Salvar Lista
                      </span>
                      <FaSave className="p-mx-auto users-button-icon" />
                    </Button>
                  </div>
                </div>
                <div className="p-mt-3">
                  <label className="p-my-0 p-mt-4 p-d-flex">
                    Nome da Lista
                  </label>
                  <InputText
                    disabled
                    className="p-mb-3 settings-input"
                    value={list.name}
                  />
                  {list.province_id && list.city_id && (
                    <div>
                      <label>Mais informações</label>
                      <div className="p-d-flex p-jc-center p-mt-2">
                        <CitiesDropSelect
                          province={province}
                          setProvince={setProvince}
                          onPage={false}
                          disabled
                        />
                      </div>
                      <div className="p-d-flex p-jc-center p-mt-1">
                        <ListCitiesDropSelect
                          city={city}
                          setCity={setCity}
                          UF={province.code}
                          disabled
                        />
                      </div>
                    </div>
                  )}
                </div>
                <ListItems
                  productsOnList={productsOnList}
                  setProductsOnList={setProductsOnList}
                  servicesOnList={servicesOnList}
                  setServicesOnList={setServicesOnList}
                />
              </div>
            )}
          </div>
        </div>
      );
    }
    return <div />;
  }

  return changeBreakpoints();
};

export default SelectedList;
