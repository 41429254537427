export interface IManufacturer {
  id: string;
  name: string;
  code3c_id: string;
  imageLight: string;
  imageHeavy: string;
}

export interface IEditManufacturer {
  item: IManufacturer;
  edit: boolean;
  visible: boolean;
}

export interface IDeleteManufacturer {
  id: string;
  name: string;
  visible: boolean;
}

export const initialManufacturer: IManufacturer = {
  id: '',
  name: '',
  code3c_id: '',
  imageHeavy: '',
  imageLight: '',
};
