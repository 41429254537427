// Types
import IRoute from '../typings/routes.types';

// Paginas
// import Dashboard from '../pages/dashboard';
import DashboardPage from '../pages/dashboard/DashboardPage';
import Categories from '../pages/categories';
import Brandings from '../pages/brandings';
import Manufacturers from '../pages/manufacturers';
import Products from '../pages/products';
import Services from '../pages/services';
import Cities from '../pages/cities';
import Lists from '../pages/lists';
import Orders from '../pages/orders';
import Visits from '../pages/visits';
import Clients from '../pages/clients';
import Providers from '../pages/providers';
import Users from '../pages/users';
import Help from '../pages/help';
import Settings from '../pages/settings';
import Provinces from '../pages/provinces';
// import Reports from '../pages/reports';
// import SalesTarget from '../pages/salesTarget.new';
import Payments from '../pages/payments';
import FreightPage from '../pages/freight/FreightPage';
import SalesTargetPage from '../pages/salesTarget/SalesTargetPage';

export const listRoutes: Array<IRoute> = [
  {
    type: 'route',
    path: '/',
    pathname: 'Dashboard',
    icon: 'poll',
    component: DashboardPage,
  },
  {
    type: 'route',
    path: '/servicos',
    pathname: 'Serviços',
    icon: 'wrench',
    component: Services,
  },
  {
    type: 'route-list',
    path: '/',
    pathname: 'Produtos',
    icon: 'boxes',
    component: DashboardPage,
    subroutes: ['Categorias', 'Marcas', 'Fabricantes', 'Produtos'],
  },
  {
    type: 'sub-route',
    path: '/categorias',
    pathname: 'Categorias',
    icon: 'tags',
    component: Categories,
  },
  {
    type: 'sub-route',
    path: '/marcas',
    pathname: 'Marcas',
    icon: 'registered',
    component: Brandings,
  },
  {
    type: 'sub-route',
    path: '/fabricantes',
    pathname: 'Fabricantes',
    icon: 'industry',
    component: Manufacturers,
  },
  {
    type: 'sub-route',
    path: '/produtos',
    pathname: 'Produtos',
    icon: 'boxes',
    component: Products,
  },
  {
    type: 'route-list',
    path: '/',
    pathname: 'Listas',
    icon: 'clipboard-list',
    component: DashboardPage,
    subroutes: ['Estados', 'Cidades', 'Listas'],
  },
  {
    type: 'sub-route',
    path: '/estados',
    pathname: 'Estados',
    icon: 'asterisk',
    component: Provinces,
  },
  {
    type: 'sub-route',
    path: '/cidades',
    pathname: 'Cidades',
    icon: 'city',
    component: Cities,
  },
  {
    type: 'sub-route',
    path: '/listas',
    pathname: 'Listas',
    icon: 'clipboard-list',
    component: Lists,
  },
  {
    type: 'route',
    path: '/Agenda',
    pathname: 'Agenda',
    icon: 'calendar-alt',
    component: Visits,
  },
  {
    type: 'route',
    path: '/Pedidos',
    pathname: 'Pedidos',
    icon: 'book-reader',
    component: Orders,
  },
  {
    type: 'route',
    path: '/fornecedores',
    pathname: 'Fornecedores',
    icon: 'truck',
    component: Providers,
  },
  {
    type: 'route',
    path: '/clientes',
    pathname: 'Clientes',
    icon: 'briefcase',
    component: Clients,
  },
  {
    type: 'route',
    path: '/usuarios',
    pathname: 'Usuários',
    icon: 'user',
    component: Users,
  },
  {
    type: 'route',
    path: '/meta-de-vendas',
    pathname: 'Meta de Vendas',
    icon: 'compress-arrows-alt',
    component: SalesTargetPage,
  },
  // {
  //   type: 'route',
  //   path: '/relatorios',
  //   pathname: 'Relatórios',
  //   icon: 'file-invoice-dollar',
  //   component: Reports,
  // },
  {
    type: 'route-list',
    path: '/',
    pathname: 'Outras Opções',
    icon: 'desktop',
    component: DashboardPage,
    subroutes: ['Pagamento', 'Frete'],
  },
  {
    type: 'sub-route',
    path: '/formas-de-pagamento',
    pathname: 'Pagamento',
    icon: 'credit-card',
    component: Payments,
  },
  {
    type: 'sub-route',
    path: '/frete',
    pathname: 'Frete',
    icon: 'truck-loading',
    component: FreightPage,
  },
  {
    type: 'route',
    path: '/configuracoes',
    pathname: 'Configurações',
    icon: 'cog',
    component: Settings,
  },
  {
    type: 'route',
    path: '/ajuda',
    pathname: 'Ajuda',
    icon: 'question-circle',
    component: Help,
  },
];

export default listRoutes;
