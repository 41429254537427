// Importações Padrões
import React, { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollTop } from 'primereact/scrolltop';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useProvincesList } from '../hooks/useProvincesList';

// Componentes
import ItemProvince from '../components/province/itemProvince.component';
import NavProvince from '../components/province/navProvince.component';

const Provinces: React.FC = () => {
  const { setPathName, windowWidth } = useContext(Auth);
  const [provincesList] = useProvincesList();

  const [inputSearch, setInputSearch] = useState('');

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  return (
    <div className="p-py-3 p-px-3 ">
      <ScrollTop className="button-scroll-top" />
      <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
        <NavProvince
          setInputSearch={setInputSearch}
          pleaceHolder="Pesquisar estado..."
        />
        <div className="p-d-flex p-dir-col p-mt-3 p-mb-auto width100">
          {windowWidth && windowWidth > 900 && (
            <div
              className="p-grid p-my-1 p-px-2 p-mx-auto p-d-none p-d-md-inline-flex default-box-header"
              style={{
                backgroundColor: '#f8f9fa',
                border: '1px solid #e9ecef',
              }}
            >
              <div className="p-col-2 p-text-center p-as-center">Id</div>
              <div className="p-col-8 p-text-center p-as-center">Estado</div>
              <div className="p-col-2 p-text-center p-as-center">
                Habilitado
              </div>
            </div>
          )}

          {provincesList
            .filter(
              (item) =>
                item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                -1,
            )
            .map((province, index) => (
              <ItemProvince
                key={String(index)}
                id={province.id}
                code3cEnabled={province.code3cEnabled}
                name={province.name}
                active={province.active ? province.active : false}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Provinces;
