// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollTop } from 'primereact/scrolltop';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useManufacturerList } from '../hooks/useManufacturerList';

// Types
import {
  IManufacturer,
  IDeleteManufacturer,
  IEditManufacturer,
  initialManufacturer,
} from '../typings/manufacturer.types';

// Componentes
import ItemManufacturer from '../components/manufacturers/itemManufacturer.component';
import AddManufacturer from '../components/manufacturers/addManufacturer.component';
import EditManufacturer from '../components/manufacturers/editManufacturer.component';
import DeleteManufacturer from '../components/manufacturers/deleteManufacturer.component';
import NavManufacturer from '../components/manufacturers/navManufacturers.component';

const Manufacturer: React.FC = () => {
  const { setPathName, windowWidth } = useContext(Auth);
  const [manufacturersList, pending] = useManufacturerList();

  const [showHideAddManufacturer, setShowHideAddManufacturer] = useState(false);
  const [showHideEditManufacturer, setShowHideEditManufacturer] =
    useState<IEditManufacturer>({
      item: initialManufacturer,
      edit: false,
      visible: false,
    });
  const [showHideDeleteManufacturer, setShowHideDeleteManufacturer] =
    useState<IDeleteManufacturer>({ id: '', name: '', visible: false });

  const [inputSearch, setInputSearch] = useState('');

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  useEffect(() => {
    if (
      showHideAddManufacturer ||
      showHideEditManufacturer.visible ||
      showHideDeleteManufacturer.visible
    ) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [
    showHideAddManufacturer,
    showHideEditManufacturer,
    showHideDeleteManufacturer,
  ]);

  return (
    <div className="p-py-3 p-px-3 ">
      <ScrollTop className="button-scroll-top" />
      <AddManufacturer
        visible={showHideAddManufacturer}
        setVisible={setShowHideAddManufacturer}
      />
      <EditManufacturer
        visible={showHideEditManufacturer}
        setVisible={setShowHideEditManufacturer}
      />
      <DeleteManufacturer
        visible={showHideDeleteManufacturer}
        setVisible={setShowHideDeleteManufacturer}
      />
      <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
        <NavManufacturer
          placeHolder="Pesquisar..."
          pending={!pending}
          addFieldName="Novo Fabricante"
          actionAdd={showHideAddManufacturer}
          setActionAdd={setShowHideAddManufacturer}
          setInputSearch={setInputSearch}
          activeAdd
        />

        <div className="p-d-flex p-dir-col p-mt-2 p-mb-auto width100">
          {windowWidth && windowWidth > 900 && (
            <div
              className="p-grid p-my-1 p-px-3 p-mx-auto p-d-none p-d-md-inline-flex default-box-header"
              style={{
                backgroundColor: '#f8f9fa',
                border: '1px solid #e9ecef',
              }}
            >
              <div className="p-col-1 p-text-center p-as-center">Id</div>
              <div className="p-col-4 p-text-center p-as-center">Imagem</div>
              <div className="p-col-4 p-text-center p-as-center">
                Fabricante
              </div>
              <div className="p-col-3 p-text-center p-as-center">Ações</div>
            </div>
          )}

          {manufacturersList
            .filter((item) => item.name)
            .filter(
              (item) =>
                item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                -1,
            )
            .map((manufacturer: IManufacturer, index: number) => {
              return (
                <ItemManufacturer
                  key={String(index)}
                  index={index + 1}
                  manufacturer={manufacturer}
                  showHideEditManufacturer={showHideEditManufacturer}
                  setShowHideEditManufacturer={setShowHideEditManufacturer}
                  showHideDeleteManufacturer={showHideDeleteManufacturer}
                  setShowHideDeleteManufacturer={setShowHideDeleteManufacturer}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Manufacturer;
