// Types
import IRoute from '../typings/routes.types';

// Rotas
import listRoutes from '../routes/list.routes';

const parseNameRoute = (pathName: string | undefined) => {
  if (pathName !== undefined) {
    const [pathname] = listRoutes.filter(
      (route: IRoute) => route.path.toUpperCase() === pathName.toUpperCase(),
    );
    if (pathname) {
      return pathname.pathname;
    }
    if (pathName === 'Lista Selecionada') {
      return 'Itens da Lista';
    }
    return '';
  }
  return '';
};

export { parseNameRoute };
