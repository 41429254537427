// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';

// Icones
import { FaEdit, FaEye } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

// Contexto
import { Auth } from '../../services/auth.context';

// Hooks
import { useUserslist } from '../../hooks/useUsersList';

// Types
import { IUser, IEditUser, ISystemFunction } from '../../typings/users.types';
import { IValidationError } from '../../typings/yup.types';

// Controladores
import { editUser } from '../../controllers/user.controller';

// Funções
import {
  parseUserFunction,
  dataSchemaWithPass,
  dataSchema,
} from '../../functions/user.function';

// Componentes
import InputDropForm from '../input/inputDropForm.component';
import InputPassForm from '../input/inputPassForm.component';
import InputForm from '../input/inputForm.component';
import InputFormPercentageAlt from '../input/inputFormPercentageAlt.component';

interface IEditUserProps {
  visible: IEditUser;
  setVisible: React.Dispatch<React.SetStateAction<IEditUser>>;
}

const EditUser = (props: IEditUserProps) => {
  const { code3cData, accessToken, userData, handleSettingUser } = useContext(
    Auth,
  );
  const { usersList } = useUserslist();

  const { visible, setVisible } = props;

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordValidation, setPasswordValidation] = useState<string>('');
  const [commission, setCommission] = useState<number>(0);

  const [systemFunction, setSystemFunction] = useState<ISystemFunction>({
    name: 'Vendedor',
    code: 'seller',
  });

  const [peekPass, setPeekPass] = useState<boolean>(false);

  const systemFunctionList: Array<ISystemFunction> = [
    { name: 'Administrador', code: 'administrator' },
    { name: 'Estoquista', code: 'stockist' },
    { name: 'Vendedor', code: 'seller' },
  ];

  const [changePassword, setChangePassword] = useState(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);
  const [emailErr, setEmailErr] = useState<boolean>(false);
  const [pendingAction, setPendingAction] = useState<boolean>(false);

  const initialState = () => {
    setName('');
    setEmail('');
    setPassword('');
    setPasswordValidation('');
    setSystemFunction({ name: 'Vendedor', code: 'seller' });
    setPeekPass(false);
    setFormErr([]);
    setPendingAction(false);
    setChangePassword(false);
    setVisible({ ...visible, visible: false });
    setCommission(0);
  };

  function handleEditUser() {
    setPendingAction((state) => !state);

    let dataValidation: Partial<IUser> = {
      code3c_id: code3cData?.id,
      name,
      email,
      systems: [
        {
          systemActive: true,
          systemId: 'CFDV',
          systemName: 'Cansho Força de Vendas',
          function: parseUserFunction(systemFunction.code),
        },
      ],
      commission,
    };

    let dataPassSchema: yup.AnySchema = dataSchema;
    if (changePassword) {
      dataValidation = { ...dataValidation, password, passwordValidation };
      dataPassSchema = dataSchemaWithPass;
    }

    dataPassSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        if (changePassword) delete dataValidation.passwordValidation;
        if (userData?.id === visible.item.id) {
          const response =
            handleSettingUser && (await handleSettingUser(dataValidation));
          if (response) {
            setPendingAction((state) => !state);
            initialState();
          }
        } else if (accessToken) {
          const response = await editUser(
            {
              ...dataValidation,
              systems: visible.item.systems.map((system) => {
                if (system.systemId === 'CFDV') {
                  return { ...system, function: systemFunction.code };
                }
                return system;
              }),
            },
            visible.item.id,
            accessToken,
          );
          if (response) {
            setFormErr([]);
            setPendingAction((state) => !state);
            initialState();
          } else {
            setFormErr([]);
            setPendingAction((state) => !state);
          }
        }
        setFormErr([]);
      })
      .catch((error: IValidationError) => {
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        setPendingAction((state) => !state);
        console.error(error.inner);
      });
  }

  useEffect(() => {
    if (
      usersList.filter(
        (user) => user.email === email && user.id !== visible.item.id,
      ).length >= 1
    ) {
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }
  }, [email, usersList, visible.item.id]);

  useEffect(() => {
    setName(visible.item.name);
    setEmail(visible.item.email);
    setPassword(visible.item.password);
    const [systemUser] = visible.item.systems.filter(
      (system) => system.systemId === 'CFDV',
    );
    if (systemUser) {
      setSystemFunction({
        name: parseUserFunction(systemUser.function),
        code: systemUser.function,
      });
    }
    setPeekPass(false);
    setCommission(visible.item.commission ? visible.item.commission : 0);
  }, [visible, usersList]);

  useEffect(() => {
    if (commission < 0) {
      setCommission(0);
    } else if (commission > 100) {
      setCommission(100);
    }
  }, [commission]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              {visible.edit ? (
                <FaEdit className="p-mx-auto p-as-center modal-header-icon" />
              ) : (
                <FaEye className="p-mx-auto p-as-center modal-header-icon" />
              )}
            </div>
            <span className="p-my-auto users-button-text">
              {visible.edit ? 'Editar ' : 'Ver '}Usuário
            </span>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            {visible.edit && (
              <Button
                className="modal-cancel-button"
                label="Cancelar"
                disabled={pendingAction}
                iconPos="right"
                onClick={() => initialState()}
                autoFocus={false}
              />
            )}
            <Button
              className="modal-save-button"
              label={visible.edit ? 'Salvar' : 'Editar'}
              disabled={pendingAction || emailErr}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() =>
                visible.edit
                  ? handleEditUser()
                  : setVisible({ ...visible, edit: true })
              }
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <InputForm
        pleaceHolder=""
        disabled={!visible.edit}
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome do usuário"
        fieldCode="name"
      />
      <InputForm
        pleaceHolder=""
        disabled={false}
        item={email}
        setItem={setEmail}
        formErr={formErr}
        fieldLabel="Email do usuário"
        fieldCode="email"
      />
      <small className="p-d-block input-invalid">
        {emailErr && <p>Esse email já está cadastrado</p>}
      </small>
      {visible.edit && (
        <div className=" p-mb-0 p-my-2 p-d-flex p-flex-md-row">
          <Checkbox
            checked={changePassword}
            onChange={() => setChangePassword(!changePassword)}
            className={
              changePassword
                ? 'settings-checkbox-selected'
                : 'settings-checkbox'
            }
          />
          <p className="p-pl-2 p-m-0">Alterar Senha</p>
        </div>
      )}

      {changePassword && (
        <>
          <InputPassForm
            disabled={false}
            item={password}
            setItem={setPassword}
            formErr={formErr}
            fieldLabel="Senha do usuário"
            fieldCode="password"
            peekPass={peekPass}
            setPeekPass={setPeekPass}
          />
          <InputPassForm
            disabled={false}
            item={passwordValidation}
            setItem={setPasswordValidation}
            formErr={formErr}
            fieldLabel="Confirmação de Senha do usuário"
            fieldCode="passwordValidation"
            peekPass={peekPass}
            setPeekPass={setPeekPass}
          />
        </>
      )}
      <InputDropForm
        filter={false}
        filterBy=""
        disabled={!visible.edit || userData?.id === visible.item.id}
        item={systemFunction}
        setItem={setSystemFunction}
        options={systemFunctionList}
        formErr={formErr}
        fieldLabel="Tipo de usuário"
        fieldCode="systems[0].function"
      />
      {systemFunction.code === 'seller' && (
        <InputFormPercentageAlt
          pleaceHolder=""
          disabled={false}
          item={commission}
          setItem={setCommission}
          formErr={[]}
          fieldLabel="Comissão do Vendedor (%)"
          fieldCode="commission"
        />
      )}
    </Dialog>
  );
};

export default EditUser;
