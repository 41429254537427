// Importações Padrões
import React, { useContext, useState } from 'react';
import firebase from 'firebase';

// Icones
import {
  BiCheckbox,
  BiCheckboxChecked,
  BiCheckboxSquare,
  BiTime,
} from 'react-icons/bi';

// React Prime
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

// Types
import { IOrder } from '../../../typings/order.types';

// Componentes
import { toasterError, toasterWarn } from '../../global/toaster.component';
import Product from './product.component';
import Service from './service.component';

// Types
import { IProduct } from '../../../typings/product.types';

// Controladores
import {
  editOrder,
  editOrderProduct,
} from '../../../controllers/order.controller';

// Hooks
import { useProductList } from '../../../hooks/useProductsList';

// Contexto
import { Auth } from '../../../services/auth.context';

interface IITemMenu {
  visible: IOrder;
  processing: string;
}

const ItemService = ({ visible, processing }: IITemMenu) => {
  const { windowWidth } = useContext(Auth);

  const [productsList] = useProductList();

  const [pending, setPending] = useState({ pending: false, index: -1 });
  const [pendingProduct, setPendingProduct] = useState({
    pending: false,
    index: -1,
  });
  const [open, setOpen] = useState({ open: false, index: -1 });

  const handleCheckboxSeparated = async (
    id: string,
    quantity: number,
    reservationQuantity: number,
    separated: boolean,
    useProductReservation: boolean,
    productIndex: number,
  ) => {
    setPendingProduct({ pending: true, index: productIndex });
    const [productSelected] = productsList.filter(
      (product) => product.id === id,
    );
    if (quantity > 0 && reservationQuantity === 0) {
      if (separated === false) {
        const dataOrder: Partial<IOrder> = {
          productOrder: visible.productOrder.map((product) => {
            if (product.index === productIndex) {
              return { ...product, separated: true };
            }
            return product;
          }),
        };
        const dataProduct: Partial<IProduct> = {
          productAmount: productSelected.productAmount - quantity,
        };
        const response = await editOrderProduct(
          dataOrder,
          dataProduct,
          visible.id,
          id,
        );
        if (response) {
          setTimeout(() => {
            setPendingProduct({ pending: false, index: NaN });
          }, 400);
        } else {
          setPendingProduct({ pending: false, index: NaN });
        }
      }
    }
    if (quantity === 0 && reservationQuantity > 1) {
      if (useProductReservation === true) {
        const dataOrder: Partial<IOrder> = {
          productOrder: visible.productOrder.map((product) => {
            if (product.index === productIndex) {
              return { ...product, useProductReservation: false };
            }
            return product;
          }),
        };
        const dataProduct: Partial<IProduct> = {
          productAmount: productSelected.productAmount - reservationQuantity,
          reservationAvailable:
            productSelected.reservationAvailable + reservationQuantity,
        };
        const response = await editOrderProduct(
          dataOrder,
          dataProduct,
          visible.id,
          id,
        );
        if (response) {
          setTimeout(() => {
            setPendingProduct({ pending: false, index: NaN });
          }, 400);
        } else {
          setPendingProduct({ pending: false, index: NaN });
        }
      }
    }
    if (quantity > 0 && reservationQuantity > 0) {
      if (separated === false && useProductReservation === true) {
        const dataOrder: Partial<IOrder> = {
          productOrder: visible.productOrder.map((product) => {
            if (product.index === productIndex) {
              return { ...product, separated: true };
            }
            return product;
          }),
        };
        const dataProduct: Partial<IProduct> = {
          productAmount: productSelected.productAmount - quantity,
        };
        const response = await editOrderProduct(
          dataOrder,
          dataProduct,
          visible.id,
          id,
        );
        if (response) {
          setTimeout(() => {
            setPendingProduct({ pending: false, index: NaN });
          }, 400);
        } else {
          setPendingProduct({ pending: false, index: NaN });
        }
      }
      if (separated === true && useProductReservation === true) {
        const dataOrder: Partial<IOrder> = {
          productOrder: visible.productOrder.map((product) => {
            if (product.index === productIndex) {
              return { ...product, useProductReservation: false };
            }
            return product;
          }),
        };
        const dataProduct: Partial<IProduct> = {
          productAmount: productSelected.productAmount - reservationQuantity,
          reservationAvailable:
            productSelected.reservationAvailable + reservationQuantity,
        };
        const response = await editOrderProduct(
          dataOrder,
          dataProduct,
          visible.id,
          id,
        );
        if (response) {
          setTimeout(() => {
            setPendingProduct({ pending: false, index: NaN });
          }, 400);
        } else {
          setPendingProduct({ pending: false, index: NaN });
        }
      }
    }
  };

  const confirmDialogSeparated = (
    id: string,
    quantity: number,
    reservationQuantity: number,
    separated: boolean,
    useProductReservation: boolean,
    productIndex: number,
  ) => {
    const [productSelected] = productsList.filter(
      (product) => product.id === id,
    );

    confirmDialog({
      message: () => {
        return (
          <>
            <div>
              Tem certeza que deseja separar {reservationQuantity} produtos da
              reserva para o estoque?
            </div>
            <br />
            <div>Produtos no estoque: {productSelected.productAmount}</div>
            <br />
            <div>Essa ação é irreversível</div>
          </>
        );
      },
      icon: 'pi pi-exclamation-triangle',
      acceptClassName:
        productSelected.productAmount > reservationQuantity
          ? 'accept-button'
          : 'disabled-button',
      rejectClassName: 'reject-button',
      rejectLabel: 'Não',
      acceptLabel: 'Sim',
      accept: () => {
        if (productSelected.productAmount > reservationQuantity) {
          handleCheckboxSeparated(
            id,
            quantity,
            reservationQuantity,
            separated,
            useProductReservation,
            productIndex,
          );
        } else {
          toasterWarn('Erro', 'Não há estoque suficiente');
        }
      },
    });
  };

  function handleCheckBoxProduct(
    id: string,
    quantity: number,
    reservationQuantity: number,
    separated: boolean,
    useProductReservation: boolean,
    productIndex: number,
    toDo: string | firebase.firestore.Timestamp,
    doing: string | firebase.firestore.Timestamp,
    done: string | firebase.firestore.Timestamp,
  ) {
    const [productSelected] = productsList.filter(
      (product) => product.id === id,
    );
    if (toDo !== 'Pendente' && doing === 'Pendente' && done === 'Pendente') {
      return (
        <BiCheckbox
          onClick={() =>
            toasterError(
              'ERRO',
              'Voce deve atualizar o status do serviço para realizar essa ação!',
            )
          }
          className="order-checkbox"
        />
      );
    }
    if (toDo !== 'Pendente' && doing !== 'Pendente' && done === 'Pendente') {
      if (quantity > 0 && reservationQuantity === 0) {
        if (separated === false) {
          if (productSelected && quantity <= productSelected.productAmount) {
            return (
              <BiCheckbox
                onClick={() =>
                  handleCheckboxSeparated(
                    id,
                    quantity,
                    reservationQuantity,
                    separated,
                    useProductReservation,
                    productIndex,
                  )
                }
                className="order-checkbox"
              />
            );
          }
          return (
            <BiCheckbox
              onClick={() =>
                toasterWarn(
                  'ATENÇÃO',
                  'Não tem produto suficiente no estoque para separar esse produto!',
                )
              }
              className="order-checkbox"
            />
          );
        }
        return (
          <BiCheckboxChecked
            onClick={() =>
              toasterError('ERRO', 'Esse produto já foi separado!')
            }
            className="order-checkbox"
          />
        );
      }
      if (quantity === 0 && reservationQuantity > 1) {
        if (useProductReservation === true) {
          return (
            <BiCheckboxSquare
              onClick={() =>
                confirmDialogSeparated(
                  id,
                  quantity,
                  reservationQuantity,
                  separated,
                  useProductReservation,
                  productIndex,
                )
              }
              className="order-checkbox-reservation"
            />
          );
        }
        return (
          <BiCheckboxChecked
            onClick={() =>
              toasterError('ERRO', 'Esse produto já foi separado!')
            }
            className="order-checkbox"
          />
        );
      }
      if (quantity > 0 && reservationQuantity > 0) {
        if (separated === false) {
          return (
            <BiCheckbox
              onClick={() =>
                handleCheckboxSeparated(
                  id,
                  quantity,
                  reservationQuantity,
                  separated,
                  useProductReservation,
                  productIndex,
                )
              }
              className="order-checkbox"
            />
          );
        }
        if (separated === true && useProductReservation === true) {
          return (
            <BiCheckboxSquare
              onClick={() =>
                confirmDialogSeparated(
                  id,
                  quantity,
                  reservationQuantity,
                  separated,
                  useProductReservation,
                  productIndex,
                )
              }
              className="order-checkbox-reservation"
            />
          );
        }
        return (
          <BiCheckboxChecked
            onClick={() =>
              toasterError('ERRO', 'Esse produto já foi separado!')
            }
            className="order-checkbox"
          />
        );
      }
    }
    return (
      <BiCheckboxChecked
        onClick={() => toasterError('ERRO', 'Esse produto já foi separado!')}
        className="order-checkbox"
      />
    );
  }

  function handleCheckBoxProductResponsive(
    id: string,
    quantity: number,
    reservationQuantity: number,
    separated: boolean,
    useProductReservation: boolean,
    productIndex: number,
    toDo: string | firebase.firestore.Timestamp,
    doing: string | firebase.firestore.Timestamp,
    done: string | firebase.firestore.Timestamp,
  ) {
    const [productSelected] = productsList.filter(
      (product) => product.id === id,
    );
    if (toDo !== 'Pendente' && doing === 'Pendente' && done === 'Pendente') {
      return (
        <BiCheckbox
          onClick={() =>
            toasterError(
              'ERRO',
              'Voce deve atualizar o status do serviço para realizar essa ação!',
            )
          }
          className="order-checkbox"
        />
      );
    }
    if (toDo !== 'Pendente' && doing !== 'Pendente' && done === 'Pendente') {
      if (quantity > 0 && reservationQuantity === 0) {
        if (separated === false) {
          if (productSelected && quantity <= productSelected.productAmount) {
            return (
              <Button
                className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive p-d-flex"
                label="SEPARAR"
                onClick={() =>
                  handleCheckboxSeparated(
                    id,
                    quantity,
                    reservationQuantity,
                    separated,
                    useProductReservation,
                    productIndex,
                  )
                }
              />
            );
          }
          return (
            <Button
              className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive p-d-flex"
              label="SEPARAR"
              onClick={() =>
                toasterWarn(
                  'ATENÇÃO',
                  'Não tem produto suficiente no estoque para separar esse produto!',
                )
              }
            />
          );
        }
        return (
          <Button
            className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive-separated p-d-flex"
            label="SEPARAR"
            onClick={() =>
              toasterError('ERRO', 'Esse produto já foi separado!')
            }
          />
        );
      }
      if (quantity === 0 && reservationQuantity > 1) {
        if (useProductReservation === true) {
          return (
            <Button
              className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive-square p-d-flex"
              label="SEPARAR"
              onClick={() =>
                confirmDialogSeparated(
                  id,
                  quantity,
                  reservationQuantity,
                  separated,
                  useProductReservation,
                  productIndex,
                )
              }
            />
          );
        }
        return (
          <Button
            className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive-separated p-d-flex"
            label="SEPARAR"
            onClick={() =>
              toasterError('ERRO', 'Esse produto já foi separado!')
            }
          />
        );
      }
      if (quantity > 0 && reservationQuantity > 0) {
        if (separated === false) {
          return (
            <Button
              className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive p-d-flex"
              label="SEPARAR"
              onClick={() =>
                handleCheckboxSeparated(
                  id,
                  quantity,
                  reservationQuantity,
                  separated,
                  useProductReservation,
                  productIndex,
                )
              }
            />
          );
        }
        if (separated === true && useProductReservation === true) {
          return (
            <Button
              className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive-square p-d-flex"
              label="SEPARAR"
              onClick={() =>
                confirmDialogSeparated(
                  id,
                  quantity,
                  reservationQuantity,
                  separated,
                  useProductReservation,
                  productIndex,
                )
              }
            />
          );
        }
        return (
          <Button
            className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive-separated p-d-flex"
            label="SEPARAR"
            onClick={() =>
              toasterError('ERRO', 'Esse produto já foi separado!')
            }
          />
        );
      }
    }
    return (
      <Button
        className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive-separated p-d-flex"
        label="SEPARAR"
        onClick={() => toasterError('ERRO', 'Esse produto já foi separado!')}
      />
    );
  }

  const handleEditService = async (serviceIndex: number, status: string) => {
    setPending({ pending: true, index: serviceIndex });
    if (status === 'toDo') {
      const dataOrder: Partial<IOrder> = {
        serviceOrder: visible.serviceOrder.map((service) => {
          if (service.index === serviceIndex) {
            return {
              ...service,
              status: {
                ...service.status,
                doing: firebase.firestore.Timestamp.now(),
              },
            };
          }
          return service;
        }),
      };

      const response = await editOrder(dataOrder, visible.id);
      if (response) {
        setTimeout(() => {
          setPending({ pending: false, index: NaN });
        }, 400);
      } else {
        setPending({ pending: false, index: NaN });
      }
    }
    if (status === 'done') {
      const dataOrder: Partial<IOrder> = {
        serviceOrder: visible.serviceOrder.map((service) => {
          if (service.index === serviceIndex) {
            return {
              ...service,
              status: {
                ...service.status,
                done: firebase.firestore.Timestamp.now(),
              },
            };
          }
          return service;
        }),
      };

      const response = editOrder(dataOrder, visible.id);
      if (!!response) {
        setTimeout(() => {
          setPending({ pending: false, index: NaN });
        }, 400);
      } else {
        setPending({ pending: false, index: NaN });
      }
    }
  };

  function handleCheckBoxService(
    index: number,
    toDo: string | firebase.firestore.Timestamp,
    done: string | firebase.firestore.Timestamp,
    doing: string | firebase.firestore.Timestamp,
  ) {
    const countProduct = visible.productOrder.filter(
      (product) => product.service_index === index,
    ).length;

    const amountProduct = visible.productOrder.filter(
      (product) =>
        product.service_index === index && product.separated === true,
    ).length;

    const futureAmountProduct = visible.productOrder.filter(
      (product) =>
        product.service_index === index &&
        product.useProductReservation === false,
    ).length;

    if (toDo !== 'Pendente' && doing === 'Pendente' && done === 'Pendente') {
      return (
        <BiCheckbox
          onClick={() => handleEditService(index, 'toDo')}
          className="order-checkbox"
        />
      );
    }
    if (toDo !== 'Pendente' && doing !== 'Pendente' && done === 'Pendente') {
      if (
        countProduct === amountProduct &&
        countProduct === futureAmountProduct
      ) {
        return (
          <BiTime
            onClick={() => handleEditService(index, 'done')}
            className="order-checkbox"
          />
        );
      }
      return (
        <BiTime
          onClick={() =>
            toasterError(
              'ERRO',
              'Voce deve separar todos os produtos desse serviço antes de concluir!',
            )
          }
          className="order-checkbox"
        />
      );
    }
    if (toDo !== 'Pendente' && doing !== 'Pendente' && done !== 'Pendente') {
      return (
        <BiCheckboxChecked
          onClick={() => toasterError('ERRO', 'Esse serviço já foi concluído!')}
          className="order-checkbox"
        />
      );
    }
    return null;
  }

  function handleCheckBoxServiceResponsive(
    index: number,
    toDo: string | firebase.firestore.Timestamp,
    done: string | firebase.firestore.Timestamp,
    doing: string | firebase.firestore.Timestamp,
  ) {
    const countProduct = visible.productOrder.filter(
      (product) => product.service_index === index,
    ).length;

    const amountProduct = visible.productOrder.filter(
      (product) =>
        product.service_index === index && product.separated === true,
    ).length;

    const futureAmountProduct = visible.productOrder.filter(
      (product) =>
        product.service_index === index &&
        product.useProductReservation === false,
    ).length;

    if (toDo !== 'Pendente' && doing === 'Pendente' && done === 'Pendente') {
      return (
        <Button
          className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive p-d-flex"
          label="SEPARAR"
          onClick={() => handleEditService(index, 'toDo')}
        />
      );
    }
    if (toDo !== 'Pendente' && doing !== 'Pendente' && done === 'Pendente') {
      if (
        countProduct === amountProduct &&
        countProduct === futureAmountProduct
      ) {
        return (
          <Button
            className="p-mx-auto p-as-center p-jc-center item-menu-order-await-responsive p-d-flex"
            label="EM ANDAMENTO"
            onClick={() => handleEditService(index, 'done')}
          />
        );
      }
      return (
        <Button
          className="p-mx-auto p-as-center p-jc-center item-menu-order-await-responsive p-d-flex"
          label="EM ANDAMENTO"
          onClick={() =>
            toasterError(
              'ERRO',
              'Voce deve separar todos os produtos desse serviço antes de concluir!',
            )
          }
        />
      );
    }
    if (toDo !== 'Pendente' && doing !== 'Pendente' && done !== 'Pendente') {
      return (
        <Button
          className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive-separated p-d-flex"
          label="Concluído"
          onClick={() => toasterError('ERRO', 'Esse serviço já foi concluído!')}
        />
      );
    }
    return null;
  }

  return (
    <>
      {visible.serviceOrder.length === 0 ? (
        <div className="p-mt-5 p-d-flex p-as-center p-jc-center">
          Esse Pedido não possui nenhum Serviço!
        </div>
      ) : (
        <>
          {windowWidth && windowWidth > 900 && (
            <div className="p-mt-2 p-d-flex p-as-center p-jc-center">
              <div className="p-grid p-my-1 p-mx-auto p-d-none p-d-md-inline-flex default-box-header">
                <div className="p-col-2 p-text-center p-as-center">#</div>
                <div className="p-col-2 p-text-center p-as-center">Imagem</div>
                <div className="p-col-2 p-text-center p-as-center">Serviço</div>
                <div className="p-col-2 p-text-center p-as-center">Preço</div>
                <div className="p-col-2 p-text-center p-as-center">Obs</div>
                <div className="p-col-2 p-text-center p-as-center">Status</div>
                <div className="p-col-2 p-text-center p-as-center">#</div>
              </div>
            </div>
          )}

          {visible.serviceOrder.map((service) => {
            return (
              <>
                <div className="p-mt-4">
                  <Service
                    service={service}
                    visible={visible}
                    processing={processing}
                    pending={pending.pending && pending.index === service.index}
                    open={open}
                    setOpen={setOpen}
                    handleCheckBoxService={handleCheckBoxService}
                    handleCheckBoxServiceResponsive={
                      handleCheckBoxServiceResponsive
                    }
                  />
                </div>

                {open.open && open.index === service.index && (
                  <>
                    <div className="p-mt-2 p-d-flex p-as-center p-jc-center">
                      <div className="p-grid p-my-1 p-mx-auto p-d-none p-d-md-inline-flex default-box-header">
                        <div className="p-col-1 p-text-center p-as-center">
                          #
                        </div>
                        <div className="p-col-1 p-text-center p-as-center">
                          Imagem
                        </div>
                        <div className="p-col-2 p-text-center p-as-center">
                          Produto
                        </div>
                        <div className="p-col-2 p-text-center p-as-center">
                          EAN
                        </div>
                        <div className="p-col-1 p-text-center p-as-center">
                          Quant. <small>(EFi)</small>
                        </div>
                        <div className="p-col-1 p-text-center p-as-center">
                          Quant. <small>(EFu)</small>
                        </div>
                        <div className="p-col-1 p-text-center p-as-center">
                          Tipo
                        </div>
                        <div className="p-col-1 p-text-center p-as-center">
                          Preço Total
                        </div>
                        <div className="p-col-1 p-text-center p-as-center">
                          Obs
                        </div>
                        <div className="p-col-1 p-text-center p-as-center">
                          Status
                        </div>
                      </div>
                    </div>
                    {visible.productOrder.map((product) => {
                      if (product.service_index === service.index) {
                        return (
                          <Product
                            product={product}
                            visible={visible}
                            processing={processing}
                            pendingProduct={
                              pendingProduct.pending &&
                              pendingProduct.index === product.index
                            }
                            handleCheckBoxProduct={handleCheckBoxProduct}
                            handleCheckBoxProductResponsive={
                              handleCheckBoxProductResponsive
                            }
                            toDo={service.status.toDo}
                            doing={service.status.doing}
                            done={service.status.done}
                          />
                        );
                      }
                      return null;
                    })}
                  </>
                )}
              </>
            );
          })}
        </>
      )}
    </>
  );
};

export default ItemService;
