// Importações Padrões
import React, { useState, useEffect } from 'react';

// Icones
import { FaEdit, FaEye } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';

// Hooks
import { useClientsList } from '../../hooks/useClientsList';
import { useUserslist } from '../../hooks/useUsersList';

// Componentes
import InputDropForm from '../input/inputDropForm.component';
import InputTextareaComponent from '../input/inputTextarea.component';

// Types
import { IVisit, IEditVisit } from '../../typings/visit.types';
import { IValidationError } from '../../typings/yup.types';
import { IInput } from '../../typings/input.types';

// Controladores
import { deleteVisit, editVisit } from '../../controllers/visit.controller';

// Funções
import { dataSchema } from '../../functions/visit.function';

interface IEditVisitProps {
  visible: IEditVisit;
  setVisible: React.Dispatch<React.SetStateAction<IEditVisit>>;
}

const EditVisit = (props: IEditVisitProps) => {
  const { visible, setVisible } = props;

  const { clientsList } = useClientsList();
  const { usersList } = useUserslist();

  const [startDate, setStartDate] = useState<Date>();
  const [allDay, setAllDay] = useState<boolean>();
  const [note, setNote] = useState<string>('');

  const [sellerNote, setSellerNote] = useState<string>('');

  const [client, setClient] = useState<IInput>({
    name: '',
    code: '',
    imageHeavy: '',
    imageLight: '',
  });

  const [seller, setSeller] = useState<IInput>({
    name: '',
    code: '',
  });

  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);
  const [pendingAction, setPendingAction] = useState<boolean>(false);

  function handleSeller() {
    const newUsersList = usersList.map((item) => {
      const [userSystem] = item.systems.filter(
        (system) => system.function === 'seller',
      );
      if (userSystem) {
        return { name: item.name, code: item.id };
      }
      return null;
    });
    return newUsersList.filter((userList) => userList !== null) as Array<
      IInput
    >;
  }

  const initialState = () => {
    setNote('');
    setFormErr([]);
    setClient({
      name: '',
      code: '',
      imageHeavy: '',
      imageLight: '',
    });
    setVisible({ ...visible, visible: false, edit: false });
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
  };

  const handleDeleteService = async () => {
    setPendingAction((state) => !state);
    if (visible.item.id) {
      const response = await deleteVisit(visible.item.id);
      if (response) {
        initialState();
      } else {
        setPendingAction((state) => !state);
      }
    }
  };

  async function handleEditVisit() {
    setPendingAction((state) => !state);

    const dataValidation: Partial<IVisit> = {
      startDate,
      endDate: startDate,

      allDay,
      note,

      client_id: client.code,
      client_name: client.name,
      client_imageHeavy: client.imageHeavy,
      client_imageLight: client.imageLight,

      seller_id: seller.code,
      seller_name: seller.name,
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        if (visible.item.id) {
          const response = await editVisit(dataValidation, visible.item.id);
          if (response) {
            setFormErr([]);
            initialState();
          } else {
            setFormErr([]);
            setPendingAction((state) => !state);
          }
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  useEffect(() => {
    const [clientSelected] = clientsList.filter(
      (system) => system.id === visible.item.client_id,
    );

    const [sellerSelected] = usersList.filter(
      (system) => system.id === visible.item.seller_id,
    );

    if (visible.item) {
      setAllDay(visible.item.allDay);
      setStartDate(visible.item.startDate);
    }
    if (visible.item.note) {
      setNote(visible.item.note);
    } else {
      setNote('Não possui.');
    }
    if (visible.item.sellerNote) {
      setSellerNote(visible.item.sellerNote);
    } else {
      setSellerNote('Não possui.');
    }
    if (clientSelected) {
      setClient({
        name: clientSelected.name,
        code: clientSelected.id,
        imageHeavy: clientSelected.imageHeavy,
        imageLight: clientSelected.imageLight,
      });
    }
    if (sellerSelected) {
      setSeller({ name: sellerSelected.name, code: sellerSelected.id });
    }
  }, [visible, clientsList, usersList]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              {visible.edit ? (
                <FaEdit className="p-mx-auto p-as-center modal-header-icon" />
              ) : (
                <FaEye className="p-mx-auto p-as-center modal-header-icon" />
              )}
            </div>
            <span className="p-my-auto users-button-text">
              {visible.edit ? 'Editar ' : 'Ver '}Visita
            </span>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            {visible.edit && (
              <>
                <Button
                  className="modal-save-button"
                  label="Apagar"
                  disabled={pendingAction}
                  icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
                  iconPos="right"
                  autoFocus={false}
                  onClick={() => handleDeleteService()}
                />
              </>
            )}

            <Button
              className="modal-save-button"
              label={visible.edit ? 'Salvar' : 'Editar'}
              disabled={pendingAction}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() =>
                visible.edit
                  ? handleEditVisit()
                  : setVisible({ ...visible, edit: true })
              }
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <InputDropForm
        filter
        filterBy="name"
        disabled={!visible.edit}
        item={client}
        setItem={setClient}
        options={clientsList.map((clients) => {
          return {
            name: clients.name,
            code: clients.id,
            imageHeavy: clients.imageHeavy,
            imageLight: clients.imageLight,
          };
        })}
        formErr={formErr}
        fieldLabel="Cliente"
        fieldCode="client_id"
      />
      <InputDropForm
        filter
        filterBy="name"
        disabled={!visible.edit}
        item={seller}
        setItem={setSeller}
        options={handleSeller()}
        formErr={formErr}
        fieldLabel="Vendedor"
        fieldCode="seller_id"
      />
      <div className="visits-button" style={{ width: '100%' }}>
        <label className="p-my-0 p-mt-4">Data</label>
        <Calendar
          disabled={!visible.edit}
          className="settings-input-calendar"
          showIcon
          locale="pt"
          hourFormat="24"
          dateFormat="dd/mm/yy"
          value={startDate}
          onChange={(e) => setStartDate(new Date(e.target.value as Date))}
        />
      </div>
      <div className="p-my-3">
        <Checkbox
          disabled={!visible.edit}
          className={
            !allDay
              ? 'p-p-0 p-mx-auto p-as-center settings-checkbox-selected'
              : 'p-p-0 p-mx-auto p-as-center settings-checkbox'
          }
          checked={!allDay}
          onChange={() => setAllDay(!allDay)}
        />
        <label className="p-ml-2">Especificar Horário</label>
      </div>
      {!allDay && (
        <>
          <div className="visits-button">
            <label className="p-my-0 p-mt-4 ">Selecione um Horário</label>
            <Calendar
              disabled={!visible.edit}
              className="settings-input-calendar"
              showIcon
              timeOnly
              icon="pi pi-spin pi-clock"
              hourFormat="24"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value as Date)}
            />
          </div>
        </>
      )}
      <div className="p-d-flex p-my-4 p-jc-center">
        <small className="input-warn">Anotações.</small>
      </div>
      <InputTextareaComponent
        disabled={!visible.edit}
        item={note}
        setItem={setNote}
        formErr={formErr}
        fieldLabel="Anotações da Visita (Na criação)!"
        fieldCode={note}
        rows={2}
        cols={30}
      />
      <InputTextareaComponent
        disabled
        item={sellerNote}
        setItem={setSellerNote}
        formErr={formErr}
        fieldLabel="Anotações do Vendedor (Feito pelo aplicativo)!"
        fieldCode={note}
        rows={2}
        cols={30}
      />
    </Dialog>
  );
};

export default EditVisit;
