import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface BoxInfoProps {
  title: string;
  icon: IconProp;
  useDivision: boolean;
  number: number;
  division: number;
  subtitle?: string;
}

export const BoxInfo = (props: BoxInfoProps) => {
  const { title, icon, useDivision, number, division, subtitle } = props;
  return (
    <div
      className="box dashboard-box-stats p-d-flex"
      style={{ border: '1px solid #EEEEEE' }}
    >
      <div className="p-mx-auto p-as-center p-d-flex dashboard-box-stats-icon">
        <div className="p-grid p-dir-col p-mx-auto p-my-auto">
          <FontAwesomeIcon
            icon={icon}
            className="p-mx-auto p-as-center p-col p-my-auto p-p-0 dashboard-box-stats-icon-width"
          />
          <small
            className="p-text-center p-as-center p-mx-auto p-col p-p-0 p-mt-2"
            style={{ fontSize: '.75rem' }}
          >
            {title}
          </small>
          {subtitle && (
            <small
              className="p-text-center p-as-center p-mx-auto p-col p-p-0 p-m-0"
              style={{ fontSize: '.55rem' }}
            >
              {subtitle}
            </small>
          )}
        </div>
      </div>
      <div className="p-mx-auto p-jc-center p-as-center p-d-flex dashboard-box-stats-content">
        <span className="p-ml-auto p-as-center">
          <CountUp end={number} duration={3} />
        </span>

        <span className="p-mr-auto p-as-center dashboard-box-stats-muted">
          {useDivision ? (
            <>
              /
              <CountUp end={division} duration={3} />
            </>
          ) : (
            <></>
          )}
        </span>
      </div>
    </div>
  );
};
export default BoxInfo;
