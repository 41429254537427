// Importações Padrões
import React, { useContext } from 'react';
import { maskBr } from 'js-brasil';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import {
  IClient,
  IEditClient,
  IDeleteClient,
} from '../../typings/client.types';

// Componentes
import ItemCol from '../defaultBox/itemCol.component';
import ItemImage from '../defaultBox/itemImage.component';
import ItemAction, { IEdit } from '../defaultBox/itemAction.component';
import ResponsiveItemAction from '../defaultBox/responsiveItemAction.component';

interface IITemMenu {
  client: IClient;
  index: number;
  showHideEditClient: IEditClient;
  setShowHideEditClient: React.Dispatch<React.SetStateAction<IEdit<IClient>>>;
  showHideDeleteClient: IDeleteClient;
  setShowHideDeleteClient: React.Dispatch<React.SetStateAction<IDeleteClient>>;
}
const defaultImage =
  'https://cdn2.iconfinder.com/data/icons/font-awesome/1792/briefcase-512.png';

const ItemClient = ({
  client,
  index,
  showHideEditClient,
  setShowHideEditClient,
  showHideDeleteClient,
  setShowHideDeleteClient,
}: IITemMenu) => {
  const { windowWidth } = useContext(Auth);

  function changeBreakpoints() {
    if (windowWidth && windowWidth > 0 && windowWidth <= 900) {
      return (
        <div className="p-mt-1 p-mx-auto p-p-3 p-grid item-box width100">
          <p className="p-col-9 width100 p-d-inline p-my-0 p-p-0 p-mr-0">
            #{index}
          </p>
          <div className="p-px-1 p-as-center p-mx-auto">
            <ItemImage
              colGrid={4}
              content={client.imageHeavy}
              imageDefault={defaultImage}
            />
          </div>
          <ItemCol colGrid={4} field="Nome" content={client.name} />
          <ItemCol
            colGrid={1}
            field="Tipo"
            content={
              client.typeOf === 'simpleProvider' ? 'Simples' : 'Completo'
            }
          />
          {!client.socialID && (
            <ItemCol colGrid={3} field="CPF/CNPJ" content="---" />
          )}
          {client.socialID && client.socialID.length === 11 && (
            <ItemCol
              colGrid={3}
              field="CPF"
              content={maskBr.cpf(client.socialID)}
            />
          )}
          {client.socialID && client.socialID.length === 14 && (
            <ItemCol
              colGrid={3}
              field="CNPJ"
              content={maskBr.cnpj(client.socialID)}
            />
          )}
          <ResponsiveItemAction
            item={client}
            actionEdit={showHideEditClient}
            setActionEdit={setShowHideEditClient}
            actionDelete={showHideDeleteClient}
            setActionDelete={setShowHideDeleteClient}
          />
        </div>
      );
    }
    if (windowWidth && windowWidth > 900) {
      return (
        <div
          className="p-mt-1 p-mx-auto p-px-3 p-grid item-box width100"
          style={{
            border: '1px solid #e9ecef',
            borderWidth: '0 0 1px 0',
            backgroundColor: 'white',
          }}
        >
          <ItemCol colGrid={1} field="Id" content={String(index)} />
          <div className="p-col-2">
            <ItemImage
              colGrid={12}
              content={client.imageHeavy}
              imageDefault={defaultImage}
            />
          </div>
          <ItemCol colGrid={3} field="Nome" content={client.name} />
          <ItemCol
            colGrid={2}
            field="Tipo"
            content={
              client.typeOf === 'simpleProvider' ? 'Simples' : 'Completo'
            }
          />
          {!client.socialID && (
            <ItemCol colGrid={2} field="CPF/CNPJ" content="---" />
          )}
          {client.socialID && client.socialID.length === 11 && (
            <ItemCol
              colGrid={2}
              field="CPF"
              content={maskBr.cpf(client.socialID)}
            />
          )}
          {client.socialID && client.socialID.length === 14 && (
            <ItemCol
              colGrid={2}
              field="CNPJ"
              content={maskBr.cnpj(client.socialID)}
            />
          )}
          <ItemAction
            item={client}
            actionEdit={showHideEditClient}
            setActionEdit={setShowHideEditClient}
            actionDelete={showHideDeleteClient}
            setActionDelete={setShowHideDeleteClient}
            colGrid={2}
          />
        </div>
      );
    }
    return <div />;
  }

  return changeBreakpoints();
};

export default ItemClient;
