import { useCallback, useContext, useEffect, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { maskBr } from 'js-brasil';
import { editServiceNoRes } from '../../controllers/service.controller';
import { initialService, IService } from '../../typings/service.types';
import { toasterWarn } from '../global/toaster.component';
import { IListService } from '../../typings/list.types';
import InputFormCurrency from '../input/inputFormCurrency.component';
import { emptyValidationError } from '../../typings/yup.types';
import { Auth } from '../../services/auth.context';
import ItemCol from '../defaultBox/itemCol.component';

interface IServiceListProps {
  listId: string;
  service: IService;
  serviceList: Array<IListService>;
  addChangerServiceList: (data: IListService) => void;
  remChangerServiceList: (data: Partial<IListService>) => void;
}

const ServiceList = (props: IServiceListProps) => {
  const {
    listId,
    service,
    serviceList,
    addChangerServiceList,
    remChangerServiceList,
  } = props;

  const { windowWidth } = useContext(Auth);

  const [serviceActive, setServiceActive] = useState(false);
  const [serviceItem, setServiceItem] = useState<IService>(initialService);
  const [listPrice, setListPrice] = useState<number>(
    service.listMeta && service.listMeta[listId]
      ? service.listMeta[listId].listPrice
      : service.minPrice,
  );

  function serviceListCreate(serviceCreate: IService) {
    if (serviceCreate.listEnabled && serviceCreate.listMeta) {
      const onList = serviceCreate.listEnabled.filter(
        (list) => list === listId,
      );
      const [listToDB] = serviceList.filter(
        (item) => item.service_id === serviceCreate.id,
      );

      if (onList.length === 1) {
        setServiceActive(true);
        setListPrice(
          listToDB
            ? listToDB.listPrice
            : serviceCreate.listMeta[listId].listPrice,
        );
        addChangerServiceList({
          service_id: service.id,
          serviceReference: service,
          listPrice: serviceCreate.listMeta[listId].listPrice,
          changer: false,
        });
      }
    }
    setServiceItem(serviceCreate);
  }

  async function handleAddServiceList() {
    let response;
    if (service.listEnabled && service.listMeta) {
      response = await editServiceNoRes(
        {
          listEnabled: [...service.listEnabled, listId],
          listMeta: { ...service.listMeta, [listId]: { listPrice } },
        },
        service.id,
      );
    } else {
      response = await editServiceNoRes(
        {
          listEnabled: [listId],
          listMeta: { [listId]: { listPrice } },
        },
        service.id,
      );
    }
    if (response) {
      setServiceActive(true);
    }
  }

  async function handleRemServiceList() {
    let response;
    if (service.listEnabled && service.listMeta) {
      delete service.listMeta[listId];
      response = await editServiceNoRes(
        {
          listEnabled: service.listEnabled.filter(
            (listDelete) => listDelete !== listId,
          ),
          listMeta: service.listMeta,
        },
        service.id,
      );
    }
    if (response) {
      setListPrice(service.minPrice);
      setServiceActive(false);
      remChangerServiceList({
        service_id: service.id,
      });
    }
  }

  const inputPriceStatus = useCallback(() => {
    if (
      (listPrice > serviceItem.maxPrice && serviceItem.useMaxPrice) ||
      listPrice < serviceItem.minPrice
    ) {
      return (
        <>
          <i
            className="pi pi-exclamation-circle text-danger question-1"
            data-pr-tooltip="O preço do serviço não condiz com os parâmetros de
            preço mínimo e máximo, e não será salvo."
          />
          <Tooltip
            target=".question-1"
            mouseTrack
            mouseTrackLeft={10}
            className="p-text-justify"
          />
        </>
      );
    }
    if (service.listMeta && service.listMeta[listId]) {
      if (service.listMeta[listId].listPrice !== listPrice) {
        return (
          <>
            <i
              question-1aria-hidden="true"
              className="pi pi-pencil question-2"
              data-pr-tooltip="O preço do serviço foi alterado,
              por favor clique em Salvar, para aplicar a alteração."
            />
            <Tooltip
              target=".question-2"
              mouseTrack
              mouseTrackLeft={10}
              className="p-text-justify"
            />
          </>
        );
      }
      if (service.listMeta[listId].listPrice === listPrice) {
        return (
          <>
            <i
              question-1aria-hidden="true"
              className="pi pi-save question-3"
              data-pr-tooltip="O preço do serviço está salvo na lista."
            />
            <Tooltip
              target=".question-3"
              mouseTrack
              mouseTrackLeft={10}
              className="p-text-justify"
            />
          </>
        );
      }
    }
    return <></>;
  }, [listPrice, service, serviceItem, listId]);

  useEffect(() => {
    if (service.listEnabled && service.listMeta) {
      const onList = service.listEnabled.filter((list) => list === listId);
      if (onList.length === 1) {
        const hasCond =
          (listPrice > serviceItem.maxPrice && serviceItem.useMaxPrice) ||
          listPrice < serviceItem.minPrice;
        if (!hasCond) {
          addChangerServiceList({
            service_id: service.id,
            serviceReference: service,
            listPrice,
            changer: true,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [listPrice]);

  useEffect(() => {
    serviceListCreate(service);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="p-grid p-mx-0 p-my-1 p-p-2 p-justify-center editListItems-product-item"
      style={{ width: '100%' }}
    >
      <div className="p-col-1 p-my-0 p-as-center p-d-flex">
        <Checkbox
          checked={serviceActive}
          onChange={() => {
            if (
              (listPrice > serviceItem.maxPrice && serviceItem.useMaxPrice) ||
              listPrice < serviceItem.minPrice
            ) {
              toasterWarn('PREÇO', 'O preço está fora do escopo.');
            } else if (!serviceActive) {
              handleAddServiceList();
            } else {
              handleRemServiceList();
            }
          }}
          className={`p-mx-auto  ${
            serviceActive ? 'settings-checkbox-selected' : 'settings-checkbox'
          }`}
        />
      </div>
      <ItemCol colGrid={6} field="Nome" content={serviceItem.name} />
      <div
        className={`p-col-3 p-p-0 p-my-3 item-box-col p-text-center p-as-center ${
          windowWidth && windowWidth < 900 && 'p-grid'
        }`}
      >
        <p
          className={`${
            windowWidth && windowWidth > 900
              ? 'p-text-center p-col-12 p-m-0'
              : 'p-ml-1 p-col-9'
          } p-d-inline p-my-0 p-p-0 p-mr-0 p-mx-auto`}
        >
          <span className="p-float-label p-input-icon-right">
            <InputFormCurrency
              disabled={false}
              item={listPrice}
              setItem={setListPrice}
              formErr={[emptyValidationError]}
              fieldLabel="Referência de Preço"
              fieldCode="minPrice"
              floatLabel
              iconStatus={inputPriceStatus}
            />
          </span>
        </p>
      </div>
      <div
        className={`p-col-2 p-p-0 p-my-2 item-box-col p-text-center p-as-center ${
          windowWidth && windowWidth < 900 && 'p-grid'
        }`}
      >
        <p
          className={`${
            windowWidth && windowWidth > 900
              ? 'p-text-center p-col-12 p-m-0'
              : 'p-ml-1 p-col-9'
          } p-d-inline p-my-0 p-p-0 p-mr-0`}
        >
          {serviceItem.useMaxPrice ? (
            <small className="p-p-0 p-my-0 p-as-center p-mx-auto">
              {`${maskBr.currency(
                serviceItem.minPrice / 100,
              )}~${maskBr.currency(serviceItem.maxPrice / 100)}`}
            </small>
          ) : (
            <small className="p-p-0 p-my-0 p-as-center">
              {`> ${maskBr.currency(serviceItem.minPrice / 100)}
              `}
            </small>
          )}
        </p>
      </div>
    </div>
  );
};

export default ServiceList;
