// Importações Padrões
import React, { useContext, useState } from 'react';

// Icones
import { FaTrash } from 'react-icons/fa';

// React Prime
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

// Contexto
import { Auth } from '../../services/auth.context';

// Types
import { IDeleteUser } from '../../typings/users.types';

// Controladores
import { deleteUser } from '../../controllers/user.controller';

interface IDeleteUserProps {
  visible: IDeleteUser;
  setVisible: React.Dispatch<React.SetStateAction<IDeleteUser>>;
}

const DeleteUser = (props: IDeleteUserProps) => {
  const { accessToken } = useContext(Auth);
  const { visible, setVisible } = props;

  const [pendingAction, setPendingAction] = useState(false);

  const initialState = () => {
    setPendingAction(false);
    setVisible({ id: '', name: '', visible: false });
  };

  const handleDeleteUser = async () => {
    setPendingAction((state) => !state);
    if (accessToken) {
      const response = await deleteUser(visible.id, accessToken);
      if (response) {
        initialState();
      } else {
        setPendingAction((state) => !state);
      }
    }
  };

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-d-flex">
              <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
                <FaTrash className="p-p-1 p-mx-auto p-as-center modal-header-icon" />
              </div>
              <span className="p-my-auto users-button-text">
                Apagar usuário
              </span>
            </div>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '36vw' }}
      footer={() => {
        return (
          <div className="p-d-flex p-mx-auto p-jc-center">
            <Button
              disabled={pendingAction}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              className="modal-save-button"
              label="Apagar"
              onClick={() => {
                handleDeleteUser();
              }}
            />
          </div>
        );
      }}
      onHide={() => initialState()}
      baseZIndex={0}
    >
      <div className="p-text-center">
        <p className="p-m-2 ">
          <b>Tem certeza que deseja apagar o usuário: {visible.name}?</b>
        </p>
      </div>
    </Dialog>
  );
};

export default DeleteUser;
