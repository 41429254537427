// Importações Padrões
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollTop } from 'primereact/scrolltop';

// Contexto
import { Auth } from '../services/auth.context';

// Hooks
import { useCategoriesList } from '../hooks/useCategoriesList';

// Types
import {
  ICategory,
  IDeleteCategory,
  IEditCategory,
  initialCategory,
} from '../typings/category.types';

// Componentes
import ItemCategory from '../components/categories/itemCategory.component';
import AddCategory from '../components/categories/addCategory.component';
import EditCategory from '../components/categories/editCategory.component';
import DeleteCategory from '../components/categories/deleteCategory.component';
import NavCategory from '../components/categories/navCategory.component';

// Estilos
import '../styles/global/categories.scss';

const Categories: React.FC = () => {
  const { setPathName, windowWidth } = useContext(Auth);
  const [categoriesList, pending] = useCategoriesList();

  const [showHideAddCategory, setShowHideAddCategory] = useState(false);
  const [showHideEditCategory, setShowHideEditCategory] =
    useState<IEditCategory>({
      item: initialCategory,
      edit: false,
      visible: false,
    });
  const [showHideDeleteCategory, setShowHideDeleteCategory] =
    useState<IDeleteCategory>({ id: '', name: '', visible: false });

  const [inputSearch, setInputSearch] = useState('');

  const location = useLocation();

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location, setPathName]);

  useEffect(() => {
    if (
      showHideAddCategory ||
      showHideEditCategory.visible ||
      showHideDeleteCategory.visible
    ) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHideAddCategory, showHideEditCategory, showHideDeleteCategory]);

  return (
    <div className="p-py-3 p-px-3 widthAuto">
      <ScrollTop className="button-scroll-top" />
      <AddCategory
        visible={showHideAddCategory}
        setVisible={setShowHideAddCategory}
      />
      <EditCategory
        visible={showHideEditCategory}
        setVisible={setShowHideEditCategory}
      />
      <DeleteCategory
        visible={showHideDeleteCategory}
        setVisible={setShowHideDeleteCategory}
      />
      <div className="p-py-3 p-px-2 p-text-justify dashboard-display white">
        <NavCategory
          placeHolder="Pesquisar..."
          pending={!pending}
          addFieldName="Nova Categoria"
          actionAdd={showHideAddCategory}
          setActionAdd={setShowHideAddCategory}
          setInputSearch={setInputSearch}
          activeAdd
        />

        <div className="p-d-flex p-dir-col p-mt-2 p-mb-auto width100">
          {windowWidth && windowWidth > 900 && (
            <div
              className="p-grid p-my-1 p-px-3 p-mx-auto p-d-none p-d-md-inline-flex default-box-header"
              style={{
                backgroundColor: '#f8f9fa',
                border: '1px solid #e9ecef',
              }}
            >
              <div className="p-col-1 p-text-center p-as-center">Id</div>
              <div className="p-col-4 p-text-center p-as-center">Imagem</div>
              <div className="p-col-4 p-text-center p-as-center">Categoria</div>
              <div className="p-col-3 p-text-center p-as-center">Ações</div>
            </div>
          )}

          {categoriesList
            .filter((item) => item.name)
            .filter(
              (item) =>
                item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                -1,
            )
            .map((category: ICategory, index: number) => {
              return (
                <ItemCategory
                  key={String(index)}
                  index={index + 1}
                  category={category}
                  showHideEditCategory={showHideEditCategory}
                  setShowHideEditCategory={setShowHideEditCategory}
                  showHideDeleteCategory={showHideDeleteCategory}
                  setShowHideDeleteCategory={setShowHideDeleteCategory}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Categories;
