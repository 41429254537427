import { useState, useEffect, useContext } from 'react';

import { useProductList } from '../../hooks/useProductsList';
import ProductOnList from './productOnList.component';
import { ProgressSpinnerSys } from '../global/progressSpinner.component';
import { IListProducts } from '../../typings/list.types';
import { Auth } from '../../services/auth.context';

interface IListItemsProps {
  inputSearch: string;
  productsOnList: IListProducts;
  setProductsOnList: React.Dispatch<React.SetStateAction<IListProducts>>;
}

const ListItensProducts = (props: IListItemsProps) => {
  const { windowWidth } = useContext(Auth);

  const { productsOnList, setProductsOnList, inputSearch } = props;
  const [productsList] = useProductList();
  const [pending, setPending] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPending(false);
    }, 1000);
  }, []);

  return (
    <div>
      {windowWidth && windowWidth > 900 && (
        <div className="p-grid p-my-1 p-mx-auto p-d-md-inline-flex default-box-header">
          <div className="p-col-1 p-text-center p-as-center">Inserir</div>
          <div className="p-col-4 p-text-center p-as-center">Nome</div>
          <div className="p-col-2 p-text-center p-as-center">EAN</div>
          <div className="p-col-3 p-text-center p-as-center">Referência</div>
          <div className="p-col-2 p-text-center p-as-center">Preço</div>
        </div>
      )}

      {!pending ? (
        productsList
          .filter(
            (item) =>
              item.name.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                -1 ||
              item.barCode.toUpperCase().indexOf(inputSearch.toUpperCase()) !==
                -1,
          )
          .map(
            (item, index) => {
              return (
                <ProductOnList
                  key={String(index)}
                  index={index}
                  item={item}
                  productsOnList={productsOnList}
                  setProductsOnList={setProductsOnList}
                  inputSearch={inputSearch}
                />
              );
            },
            [inputSearch],
          )
      ) : (
        <div
          className="p-d-flex p-jc-center p-as-center"
          style={{ height: '70vh' }}
        >
          <ProgressSpinnerSys />
        </div>
      )}
    </div>
  );
};

export default ListItensProducts;
