export interface IValidationError {
  path: string;
  errors: Array<string>;
  inner: Array<IValidationError>;
}

export const emptyValidationError = {
  path: '',
  errors: [],
  inner: [],
};
