import { InputTextarea } from 'primereact/inputtextarea';

import './AreaInput.scss';

export interface AreaInputProps {
  label: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  rows: number;
}
export const AreaInput = (props: AreaInputProps) => {
  const { label, value, setValue, rows } = props;
  return (
    <div className="area-input">
      <label>{label}</label>
      <InputTextarea rows={rows} value={value} onChange={(e) => setValue(e.target.value)} />
    </div>
  );
};
