import { Slider, SliderValueType } from 'primereact/slider';
import { useEffect, useState } from 'react';
import { IValidationError } from '../../../typings/yup.types';
import { ErrorsInput } from '../errors-input/ErrorsInput';
import { MandatoryAsterisk } from '../mandatory-asterisk/MandatoryAsterisk';

import './SliderInput.scss';

export interface SliderInputProps {
  label: string;
  value: SliderValueType;
  setValue: React.Dispatch<React.SetStateAction<SliderValueType>>;
  mandatory: boolean;
  errors?: Array<IValidationError>;
  errorCode?: string;
}
export const SliderInput = (props: SliderInputProps) => {
  const { label, value, setValue, mandatory, errors, errorCode } = props;

  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    if (errors && errors.filter((error) => error.path === errorCode).length >= 1) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
  }, [errors]);

  return (
    <div className="slider-input">
      <Slider value={value} onChange={(e) => setValue(e.value)} />
      <label className={`${isInvalid && 'invalid'}`}>
        {`${label}: ${value}%`}
        {mandatory && <MandatoryAsterisk />}
      </label>
      <div className="errors">
        {errors
          ?.filter((error) => error.path === errorCode)
          .map((errorComponent, index) => {
            return <ErrorsInput key={String(index)} errorMessage={errorComponent.errors} />;
          })}
      </div>
    </div>
  );
};
