// Types
import { IListMeta, IProduct } from './product.types';
import { initialService, IService } from './service.types';

export interface IList {
  id: string;
  name: string;
  code3c_id: string;
  province_id?: string;
  city_id?: string;
  productList?: Array<IListProduct>;
  // Corrigir
  // eslint-disable-next-line
  serviceList?: Array<any>;
}

export interface IListProduct {
  product_id: string;
  listPrice: number;
  productReference: IProduct;
  changer: boolean;
}
export interface IListProducts {
  [string: string]: IListPrice;
}

export interface IListPrice {
  listPrice: number;
  listMeta?: { [string: string]: IListMeta };
  listEnabled?: Array<string>;
}

export interface IListService {
  service_id: string;
  listPrice: number;
  serviceReference: IService;
  changer: boolean;
}

export interface IEditList {
  item: IList;
  edit: boolean;
  visible: boolean;
}

export interface IEditListItens {
  item: IList;
  edit: boolean;
  visible: boolean;
}

export interface IDeleteList {
  id: string;
  name: string;
  visible: boolean;
}

export const initialListService = {
  product_id: '',
  listPrice: 0,
  serviceReference: initialService,
  changer: false,
};

export const initialList = {
  id: '',
  name: '',
  code3c_id: '',
  province_id: '',
  city_id: '',
  productList: [],
  serviceList: [],
};
