import React from 'react';
import {
  generatorImageURL,
  generatorProductBase,
} from '../../functions/image.function';

interface IProductTitle {
  name: string;
  image: string;
  useProductBase: boolean;
}

const ProductTitle = (props: IProductTitle): JSX.Element => {
  const { name, image, useProductBase } = props;

  const defaultImage =
    'https://i.pinimg.com/originals/61/3c/ea/613ceaae3543acdf381af20da47612dc.png';

  return (
    <>
      {useProductBase ? (
        <div className="p-grid p-my-1">
          <div className="p-col-2 p-d-flex">
            <img
              alt="Imagem Empresa"
              className="p-mx-auto p-d-flex p-as-center"
              style={{ width: '50px', height: '50px', borderRadius: '5px' }}
              src={image !== '' ? generatorProductBase(image) : defaultImage}
            />
          </div>
          <div className="p-col-10">
            <p className="p-as-center editAmount-title">{name}</p>
          </div>
        </div>
      ) : (
        <div className="p-grid p-my-1">
          <div className="p-col-2 p-d-flex">
            <img
              alt="Imagem Empresa"
              className="p-mx-auto p-d-flex p-as-center"
              style={{ width: '50px', height: '50px', borderRadius: '5px' }}
              src={image !== '' ? generatorImageURL(image) : defaultImage}
            />
          </div>
          <div className="p-col-10">
            <p className="p-as-center editAmount-title">{name}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductTitle;
