import React, { useState, useEffect, useContext, useCallback } from 'react';
import { FaEdit, FaEye } from 'react-icons/fa';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Auth } from '../../services/auth.context';
import { IList, IEditList, initialList } from '../../typings/list.types';
import { IValidationError } from '../../typings/yup.types';
import { editList } from '../../controllers/list.controller';
import InputForm from '../input/inputForm.component';
import { dataSchema } from '../../functions/list.function';
import CitiesDropSelect from '../cities/citiesDropSelect.component';
import ListCitiesDropSelect from './listCitiesDropSelect.component';
import { IInput, initialInputOptions } from '../../typings/input.types';
import { useProvincesList } from '../../hooks/useProvincesList';
import { useCitiesList } from '../../hooks/useCitiesList';

interface IEditListProps {
  visible: IEditList;
  setVisible: React.Dispatch<React.SetStateAction<IEditList>>;
}

const EditList = (props: IEditListProps) => {
  const { visible, setVisible } = props;
  const { code3cData } = useContext(Auth);

  const [name, setName] = useState<string>('');
  const [province, setProvince] = useState<IInput>(initialInputOptions);
  const [city, setCity] = useState<IInput>(initialInputOptions);

  const [optionalActions, setOptionalActions] = useState<boolean>(false);
  const [formErr, setFormErr] = useState<Array<IValidationError>>([]);
  const [pendingAction, setPendingAction] = useState<boolean>(false);

  const [provincesList] = useProvincesList();
  const [citiesList] = useCitiesList(province.code);

  const editListCreate = useCallback(
    (visibles: IEditList) => {
      setName(visibles.item.name);
      const hasActionOptions =
        visibles.item.province_id &&
        visibles.item.city_id &&
        visibles.item.province_id !== '' &&
        visibles.item.city_id !== '';
      if (hasActionOptions) {
        setOptionalActions(true);
        const [selectedProvince] = provincesList.filter(
          (provinces) => provinces.id === visibles.item.province_id,
        );
        const [selectedCity] = citiesList.filter(
          (cities) => cities.id === visibles.item.city_id,
        );
        setProvince({
          name: selectedProvince.name,
          code: selectedProvince.id,
        });
        selectedCity &&
          setCity({
            name: selectedCity.name,
            code: selectedCity.id,
          });
      }
    },
    [provincesList, citiesList],
  );

  function editListDestroy() {
    const emptyString = '';
    setName(emptyString);
    setProvince(initialInputOptions);
    setCity(initialInputOptions);
    setFormErr([]);
    setOptionalActions(false);
    setVisible({ visible: false, item: initialList, edit: false });
    setTimeout(() => {
      setPendingAction(false);
    }, 400);
  }

  async function handleEditList() {
    setPendingAction((state) => !state);

    let dataValidation: Partial<IList>;

    if (optionalActions) {
      dataValidation = {
        name,
        code3c_id: code3cData?.id,
        province_id: province.code,
        city_id: city.code,
      };
    } else {
      dataValidation = {
        name,
        code3c_id: code3cData?.id,
      };
    }

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await editList(dataValidation, visible.item.id);
        if (response) {
          setFormErr([]);
          editListDestroy();
        } else {
          setFormErr([]);
          setPendingAction((state) => !state);
        }
      })
      .catch((error: IValidationError) => {
        setPendingAction((state) => !state);
        if (error) {
          setFormErr(error.inner);
        } else {
          setFormErr([]);
        }
        console.error(error.inner);
      });
  }

  useEffect(() => {
    editListCreate(visible);
  }, [visible, editListCreate]);

  return (
    <Dialog
      header={() => {
        return (
          <div className="p-d-flex">
            <div className="p-p-0 p-mr-2 p-d-flex modal-header-button">
              {visible.edit ? (
                <FaEdit className="p-mx-auto p-as-center modal-header-icon" />
              ) : (
                <FaEye className="p-mx-auto p-as-center modal-header-icon" />
              )}
            </div>
            <span className="p-my-auto users-button-text">
              {visible.edit ? 'Editar ' : 'Ver '}Lista
            </span>
          </div>
        );
      }}
      visible={visible.visible}
      breakpoints={{ '900px': '100vw' }}
      style={{ width: '40vw' }}
      footer={() => {
        return (
          <div>
            <Button
              className="modal-save-button"
              label={visible.edit ? 'Salvar' : 'Editar'}
              disabled={pendingAction}
              icon={pendingAction ? 'pi pi-spin pi-spinner' : ''}
              iconPos="right"
              onClick={() =>
                visible.edit
                  ? handleEditList()
                  : setVisible({ ...visible, edit: true })
              }
              autoFocus={false}
            />
          </div>
        );
      }}
      onHide={() => editListDestroy()}
      baseZIndex={0}
    >
      <InputForm
        pleaceHolder=""
        disabled={!visible.edit}
        item={name}
        setItem={setName}
        formErr={formErr}
        fieldLabel="Nome da Lista"
        fieldCode="name"
      />
      <Checkbox
        disabled={!visible.edit}
        checked={optionalActions}
        onChange={() => {
          setOptionalActions((state) => !state);
        }}
        className={`p-p-0 p-mx-auto p-as-center ${
          optionalActions ? 'settings-checkbox-selected' : 'settings-checkbox'
        }`}
      />
      <label className="p-ml-2">Inserir mais informações</label>
      {optionalActions && (
        <div>
          <div className="p-d-flex p-jc-center p-mt-3">
            <CitiesDropSelect
              province={province}
              setProvince={setProvince}
              onPage={false}
              disabled={!visible.edit}
            />
          </div>
          <div className="p-d-flex p-jc-center p-mt-2">
            <ListCitiesDropSelect
              city={city}
              setCity={setCity}
              UF={province.code}
              disabled={!visible.edit}
            />
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default EditList;
