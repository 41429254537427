// Importações Padrões
import firebase from 'firebase';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Componentes
import {
  toasterError,
  toasterSuccess,
  toasterWarn,
} from '../components/global/toaster.component';

const paymentsCollection = firebaseConfig.firestore().collection('FDV-payment');

async function enablePayment(
  id: string,
  code3cEnabled: Array<string>,
  enabled: boolean,
) {
  try {
    const dataDBExists = await paymentsCollection.doc(id).get();
    if (!dataDBExists) {
      return false;
    }
    await paymentsCollection.doc(id).update({
      code3cEnabled,
      updated_at: firebase.firestore.Timestamp.now(),
    });
    if (enabled) {
      toasterWarn('DESABILITAR', 'Estado desabilitado!');
    } else {
      toasterSuccess('HABILITAR', 'Estado habilitado!');
    }
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a sua requisição!');
    return false;
  }
}

export { paymentsCollection, enablePayment };
