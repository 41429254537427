// Importações Padrões
import { useContext, useState } from 'react';

// React Prime
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

// Icones
import {
  BiCheckbox,
  BiCheckboxChecked,
  BiCheckboxSquare,
} from 'react-icons/bi';

// Controladores
import { editOrderProduct } from '../../../controllers/order.controller';

// Types
import { IOrder } from '../../../typings/order.types';
import { IProduct } from '../../../typings/product.types';

// Componentes
import Product from './product.component';
import { toasterError, toasterWarn } from '../../global/toaster.component';

// Hooks
import { useProductList } from '../../../hooks/useProductsList';

// Contexto
import { Auth } from '../../../services/auth.context';

interface IITemMenu {
  visible: IOrder;
  processing: string;
  search: string;
}

const ItemProduct = ({ visible, processing, search }: IITemMenu) => {
  const { windowWidth } = useContext(Auth);

  const [productsList] = useProductList();

  const [pending, setPending] = useState({ pending: false, index: -1 });

  const handleCheckboxSeparated = async (
    id: string,
    quantity: number,
    reservationQuantity: number,
    separated: boolean,
    useProductReservation: boolean,
    productIndex: number,
  ) => {
    setPending({ pending: true, index: productIndex });
    const [productSelected] = productsList.filter(
      (product) => product.id === id,
    );
    if (quantity > 0 && reservationQuantity === 0) {
      if (separated === false) {
        const dataOrder: Partial<IOrder> = {
          productOrder: visible.productOrder.map((product) => {
            if (product.index === productIndex) {
              return { ...product, separated: true };
            }
            return product;
          }),
        };
        const dataProduct: Partial<IProduct> = {
          productAmount: productSelected.productAmount - quantity,
        };
        const response = await editOrderProduct(
          dataOrder,
          dataProduct,
          visible.id,
          id,
        );
        if (response) {
          setTimeout(() => {
            setPending({ pending: false, index: NaN });
          }, 400);
        } else {
          setPending({ pending: false, index: NaN });
        }
      }
    }
    if (quantity === 0 && reservationQuantity > 1) {
      if (useProductReservation === true) {
        const dataOrder: Partial<IOrder> = {
          productOrder: visible.productOrder.map((product) => {
            if (product.index === productIndex) {
              return { ...product, useProductReservation: false };
            }
            return product;
          }),
        };
        const dataProduct: Partial<IProduct> = {
          productAmount: productSelected.productAmount - reservationQuantity,
          reservationAvailable:
            productSelected.reservationAvailable + reservationQuantity,
        };
        const response = await editOrderProduct(
          dataOrder,
          dataProduct,
          visible.id,
          id,
        );
        if (response) {
          setTimeout(() => {
            setPending({ pending: false, index: NaN });
          }, 400);
        } else {
          setPending({ pending: false, index: NaN });
        }
      }
    }
    if (quantity > 0 && reservationQuantity > 0) {
      if (separated === false && useProductReservation === true) {
        const dataOrder: Partial<IOrder> = {
          productOrder: visible.productOrder.map((product) => {
            if (product.index === productIndex) {
              return { ...product, separated: true };
            }
            return product;
          }),
        };
        const dataProduct: Partial<IProduct> = {
          productAmount: productSelected.productAmount - quantity,
        };
        const response = await editOrderProduct(
          dataOrder,
          dataProduct,
          visible.id,
          id,
        );
        if (response) {
          setTimeout(() => {
            setPending({ pending: false, index: NaN });
          }, 400);
        } else {
          setPending({ pending: false, index: NaN });
        }
      }
      if (separated === true && useProductReservation === true) {
        const dataOrder: Partial<IOrder> = {
          productOrder: visible.productOrder.map((product) => {
            if (product.index === productIndex) {
              return { ...product, useProductReservation: false };
            }
            return product;
          }),
        };
        const dataProduct: Partial<IProduct> = {
          productAmount: productSelected.productAmount - reservationQuantity,
          reservationAvailable:
            productSelected.reservationAvailable + reservationQuantity,
        };
        const response = await editOrderProduct(
          dataOrder,
          dataProduct,
          visible.id,
          id,
        );
        if (response) {
          setTimeout(() => {
            setPending({ pending: false, index: NaN });
          }, 400);
        } else {
          setPending({ pending: false, index: NaN });
        }
      }
    }
  };

  const sumDisabledProduct = visible.productOrder.filter((item) => {
    if (item.service_index && item.service_index < 0) {
      return true;
    }
    return false;
  }).length;

  const confirmDialogSeparated = (
    id: string,
    quantity: number,
    reservationQuantity: number,
    separated: boolean,
    useProductReservation: boolean,
    productIndex: number,
  ) => {
    const [productSelected] = productsList.filter(
      (product) => product.id === id,
    );

    confirmDialog({
      message: () => {
        return (
          <>
            <div>
              Tem certeza que deseja separar {reservationQuantity} produtos da
              reserva para o estoque?
            </div>
            <br />
            <div>Produtos no estoque: {productSelected.productAmount}</div>
            <br />
            <div>Essa ação é irreversível</div>
          </>
        );
      },
      icon: 'pi pi-exclamation-triangle',
      acceptClassName:
        productSelected.productAmount > reservationQuantity
          ? 'accept-button'
          : 'disabled-button',
      rejectClassName: 'reject-button',
      rejectLabel: 'Não',
      acceptLabel: 'Sim',
      accept: () => {
        if (productSelected.productAmount > reservationQuantity) {
          handleCheckboxSeparated(
            id,
            quantity,
            reservationQuantity,
            separated,
            useProductReservation,
            productIndex,
          );
        } else {
          toasterWarn('Erro', 'Não há estoque suficiente');
        }
      },
    });
  };

  function handleCheckBoxProduct(
    id: string,
    quantity: number,
    reservationQuantity: number,
    separated: boolean,
    useProductReservation: boolean,
    productIndex: number,
  ) {
    const [productSelected] = productsList.filter(
      (product) => product.id === id,
    );
    if (quantity > 0 && reservationQuantity === 0) {
      if (separated === false) {
        if (productSelected && quantity <= productSelected.productAmount) {
          return (
            <BiCheckbox
              onClick={() =>
                handleCheckboxSeparated(
                  id,
                  quantity,
                  reservationQuantity,
                  separated,
                  useProductReservation,
                  productIndex,
                )
              }
              className="order-checkbox"
            />
          );
        }
        return (
          <BiCheckbox
            onClick={() =>
              toasterWarn(
                'ATENÇÃO',
                'Não tem produto suficiente no estoque para separar esse produto!',
              )
            }
            className="order-checkbox"
          />
        );
      }
      return (
        <BiCheckboxChecked
          onClick={() => toasterError('ERRO', 'Esse produto já foi separado!')}
          className="order-checkbox"
        />
      );
    }
    if (quantity === 0 && reservationQuantity > 1) {
      if (useProductReservation === true) {
        return (
          <BiCheckboxSquare
            onClick={() =>
              confirmDialogSeparated(
                id,
                quantity,
                reservationQuantity,
                separated,
                useProductReservation,
                productIndex,
              )
            }
            className="order-checkbox-reservation"
          />
        );
      }
      return (
        <BiCheckboxChecked
          onClick={() => toasterError('ERRO', 'Esse produto já foi separado!')}
          className="order-checkbox"
        />
      );
    }
    if (quantity > 0 && reservationQuantity > 0) {
      if (separated === false) {
        return (
          <BiCheckbox
            onClick={() =>
              handleCheckboxSeparated(
                id,
                quantity,
                reservationQuantity,
                separated,
                useProductReservation,
                productIndex,
              )
            }
            className="order-checkbox"
          />
        );
      }
      if (separated === true && useProductReservation === true) {
        return (
          <BiCheckboxSquare
            onClick={() =>
              confirmDialogSeparated(
                id,
                quantity,
                reservationQuantity,
                separated,
                useProductReservation,
                productIndex,
              )
            }
            className="order-checkbox-reservation"
          />
        );
      }
      return (
        <BiCheckboxChecked
          onClick={() => toasterError('ERRO', 'Esse produto já foi separado!')}
          className="order-checkbox"
        />
      );
    }
    return null;
  }

  function handleCheckBoxProductResponsive(
    id: string,
    quantity: number,
    reservationQuantity: number,
    separated: boolean,
    useProductReservation: boolean,
    productIndex: number,
  ) {
    const [productSelected] = productsList.filter(
      (product) => product.id === id,
    );
    if (quantity > 0 && reservationQuantity === 0) {
      if (separated === false) {
        if (productSelected && quantity <= productSelected.productAmount) {
          return (
            <Button
              className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive p-d-flex"
              label="SEPARAR"
              onClick={() =>
                handleCheckboxSeparated(
                  id,
                  quantity,
                  reservationQuantity,
                  separated,
                  useProductReservation,
                  productIndex,
                )
              }
            />
          );
        }
        return (
          <Button
            className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive p-d-flex"
            label="SEPARAR"
            onClick={() =>
              toasterWarn(
                'ATENÇÃO',
                'Não tem produto suficiente no estoque para separar esse produto!',
              )
            }
          />
        );
      }
      return (
        <Button
          className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive-separated p-d-flex"
          label="SEPARAR"
          onClick={() => toasterError('ERRO', 'Esse produto já foi separado!')}
        />
      );
    }
    if (quantity === 0 && reservationQuantity > 1) {
      if (useProductReservation === true) {
        return (
          <Button
            className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive-square p-d-flex"
            label="SEPARAR"
            onClick={() =>
              confirmDialogSeparated(
                id,
                quantity,
                reservationQuantity,
                separated,
                useProductReservation,
                productIndex,
              )
            }
          />
        );
      }
      return (
        <Button
          className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive-separated p-d-flex"
          label="SEPARAR"
          onClick={() => toasterError('ERRO', 'Esse produto já foi separado!')}
        />
      );
    }
    if (quantity > 0 && reservationQuantity > 0) {
      if (separated === false) {
        return (
          <Button
            className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive p-d-flex"
            label="SEPARAR"
            onClick={() =>
              handleCheckboxSeparated(
                id,
                quantity,
                reservationQuantity,
                separated,
                useProductReservation,
                productIndex,
              )
            }
          />
        );
      }
      if (separated === true && useProductReservation === true) {
        return (
          <Button
            className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive-square p-d-flex"
            label="SEPARAR"
            onClick={() =>
              confirmDialogSeparated(
                id,
                quantity,
                reservationQuantity,
                separated,
                useProductReservation,
                productIndex,
              )
            }
          />
        );
      }
      return (
        <Button
          className="p-mx-auto p-as-center p-jc-center item-menu-order-responsive-separated p-d-flex"
          label="SEPARAR"
          onClick={() => toasterError('ERRO', 'Esse produto já foi separado!')}
        />
      );
    }
    return null;
  }

  return (
    <>
      {sumDisabledProduct === 0 ? (
        <div className="p-mt-5 p-d-flex p-as-center p-jc-center scrollitem">
          Esse Pedido não possui nenhum Produto!
        </div>
      ) : (
        <>
          {windowWidth && windowWidth > 900 && (
            <div
              className="p-mt-2 p-d-flex p-as-center p-jc-center scrollitem"
              style={{
                backgroundColor: '#f8f9fa',
                border: '1px solid #e9ecef',
              }}
            >
              <div className="p-grid p-my-1 p-mx-auto p-d-md-inline-flex default-box-header">
                <div className="p-col-1 p-text-center p-as-center">#</div>
                <div className="p-col-1 p-text-center p-as-center">Imagem</div>
                <div className="p-col-2 p-text-center p-as-center">Produto</div>
                <div className="p-col-2 p-text-center p-as-center">EAN</div>
                <div className="p-col-1 p-text-center p-as-center">
                  Quant. <small>(EFi)</small>
                </div>
                <div className="p-col-1 p-text-center p-as-center">
                  Quant. <small>(EFu)</small>
                </div>
                <div className="p-col-1 p-text-center p-as-center">Tipo</div>
                <div className="p-col-1 p-text-center p-as-center">
                  Preço Total
                </div>
                <div className="p-col-1 p-text-center p-as-center">Obs</div>
                <div className="p-col-1 p-text-center p-as-center">Status</div>
              </div>
            </div>
          )}

          {visible.productOrder
            .filter(
              (item) =>
                item.product_name
                  .toUpperCase()
                  .indexOf(search.toUpperCase()) !== -1,
            )
            .map((product) => {
              if (product.service_index && product.service_index < 0) {
                return (
                  <Product
                    product={product}
                    visible={visible}
                    processing={processing}
                    pending={pending.pending && pending.index === product.index}
                    handleCheckBoxProduct={handleCheckBoxProduct}
                    handleCheckBoxProductResponsive={
                      handleCheckBoxProductResponsive
                    }
                  />
                );
              }
              return null;
            })}
        </>
      )}
    </>
  );
};

export default ItemProduct;
