// Importações Padrões
import firebase from 'firebase';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Types
import { IProvider } from '../typings/provider.types';

// Componentes
import {
  toasterError,
  toasterSuccess,
} from '../components/global/toaster.component';

// Funções
import { deleteImage } from '../functions/image.function';

const providerCollection = firebaseConfig
  .firestore()
  .collection('FDV-provider');

async function addProvider(data: Partial<IProvider>) {
  try {
    const response = await providerCollection.add({
      ...data,
      created_at: firebase.firestore.Timestamp.now(),
      updated_at: firebase.firestore.Timestamp.now(),
    });
    if (response.id) {
      toasterSuccess('CRIAR', `Fornecedor ${data.name} Criado`);
      return true;
    }
    return false;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a seu fornecedor!');
    return false;
  }
}

async function editProvider(data: Partial<IProvider>, id: string) {
  try {
    const dataDBExists = await providerCollection.doc(id).get();
    if (!dataDBExists) {
      return [];
    }
    await providerCollection.doc(id).update({
      ...data,
      updated_at: firebase.firestore.Timestamp.now(),
    });
    toasterSuccess('EDITAR', `Fornecedor ${data.name} Editado!`);
    return true;
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Parece que tem algo errado com a seu fornecedor!');
    return false;
  }
}
async function deleteProvider(id: string, name: string) {
  try {
    const response = await providerCollection.doc(id).get();
    if (response.data()) {
      const item = response.data() as IProvider;
      deleteImage(item.imageHeavy);
    }
    await providerCollection.doc(id).delete();
    toasterSuccess('APAGAR', `Fornecedor ${name} Apagado!`);
    return true;
  } catch (err) {
    console.log(err);
    toasterError('ERRO', 'Parece que tem algo errado com a seu fornecedor!');
    return false;
  }
}

async function searchProvider(id: string) {
  try {
    const data = await providerCollection.doc(id).get();
    return <IProvider>{ ...data.data(), id };
  } catch (err) {
    console.error(err);
    toasterError('ERRO', 'Não foi possível ler os dados.');
    return null;
  }
}

export {
  providerCollection,
  addProvider,
  editProvider,
  searchProvider,
  deleteProvider,
};
