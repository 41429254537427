import { useCallback, useContext } from 'react';
import 'moment/locale/pt-br';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import {
  progressBodyTemplate,
  statusBodyTemplate,
  feedbackBodyTemplate,
  goalBodyTemplate,
  parseGoal,
  parseRealized,
  parseNotRealized,
  parseStatus,
} from './DataTableBody';
import { SalesTargetProvider } from '../contexts/SalesTargetContextt';
import { SelectedSalesTargetHeader } from './SelectedSalesTargetHeader';
import { filterApplyTemplate, filterClearTemplate } from './DataTableFilters';

import './ClientSalesTarget.scss';

export function ClientSalesTarget() {
  const {
    clients,
    goal,
    realized,
    setSelectedClient,
    toggleView,
    setToggleView,
  } = useContext(SalesTargetProvider);

  const renderClientSalesTarget = useCallback(() => {
    return (
      <div className="client-sales-target">
        <SelectedSalesTargetHeader
          headerGoal={goal || 0}
          headerRealized={realized || 0}
          toggleView={toggleView!}
          setToggleView={setToggleView!}
        />
        <div className="card">
          <DataTable
            value={clients}
            stripedRows
            showGridlines
            rowHover
            removableSort
            emptyMessage="Nenhum Cliente"
            sortField="name"
            sortOrder={1}
          >
            <Column
              field="name"
              header="Nome"
              sortable
              filter
              filterPlaceholder="Pesquisar Nome"
              filterClear={filterClearTemplate}
              filterApply={filterApplyTemplate}
              filterMatchMode="contains"
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              maxConstraints={1}
            />
            <Column
              field="seller"
              header="Vendedor"
              sortable
              filter
              filterField="seller"
              filterPlaceholder="Pesquisar Vendedor"
              filterClear={filterClearTemplate}
              filterApply={filterApplyTemplate}
              filterMatchMode="contains"
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              maxConstraints={1}
            />
            <Column
              field="potential"
              header="Potencial"
              sortable
              body={(rowData) => {
                return goalBodyTemplate(parseGoal(rowData.potential));
              }}
            />
            <Column
              field="goal"
              header="Meta"
              sortable
              body={(rowData) => {
                return goalBodyTemplate(parseGoal(rowData.goal));
              }}
            />
            <Column
              field="realized"
              header="Realizado"
              sortable
              body={(rowData) => {
                return feedbackBodyTemplate(
                  parseRealized(rowData.realized, rowData.goal),
                );
              }}
            />
            <Column
              field="notRealized"
              header="Não Realizado"
              sortable
              body={(rowData) => {
                return feedbackBodyTemplate(
                  parseNotRealized(rowData.notRealized, rowData.goal),
                );
              }}
            />
            <Column
              field="status"
              header="Status"
              sortable
              body={(rowData) => {
                return statusBodyTemplate(parseStatus(rowData.status));
              }}
            />
            <Column
              field="progress"
              header="Progresso"
              body={(rowData) => {
                return progressBodyTemplate(rowData.progress);
              }}
              sortable
              filterClear={filterClearTemplate}
              filterApply={filterApplyTemplate}
              filterMatchMode="contains"
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              maxConstraints={1}
            />
            <Column
              body={(rowData) => {
                return (
                  <div className="p-d-flex p-jc-center">
                    <Button
                      className="button"
                      onClick={() => {
                        if (setSelectedClient && setToggleView) {
                          setSelectedClient(rowData);
                          setToggleView('SELECTED_CLIENT');
                        }
                      }}
                    >
                      Abrir
                    </Button>
                  </div>
                );
              }}
            />
          </DataTable>
        </div>
      </div>
    );
  }, [clients]);

  return renderClientSalesTarget();
}
