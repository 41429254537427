import { useEffect, useState } from 'react';
import moment from 'moment';

import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

import { IValidationError } from '../../../typings/yup.types';
import { ErrorsInput } from '../errors-input/ErrorsInput';
import { MandatoryAsterisk } from '../mandatory-asterisk/MandatoryAsterisk';

import './RangeDateInput.scss';

export interface DateInputProps {
  dates: Date[] | undefined;
  setDates: React.Dispatch<React.SetStateAction<Date[] | undefined>>;
  label: string;
  mandatory: boolean;
  errors?: Array<IValidationError>;
  errorCode?: string;
}

export const DateInput = (props: DateInputProps) => {
  const { dates, setDates, label, mandatory, errors, errorCode } = props;

  const [isInvalid, setIsInvalid] = useState(false);

  // eslint-disable-next-line
  const monthNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1, width: '10rem' }}
      />
    );
  };

  // eslint-disable-next-line
  const yearNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  useEffect(() => {
    if (
      errors &&
      errors.filter((error) => error.path === errorCode).length >= 1
    ) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
  }, [errors]);

  return (
    <div className="range-date-input">
      <label className={`${isInvalid && 'invalid'}`}>
        {label}
        {mandatory && <MandatoryAsterisk />}
      </label>
      <Calendar
        id="range"
        locale="pt"
        dateFormat="dd/mm/yy"
        value={dates}
        onChange={(e) => {
          setDates(Array.isArray(e.value) ? (e.value as Date[]) : undefined);
        }}
        selectionMode="range"
        readOnlyInput
        monthNavigator
        yearNavigator
        yearRange={`2020:${moment().year() + 2}`}
        monthNavigatorTemplate={monthNavigatorTemplate}
        yearNavigatorTemplate={yearNavigatorTemplate}
        className={`${isInvalid && 'invalid'}`}
      />
      {errors
        ?.filter((error) => error.path === errorCode)
        .map((errorComponent, index) => {
          return (
            <ErrorsInput
              key={String(index)}
              errorMessage={errorComponent.errors}
            />
          );
        })}
    </div>
  );
};
