// Importações Padrões
import React from 'react';

// React Prime
import { ProgressSpinner } from 'primereact/progressspinner';

export const ProgressSpinnerSys: React.FC = () => {
  return (
    <ProgressSpinner
      className="p-as-center"
      style={{ width: '35px', height: '35px' }}
      strokeWidth="8"
      fill="#EEEEEE"
      animationDuration=".5s"
    />
  );
};
