// Importações Padrões
import { useContext, useEffect, useState } from 'react';

// Contexto
import { Auth } from '../services/auth.context';

// Types
import { IClient } from '../typings/client.types';

// Controladores
import { clientCollection } from '../controllers/client.controller';

interface IUseClient {
  clientsList: Array<IClient>;
  pending: boolean;
}

export function useClientsList(): IUseClient {
  const { code3cData } = useContext(Auth);

  const [clientsList, setClientsList] = useState<Array<IClient>>([]);
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    setPending((state) => !state);
    const unsubscribe = clientCollection
      .where('code3c_id', '==', code3cData?.id)
      .where('systemId', '==', 'CFDV')
      .onSnapshot((response) => {
        const list: Array<IClient> = [];

        response.docs.forEach((doc) => {
          const documentData = { ...doc.data(), id: doc.id } as IClient;
          list.push(documentData);
        });
        setClientsList(list);
      });
    setPending((state) => !state);

    return () => {
      unsubscribe();
      setPending(false);
    };
  }, [code3cData?.id]);

  return { clientsList, pending };
}
