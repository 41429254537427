// Importações Padrões
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Icones
import {
  FaIndent,
  FaSignOutAlt,
  FaUserCircle,
  FaBell,
  FaCoffee,
} from 'react-icons/fa';

// React Prime
import { Badge } from 'primereact/badge';

// Contexto
import { Auth } from '../../services/auth.context';

// Componentes
import NotificationBox from './notificationBox.component';

// Funções
import { signOut } from '../../functions/auth.function';
import { parseNameRoute } from '../../functions/header.function';

// Estilos
import '../../styles/global/header.scss';

const Header = () => {
  const {
    currentUser,
    extender,
    toggleMenuSize,
    pathName,
    expireTime,
  } = useContext(Auth);

  const history = useHistory();

  const [visible, setVisible] = useState<boolean>(false);

  const toggleNotificationBox = useCallback(() => {
    setVisible((state) => !state);
  }, []);

  const toggleUserBox = () => {
    history.push('/configuracoes');
  };

  useEffect(() => {
    setVisible(false);
  }, [pathName]);

  return currentUser ? (
    <div className="header-display p-d-flex">
      <NotificationBox visible={visible} setVisible={setVisible} />
      {extender ? (
        <FaIndent
          className="p-as-center p-ml-3 header-button-extended"
          onClick={() => {
            if (toggleMenuSize) {
              toggleMenuSize();
            }
          }}
        />
      ) : (
        <FaIndent
          className="p-as-center p-ml-3 header-button-closed"
          onClick={() => {
            if (toggleMenuSize) {
              toggleMenuSize();
            }
          }}
        />
      )}
      <span className="p-as-center p-ml-3 header-text">
        {parseNameRoute(pathName)}
      </span>
      <div className="p-as-center p-d-flex p-ml-auto p-mr-3">
        {process.env.NODE_ENV === 'development' && (
          <FaCoffee className="p-as-center p-mr-3 header-develop-icon" />
        )}
        <div
          aria-hidden="true"
          className="header-notification-box"
          onPointerDown={(e) => {
            e.buttons === 1 && toggleNotificationBox();
          }}
        >
          <FaBell
            className={
              !expireTime
                ? 'p-as-center header-notification-bell'
                : 'p-as-center header-notification-bell-active'
            }
          />
          {expireTime && (
            <Badge value="1" className="header-notification-badge" />
          )}
        </div>
        <FaUserCircle
          className="p-as-center p-ml-3 header-button-user"
          onClick={() => {
            toggleUserBox();
          }}
        />
        <FaSignOutAlt
          className="p-as-center p-ml-3 header-signout-button"
          onClick={() => {
            signOut();
          }}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Header;
