// Importações Padrões
import Compress from 'react-image-file-resizer';

// Configurações
import firebaseConfig from '../config/config.firebase';

// Componentes
import { toasterError } from '../components/global/toaster.component';

function generatorImageURL(photoName: string) {
  return `https://firebasestorage.googleapis.com/v0/b/erp-cansho.appspot.com/o/CFDV%2F${photoName}?alt=media`;
}

function generatorAllImageURL(photoName: string) {
  return `https://firebasestorage.googleapis.com/v0/b/erp-cansho.appspot.com/o/all%2F${photoName}?alt=media`;
}

function generatorProductBase(photoName: string) {
  return `https://firebasestorage.googleapis.com/v0/b/erp-cansho.appspot.com/o/productBase%2F${photoName}?alt=media`;
}

const validFileExtensions = ['.jpg', '.jpeg', '.bmp', '.gif', '.png'];

function ValidateSingleInput(oInput: EventTarget & HTMLInputElement) {
  if (oInput.type === 'file') {
    const sFileName = oInput.value;
    if (sFileName.length > 0) {
      let blnValid = false;
      for (let j = 0; j < validFileExtensions.length; j += 1) {
        const sCurExtension = validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length,
            )
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }
      if (!blnValid) {
        toasterError('ERRO', 'O seu arquivo não é uma imagem válida!');
        return false;
      }
    }
  }
  return true;
}

function previewImage(
  file: File,
  setImage: React.Dispatch<React.SetStateAction<string>>,
  setImageRes: React.Dispatch<React.SetStateAction<boolean>>,
) {
  Compress.imageFileResizer(
    file, // The file to be resized.
    400, // The Width of the new File
    400, // The Heigth of the new File
    'PNG', // The Compress Format: WEBP || JPEG || PNG
    80, // The Quality of the new File
    0, // The Rotation of the new File
    (uri) => {
      setImage(uri as string);
      setImageRes(true);
    },

    'base64', // The Returning Format: blob || base64
  );
}

async function getURLFromDB(
  editImage: boolean,
  imgBase64: File | string,
  imgOnStorage: string,
  code3c_id: string,
  collectionName: string,
) {
  if (editImage) {
    if (imgBase64 !== imgOnStorage && collectionName !== 'FDV-Product') {
      try {
        await firebaseConfig.storage().ref(`CFDV/${imgOnStorage}`).delete();
      } catch (err) {
        console.log(err);
      }
      const milsecDate = new Date();
      const name = `${collectionName}-${code3c_id}-${milsecDate.getTime()}.jpg`;
      const storageRef = firebaseConfig.storage().ref(`CFDV/${name}`);
      await storageRef.putString(imgBase64 as string, 'data_url');
      return name;
    }
    return imgOnStorage;
  }
  const milsecDate = new Date();
  const name = `${collectionName}-${code3c_id}-${milsecDate.getTime()}.jpg`;
  const storageRef = firebaseConfig.storage().ref(`CFDV/${name}`);
  await storageRef.putString(imgBase64 as string, 'data_url');
  return name;
}

const deleteImage = async (imgOnStorage: string) => {
  try {
    if (imgOnStorage !== '') {
      await firebaseConfig.storage().ref(`CFDV/${imgOnStorage}`).delete();
    }
  } catch (err) {
    console.log(err);
  }
};

export {
  generatorImageURL,
  generatorAllImageURL,
  generatorProductBase,
  ValidateSingleInput,
  previewImage,
  getURLFromDB,
  deleteImage,
};
