import React, { useCallback, useContext } from 'react';

import moment from 'moment';
import XLSX from 'xlsx';
import { maskBr } from 'js-brasil';

import { FaFileCsv, FaFileDownload, FaFileExcel } from 'react-icons/fa';
import { Button } from 'primereact/button';
import { IProduct } from '../../typings/product.types';
import { parseUnitAbbreviation } from '../../functions/product.function';
import { Auth } from '../../services/auth.context';

interface IExportData {
  NOME: string;
  EAN?: string;
  ESTOQUE?: number;
  RESERVA?: number;
  TIPO?: string;
  'PREÇO MÍNIMO'?: string;
  'PREÇO MÁXIMO'?: string;
  DISPONIVEL_VENDA?: number;
  DISPONIVEL_RESERVA?: number;
  PRODUTO_BASE?: string;
}

const ExportExcelButton = (props: {
  format: string;
  productList: Array<IProduct>;
  options: Array<string>;
}) => {
  const { code3cData } = useContext(Auth);
  const { format, productList, options } = props;

  const formatIcon = useCallback(() => {
    switch (format) {
      case 'XLS':
      case 'XLSX': {
        return <FaFileExcel />;
      }
      case 'CSV': {
        return <FaFileCsv />;
      }
      default: {
        return <FaFileDownload />;
      }
    }
  }, [format]);

  const exportData = () => {
    const data = productList.map((product) => {
      const newItem: IExportData = {
        NOME: product.name,
        EAN: product.barCode,
        ESTOQUE: product.productAmount,
        DISPONIVEL_VENDA: product.saleAvailable,
        RESERVA: product.productReservation,
        DISPONIVEL_RESERVA: product.reservationAvailable,
        TIPO: parseUnitAbbreviation(product.unit),
        'PREÇO MÍNIMO': maskBr.currency(product.minPrice / 100),
        'PREÇO MÁXIMO': product.useMaxPrice
          ? maskBr.currency(product.maxPrice / 100)
          : '---',
        PRODUTO_BASE: product.useProductBase ? 'SIM' : 'NÂO',
      };

      if (options.indexOf('EAN') === -1) delete newItem.EAN;

      if (options.indexOf('ESTOQUE') === -1) delete newItem.ESTOQUE;
      if (options.indexOf('DISPONIVEL_VENDA') === -1) {
        delete newItem.DISPONIVEL_VENDA;
      }

      if (options.indexOf('RESERVA') === -1) delete newItem.RESERVA;
      if (options.indexOf('DISPONIVEL_RESERVA') === -1) {
        delete newItem.DISPONIVEL_RESERVA;
      }

      if (
        options.indexOf('ESTOQUE') === -1 &&
        options.indexOf('RESERVA') === -1
      ) {
        delete newItem.TIPO;
      }
      if (options.indexOf('PRECOS') === -1) {
        delete newItem['PREÇO MÍNIMO'];
        delete newItem['PREÇO MÁXIMO'];
      }

      if (options.indexOf('PRODUTO_BASE') === -1) delete newItem.PRODUTO_BASE;

      return newItem;
    });
    const fields = Object.keys(data[0]);

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { header: fields });
    XLSX.utils.book_append_sheet(wb, ws, 'Página 1');
    XLSX.writeFile(
      wb,
      `FDV-Produtos-${code3cData!.id}-${moment().format(
        'll',
      )}.${format.toLocaleLowerCase()}`,
    );
  };

  return (
    <div className="width100 p-d-flex">
      <Button
        className="p-mx-auto button-primary"
        disabled={format === ''}
        onClick={() => {
          exportData();
        }}
      >
        Exportar&nbsp;
        {formatIcon()}
      </Button>
    </div>
  );
};
export default ExportExcelButton;
