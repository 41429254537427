// Importações Padrões
import { maskBr } from 'js-brasil';

// React Prime
import { Tooltip } from 'primereact/tooltip';

interface IItemColProps {
  content: string | number;
  colGrid: number;
  price: number;
  productIndex: number;
}

const ItemColTaken = (props: IItemColProps) => {
  const { content, colGrid, price, productIndex } = props;

  return (
    <>
      <div
        className={`p-col-${colGrid} p-p-0 p-my-2 item-box-col p-text-center p-as-center `}
      >
        <p className="p-text-center p-col-12 p-m-0 p-d-inline p-my-0 p-p-0 p-mr-0">
          <Tooltip target={`.itemColOrderTaken-${productIndex}`} />

          {content !== 0 ? (
            <u>
              <i
                className={`p-d-flex p-jc-center p-as-center cursor-pointer itemColOrderTaken-${productIndex}`}
                data-pr-tooltip={`Preço Unitário: ${maskBr.currency(
                  price / 100,
                )}`}
                data-pr-position="bottom"
              >
                {content}
              </i>
            </u>
          ) : (
            <>{content}</>
          )}
        </p>
      </div>
    </>
  );
};

export default ItemColTaken;
